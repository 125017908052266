import { Spin } from 'antd';
import { useAuthContext } from 'context/AuthContext';
import { EStorageKey, Routes } from 'enums';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from './Header/Header';

export function AuthorizedLayout() {
  const { isLogged, permissions } = useAuthContext();

  if (!isLogged) {
    return <Navigate to={Routes.EAuthRoutes.LOGIN_PAGE} />;
  }

  if (!permissions.length)
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spin size="large" />
      </div>
    );

  return (
    <>
      <Header />
      <main>
        <Suspense
          fallback={
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
              <Spin size="large" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </main>
    </>
  );
}

export function Layout() {
  const { isLogged } = useAuthContext();
  const redirectTo = sessionStorage.getItem(EStorageKey.REDIRECT_TO);

  if (isLogged) {
    return <Navigate to={redirectTo || Routes.EHomeRoutes.INDEX} replace />;
  }

  return (
    <>
      <main>
        <Suspense
          fallback={
            <div className="h-100 d-flex flex-column align-items-center justify-content-center">
              <Spin />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </main>
    </>
  );
}
