import { IPermissionDataSource } from 'data/Permission/PermissionDataSource';
import { TDataResponseEOSS, TPermission } from 'models';
import { TAxiosClient } from 'services/axios';
import orderBy from 'lodash/orderBy';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Represents a remote data source implementation for handling permission data.
 * @author thai.ngo@zien.vn
 */
export class PermissionRemoteDataSourceImpl implements IPermissionDataSource {
  httpService: TAxiosClient;

  private static instance: PermissionRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of PermissionRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {PermissionRemoteDataSourceImpl} The singleton instance of PermissionRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): PermissionRemoteDataSourceImpl {
    if (!PermissionRemoteDataSourceImpl.instance) {
      PermissionRemoteDataSourceImpl.instance = new PermissionRemoteDataSourceImpl(httpService);
    }
    return PermissionRemoteDataSourceImpl.instance;
  }

  async getListPermission(): Promise<TDataResponseEOSS<TPermission[]>> {
    const { data, status } = await this.httpService.get<TPermission[]>('/permissions/admin/all');
    return {
      data: orderBy(mapSnakeCaseToCamelCase(data), 'name', 'asc'),
      code: status
    };
  }

  async getListEyeviewPermission(): Promise<TDataResponseEOSS<TPermission[]>> {
    const { data, status } = await this.httpService.get<TPermission[]>('/permissions/eyeview/all');
    return {
      data: orderBy(mapSnakeCaseToCamelCase(data), 'name', 'asc'),
      code: status
    };
  }
}
