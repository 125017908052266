export default {
  domainPage: {
    breadcrumb: {
      listing: 'Domain Management',
      create: 'Create Domain'
    },
    sections: {
      search: {
        title: 'Search Domain',
        instruction: 'Search by domain name'
      },
      create: {
        title: 'Create Domain'
      },
      listing: {
        title: 'Domain List',
        emptyDescription: 'By searching domain name'
      },
      general: {
        title: 'General'
      }
    },
    entity: 'Domain',
    table: {
      columns: {
        name: 'domain',
        valid: 'valid',
        comment: 'comment'
      }
    },
    '404': {
      title: 'Not Found',
      subTitle: 'Domain with this ID has not existed yet'
    }
  }
};
