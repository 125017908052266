import { Form, Result } from 'antd';
import { ERoleKey, Routes } from 'enums';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useAdminDetailPageController } from './AdminDetailPageController';
import { useLayoutEffect } from 'react';
import AdminGeneral from './components/AdminGeneral';
import PermissionSection from './components/PermissionSection';
import styles from './adminDetailPage.module.scss';
import { FormAction } from 'presentation/components';

function AdminDetailPageView() {
  const navigate = useNavigate();
  const {
    listLanguage,
    currentAdminUser,
    form,
    isDomainAdminType,
    currentAdminType,
    listPermissionsAdditional,
    listPermission,
    canSelfInvalid,
    isWeightLower,
    setListPermissionsAdditional,
    handleSubmit
  } = useAdminDetailPageController();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { scrollToTop } = useOutletContext<{ scrollToTop: () => void }>();

  const isAllowSubmit = usePermission(ERoleKey.UPDATE).allowed;

  useLayoutEffect(() => scrollToTop(), []);

  function handleSelectAdditionalPermission(option: { label: string; value: number }) {
    setListPermissionsAdditional((prev) =>
      prev.map((permission) => {
        if (permission.id === option.value) {
          return {
            ...permission,
            id: option.value,
            displayName: option.label
          };
        }
        return permission;
      })
    );
  }

  function handleRemovePermission(id: number) {
    setListPermissionsAdditional((prev) => prev.filter((permission) => permission.id !== id));
  }

  if (!currentAdminUser)
    return (
      <Result
        status="404"
        title={t('adminUserPage.404.title')}
        subTitle={t('adminUserPage.404.subTitle')}
      />
    );

  return (
    <div
      className={`${styles.container}`}
      style={{
        paddingRight: 10
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit}
        scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
        className="row"
        colon={false}
        labelCol={{
          style: {
            width: 135
          }
        }}
      >
        <div className="col-12 col-md-8">
          <AdminGeneral
            listLanguage={listLanguage}
            isDomainAdminType={isDomainAdminType}
            editMode
            disableValidField={isWeightLower || canSelfInvalid}
          />
        </div>
        {currentAdminType && (
          <div className="col-12 col-md-4">
            <PermissionSection
              currentAdminType={currentAdminType}
              isDomainAdminType={isDomainAdminType}
              listPermission={listPermission}
              listPermissionsAdditional={listPermissionsAdditional}
              onRemovePermission={handleRemovePermission}
              onSelectPermission={handleSelectAdditionalPermission}
            />
          </div>
        )}

        <FormAction
          onCancel={() => {
            navigate(
              {
                pathname: Routes.EAdminRoutes.LISTING,
                search: searchParams.toString()
              },
              { replace: true }
            );
          }}
          onSubmit={() => {
            form.submit();
          }}
          isAllowSubmit={isAllowSubmit}
        />
      </Form>
    </div>
  );
}

export default AdminDetailPageView;
