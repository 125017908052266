const DropdownFilterIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4907 3.16438C8.17004 2.90553 5.82776 2.90553 3.50707 3.16438C3.47149 3.16846 3.43766 3.18201 3.40911 3.20363C3.38055 3.22524 3.35832 3.25412 3.34473 3.28725C3.33115 3.32038 3.3267 3.35656 3.33186 3.39199C3.33702 3.42743 3.3516 3.46083 3.37407 3.48872L5.42565 6.02505C6.16051 6.93353 6.56142 8.06665 6.5614 9.23513V10.9204L7.4364 11.562V9.23455C7.43652 8.06626 7.83742 6.93338 8.57215 6.02505L10.6237 3.48872C10.6462 3.46083 10.6608 3.42743 10.6659 3.39199C10.6711 3.35656 10.6667 3.32038 10.6531 3.28725C10.6395 3.25412 10.6173 3.22524 10.5887 3.20363C10.5601 3.18201 10.5263 3.16846 10.4907 3.16438ZM3.41024 2.29463C5.79545 2.02809 8.20294 2.02809 10.5882 2.29463C11.4317 2.38913 11.8382 3.37847 11.3045 4.0388L9.2529 6.57513C8.64381 7.32787 8.31147 8.26683 8.3114 9.23513V12.4254C8.31146 12.5063 8.28908 12.5856 8.24676 12.6546C8.20443 12.7235 8.14382 12.7794 8.07165 12.816C7.99948 12.8525 7.91858 12.8684 7.83795 12.8617C7.75732 12.8551 7.68011 12.8262 7.6149 12.7783L5.8649 11.495C5.80953 11.4543 5.76451 11.4011 5.7335 11.3398C5.70249 11.2785 5.68635 11.2108 5.6864 11.142V9.23455C5.6864 8.26622 5.3539 7.32763 4.74549 6.57455L2.6939 4.03938C2.15957 3.37905 2.56557 2.38913 3.41024 2.29463Z"
        fill="black"
      />
    </svg>
  );
};

export { DropdownFilterIcon };
