import { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { TAdminUser, TDevice, TPaginationEOSS, TSortOrder } from 'models';
import { SortIcon } from 'presentation/components';
import { EStatusInvalid, EStatusString } from 'enums';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import { TOOLTIP_DELAY } from 'constant';
import styles from './tableListAdmin.module.scss';
import { SmartTooltip } from 'presentation/components';
import { useLoaderContext } from 'context/LoaderContext';
import { concatURLSearchParams } from 'utils/common';

type Props = {
  isLoading: boolean;
  listAdminUsers: TAdminUser[];
  sortKey: string | null;
  order: TSortOrder;
  searchText: string | null;
  onChangeSort?: (order?: string | null, columnKey?: string) => void;
  paging: TPaginationEOSS;
};

function TableListAdmin({
  listAdminUsers,
  sortKey,
  order,
  onChangeSort,
  isLoading,
  paging,
  searchText
}: Props) {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();

  const { loader } = useLoaderContext();

  const [clickedId, setClickedId] = useState<number>();

  useEffect(() => {
    return () => {
      setClickedId(undefined);
    };
  }, []);

  const columns: ColumnsType<TAdminUser> = useMemo(() => {
    return [
      {
        title: () => t('adminUserPage.table.columns.login'),
        dataIndex: 'username',
        key: 'username',
        render: (value, record) => {
          return (
            <Link
              data-name="username"
              to={{
                pathname: `${record.id}`,
                search: searchText
                  ? concatURLSearchParams({
                      search: searchText,
                      page: `${paging.pageNum}`,
                      sortKey,
                      order
                    })
                  : ''
              }}
              style={{
                pointerEvents: record.id === clickedId ? 'none' : 'auto'
              }}
              onClick={() => {
                loader.start();
                setClickedId(record.id);
              }}
            >
              {value}
            </Link>
          );
        }
      },
      {
        title: () => t('adminUserPage.table.columns.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        render: (value: string) => {
          return (
            <SmartTooltip
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              <span data-name="firstName">{value}</span>
            </SmartTooltip>
          );
        }
      },
      {
        title: () => t('adminUserPage.table.columns.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
        render: (value: string) => {
          return (
            <SmartTooltip
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              <span data-name="lastName">{value}</span>
            </SmartTooltip>
          );
        }
      },
      {
        title: () => t('adminUserPage.table.columns.domain'),
        dataIndex: 'domain',
        key: 'domain',
        render: (_, record) => {
          return <span data-name="domain">{record.domain?.name}</span>;
        }
      },
      {
        title: () => t('adminUserPage.table.columns.adminType'),
        dataIndex: 'adminType',
        key: 'adminType',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'adminType' ? order : null,
        sorter: (a, b) => b.adminType.displayName.length - a.adminType.displayName.length,
        render: (_, record) => {
          return <span data-name="adminType">{record?.adminType?.displayName}</span>;
        }
      },
      {
        title: () => t('adminUserPage.table.columns.valid'),
        dataIndex: 'invalid',
        key: 'invalid',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'invalid' ? order : null,
        sorter: (a, b) => a.invalid - b.invalid,
        render: (value, record) => {
          return record.invalid === EStatusInvalid.INVALID ? (
            <span className="text-uppercase" style={{ color: '#F46363' }}>
              {EStatusString.INVALID}
            </span>
          ) : (
            <span className="text-uppercase" style={{ color: '#17a235' }}>
              {EStatusString.VALID}
            </span>
          );
        }
      }
    ];
  }, [order, sortKey, searchText, paging.pageNum, clickedId]);
  return (
    <Table
      id="adminPage_listingTable"
      className={styles.container}
      tableLayout="auto"
      loading={isLoading}
      rowKey="id"
      dataSource={listAdminUsers}
      columns={columns}
      bordered={false}
      rowClassName={(_, index) => `${index % 2 ? 'row-even' : 'row-odd'}`}
      scroll={
        listAdminUsers.length > 0
          ? {
              x: 'auto',
              y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
            }
          : {
              x: 'min-content'
            }
      }
      rowHoverable={false}
      pagination={{
        pageSize: paging.pageLimit,
        current: paging.pageNum,
        total: paging.total
      }}
      onChange={(pagination, filters, sorter) => {
        const sorterValue = sorter as SorterResult<TDevice>;
        onChangeSort && onChangeSort(sorterValue.order, sorterValue.columnKey?.toString());
      }}
    />
  );
}

export default TableListAdmin;
