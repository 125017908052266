import { Button, Form, FormInstance, Input } from 'antd';
import React, { FC } from 'react';
import { TBindCamera } from '../cameraGroupPage.types';
import styles from '../cameraGroupPage.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  form: FormInstance<TBindCamera>;
  isSubmitting: boolean;
  onBindCamera: (serials: string) => void;
};

const BindAction: FC<Props> = ({ form, isSubmitting, onBindCamera }) => {
  const { t } = useTranslation();

  return (
    <div id="deviceGroupPage_bindAction" className="d-flex flex-column">
      <Form
        form={form}
        onFinish={(values: TBindCamera) => {
          const { serials } = values;
          if (serials) {
            onBindCamera(serials);
          }
        }}
      >
        <div className={`${styles.content} fs-12 fw-normal text-black mt-2 mb-3`}>
          {t('cameraGroupPage.sections.bind.description')}
        </div>
        <Form.Item<TBindCamera> name="serials" className="mb-3">
          <Input
            className={`${styles.actionInputNoIcon}`}
            placeholder={t('cameraGroupPage.sections.bind.placeholder')}
          />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button
            className={`${styles.actionBtn} btn-fill`}
            htmlType="submit"
            disabled={isSubmitting}
            onClick={() => {
              const event = new CustomEvent('close-sidebar-action');
              document.dispatchEvent(event);
            }}
          >
            <span className={styles.btnContent}>{t('components.bind')}</span>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(BindAction);
