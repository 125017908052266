import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import React, { memo, useEffect, useRef } from 'react';
import styles from './toggleOption.module.scss';
import useResponsive from 'hooks/useResponsive';

type Props = {
  className?: string;
  value: string;
  stretch?: boolean;
  options: (string | number)[];
  onToggleChange: (value: SegmentedValue) => void;
};

const ToggleOption = memo(
  ({ className, value, stretch = false, options, onToggleChange }: Props) => {
    const { isMobile } = useResponsive();

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (!stretch) {
        if (!isMobile) {
          ref.current?.style.setProperty('--container-width', '100px');
        } else {
          ref.current?.style.setProperty('--container-width', '80px');
        }
      }
    }, [isMobile, stretch]);

    return (
      <div
        ref={ref}
        className={`${stretch ? styles.stretchContainer : styles.container} ${className ?? ''}`}
        style={
          {
            '--stretch-background-color': stretch ? 'var(--primary-4)' : 'var(--primary-3)'
          } as React.CSSProperties
        }
      >
        <Segmented options={options} value={value} onChange={onToggleChange} />
      </div>
    );
  }
);

export { ToggleOption };
