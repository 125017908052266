export function EyeClose() {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.09979 4.16438L7.19811 6.06646C7.19489 6.03244 7.19489 5.99829 7.19811 5.9644C7.19811 5.48694 7.38771 5.02919 7.72514 4.69154C8.06271 4.35404 8.52038 4.1644 8.99774 4.1644L9.09979 4.16438ZM10.7974 5.86232L8.89569 7.76439H8.99773C9.47509 7.76439 9.93275 7.57475 10.2703 7.23725C10.6078 6.89961 10.7974 6.44185 10.7974 5.96439C10.8006 5.9305 10.8006 5.89635 10.7974 5.86233L10.7974 5.86232ZM17.2881 6.31836C17.1321 6.52233 13.5568 11.3645 8.99774 11.3645C7.96122 11.3482 6.93954 11.1153 5.99835 10.6806L7.92399 8.75453C8.26557 8.89141 8.62979 8.96266 8.99774 8.96453C9.79325 8.96453 10.5561 8.64846 11.1186 8.08582C11.6811 7.52319 11.9971 6.7602 11.9971 5.96453C11.9982 5.58844 11.9268 5.21572 11.7872 4.86659L14.1507 2.50262V2.50248C15.3325 3.38949 16.3863 4.43535 17.2821 5.61056C17.3571 5.71329 17.3977 5.8373 17.3977 5.96453C17.3977 6.09175 17.3571 6.21578 17.2821 6.3185L17.2881 6.31836ZM6.20813 7.06232L3.84465 9.42643C2.66282 8.53943 1.60902 7.49357 0.713213 6.31836C0.638229 6.21563 0.597656 6.09161 0.597656 5.96439C0.597656 5.83716 0.638229 5.71314 0.713213 5.61042C0.863183 5.40645 4.43863 0.564236 8.99771 0.564236C10.0342 0.580575 11.0559 0.813477 11.9971 1.2482L10.0715 3.17424C9.72987 3.0375 9.36566 2.96625 8.99771 2.96424C8.2022 2.96424 7.43936 3.28032 6.87685 3.84295C6.31433 4.40559 5.99832 5.16858 5.99832 5.96425C5.99711 6.34033 6.06848 6.71319 6.20827 7.06219L6.20813 7.06232ZM4.02461 11.7903L14.8224 0.990306C14.9546 0.835752 14.9997 0.624819 14.942 0.429801C14.8844 0.234801 14.7319 0.0822462 14.537 0.0246803C14.3419 -0.0330433 14.131 0.0119571 13.9766 0.144279L3.17876 10.9443C3.05182 11.0531 2.97603 11.2099 2.96947 11.3769C2.96304 11.5439 3.02665 11.7061 3.14475 11.8244C3.26298 11.9425 3.42514 12.0061 3.59212 11.9995C3.75909 11.9931 3.91589 11.9173 4.02462 11.7903L4.02461 11.7903Z"
        fill="#4A61E3"
      />
    </svg>
  );
}
