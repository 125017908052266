const translation = {
  serverPage: {
    breadcrumb: {
      listing: 'Server Management'
    },
    tabs: {
      bvr: 'bvr',
      vas: 'vas',
      vrs: 'vrs'
    },
    sections: {
      search: {
        title: 'Search {{tabName}} Server',
        instruction: 'Search by server name'
      },
      create: {
        title: 'Create {{tabName}} Server'
      },
      listing: {
        title: '{{tabName}} Server List',
        emptyDescription: 'By searching server name'
      },
      general: {
        title: 'General'
      }
    },
    entity: 'Server',
    table: {
      columns: {
        type: 'type',
        serialNumber: 'serial number',
        version: 'version',
        serverName: 'server name',
        usage: 'usage',
        domain: 'domain',
        accountDevices: {
          customer: 'customer',
          devices: 'devices'
        },
        accountEyeviewUser: 'customer / eyeview user',
        status: 'status',
        ipAddress: 'ip address',
        lastContacted: 'last contacted'
      }
    },
    '404': {
      title: 'Not Found',
      subTitle: 'Server with this ID has not existed yet'
    }
  }
};

export default translation;
