import { AxiosError } from 'axios';
import { IAccountTypeDataSource } from 'data/AccountType/AccountTypeDataSource';
import { IDeviceTypeDataSource } from 'data/DeviceType/DeviceTypeDataSource';
import { ILanguageDataSource } from 'data/Language/LanguageDataSource';
import { IPermissionDataSource } from 'data/Permission/PermissionDataSource';
import { IServerTypeDataSource } from 'data/ServerType/ServerTypeDataSource';
import { IServiceDataSource } from 'data/Service/ServiceDataSource';
import {
  TAccountType,
  TAdminUserType,
  TCameraGroupType,
  TDataResponseEOSS,
  TDeviceType,
  TLanguage,
  TPermission,
  TServerType,
  TService
} from 'models';
import { IEOSSGlobalRepository } from './EOSSGlobalRepository';
import { TDynamicResponse } from 'models/DynamicResponse';
import { INameMappingDataSource } from 'data/NameMapping/NameMappingDataSource';
import { convertAxiosErrorToApiError } from 'utils/common';
import { ICameraGroupTypeDataSource } from 'data/CameraGroupType/CameraGroupTypeDataSource';
import { IAdminTypeDataSource } from 'data/AdminType/AdminTypeDataSource';

/**
 * Represents a remote data source implementation for handling system type data.
 */
export class EOSSGlobalRepositoryImpl implements IEOSSGlobalRepository {
  serverTypeDataSource: IServerTypeDataSource;

  accountTypeDataSource: IAccountTypeDataSource;

  deviceTypeDataSource: IDeviceTypeDataSource;

  languageDataSource: ILanguageDataSource;

  permissionDataSource: IPermissionDataSource;

  servicesDataSource: IServiceDataSource;

  cameraGroupTypeDataSource: ICameraGroupTypeDataSource;

  nameMappingDataSource: INameMappingDataSource;

  adminTypeDataSource: IAdminTypeDataSource;

  private static instance: EOSSGlobalRepositoryImpl;

  constructor(
    serverTypeDataSource: IServerTypeDataSource,
    accountTypeDataSource: IAccountTypeDataSource,
    deviceTypeDataSource: IDeviceTypeDataSource,
    languageDataSource: ILanguageDataSource,
    permissionDataSource: IPermissionDataSource,
    servicesDataSource: IServiceDataSource,
    cameraGroupTypeDataSource: ICameraGroupTypeDataSource,
    nameMappingDataSource: INameMappingDataSource,
    adminTypeDataSource: IAdminTypeDataSource
  ) {
    this.serverTypeDataSource = serverTypeDataSource;
    this.accountTypeDataSource = accountTypeDataSource;
    this.deviceTypeDataSource = deviceTypeDataSource;
    this.languageDataSource = languageDataSource;
    this.permissionDataSource = permissionDataSource;
    this.servicesDataSource = servicesDataSource;
    this.nameMappingDataSource = nameMappingDataSource;
    this.cameraGroupTypeDataSource = cameraGroupTypeDataSource;
    this.adminTypeDataSource = adminTypeDataSource;
  }

  /**
   * Gets the singleton instance of EOSSGlobalRepositoryImpl.
   * @returns {EOSSGlobalRepositoryImpl} The singleton instance of EOSSGlobalRepositoryImpl.
   */
  public static getInstance(
    serverTypeDataSource: IServerTypeDataSource,
    accountTypeDataSource: IAccountTypeDataSource,
    deviceTypeDataSource: IDeviceTypeDataSource,
    languageDataSource: ILanguageDataSource,
    permissionDataSource: IPermissionDataSource,
    servicesDataSource: IServiceDataSource,
    cameraGroupTypeDataSource: ICameraGroupTypeDataSource,
    nameMappingDataSource: INameMappingDataSource,
    adminTypeDataSource: IAdminTypeDataSource
  ): EOSSGlobalRepositoryImpl {
    if (!EOSSGlobalRepositoryImpl.instance) {
      EOSSGlobalRepositoryImpl.instance = new EOSSGlobalRepositoryImpl(
        serverTypeDataSource,
        accountTypeDataSource,
        deviceTypeDataSource,
        languageDataSource,
        permissionDataSource,
        servicesDataSource,
        cameraGroupTypeDataSource,
        nameMappingDataSource,
        adminTypeDataSource
      );
    }
    return EOSSGlobalRepositoryImpl.instance;
  }

  async getCameraGroupType(): Promise<TCameraGroupType[]> {
    try {
      const { data } = await this.cameraGroupTypeDataSource.getCameraGroupTypes();
      return data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListServerType(): Promise<TServerType[]> {
    try {
      const request = await this.serverTypeDataSource.getListServerType();
      return request;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListAccountType(): Promise<TAccountType[]> {
    try {
      const request = await this.accountTypeDataSource.getListAccountType();
      return request;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListDeviceType(): Promise<TDeviceType[]> {
    try {
      const request = await this.deviceTypeDataSource.getListDeviceType();
      return request;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListLanguage(): Promise<TLanguage[]> {
    try {
      const response = await this.languageDataSource.getListLanguages();
      return response;
    } catch (error) {
      const err = error as AxiosError;
      throw JSON.stringify({ message: err.message, status: err.response?.status });
    }
  }

  async getListPermission(): Promise<TDataResponseEOSS<TPermission[]>> {
    try {
      const response = await this.permissionDataSource.getListPermission();
      return response;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListServices(): Promise<TService[]> {
    try {
      const response = await this.servicesDataSource.getListServices();
      return response;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getFieldDisplayNameMappings(): Promise<TDynamicResponse> {
    try {
      const response = await this.nameMappingDataSource.getFieldDisplayNameMappings();
      return response;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListAdminType(): Promise<TAdminUserType[]> {
    try {
      const response = await this.adminTypeDataSource.getListAdminTypes();
      return response;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
