type Props = {
  active?: boolean;
};

export function AdminIcon({ active = false }: Props) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          className="active-header-icon"
          d="M22.429 16.3081L23.7123 15.0247L20.3206 11.6331L15.004 16.9497C14.5456 16.6747 13.9956 16.5831 13.4456 16.5831C11.704 16.5831 10.2373 18.0497 10.2373 19.7914C10.2373 21.5331 11.704 22.9997 13.4456 22.9997C15.1873 22.9997 16.654 21.5331 16.654 19.7914C16.654 19.2414 16.4706 18.6914 16.2873 18.2331L18.029 16.4914L20.1373 18.5997L21.4206 17.3164L19.3123 15.2081L20.3206 14.1997L22.429 16.3081ZM13.4456 21.1664C12.7123 21.1664 12.0706 20.5247 12.0706 19.7914C12.0706 19.0581 12.7123 18.4164 13.4456 18.4164C14.179 18.4164 14.8206 19.0581 14.8206 19.7914C14.8206 20.5247 14.179 21.1664 13.4456 21.1664Z"
        />
        <path
          className="active-header-icon"
          d="M3.82064 18.4167C3.82064 14.8417 6.6623 12 10.2373 12C12.0706 12 13.8123 12.825 15.0956 14.2L16.4706 13.0083C15.6456 12.0917 14.729 11.3583 13.629 10.9C14.9123 9.89167 15.7373 8.24167 15.7373 6.5C15.7373 3.475 13.2623 1 10.2373 1C7.21231 1 4.7373 3.475 4.7373 6.5C4.7373 8.24167 5.5623 9.89167 6.9373 10.9C4.00397 12.1833 1.9873 15.025 1.9873 18.4167V23H9.32064V21.1667H3.82064V18.4167ZM6.57064 6.5C6.57064 4.48333 8.22064 2.83333 10.2373 2.83333C12.254 2.83333 13.904 4.48333 13.904 6.5C13.904 8.51667 12.254 10.1667 10.2373 10.1667C8.22064 10.1667 6.57064 8.51667 6.57064 6.5Z"
        />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        className="inactive-header-icon"
        d="M22.429 16.3081L23.7123 15.0247L20.3206 11.6331L15.004 16.9497C14.5456 16.6747 13.9956 16.5831 13.4456 16.5831C11.704 16.5831 10.2373 18.0497 10.2373 19.7914C10.2373 21.5331 11.704 22.9997 13.4456 22.9997C15.1873 22.9997 16.654 21.5331 16.654 19.7914C16.654 19.2414 16.4706 18.6914 16.2873 18.2331L18.029 16.4914L20.1373 18.5997L21.4206 17.3164L19.3123 15.2081L20.3206 14.1997L22.429 16.3081ZM13.4456 21.1664C12.7123 21.1664 12.0706 20.5247 12.0706 19.7914C12.0706 19.0581 12.7123 18.4164 13.4456 18.4164C14.179 18.4164 14.8206 19.0581 14.8206 19.7914C14.8206 20.5247 14.179 21.1664 13.4456 21.1664Z"
      />
      <path
        className="inactive-header-icon"
        d="M3.82064 18.4167C3.82064 14.8417 6.6623 12 10.2373 12C12.0706 12 13.8123 12.825 15.0956 14.2L16.4706 13.0083C15.6456 12.0917 14.729 11.3583 13.629 10.9C14.9123 9.89167 15.7373 8.24167 15.7373 6.5C15.7373 3.475 13.2623 1 10.2373 1C7.21231 1 4.7373 3.475 4.7373 6.5C4.7373 8.24167 5.5623 9.89167 6.9373 10.9C4.00397 12.1833 1.9873 15.025 1.9873 18.4167V23H9.32064V21.1667H3.82064V18.4167ZM6.57064 6.5C6.57064 4.48333 8.22064 2.83333 10.2373 2.83333C12.254 2.83333 13.904 4.48333 13.904 6.5C13.904 8.51667 12.254 10.1667 10.2373 10.1667C8.22064 10.1667 6.57064 8.51667 6.57064 6.5Z"
      />
    </svg>
  );
}
