import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Dropdown, MenuProps, Modal } from 'antd';
import {
  AccountIcon,
  AdminIcon,
  ArrowScrollLeftIcon,
  ArrowScrollRightIcon,
  CameraGroupIcon,
  DeviceIcon,
  DomainIcon,
  DropdownIcon,
  EyeviewSessionsIcon,
  HamburgerIcon,
  ServerIcon,
  SystemIcon,
  UserIcon
} from 'assets/icons';
import EyecastLogo from 'assets/images/eyecast-logo.png';
import { useAuthContext } from 'context/AuthContext';
import { checkPermission, usePermissionContext } from 'context/PermissionContext';
import { ERoleKey, EStorageKey, Routes } from 'enums';
import { Breadcrumb } from 'presentation/components';
import ProfilePageView from 'presentation/pages/ProfilePage/ProfilePageView';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './header.module.scss';
import { parseLocalUser } from 'utils/common';
import { useAppUtil } from 'context/UtilContext';
import { LogoutIcon } from 'assets/icons/LogoutIcon';
import { useDragNScroll } from 'hooks/useDragNScroll';
import { TbLayoutSidebarRightExpandFilled } from 'react-icons/tb';

type MenuItem = {
  id: number;
  name: string;
  icon: {
    active: React.ReactNode;
    inactive: React.ReactNode;
  };
  path: string;
  requiredRole?: ERoleKey;
};

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuthContext();
  const { permissionObj } = usePermissionContext();
  const { openNotification } = useAppUtil();

  const currentProfile = parseLocalUser(localStorage.getItem(EStorageKey.EOSS_CURRENT_USER));

  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState(false);

  useEffect(() => {
    const drawerClick = () => setDrawerOpen(true);
    document.addEventListener('customDrawer', drawerClick);
    return () => document.removeEventListener('customDrawer', drawerClick);
  }, []);

  const menuList = useMemo(
    () =>
      [
        {
          id: 1,
          name: t('header.admin'),
          icon: {
            active: <AdminIcon active />,
            inactive: <AdminIcon />
          },
          path: Routes.EAdminRoutes.LISTING,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 2,
          name: t('header.domain'),
          icon: {
            active: <DomainIcon active />,
            inactive: <DomainIcon />
          },
          path: Routes.EDomainRoutes.LISTING,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 3,
          name: t('header.account'),
          icon: {
            active: <AccountIcon active />,
            inactive: <AccountIcon />
          },
          path: Routes.EAccountRoutes.LISTING,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 4,
          name: t('header.devices'),
          icon: {
            active: <DeviceIcon active />,
            inactive: <DeviceIcon />
          },
          path: Routes.EDevicesRoutes.INDEX,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 5,
          name: t('header.cameraGroups'),
          icon: {
            active: <CameraGroupIcon active />,
            inactive: <CameraGroupIcon />
          },
          path: Routes.ECameraGroupRoutes.LISTING,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 6,
          name: t('header.user'),
          icon: {
            active: <UserIcon active />,
            inactive: <UserIcon />
          },
          path: Routes.EUserRoutes.LISTING,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 7,
          name: t('header.eyeviewSession'),
          icon: {
            active: <EyeviewSessionsIcon active />,
            inactive: <EyeviewSessionsIcon />
          },
          path: Routes.EEyeviewSessionsRoutes.LISTING,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 8,
          name: t('header.servers'),
          icon: {
            active: <ServerIcon active />,
            inactive: <ServerIcon />
          },
          path: Routes.EServerRoutes.INDEX,
          requiredRole: ERoleKey.VIEW
        },
        {
          id: 9,
          name: t('header.system'),
          icon: {
            active: <SystemIcon active />,
            inactive: <SystemIcon />
          },
          path: Routes.ESystemRoutes.INDEX,
          requiredRole: ERoleKey.VIEW
        }
      ].filter((menu) =>
        checkPermission({ permissionObj, routerName: menu.path, role: menu.requiredRole })
      ),
    [permissionObj]
  );

  const {
    ref,
    isOverflow,
    isActiveScrollLeftIcon,
    isActiveScrollRightIcon,
    scrollToBeginOffset,
    scrollToLastOffset
  } = useDragNScroll();

  const profileDropdown = useMemo(
    () =>
      [
        {
          key: 'header-profile-btn',
          style: { height: '50px' },
          icon: (
            <UserOutlined
              style={{
                fontSize: 20,
                color: 'var(--primary-1)'
              }}
            />
          ),
          label: <span className="m-1 text-black fw-bolder">{t('header.profile')}</span>,
          onClick: () => {
            openNotification({
              type: 'info',
              title: t('components.comingSoon')
            });
          }
          // TODO will implement in EP2
          // onClick: () => setOpenProfile(true)
        },
        {
          key: 'header-logout-btn',
          style: { height: '50px' },
          icon: (
            <LogoutOutlined
              style={{
                fontSize: 20,
                color: 'var(--primary-1)'
              }}
            />
          ),
          label: <span className="m-1 text-black fw-bolder">{t('header.logout')}</span>,
          onClick: () => {
            navigate(Routes.EAuthRoutes.LOGIN_PAGE, {
              replace: true
            });
            logout();
          }
        }
      ] as MenuProps['items'],
    []
  );

  return (
    <header className="position-relative">
      <div
        className={`${styles.banner} px-2 px-lg-5 pt-2 pt-md-1 pb-2 pb-md-0 justify-content-center justify-content-md-between position-relative gap-2 gap-lg-5`}
        style={{ transition: 'all 0.2s ease' }}
      >
        <div
          className="d-block d-md-none position-absolute start-0 ms-4 cursor-pointer"
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          <HamburgerIcon />
        </div>
        <div
          onClick={() => navigate(menuList[0].path)}
          className="cursor-pointer d-inline-flex align-self-center"
        >
          <img className={styles.logo} src={EyecastLogo} alt="Logo" />
        </div>
        {isOverflow && (
          <Button
            className={styles.hideOnMobile}
            shape="circle"
            type="text"
            size="small"
            disabled={!isActiveScrollLeftIcon}
            icon={<ArrowScrollLeftIcon isActive={isActiveScrollLeftIcon} />}
            onClick={() => scrollToBeginOffset()}
          />
        )}
        <div
          ref={ref}
          className="d-none d-md-flex align-items-center align-items-md-start gap-2 gap-lg-8 gap-xl-12 overflow-x-auto custom-scrollbar--hidden"
          style={{
            flex: 1
          }}
        >
          <nav className="d-none d-md-flex">
            <ul
              className="d-flex gap-3 gap-lg-7 gap-xl-12 p-0 pt-1 m-0"
              style={{ transition: 'all 0.2s ease' }}
            >
              {menuList.map((menuItem: MenuItem, index: number) => (
                <Link
                  key={index}
                  to={menuItem.path}
                  className={`d-flex h-100 mx-2 align-items-stretch position-relative ${
                    location.pathname.includes(menuItem.path) ? styles.itemActive : ''
                  }`}
                >
                  <div
                    className={`${styles.menuItem} d-flex align-items-center flex-column justify-content-center`}
                  >
                    <div>
                      {location.pathname.includes(menuItem.path)
                        ? menuItem.icon.active
                        : menuItem.icon.inactive}
                    </div>
                    <div>
                      <span className="text-nowrap user-select-none">{menuItem.name}</span>
                    </div>
                  </div>
                </Link>
              ))}
              <div
                className={styles.logoutButton}
                onClick={() => {
                  navigate(Routes.EAuthRoutes.LOGIN_PAGE, {
                    replace: true
                  });
                  logout();
                }}
              >
                <div
                  className={`${styles.menuItem} d-flex align-items-center flex-column justify-content-center`}
                >
                  <div>
                    <LogoutIcon />
                  </div>
                  <div>
                    <span className="text-nowrap user-select-none">{t('header.logout')}</span>
                  </div>
                </div>
              </div>
            </ul>
          </nav>
        </div>
        {isOverflow && (
          <Button
            className={`${styles.hideOnMobile} me-4`}
            shape="circle"
            type="text"
            size="small"
            disabled={!isActiveScrollRightIcon}
            icon={<ArrowScrollRightIcon isActive={isActiveScrollRightIcon} />}
            onClick={() => scrollToLastOffset()}
          />
        )}
        <Dropdown
          menu={{ items: profileDropdown }}
          trigger={['click']}
          destroyPopupOnHide
          forceRender={false}
        >
          <div
            className={`${styles.userDropdown} d-none d-md-flex align-items-center gap-3 px-3 mb-1`}
          >
            <div className={`${styles.userInfo}`}>
              <div className={`fs-14 fw-semibold ${styles.userName}`}>
                {currentProfile?.username}
              </div>
              <div className={`fs-12 ${styles.role}`} style={{ color: 'var(--primary-5)' }}>
                {currentProfile?.adminTypeDisplayName}
              </div>
            </div>
            <div>
              <DropdownIcon color="var(--sub-1)" />
            </div>
          </div>
        </Dropdown>
      </div>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setDrawerOpen(false)}
        key="left"
        open={drawerOpen}
        width="80%"
      >
        <div>
          <div className="px-4 my-6">
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div>
                <div className="fs-16 fw-semibold" style={{ color: 'var(--primary-1)' }}>
                  {currentProfile?.username}
                </div>
                <div className="fs-14" style={{ color: 'var(--primary-1)' }}>
                  {currentProfile?.adminTypeDisplayName}
                </div>
              </div>
              <div
                onClick={() => setDrawerOpen(false)}
                className="rounded p-1"
                style={{ background: 'var(--primary-3)' }}
              >
                <IoIosArrowForward size={24} color="var(--primary-1)" />
              </div>
            </div>
          </div>
        </div>
        <Divider className="m-0" style={{ backgroundColor: 'var(--primary-5)' }} />
        <div className="my-6">
          {menuList.map((menu) => {
            const isActive = location.pathname.includes(menu.path);
            return (
              <Link key={menu.id} to={menu.path} style={{ color: 'inherit' }}>
                <div
                  className="d-flex align-items-center px-4 py-5"
                  style={{
                    backgroundColor: `${isActive ? 'var(--primary-3)' : ''}`
                  }}
                  onClick={() => setDrawerOpen(false)}
                >
                  <div className="me-3">{isActive ? menu.icon.active : menu.icon.inactive}</div>
                  <div
                    className="fs-16 fw-semibold"
                    style={{ color: `${isActive ? 'var(--primary-1)' : ''}` }}
                  >
                    {menu.name}
                  </div>
                </div>
              </Link>
            );
          })}
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(Routes.EAuthRoutes.LOGIN_PAGE, {
                replace: true
              });
              logout();
            }}
          >
            <div
              className="d-flex align-items-center px-4 py-5"
              onClick={() => setDrawerOpen(false)}
            >
              <div className="me-3">
                <LogoutIcon isMobile />
              </div>
              <div className="fs-16 fw-semibold">{t('header.logout')}</div>
            </div>
          </div>
        </div>
      </Drawer>
      <Breadcrumb />
      <Modal
        key={'header-profile-modal'}
        title={<span className="text-black fs-4">{t('header.profile')}</span>}
        open={openProfile}
        onCancel={() => setOpenProfile(false)}
        footer={null}
        destroyOnClose
        forceRender={false}
        maskClosable={false}
      >
        <ProfilePageView />
      </Modal>
      <div
        onClick={() => {
          const event = new CustomEvent('open-sidebar-action');
          document.dispatchEvent(event);
        }}
        className={`${styles.drawerAction} position-absolute top-0 end-0 me-2 mt-2`}
        style={{ cursor: 'pointer' }}
      >
        <TbLayoutSidebarRightExpandFilled color="white" size={24} />
      </div>
    </header>
  );
}
