import { Form } from 'antd';
import { ESubFeatureKey, Routes } from 'enums';
import { LeftColumn, PermissionWrapper, RightColumn, Section } from 'presentation/components';
import { Outlet, useMatch } from 'react-router-dom';
import { useCameraGroupsPageController } from './CameraGroupsPageController';
import styles from './cameraGroupPage.module.scss';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { TCameraBinding, TEyeviewUser } from 'models';
import { useAppUtil } from 'context/UtilContext';
import { DECOMMISSIONED } from 'constant';
import useFlag from 'hooks/useFlag';
import {
  SearchAction,
  SyncAction,
  AssignAction,
  DeleteAction,
  CreateAction,
  BindAction
} from './components';

const CameraGroupsPageView = () => {
  const { t } = useTranslation();

  const { openModal } = useAppUtil();

  const {
    displaySyncToggle,
    searchForm,
    bindCameraForm,
    syncCameraForm,
    assignEyeviewUserForm,
    cameraGroupId,
    cameraBindingSwitchValue,
    assignedEyeviewUserList,
    accountList,
    currentCameraGroup,
    refetchCountRef,
    eyeviewUserList,
    actionStatus,
    deviceBindingExtraOptions,
    onDeleteCameraGroup,
    onSearchChange,
    onCreateCameraGroupClick,
    onSearchSubmit,
    onSyncCameraFormSubmit,
    onBindCameraFormSubmit,
    onAssignEyeviewUser,
    onChangeCurrentCamera,
    onResetActionStatus,
    onCameraBindingSwitchChange,
    resetBindForm
  } = useCameraGroupsPageController();

  const isShowDetailActions = !!cameraGroupId && currentCameraGroup;

  const [isBindSubmitting, markAsBindSubmitting, markAsBindNotSubmitting] = useFlag(false);
  const [isSyncSubmitting, markAsSyncSubmitting, markAsSyncNotSubmitting] = useFlag(false);
  const [isAssignSubmitting, markAsAssignSubmitting, markAsAssignNotSubmitting] = useFlag(false);
  const [isDeleteSubmitting, markAsDeleteSubmitting, markAsDeleteNotSubmitting] = useFlag(false);

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);

  // 2024-02-09 Feedback: Create Action should be shown in both Listing and Detail page
  const isInListingPage = useMatch(Routes.ECameraGroupRoutes.LISTING);
  const isInDetailPage = useMatch(Routes.ECameraGroupRoutes.DETAILED);

  const currentCameraIdSelected = Form.useWatch('cameraId', syncCameraForm);
  const currentCameraSelectedInSync = currentCameraGroup?.cameras.find(
    (camera) => camera?.id === Number(currentCameraIdSelected)
  );

  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  // check create because useMatch understands /camera-group/create as /camera-group/:adminUserId
  const canShowCreateAction =
    isInListingPage ||
    (isInDetailPage && isInDetailPage.pathname != Routes.ECameraGroupRoutes.CREATE);

  const filterAssignedEyeviewUserList = useMemo(
    () => eyeviewUserList.data.filter((data) => !(assignedEyeviewUserList ?? []).includes(data.id)),
    [eyeviewUserList, assignedEyeviewUserList]
  );

  const handleBindCamera = useCallback(
    (serialNumber: string) => {
      markAsBindSubmitting();
      const isSingle = serialNumber.split(',').length === 1;
      openModal({
        title: t('components.confirmationTitle'),
        content: t(`cameraGroupPage.action.${isSingle ? 'bind' : 'binds'}`),
        okText: t('components.bind'),
        cancelText: t('components.cancel'),
        styles: {
          content: {
            width: 380
          }
        },
        onOk: () =>
          onBindCameraFormSubmit({
            serialNumber,
            cameraGroupId,
            callback: () => markAsBindNotSubmitting()
          }),
        onCancel: () => markAsBindNotSubmitting()
      });
    },
    [cameraGroupId]
  );

  const handleSyncCameraConfig = useCallback(
    (cameraId: string) => {
      markAsSyncSubmitting();
      const camera = currentCameraGroup?.cameras.find(
        (c: TCameraBinding) => c?.id === Number(cameraId)
      );
      openModal({
        title: t('components.confirmationTitle'),
        content: (
          <>
            <div>{t('cameraGroupPage.action.sync')}</div>
            <div>
              <span className="fw-medium">{camera?.serialNumber}</span>
              {'?'}
            </div>
          </>
        ),
        okText: t('components.ok'),
        cancelText: t('components.cancel'),
        styles: {
          content: {
            width: 'auto'
          }
        },
        width: 'fit-content',
        onOk: () => onSyncCameraFormSubmit(cameraId, () => markAsSyncNotSubmitting()),
        onCancel: () => markAsSyncNotSubmitting()
      });
    },
    [currentCameraGroup]
  );

  const handleAssignEyeviewUser = useCallback(
    (eyeviewUserId: number) => {
      markAsAssignSubmitting();
      const eyeviewUsername = eyeviewUserList.data.find(
        (value: TEyeviewUser) => value?.id === eyeviewUserId
      )?.username;
      openModal({
        title: t('components.confirmationTitle'),
        content: (
          <>
            <div>{t('cameraGroupPage.action.assign.from')}</div>
            <div>
              <span className="fw-medium">{currentCameraGroup?.name ?? ''}</span>{' '}
              {t('cameraGroupPage.action.assign.to')}{' '}
              <span className="fw-medium">{eyeviewUsername}</span>
              {'?'}
            </div>
          </>
        ),
        okText: t('components.ok'),
        cancelText: t('components.cancel'),
        styles: {
          content: {
            width: 'auto'
          }
        },
        width: 'fit-content',
        onOk: () => onAssignEyeviewUser(eyeviewUserId, () => markAsAssignNotSubmitting()),
        onCancel: () => markAsAssignNotSubmitting()
      });
    },
    [eyeviewUserList, currentCameraGroup]
  );

  const handleDeleteCameraGroup = useCallback(() => {
    markAsDeleteSubmitting();
    openModal({
      title: t('components.confirmationTitle'),
      content: t('components.confirmationMessage', {
        action: t('components.delete').toLowerCase(),
        entity: t('cameraGroupPage.entity').toLowerCase()
      }),
      okText: t('components.delete'),
      cancelText: t('components.cancel'),
      onOk: () => onDeleteCameraGroup(Number(cameraGroupId), () => markAsDeleteNotSubmitting()),
      onCancel: () => markAsDeleteNotSubmitting(),
      wrapClassName: styles.confirmModal
    });
  }, [cameraGroupId]);

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  return (
    <div className={`wrap-container overflow-hidden ${styles.container}`}>
      <div className="h-100 w-100 position-relative">
        <LeftColumn
          withCollapse
          leftColumnOpen={leftColumnOpen}
          onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
          onMouseMove={() => toolTipRef.current?.onShowTooltip()}
          onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
        >
          <Section title={t('cameraGroupPage.sections.search.title')} classNameBody="p-4">
            <SearchAction
              form={searchForm}
              accountList={accountList}
              onSearchChange={onSearchChange}
              onSearchSubmit={onSearchSubmit}
            />
          </Section>

          {canShowCreateAction && (
            <PermissionWrapper.Create>
              <Section title={t('cameraGroupPage.sections.create.title')} classNameBody="px-4 py-5">
                <CreateAction onCreateCameraGroupClick={onCreateCameraGroupClick} />
              </Section>
            </PermissionWrapper.Create>
          )}

          {isShowDetailActions && (
            <>
              {/**
               * TODO: CLONE CAMERA GROUP IS REMOVED IN EP1, WILL SUPPORT AS SOON AS BACKEND POSSIBLE
               */}
              {/* <PermissionWrapper.Create>
                  <Section title="Clone Camera Group" classNameBody="px-4 pt-3 pb-4">
                    <div className="d-flex flex-column">
                      <span className={`${styles.description} mb-3`}>
                        Assign a new Camera Group by cloning group{' '}
                        <span className={styles.activeText}>{currentCameraGroup?.name}</span>{' '}
                        configuration.
                      </span>
                      <Link
                        className="align-self-end"
                        to={`${cameraGroupId}/clone`}
                        state={[
                          {
                            path: Routes.ECameraGroupRoutes.DETAILED,
                            breadcrumb: currentCameraGroup?.name
                          }
                        ]}
                      >
                        <Button className={`${styles.actionBtn} btn-fill`}>
                          <span className={styles.btnContent}>Clone</span>
                        </Button>
                      </Link>
                    </div>
                  </Section>
                </PermissionWrapper.Create> */}
              {(currentCameraGroup.type.name ?? '').toLowerCase() !==
                DECOMMISSIONED.toLowerCase() && (
                <PermissionWrapper.Update subFeature={ESubFeatureKey.ASSIGNMENT}>
                  <Section
                    title={t('cameraGroupPage.sections.bind.title')}
                    classNameBody="px-4 pt-3 pb-4"
                  >
                    <BindAction
                      form={bindCameraForm}
                      isSubmitting={isBindSubmitting}
                      onBindCamera={handleBindCamera}
                    />
                  </Section>
                </PermissionWrapper.Update>
              )}

              {currentCameraGroup.cameras.length > 0 && (
                <PermissionWrapper.Update subFeature={ESubFeatureKey.ASSIGNMENT}>
                  <Section
                    title={t('cameraGroupPage.sections.sync.title')}
                    classNameBody="px-4 pt-3 pb-4"
                  >
                    <SyncAction
                      form={syncCameraForm}
                      cameraBindingSwitchValue={cameraBindingSwitchValue}
                      currentCameraGroup={currentCameraGroup}
                      deviceBindingExtraOptions={deviceBindingExtraOptions}
                      displaySyncToggle={displaySyncToggle}
                      isSyncSubmitting={isSyncSubmitting}
                      onCameraBindingSwitchChange={onCameraBindingSwitchChange}
                      onSyncCameraConfig={handleSyncCameraConfig}
                      currentCameraSelectedInSync={currentCameraSelectedInSync}
                    />
                  </Section>
                </PermissionWrapper.Update>
              )}
              {filterAssignedEyeviewUserList.length > 0 &&
                (currentCameraGroup.type.name ?? '').toLowerCase() !==
                  DECOMMISSIONED.toLowerCase() && (
                  <PermissionWrapper.Update subFeature={ESubFeatureKey.ASSIGNMENT}>
                    <Section
                      title={t('cameraGroupPage.sections.assign.title')}
                      classNameBody="px-4 pt-3 pb-4"
                    >
                      <AssignAction
                        form={assignEyeviewUserForm}
                        currentCameraGroup={currentCameraGroup}
                        eyeviewUserList={eyeviewUserList}
                        filterAssignedEyeviewUserList={filterAssignedEyeviewUserList}
                        isSubmitting={isAssignSubmitting}
                        onAssignEyeviewUser={handleAssignEyeviewUser}
                      />
                    </Section>
                  </PermissionWrapper.Update>
                )}

              <PermissionWrapper.Update subFeature={ESubFeatureKey.ASSIGNMENT}>
                <Section
                  title={t('cameraGroupPage.sections.delete.title')}
                  classNameBody="px-4 pt-3 pb-4"
                >
                  <DeleteAction
                    currentCameraGroup={currentCameraGroup}
                    isSubmitting={isDeleteSubmitting}
                    onDeleteCurrentCameraGroup={handleDeleteCameraGroup}
                  />
                </Section>
              </PermissionWrapper.Update>
            </>
          )}
        </LeftColumn>
        <RightColumn
          toolTipRef={toolTipRef}
          leftColumnOpen={leftColumnOpen}
          onSideBarClick={() => {
            leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
          }}
        >
          <Outlet
            context={{
              refetchCount: refetchCountRef.current,
              actionStatus,
              onChangeCurrentCamera,
              onResetActionStatus,
              resetBindForm
            }}
          />
        </RightColumn>
      </div>
    </div>
  );
};

export default CameraGroupsPageView;
