import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import type { TAccount, TPaginationEOSS } from 'models';
import { EHttpStatusCode } from 'enums';
import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { AccountRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { useAppUtil } from 'context/UtilContext';
import { ApiErrorResponse } from 'models/ApiError';

const useListAccountController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const { openNotification } = useAppUtil();
  const { refetchCount } = useOutletContext<{ refetchCount: number }>();

  const accountRepository = AccountRepository(AxiosClient);

  const searchStr = searchParams.get('search');
  const pageNumber = searchParams.get('page');

  const [accountList, setAccountList] = useState<{
    data: TAccount[];
    loading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    loading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  const handleFetchAccountList = async (searchStr: string) => {
    if (accountList.loading) return;
    setAccountList((prev) => ({
      ...prev,
      loading: true
    }));
    try {
      const { code, data } = await accountRepository.getAccountsFilter(searchStr);
      if (code === EHttpStatusCode.OK) {
        setAccountList((prev) => ({
          ...prev,
          loading: false,
          data,
          paging: {
            ...prev.paging,
            total: data.length,
            pageLimit: DEFAULT_LIMIT
          }
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.search')} ${t('accountPage.entity')}`,
        description: message
      });
      setAccountList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0) {
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
    }
  };

  function refetchList() {
    if (!searchStr) return;
    handleFetchAccountList(searchStr);
  }

  useEffect(() => {
    if (refetchCount > 0) {
      refetchList();
    }
  }, [refetchCount]);

  useEffect(() => {
    if (searchStr) {
      if (!pageNumber) {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
      } else {
        if (
          Number.isInteger(Number(pageNumber)) &&
          !pageNumber.includes('.') &&
          Number(pageNumber) >= 1
        ) {
          handleFetchAccountList(searchStr);
        } else if (
          Number(pageNumber) === Math.floor(Number(pageNumber)) &&
          Number(pageNumber) >= 1
        ) {
          searchParams.set('page', Math.floor(Number(pageNumber)).toString());
          setSearchParams(searchParams);
        } else {
          openNotification({
            type: 'error',
            title: t('accountPage.accountListingTable.invalidPageNo')
          });
        }
      }
    } else {
      setAccountList({
        data: [],
        loading: false,
        paging: {
          pageLimit: DEFAULT_LIMIT,
          pageNum: DEFAULT_PAGE_NUMBER,
          total: 0,
          totalPage: 0
        }
      });
    }
  }, [searchStr, pageNumber]);

  useEffect(() => {
    if (pageNumber) {
      setAccountList((prev) => {
        return {
          ...prev,
          paging: {
            ...prev.paging,
            pageNum: Number(pageNumber)
          }
        };
      });
    }
  }, [pageNumber]);

  return {
    accountList,
    onPageChange: handlePageChange,
    handleFetchAccountList
  };
};

export default useListAccountController;
