export function ExpandIcon({
  expanded = false,
  isBlue = false,
  onClick
}: {
  expanded: boolean;
  isBlue?: boolean;
  onClick?: () => void;
}) {
  return (
    <svg
      style={{
        transition: 'transform 0.25s linear',
        transform: `${expanded ? 'rotate(0deg)' : 'rotate(-90deg)'}`
      }}
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <circle
        cx="12"
        cy="12"
        r="11.75"
        fill={isBlue ? '#E9ECFC' : '#F9FAFD'}
        stroke={isBlue ? '' : '#C9D0F7'}
        strokeWidth="0.5"
      />
      <path
        d="M16.4184 11.0215L11.9036 14.8318L7.58203 11.0215"
        stroke="#4A61E3"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}
