import { TServerType, TDataResponseEOSS } from 'models';
import { TAxiosClient } from 'services/axios';
import { IServerTypeDataSource } from './ServerTypeDataSource';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Represents a remote data source implementation for handling server type data.
 * @author hung.nguyen@zien.vn
 */
export class ServerTypeRemoteDataSourceImpl implements IServerTypeDataSource {
  httpService: TAxiosClient;

  private static instance: ServerTypeRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of ServerTypeRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {ServerTypeRemoteDataSourceImpl} The singleton instance of ServerTypeRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): ServerTypeRemoteDataSourceImpl {
    if (!ServerTypeRemoteDataSourceImpl.instance) {
      ServerTypeRemoteDataSourceImpl.instance = new ServerTypeRemoteDataSourceImpl(httpService);
    }
    return ServerTypeRemoteDataSourceImpl.instance;
  }

  async getListServerType(): Promise<TServerType[]> {
    const { data } = await this.httpService.get<TServerType[]>('/server/types/all');
    return data.map((value) => mapSnakeCaseToCamelCase(value));
  }

  async addServerType({
    name,
    description
  }: {
    name: string;
    description?: string;
  }): Promise<TServerType> {
    const { data } = await this.httpService.post<TDataResponseEOSS<TServerType>>(
      '/admin/server-type',
      {
        name,
        description
      }
    );
    return data.data;
  }

  async updateServerType({
    serverTypeId,
    name,
    validId,
    description
  }: {
    name: string;
    description?: string;
    serverTypeId: number;
    validId: number;
  }): Promise<TServerType> {
    const { data } = await this.httpService.put<TDataResponseEOSS<TServerType>>(
      `/admin/server-type/${serverTypeId}`,
      { name, description, valid_id: validId }
    );
    return data.data;
  }
}
