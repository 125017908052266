type Props = {
  active?: boolean;
};

export function EyeviewSessionsIcon({ active = false }: Props) {
  if (active) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.86689 3.62891C1.8243 3.62891 0.990234 4.46734 0.990234 5.51538C0.990234 6.56342 1.8243 7.40185 2.86689 7.40185C3.90947 7.40185 4.74354 6.56342 4.74354 5.51538C4.74354 4.46734 3.88862 3.62891 2.86689 3.62891Z"
          className="active-header-icon"
        />
        <path
          d="M8.30934 7.11182H19.2148C20.0906 7.11182 20.7995 6.39916 20.7995 5.5188C20.7995 4.63845 20.0906 3.92578 19.2148 3.92578H8.30934C7.43357 3.92578 6.72461 4.63845 6.72461 5.5188C6.74546 6.39916 7.45442 7.11182 8.30934 7.11182Z"
          className="active-header-icon"
        />
        <path
          d="M2.86689 9.94141C1.8243 9.94141 0.990234 10.7798 0.990234 11.8279C0.990234 12.8759 1.8243 13.7144 2.86689 13.7144C3.90947 13.7144 4.74354 12.8759 4.74354 11.8279C4.74354 10.7798 3.88862 9.94141 2.86689 9.94141Z"
          className="active-header-icon"
        />
        <path
          d="M2.86689 20.019C3.90334 20.019 4.74354 19.1744 4.74354 18.1326C4.74354 17.0907 3.90334 16.2461 2.86689 16.2461C1.83044 16.2461 0.990234 17.0907 0.990234 18.1326C0.990234 19.1744 1.83044 20.019 2.86689 20.019Z"
          className="active-header-icon"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2867 10.2344L14.2408 10.4954C14.012 10.6117 13.7909 10.7403 13.5783 10.88L12.9354 10.6244C12.1222 10.3011 11.1957 10.6185 10.7481 11.3738L9.5353 13.4204H8.30934C7.43357 13.4204 6.72461 12.7078 6.72461 11.8274C6.72461 10.947 7.43357 10.2344 8.30934 10.2344H14.2867Z"
          className="active-header-icon"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7517 19.7251H10.7513L10.6119 19.4899C10.6026 19.4742 10.598 19.4568 10.5979 19.4395C10.5981 19.4566 10.6027 19.4737 10.6119 19.4891L10.7517 19.7251ZM13.811 16.5391H13.8111C13.7968 16.6659 13.7895 16.7949 13.7895 16.9257L13.7894 16.9249C13.7894 16.7944 13.7968 16.6657 13.811 16.5391ZM10.0285 16.5391C10.0218 16.667 10.0183 16.7959 10.0183 16.9257C10.0183 17.156 10.0292 17.3835 10.0501 17.6078L9.49744 18.1131C9.04082 18.5305 8.84307 19.1413 8.93681 19.7251H8.30934C7.43357 19.7251 6.72461 19.0124 6.72461 18.1321C6.72461 17.2517 7.43357 16.5391 8.30934 16.5391H10.0285ZM11.8446 18.1847C11.8463 18.1921 11.8471 18.1995 11.8472 18.2068C11.8471 18.1997 11.8462 18.1926 11.8446 18.1855C11.7592 17.8116 11.7099 17.423 11.703 17.0238C11.71 17.4227 11.7592 17.811 11.8446 18.1847Z"
          className="active-header-icon"
        />
        <path
          d="M16.1365 9.95703L15.8242 11.7125C15.1026 11.9442 14.4501 12.3285 13.8943 12.8253L12.2613 12.1827L10.8945 14.4658L12.205 15.6518C12.1022 16.0769 12.0463 16.5212 12.0463 16.9788C12.0463 17.4364 12.1022 17.8807 12.205 18.3058L10.8945 19.4918L12.2613 21.7749L13.8943 21.1323C14.4501 21.6291 15.1026 22.0134 15.8242 22.2451L16.1365 24.0006H18.7575L19.0697 22.2451C19.7914 22.0134 20.4439 21.6291 20.9997 21.1323L22.6327 21.7749L23.9995 19.4918L22.6941 18.3006C22.7959 17.8773 22.8477 17.4342 22.8477 16.9788C22.8477 16.5234 22.7959 16.0803 22.6941 15.657L23.9995 14.4658L22.6327 12.1827L20.9997 12.8253C20.4439 12.3285 19.7914 11.9442 19.0697 11.7125L18.7575 9.95703H16.1365ZM17.447 13.6351C19.2564 13.6351 20.7232 15.1321 20.7232 16.9788C20.7232 18.8255 19.2564 20.3225 17.447 20.3225C15.6376 20.3225 14.1708 18.8255 14.1708 16.9788C14.1708 15.1321 15.6376 13.6351 17.447 13.6351Z"
          className="active-header-icon"
        />
      </svg>
    );
  }
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.86689 3.62891C1.8243 3.62891 0.990234 4.46734 0.990234 5.51538C0.990234 6.56342 1.8243 7.40185 2.86689 7.40185C3.90947 7.40185 4.74354 6.56342 4.74354 5.51538C4.74354 4.46734 3.88862 3.62891 2.86689 3.62891Z"
        className="inactive-header-icon"
      />
      <path
        d="M8.30934 7.11182H19.2148C20.0906 7.11182 20.7995 6.39916 20.7995 5.5188C20.7995 4.63845 20.0906 3.92578 19.2148 3.92578H8.30934C7.43357 3.92578 6.72461 4.63845 6.72461 5.5188C6.74546 6.39916 7.45442 7.11182 8.30934 7.11182Z"
        className="inactive-header-icon"
      />
      <path
        d="M2.86689 9.94141C1.8243 9.94141 0.990234 10.7798 0.990234 11.8279C0.990234 12.8759 1.8243 13.7144 2.86689 13.7144C3.90947 13.7144 4.74354 12.8759 4.74354 11.8279C4.74354 10.7798 3.88862 9.94141 2.86689 9.94141Z"
        className="inactive-header-icon"
      />
      <path
        d="M2.86689 20.019C3.90334 20.019 4.74354 19.1744 4.74354 18.1326C4.74354 17.0907 3.90334 16.2461 2.86689 16.2461C1.83044 16.2461 0.990234 17.0907 0.990234 18.1326C0.990234 19.1744 1.83044 20.019 2.86689 20.019Z"
        className="inactive-header-icon"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2867 10.2344L14.2408 10.4954C14.012 10.6117 13.7909 10.7403 13.5783 10.88L12.9354 10.6244C12.1222 10.3011 11.1957 10.6185 10.7481 11.3738L9.5353 13.4204H8.30934C7.43357 13.4204 6.72461 12.7078 6.72461 11.8274C6.72461 10.947 7.43357 10.2344 8.30934 10.2344H14.2867Z"
        className="inactive-header-icon"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7517 19.7251H10.7513L10.6119 19.4899C10.6026 19.4742 10.598 19.4568 10.5979 19.4395C10.5981 19.4566 10.6027 19.4737 10.6119 19.4891L10.7517 19.7251ZM13.811 16.5391H13.8111C13.7968 16.6659 13.7895 16.7949 13.7895 16.9257L13.7894 16.9249C13.7894 16.7944 13.7968 16.6657 13.811 16.5391ZM10.0285 16.5391C10.0218 16.667 10.0183 16.7959 10.0183 16.9257C10.0183 17.156 10.0292 17.3835 10.0501 17.6078L9.49744 18.1131C9.04082 18.5305 8.84307 19.1413 8.93681 19.7251H8.30934C7.43357 19.7251 6.72461 19.0124 6.72461 18.1321C6.72461 17.2517 7.43357 16.5391 8.30934 16.5391H10.0285ZM11.8446 18.1847C11.8463 18.1921 11.8471 18.1995 11.8472 18.2068C11.8471 18.1997 11.8462 18.1926 11.8446 18.1855C11.7592 17.8116 11.7099 17.423 11.703 17.0238C11.71 17.4227 11.7592 17.811 11.8446 18.1847Z"
        className="inactive-header-icon"
      />
      <path
        d="M16.1365 9.95703L15.8242 11.7125C15.1026 11.9442 14.4501 12.3285 13.8943 12.8253L12.2613 12.1827L10.8945 14.4658L12.205 15.6518C12.1022 16.0769 12.0463 16.5212 12.0463 16.9788C12.0463 17.4364 12.1022 17.8807 12.205 18.3058L10.8945 19.4918L12.2613 21.7749L13.8943 21.1323C14.4501 21.6291 15.1026 22.0134 15.8242 22.2451L16.1365 24.0006H18.7575L19.0697 22.2451C19.7914 22.0134 20.4439 21.6291 20.9997 21.1323L22.6327 21.7749L23.9995 19.4918L22.6941 18.3006C22.7959 17.8773 22.8477 17.4342 22.8477 16.9788C22.8477 16.5234 22.7959 16.0803 22.6941 15.657L23.9995 14.4658L22.6327 12.1827L20.9997 12.8253C20.4439 12.3285 19.7914 11.9442 19.0697 11.7125L18.7575 9.95703H16.1365ZM17.447 13.6351C19.2564 13.6351 20.7232 15.1321 20.7232 16.9788C20.7232 18.8255 19.2564 20.3225 17.447 20.3225C15.6376 20.3225 14.1708 18.8255 14.1708 16.9788C14.1708 15.1321 15.6376 13.6351 17.447 13.6351Z"
        className="inactive-header-icon"
      />
    </svg>
  );
}
