import { useCallback, useState } from 'react';

type NoneToVoidFunction = () => void;

/**
 * Creates a stateful boolean hook to set it to true or false.
 * @param - The initial value of the flag. Defaults to false.
 * @return
 * value: The current boolean value of the flag.
 * setTrue: A function that sets the flag to true.
 * setFalse: A function that sets the flag to false.
 */
const useFlag = (initial = false): [boolean, NoneToVoidFunction, NoneToVoidFunction] => {
  const [value, setValue] = useState<boolean>(initial);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  return [value, setTrue, setFalse];
};

export default useFlag;
