import { useLoaderContext } from 'context/LoaderContext';
import { TControllerResponse, TError } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getError } from 'utils/common';

export const useResetPasswordController = () => {
  const { loader } = useLoaderContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  async function handleSendEmail(params: { email: string }): Promise<TControllerResponse> {
    try {
      loader.start();
      setIsLoading(true);
      console.debug('handleSendEmail', { params });
      return { error: '', success: true };
    } catch (error) {
      const err = getError<TError>(error as string);
      return {
        error: t('resetPasswordPage.emailHasNotBeenRegistered') || err.message,
        success: false
      };
    } finally {
      loader.complete();
      setIsLoading(false);
    }
  }

  async function handleResetPassword(params: {
    newPassword: string;
    requestId: string;
  }): Promise<TControllerResponse> {
    try {
      loader.start();
      setIsLoading(true);
      console.debug('handleResetPassword', { params });
      return { error: '', success: true };
    } catch (error) {
      const err = getError<TError>(error as string);
      return {
        error: t('resetPasswordPage.requestIdExpired') || err.message,
        success: false
      };
    } finally {
      loader.complete();
      setIsLoading(false);
    }
  }

  return {
    sendMail: handleSendEmail,
    resetPassword: handleResetPassword,
    isLoading
  };
};
