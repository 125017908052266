export function EyeOpen() {
  return (
    <svg width="18" height="12" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.44095 4.99953C8.44095 5.39564 8.28927 5.77539 8.01932 6.0555C7.74927 6.3355 7.38314 6.49283 7.00125 6.49283C6.61936 6.49283 6.25324 6.3355 5.98318 6.0555C5.71323 5.7754 5.56155 5.39564 5.56155 4.99953C5.56155 4.60342 5.71323 4.22367 5.98318 3.94356C6.25323 3.66356 6.61936 3.50624 7.00125 3.50624C7.38314 3.50624 7.74926 3.66356 8.01932 3.94356C8.28927 4.22366 8.44095 4.60342 8.44095 4.99953ZM13.6288 4.70588C13.5088 4.53666 10.6485 0.519531 7.00125 0.519531C3.35401 0.519531 0.493807 4.53664 0.373695 4.70588C0.313708 4.7911 0.28125 4.89399 0.28125 4.99953C0.28125 5.10508 0.313708 5.20797 0.373695 5.29318C0.49367 5.4624 3.35401 9.47953 7.00125 9.47953C10.6485 9.47953 13.5087 5.46242 13.6288 5.29318C13.6888 5.20796 13.7212 5.10508 13.7212 4.99953C13.7212 4.89399 13.6888 4.79109 13.6288 4.70588ZM7.00125 7.48836C6.36485 7.48836 5.75458 7.22614 5.30457 6.75937C4.85456 6.29261 4.60175 5.65962 4.60175 4.99953C4.60175 4.33944 4.85456 3.70645 5.30457 3.23969C5.75459 2.77293 6.36485 2.51071 7.00125 2.51071C7.63765 2.51071 8.24792 2.77292 8.69793 3.23969C9.14794 3.70646 9.40075 4.33944 9.40075 4.99953C9.40075 5.65962 9.14794 6.29261 8.69793 6.75937C8.24792 7.22613 7.63765 7.48836 7.00125 7.48836Z"
        fill="#4A61E3"
      />
    </svg>
  );
}
