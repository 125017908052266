export const DragAndDropIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path d="M10.2222 3L15.6108 8.83333H4.83362L10.2222 3Z" fill="#4A61E3" />
        <path d="M10.2231 17L4.83449 11.1667H15.6117L10.2231 17Z" fill="#4A61E3" />
      </svg>
    </span>
  );
};
