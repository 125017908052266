import { TCameraGroup } from 'models';
import { useOutletContext } from 'react-router-dom';

export const useCameraGroupDetailPageController = () => {
  const {
    actionStatus,
    currentCameraGroup,
    onChangeCurrentCamera,
    onResetActionStatus,
    resetBindForm
  } = useOutletContext<{
    actionStatus: {
      type: 'sync' | 'bind' | 'assign' | null;
      success: boolean;
    };
    currentCameraGroup: TCameraGroup;
    onChangeCurrentCamera: (value: TCameraGroup) => void;
    onResetActionStatus: () => void;
    resetBindForm: () => void;
  }>();

  return {
    actionStatus,
    currentCameraGroup,
    onChangeCurrentCamera,
    onResetActionStatus,
    resetBindForm
  };
};
