import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode, EStorageKey, Routes } from 'enums';
import type {
  Content,
  DataList,
  TAccount,
  TCameraGroupAssign,
  TCameraGroupAvailable,
  TEyeviewUser,
  TPaginationEOSS
} from 'models';
import { ApiErrorResponse, invalidTypeError } from 'models/ApiError';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';
import { EyeviewUserRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError, isApiErrorResponse } from 'utils/common';
import { useForm } from 'antd/es/form/Form';
import { useLoaderContext } from 'context/LoaderContext';

export type TEyeviewUserField = {
  accountId: string;
  invalid: number;
  username: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  languageId: number;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  comment?: string;
};

export type TSelect = {
  label: string;
  value: number | string;
};

export const useEyeviewUserPageController = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { loader } = useLoaderContext();

  const [searchFrom] = useForm();

  const { t } = useTranslation();

  const eyeviewUserRepository = EyeviewUserRepository(AxiosClient);
  const { userId } = useParams<{ userId: string }>();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get('search');

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);

  const refetchCountRef = useRef<number>(0);

  const currentUser = useRouteLoaderData(Routes.EUserRoutes.DETAIL) as TEyeviewUser;

  const [assignGroupAction, setAssignGroupAction] = useState<{
    isLoading: boolean;
    isSuccess: boolean;
  }>({ isLoading: false, isSuccess: false });

  const [listCameraGroupAssignment, setListCameraGroupAssignment] = useState<
    DataList<Array<TCameraGroupAssign>>
  >({
    data: [],
    loading: false
  });

  const [listCameraGroupRowSelected, setListCameraGroupRowSelected] = useState<React.Key[]>([]);

  const [listAvailableCameraGroupsAssignment, setListAvailableCameraGroupsAssignment] = useState<
    DataList<Array<TCameraGroupAssign>> & {
      pagination: TPaginationEOSS;
    }
  >({
    loading: false,
    data: [],
    pagination: {
      total: 0,
      pageNum: DEFAULT_PAGE_NUMBER - 1,
      pageLimit: DEFAULT_LIMIT,
      totalPage: 0
    }
  });

  const [cameraGroupIdSelected, setCameraGroupIdSelected] = useState<number | null>(null);

  const { openNotification } = useAppUtil();

  const handleSearchSubmit = (values: { searchString: string }) => {
    if (values.searchString) {
      searchParams.set('search', values.searchString);
      searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());
      setSearchParams(searchParams);
      if (searchString === searchParams.get('search')) {
        refetchCountRef.current += 1;
      } else {
        refetchCountRef.current = 0;
      }
      navigate(
        { pathname: Routes.EUserRoutes.LISTING, search: searchParams.toString() },
        { replace: true }
      );
    } else {
      navigate({ pathname: Routes.EUserRoutes.LISTING });
    }
  };

  const handleRefetchCameraGroupAssignment = async () => {
    if (listCameraGroupAssignment.loading || !currentUser.id) return;
    setListCameraGroupAssignment((prev) => ({
      ...prev,
      loading: true
    }));
    try {
      const { code, data } = await eyeviewUserRepository.getEyeviewUserById(currentUser.id);
      if (code === EHttpStatusCode.OK) {
        setListCameraGroupAssignment({
          data: data.groups,
          loading: false
        });
        setListCameraGroupRowSelected(data.groups.map((group: TCameraGroupAssign) => group.id));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('userPage.entity'),
        t,
        identifier: `ID ${currentUser.id.toString()}`
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
      setListCameraGroupAssignment((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleClickCreateUser = () => {
    loader.start();
    navigate({
      pathname: Routes.EUserRoutes.CREATE
    });
  };

  const handleClickCloneUser = () => {
    if (userId) {
      navigate({
        pathname: `${userId}/clone`,
        search: searchParams.toString()
      });
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) {
      searchParams.delete('search');
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
  };

  const handleFetchAccountList = async (): Promise<{ success: boolean; data: TAccount[] }> => {
    try {
      const { code, data } = await eyeviewUserRepository.getAllValidAccountList();
      if (code === EHttpStatusCode.OK) {
        return {
          success: true,
          data
        };
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('accountPage.entity')}`,
        description: message
      });
    }
    return {
      success: false,
      data: []
    };
  };

  const handleCameraGroupIdSelected = (cameraGroupId: number | null) =>
    setCameraGroupIdSelected(cameraGroupId);

  const handleFetchAllCameraGroupList = async ({ userId }: { userId: number }) => {
    try {
      if (listAvailableCameraGroupsAssignment.loading) return;
      setCameraGroupIdSelected(null);
      setListAvailableCameraGroupsAssignment((prev) => {
        return {
          ...prev,
          data: [],
          loading: true
        };
      });

      const { data: dataFirstPage, code } = await eyeviewUserRepository.getListCameraGroupByUserId({
        search: '*',
        userId,
        page: DEFAULT_PAGE_NUMBER - 1
      });

      setListAvailableCameraGroupsAssignment((prev) => ({
        ...prev,
        data: [...dataFirstPage.content]
      }));

      const totalPage = dataFirstPage.total_pages;

      if (code === EHttpStatusCode.OK) {
        if (totalPage > 1) {
          const data = await Promise.allSettled(
            Array.from(Array(totalPage))
              .slice(1)
              .map(async (_, pageNumber) => {
                const { data, code } = await eyeviewUserRepository.getListCameraGroupByUserId({
                  search: '*',
                  userId,
                  page: pageNumber + 1 // Not magic number: We need to fetch from second page
                });
                if (code === EHttpStatusCode.OK) {
                  return data;
                }
              })
          );
          const dataSecondToEnd = data.reduce(
            (
              acc: Content[],
              currentValue: PromiseSettledResult<TCameraGroupAvailable | undefined>
            ) => {
              if (currentValue && currentValue.status === 'fulfilled') {
                if (currentValue?.value) {
                  const temp = [...acc, ...currentValue.value.content];
                  return temp;
                }
              }
              return acc;
            },
            []
          );

          setListAvailableCameraGroupsAssignment((prev) => ({
            ...prev,
            data: [...dataFirstPage.content, ...dataSecondToEnd]
          }));
        } else {
          const dataSource = [...dataFirstPage.content];
          setListAvailableCameraGroupsAssignment((prev) => ({
            ...prev,
            data: dataSource
          }));
        }
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('userPage.sections.cameraGroup.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
    } finally {
      setListAvailableCameraGroupsAssignment((prev) => {
        return {
          ...prev,
          loading: false
        };
      });
    }
  };

  const handleAssignSubmit = async (callback: () => void) => {
    try {
      if (assignGroupAction.isLoading || !currentUser || !cameraGroupIdSelected) return;
      setAssignGroupAction({ isSuccess: false, isLoading: true });
      const code = await eyeviewUserRepository.assignCameraGroupToEyeviewUser({
        cameraGroupId: `${cameraGroupIdSelected}`,
        eyeviewUserId: `${currentUser.id}`
      });
      if (code === EHttpStatusCode.OK) {
        setAssignGroupAction({
          isLoading: false,
          isSuccess: true
        });
        sessionStorage.setItem(
          EStorageKey.USER_GROUPS_BINDING_KEYS,
          JSON.stringify([cameraGroupIdSelected])
        );
        openNotification({
          type: 'success',
          title: `${t('actions.assign')} ${t('cameraGroupPage.entity')}`,
          description: `${t('components.success')}`,
          onClose: () => {
            sessionStorage.removeItem(EStorageKey.USER_GROUPS_BINDING_KEYS);
            const element = document.querySelector(
              '#eyeviewUserPage_deviceGroupAssignmentTable tbody tr.new-group'
            );
            if (element) {
              element.classList.remove('new-group');
            }
          }
        });
        handleRefetchCameraGroupAssignment();
        const availableCameraGroupsAssignmentFilter =
          listAvailableCameraGroupsAssignment.data.filter(
            (value: TCameraGroupAssign) => cameraGroupIdSelected !== value.id
          );
        setListAvailableCameraGroupsAssignment((prev) => ({
          ...prev,
          data: availableCameraGroupsAssignmentFilter
        }));
        setCameraGroupIdSelected(null);
      }
    } catch (error) {
      setAssignGroupAction({ isLoading: false, isSuccess: false });

      if (isApiErrorResponse(error)) {
        const { error: responseError } = error as ApiErrorResponse;

        if (responseError == invalidTypeError.error) {
          openNotification({
            type: 'error',
            title: `${t('actions.assign')} ${t('cameraGroupPage.entity')}`,
            description: t('errors.custom.deviceGroup.bindUserToDecommissionedGroupError')
          });
          return;
        }
      }

      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('userPage.entity'),
        t,
        identifier: `ID ${currentUser.id.toString()}`
      });
      openNotification({
        type: 'error',
        title: `${t('actions.assign')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
    } finally {
      setAssignGroupAction((prev) => {
        return {
          ...prev,
          isLoading: false
        };
      });
      callback();
    }
  };

  const handleListCameraGroupRowSelectedChange = (value: React.Key[]) =>
    setListCameraGroupRowSelected(value);

  const handleListCameraGroupChange = (value: { data: TCameraGroupAssign[]; loading: boolean }) =>
    setListCameraGroupAssignment(value);

  useEffect(() => {
    if (searchFrom) {
      searchFrom.setFieldValue('searchString', searchString);
    }
  }, [searchString, location]);

  useEffect(() => {
    if (currentUser) {
      handleFetchAllCameraGroupList({
        userId: currentUser.id
      });
    }
  }, [currentUser]);

  useEffect(() => {
    return () => {
      setCameraGroupIdSelected(null);
      setListAvailableCameraGroupsAssignment({
        loading: false,
        data: [],
        pagination: {
          total: 0,
          pageNum: DEFAULT_PAGE_NUMBER - 1,
          pageLimit: DEFAULT_LIMIT,
          totalPage: 0
        }
      });
    };
  }, [userId]);

  return {
    searchFrom,
    searchValue,
    selectedRegions,
    cameraGroupIdSelected,
    refetchCountRef,
    currentUser,
    searchString,
    listAvailableCameraGroupsAssignment,
    assignGroupAction,
    listCameraGroupAssignment,
    listCameraGroupRowSelected,
    onSearchSubmit: handleSearchSubmit,
    onAssignSubmit: handleAssignSubmit,
    onClickCreateUser: handleClickCreateUser,
    onClickCloneUser: handleClickCloneUser,
    onChangeSearch: handleSearchChange,
    onFetchAccountList: handleFetchAccountList,
    onCameraGroupIdSelected: handleCameraGroupIdSelected,
    onListCameraGroupRowSelectedChange: handleListCameraGroupRowSelectedChange,
    onListCameraGroupChange: handleListCameraGroupChange,
    onSearchValueChange: (value: string) => setSearchValue(value),
    onSelectedRegionsChange: (value: string[]) => setSelectedRegions(value)
  };
};
