export enum ERoleKey {
  VIEW = 'view',
  CREATE = 'create',
  UPDATE = 'update',
  EXECUTE = 'execute'
}

export enum EFeatureKey {
  ADMIN = 'admin',
  DOMAIN = 'domain',
  ACCOUNT = 'account',
  DEVICE = 'device',
  CAMERA_GROUP = 'camera_group',
  USER = 'user',
  EYEVIEW_SESSIONS = 'eyeview_sessions',
  SERVER = 'server',
  SYSTEM = 'system'
}

export enum ESubFeatureKey {
  BASIC = 'basic',
  ASSIGNMENT = 'assignment',
  PERMISSION = 'permission',
  CAMERA_GROUP = 'camera_group',
  SERVICE = 'service',
  COMMAND = 'command',
  OPTIONS = 'options'
}
