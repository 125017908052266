import { IServerDataSource } from 'data/Server/ServerDataSource';
import { EHttpStatusCode } from 'enums';
import { TDataResponseEOSS } from 'models';
import { TServer } from 'models/Server';
import { IServerRepository } from './ServerRepository';
import { convertAxiosErrorToApiError } from 'utils/common';
import { notFoundError } from 'models/ApiError';

/**
 * ServerRepositoryImpl class that implements IServerRepository interface.
 * @author thai.ngo@zien.vn
 */
export class ServerRepositoryImpl implements IServerRepository {
  serverDataSource: IServerDataSource;

  private static instance: ServerRepositoryImpl;

  /**
   * Constructor for ServerRepositoryImpl class.
   * @param serverDataSource - The data source for the server.
   */
  constructor(serverDataSource: IServerDataSource) {
    this.serverDataSource = serverDataSource;
  }

  /**
   * Get an instance of ServerRepositoryImpl.
   * @param serverDataSource - The data source for the server.
   * @returns An instance of ServerRepositoryImpl.
   */
  public static getInstance(serverDataSource: IServerDataSource): ServerRepositoryImpl {
    if (!ServerRepositoryImpl.instance) {
      ServerRepositoryImpl.instance = new ServerRepositoryImpl(serverDataSource);
    }
    return ServerRepositoryImpl.instance;
  }

  /**
   * Get a server by serial number and server type ID.
   * @param serialNumber - The serial number of the server.
   * @param serverTypeId - The ID of the server type.
   * @returns A promise that resolves to a data response containing the server.
   */
  async getServerBySerialNumber({
    serialNumber,
    serverTypeId
  }: {
    serialNumber: string;
    serverTypeId: number;
  }): Promise<TDataResponseEOSS<TServer>> {
    try {
      const request = await this.serverDataSource.getListServer({
        typeId: serverTypeId
      });

      const server = request.data.find((data) => data?.serialNumber === serialNumber);

      if (!server) {
        throw notFoundError;
      }

      const dataResp: TDataResponseEOSS<TServer> = {
        code: EHttpStatusCode.OK,
        data: server!
      };

      return dataResp;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  /**
   * Perform a local wildcard search by server name.
   * @param listServer - The list of servers to search within.
   * @param search - The search string with optional wildcard character '*'.
   * @returns A data response containing the filtered list of servers.
   */
  private localSearchWildCardByServerName(
    listServer: TServer[],
    {
      search
    }: {
      search?: string;
    }
  ): TDataResponseEOSS<TServer[]> {
    const searchText = search || '';
    let filteredListServer: TServer[] = listServer;

    if (searchText) {
      const regexString = searchText.replace(/\*/g, '.*');
      const regex = new RegExp(`^${regexString}$`, 'i');
      filteredListServer = listServer.filter((server) => regex.test(server.name));
    }

    const dataResp: TDataResponseEOSS<TServer[]> = {
      code: EHttpStatusCode.OK,
      data: filteredListServer
    };

    return dataResp;
  }

  /**
   * Get a list of servers based on search criteria and server type ID.
   * @param search - The search string with optional wildcard character '*'.
   * @param serverTypeId - The ID of the server type.
   * @returns A promise that resolves to a data response containing the filtered list of servers.
   */
  async getListServer({
    search,
    serverTypeId
  }: {
    search?: string;
    serverTypeId: number;
  }): Promise<TDataResponseEOSS<TServer[]>> {
    try {
      const resp = await this.serverDataSource.getListServer({
        typeId: serverTypeId
      });
      return this.localSearchWildCardByServerName(resp.data, { search: search });
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
