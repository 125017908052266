import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import { DropdownIcon } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { MdOutlineLanguage } from 'react-icons/md';

const languages = [{ label: 'eng', code: 'en' }];

export function SwitchLanguage() {
  const { i18n } = useTranslation();
  const items: MenuProps['items'] = languages.map((l) => ({
    label: l.label,
    key: l.code
  }));

  const langCode = i18n.resolvedLanguage || 'en';
  const lang = languages.find((l) => l.code === langCode);

  return (
    <Dropdown menu={{ items, onClick: (e) => i18n.changeLanguage(e.key) }} trigger={['click']}>
      <div
        className="cursor-pointer bg-white d-flex align-items-center justify-content-center"
        style={{
          width: 'fit-content',
          borderRadius: 17,
          height: 34,
          padding: '0 13px',
          border: '1px solid #0000001F'
        }}
        data-testid="switch-language-button"
      >
        <MdOutlineLanguage size={24} color="var(--primary-1)" />
        <span className="mx-1" style={{ color: 'var(--primary-1)', fontSize: 12, fontWeight: 700 }}>
          {lang?.label.toUpperCase()}
        </span>
        <DropdownIcon />
      </div>
    </Dropdown>
  );
}
