export default {
  cameraGroupPage: {
    breadcrumb: {
      listing: 'Device Group Management',
      create: 'Create Device Group'
    },
    sections: {
      search: {
        title: 'Search Device Group'
      },
      create: {
        title: 'Create Device Group'
      },
      bind: {
        title: 'Bind Device',
        description:
          'Bind cameras via a device serial number, or a list of devices separated by a comma.',
        placeholder: 'e.g. 011030010112z, 0110300101004'
      },
      deviceBind: {
        title: 'Device Binding'
      },
      cameraBind: {
        title: 'Camera Binding'
      },
      astroDevice: {
        title: 'Astro Device'
      },
      ups: {
        title: 'UPS Monitor'
      },
      eyeviewUser: {
        title: 'Eyeview User Assignment'
      },
      sync: {
        title: 'Sync Camera Service Plan',
        description: 'Set all <0>{{deviceType}}</0> cameras to the same service plan as:'
      },
      assign: {
        title: 'Assign Eyeview User',
        description:
          'Assign device group <0>{{groupName}}</0> to an Eyeview user from the list below'
      },
      delete: {
        title: 'Delete Device Group',
        description: 'Delete the device group <0>{{groupName}}</0>'
      },
      cameraGroupList: {
        title: 'Device Group List'
      },
      general: {
        title: 'General',
        publicIPMismatch:
          'Public IP mismatch detected. Connected devices (camera, Astro, and UPS) have different public IP addresses.'
      }
    },
    cameraGroupListingTable: {
      cameraGroupName: 'Device group',
      camera: {
        title: 'Camera',
        render: '<0>{{numberOfOnline}}</0>/{{length}} online'
      },
      ups: 'Ups',
      accountName: 'Customer',
      comment: 'Comment'
    },
    cameraBindingTable: {
      serialNumber: 'Serial Number',
      cameraName: 'Name',
      type: 'Type',
      status: 'Status',
      bvr: 'BVR',
      lastContact: 'Last Contact',
      serviceStartTime: 'Service Start Time',
      publicIP: 'Public IP',
      usageStartTime: 'Usage Start Time',
      extraFooter: {
        label: 'Total: {{camera}} / {{device}}'
      },
      orderBy: 'Order By:'
    },
    otherDeviceTable: {
      serialNumber: 'Serial Number',
      status: 'Status',
      lastContact: 'Last Contact',
      publicIP: 'Public IP',
      serviceProvider: 'Service Provider',
      assigned: 'Assigned'
    },
    eyeviewUserTable: {
      eyeviewUser: 'Eyeview User'
    },
    emptyTable: {
      title: 'Get Started',
      subTitle: 'By searching group name, customer'
    },
    entity: 'Device Group',
    '404': {
      title: 'Not found',
      subTitle: 'Device Group with this ID has not existed yet'
    },
    errorMessage: {
      cameraGroupId: 'Device Group ID must be a number'
    },
    action: {
      bind: 'Are you sure to bind the camera you have entered?',
      binds: 'Are you sure to bind the cameras you have entered?',
      sync: 'Are you sure to sync camera config from camera',
      assign: {
        from: 'Are you sure to assign device group',
        to: 'to eyeview user'
      }
    }
  },
  cameraGroupType: {
    entity: 'Device Group Type'
  },
  camera: {
    entity: 'Camera'
  },
  cameraConfig: {
    entity: 'Camera Config'
  }
};
