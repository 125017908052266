export const DomainEmptyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <g opacity="0.5">
        <mask
          id="mask0_1688_28531"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="35"
          y="36"
          width="89"
          height="89"
        >
          <rect x="35" y="36" width="89" height="89" fill="#4A61E3" />
        </mask>
        <g mask="url(#mask0_1688_28531)">
          <path
            d="M49 113.875V90.5125H59.0949V77.1625H75.9198V70.4875H65.8248V47.125H92.7446V70.4875H82.6497V77.1625H99.4745V90.5125H109.569V113.875H82.6497V90.5125H92.7446V83.8375H65.8248V90.5125H75.9198V113.875H49ZM72.5548 63.8125H86.0147V53.8H72.5548V63.8125ZM55.7299 107.2H69.1898V97.1875H55.7299V107.2ZM89.3796 107.2H102.84V97.1875H89.3796V107.2Z"
            fill="#21272F"
          />
        </g>
      </g>
    </svg>
  );
};
