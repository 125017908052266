export function SubmittedIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_6007_126009"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect x="0.5" y="0.5" width="31" height="31" fill="#D9D9D9" stroke="black" />
      </mask>
      <g mask="url(#mask0_6007_126009)">
        <path
          d="M14.4882 18.7549L21.668 11.5751L22.8275 12.7346L14.1346 21.4275L9.17508 16.468L10.3346 15.3084L13.7811 18.7549L14.1346 19.1084L14.4882 18.7549ZM4.17776 21.0033L4.17767 21.0031C3.50543 19.4462 3.16797 17.7806 3.16797 16.0013C3.16797 14.222 3.50543 12.5564 4.17767 10.9995L4.17776 10.9993C4.85332 9.43271 5.76806 8.07498 6.92152 6.92152C8.07498 5.76806 9.43271 4.85288 10.9993 4.17643C12.5562 3.50502 14.2219 3.16797 16.0013 3.16797C17.7807 3.16797 19.4464 3.50502 21.0033 4.17643C22.5699 4.85288 23.9276 5.76807 25.0811 6.92152C26.2345 8.07498 27.1493 9.43271 27.8248 10.9993L27.8249 10.9995C28.4972 12.5564 28.8346 14.222 28.8346 16.0013C28.8346 17.7806 28.4972 19.4462 27.8249 21.0031L27.8248 21.0033C27.1493 22.5699 26.2345 23.9276 25.0811 25.0811C23.9276 26.2345 22.5699 27.1493 21.0033 27.8248L21.0031 27.8249C19.4462 28.4972 17.7806 28.8346 16.0013 28.8346C14.222 28.8346 12.5564 28.4972 10.9995 27.8249L10.9993 27.8248C9.43271 27.1493 8.07498 26.2345 6.92152 25.0811C5.76806 23.9276 4.85332 22.5699 4.17776 21.0033ZM16.0013 27.168C19.1089 27.168 21.7591 26.0839 23.9215 23.9215C26.0839 21.7591 27.168 19.1089 27.168 16.0013C27.168 12.8937 26.0839 10.2435 23.9215 8.08108C21.7591 5.91868 19.1089 4.83464 16.0013 4.83464C12.8937 4.83464 10.2435 5.91868 8.08108 8.08108C5.91868 10.2435 4.83464 12.8937 4.83464 16.0013C4.83464 19.1089 5.91868 21.7591 8.08108 23.9215C10.2435 26.0839 12.8937 27.168 16.0013 27.168Z"
          fill="#4A61E3"
          stroke="#4A61E3"
        />
      </g>
    </svg>
  );
}
