export default {
  forms: {
    /*
     * A
     **/
    accountFilter: {
      label: 'Customer Filter:'
    },
    accountId: {
      label: 'Customer:',
      required: 'Name is required.'
    },
    accountName: {
      label: 'Name:',
      required: 'Name is required.',
      pattern:
        'Name must be 4-32 characters long, only contain letters, number, hyphen and underscore.'
    },
    accountType: {
      label: 'Customer Type:',
      required: 'Customer type cannot be empty'
    },
    address: {
      label: 'Address:',
      maxLength: 'Address must not exceed 256 characters.'
    },
    adminType: {
      label: 'Administrator Type:',
      required: 'Field can not empty!'
    },
    /**
     * B
     */
    billingDiscount: {
      label: 'Discount:',
      maxLength: 'Discount must not exceed 32 characters.'
    },
    billingLocalTax: {
      label: 'Local Tax:',
      maxLength: 'Local Tax must not exceed 32 characters.'
    },
    billingRange: {
      end: {
        label: 'End:',
        mustBeAfter: 'End date must be after Start date.',
        mustHasBefore: 'Start date must be selected'
      },
      start: {
        label: 'Start:',
        mustBeBefore: 'Start date must be before End date.'
      }
    },
    billingStateTax: {
      label: 'State Tax:',
      maxLength: 'State Tax must not exceed 32 characters.'
    },
    /**
     * C
     */
    cameraGroupName: {
      label: 'Device Group Name: ',
      required: 'Name is required.',
      pattern:
        'Name must be 8-64 characters long and can only contain letters, numbers, hyphens, underscores, spaces, and colons.'
    },
    cameraGroupType: {
      label: 'Device Group Type:',
      required: 'Device Group Type is required'
    },
    cameraHousing: {
      label: 'Housing:',
      maxLength: 'Housing must not exceed 32 characters.'
    },
    city: {
      label: 'City',
      maxLength: 'City must not exceed 64 characters.'
    },
    comment: {
      label: 'Comment:',
      maxLength: 'Comment must not exceed 256 characters.'
    },
    confirmPassword: {
      label: 'Confirm password:',
      required: 'Confirm Password is required.',
      pattern: 'Confirm Password does not match requirement.',
      notMatching: 'Password and Confirm Password do not match.'
    },
    contact: {
      label: 'Contact:'
    },
    contactName: {
      label: 'Name:',
      maxLength: 'Name must not exceed 64 characters.'
    },
    /**
     * D
     */
    domain: {
      label: 'Domain:',
      required: 'Field can not empty!'
    },
    deviceComment: {
      label: 'Comment:',
      maxLength: 'Comment must not exceed 256 characters.'
    },
    deviceGroup: {
      label: 'Device Group:'
    },
    domainName: {
      label: 'Name:',
      required: 'Name is required.',
      pattern:
        'Name must be 4-32 characters long, only contain letters, number, hyphen and underscore.'
    },
    deviceLocation: {
      label: 'Device Location:',
      maxLength: 'Location must not exceed 256 characters.'
    },
    /**
     * E
     */
    email: {
      label: 'Email:',
      required: 'Email is required.',
      pattern: 'Email format is invalid.',
      maxLength: 'Email must not exceed 64 characters.',
      unique: 'Email must not be duplicated.'
    },
    /**
     * F
     */
    firstName: {
      label: 'First name:',
      required: 'First name is required.',
      maxLength: 'First name must not exceed 64 characters.'
    },
    formsAndLens: {
      label: 'Camera Forms and Lens:'
    },
    /**
     * L
     */
    language: {
      label: 'Language:',
      instruction: 'Select your frontend language'
    },
    lastName: {
      label: 'Last name:',
      required: 'Last name is required.',
      maxLength: 'Last name must not exceed 64 characters.'
    },
    latLon: {
      label: 'Lat/Lon:',
      latValidation: 'Latitude must be between -90 and 90.',
      lonValidation: 'Longitude must be between -180 and 180.'
    },
    lens: {
      maxLength: 'Lens must not exceed 32 characters.',
      customMaxLength: 'Exceeded {{length}} characters.'
    },
    login: {
      label: 'Login:',
      required: 'Login is required.',
      pattern:
        'Username must be 4-32 characters long, only contain lowercase letters, number, hyphen and underscore.'
    },
    location: {
      label: 'Location:',
      maxLength: 'Location must not exceed 256 characters.'
    },
    locationNumber: {
      label: 'Location Number:',
      pattern: 'Location number must not exceed 256 characters.'
    },
    /**
     * N
     */
    name: {
      label: 'Name:',
      required: 'Name is required',
      maxLength: 'Name must not exceed 128 characters.',
      customMaxLength: 'Exceed {{length}} characters.'
    },
    /**
     * P
     */
    password: {
      label: 'Password:',
      required: 'Password is required.',
      pattern: 'Password does not match requirement.'
    },
    permission: {
      label: 'Permission:',
      placeholder: 'Select the permission'
    },
    phone: {
      label: 'Phone:',
      maxLength: 'Phone must not exceed 32 characters.'
    },
    phoneNumber: {
      label: 'Phone Number:',
      maxLength: 'Phone Number must not exceed 32 characters.'
    },
    primaryBVR: {
      label: 'BVR Server',
      required: 'Primary BVR is required.'
    },
    /**
     * S
     */
    serialNumber: {
      label: 'Serial Number:'
    },
    serverIp: {
      label: 'Server IP Address for CPE:'
    },
    serverName: {
      label: 'Server Name:'
    },
    serverUrl: {
      label: 'Service URL for VMS:'
    },
    serverUsage: {
      label: 'Usage Designation:'
    },
    state: {
      label: 'State',
      maxLength: 'State must not exceed 32 characters.'
    },
    street: {
      label: 'Street',
      maxLength: 'Street must not exceed 128 characters.'
    },
    /**
     * T
     */
    type: {
      label: 'Type:'
    },
    /**
     * U
     */
    url: {
      label: 'URL:',
      placeholder: 'URL',
      required: 'URL cannot be empty',
      pattern: 'Not a valid URL',
      maxLength: 'URL must not exceed 64 characters.'
    },
    username: {
      label: 'Username:',
      required: 'Username required',
      pattern:
        'Username must be 4-32 characters long, only contain lowercase letters, number, hyphen and underscore.'
    },
    /**
     * V
     */
    valid: {
      label: 'Valid:',
      required: 'Field can not empty!'
    },
    /**
     * Z
     */
    zip: {
      label: 'Zip',
      maxLength: 'Zip must not exceed 16 characters.'
    }
  }
};
