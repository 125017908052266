import axios, { AxiosError } from 'axios';
import {
  IAuthLocalDataSource,
  IAuthRemoteDataSource,
  TLoginParams
} from 'data/Auth/AuthDataSource';
import { jwtDecode } from 'jwt-decode';
import { TDataResponseEOSS, TLocalAuthProfile, TPermission } from 'models';
import { IAuthRepository } from './AuthRepository';
import { Either, Left, Right } from 'utils/either';
import { ApiErrorResponse, unknownError } from 'models/ApiError';

/**
 * Implementation of the Auth Repository interface.
 * @author hung.nguyen@zien.vn
 */
export class AuthRepositoryImpl implements IAuthRepository {
  remoteDataSource: IAuthRemoteDataSource;

  localDataSource: IAuthLocalDataSource;

  private static instance: AuthRepositoryImpl;

  constructor(remoteDataSource: IAuthRemoteDataSource, localDataSource: IAuthLocalDataSource) {
    this.remoteDataSource = remoteDataSource;
    this.localDataSource = localDataSource;
  }

  /**
   * Get an instance of AuthRepositoryImpl.
   * @param remoteDataSource - The auth data in remote source.
   * @param localDataSource - The auth data in local source.
   * @returns An instance of AuthRepositoryImpl.
   */
  public static getInstance(
    remoteDataSource: IAuthRemoteDataSource,
    localDataSource: IAuthLocalDataSource
  ): AuthRepositoryImpl {
    if (!AuthRepositoryImpl.instance) {
      AuthRepositoryImpl.instance = new AuthRepositoryImpl(remoteDataSource, localDataSource);
    }
    return AuthRepositoryImpl.instance;
  }

  saveToken(accessToken: string): void {
    this.localDataSource.saveToken(accessToken);
  }

  saveExpTime(unixTime: number): void {
    this.localDataSource.saveExpTime(unixTime);
  }

  async login(
    params: TLoginParams
  ): Promise<Either<ApiErrorResponse | undefined, TDataResponseEOSS<TLocalAuthProfile>>> {
    try {
      const { data, code } = await this.remoteDataSource.login(params);
      const decodeToken = jwtDecode(data.jwt);
      this.saveToken(data.jwt);
      if (decodeToken.exp) {
        this.saveExpTime(decodeToken.exp);
      }

      return Right.create({
        code,
        data: {
          ...data,
          username: decodeToken.sub || data.username
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err = error as AxiosError<ApiErrorResponse>;
        return Left.create(err.response?.data);
      }

      return Left.create(unknownError);
    }
  }

  async getProfile(): Promise<TDataResponseEOSS<{ permissions: TPermission[] }>> {
    try {
      const { data, code } = await this.remoteDataSource.getProfile();
      return {
        code,
        data
      };
    } catch (error) {
      const err = error as AxiosError<{ code: number; message: string }>;
      throw JSON.stringify({
        message: err.response?.data.message || err.message,
        status: err.response?.data.code
      });
    }
  }
}
