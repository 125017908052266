import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ServerFieldType, useServerDetailPageController } from './ServerDetailPageController';
import { FormInputTooltip, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Result } from 'antd';
import { Routes } from 'enums';
import styles from './serverDetailPage.module.scss';
import { DEFAULT_PAGE_NUMBER } from 'constant';

function ServerDetailPageView() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { serverType } = useParams<{ serverType: string }>();

  const { currentServer, formGeneral } = useServerDetailPageController();

  if (!currentServer) {
    return (
      <Result
        status="404"
        title={t('serverPage.404.title')}
        subTitle={t('serverPage.404.subTitle')}
      />
    );
  }

  return (
    <div className={`${styles.container} h-100`}>
      <Section title={t('serverPage.sections.general.title')} classNameBody="p-4">
        <Form
          id="serverPage_generalForm"
          form={formGeneral}
          layout="horizontal"
          className="row"
          labelCol={{
            style: {
              width: 250
            }
          }}
          wrapperCol={{ span: 13 }}
        >
          <div className="col-12">
            <FormInputTooltip name="type">
              <Form.Item<ServerFieldType> label={t('forms.type.label')} name="type" colon={false}>
                <Input disabled />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="serialNumber">
              <Form.Item<ServerFieldType>
                label={t('forms.serialNumber.label')}
                name="serialNumber"
                colon={false}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item<ServerFieldType>
                label={t('forms.serverName.label')}
                name="name"
                colon={false}
              >
                <Input disabled />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="ip">
              <Form.Item<ServerFieldType> label={t('forms.serverIp.label')} name="ip" colon={false}>
                <Input disabled />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="url">
              <Form.Item<ServerFieldType>
                label={t('forms.serverUrl.label')}
                name="url"
                colon={false}
              >
                <Input disabled />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="comment">
              <Form.Item<ServerFieldType>
                label={t('forms.comment.label')}
                name="comment"
                colon={false}
              >
                <Input.TextArea disabled autoSize={{ minRows: 2, maxRows: 4 }} />
              </Form.Item>
            </FormInputTooltip>
            <FormInputTooltip name="usage">
              <Form.Item<ServerFieldType>
                label={t('forms.serverUsage.label')}
                name="usage"
                colon={false}
              >
                <Input disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        </Form>
      </Section>
      <div className="text-end pt-2 pb-4">
        <Button
          className={`${styles.cancelBtn} btn-outline me-3`}
          onClick={() => {
            if (!searchParams.get('page')) {
              searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
              setSearchParams(searchParams);
            }
            navigate(
              {
                pathname: `${Routes.EServerRoutes.INDEX}/${serverType}`,
                search: searchParams.toString()
              },
              { replace: true }
            );
          }}
        >
          {t('components.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default ServerDetailPageView;
