import { TAxiosClient } from 'services/axios';
import { ICameraGroupTypeDataSource } from './CameraGroupTypeDataSource';
import { TDataResponseEOSS, TCameraGroupType } from 'models';
import { mapSnakeCaseToCamelCase } from 'utils/common';
/**
 * Represents a remote data source implementation for handling camera group type data.
 * @author giang.bui@zien.vn
 */
export class CameraGroupTypeRemoteDataSourceImpl implements ICameraGroupTypeDataSource {
  httpService: TAxiosClient;

  private static instance: CameraGroupTypeRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of CameraGroupTypeRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {CameraGroupTypeRemoteDataSourceImpl} The singleton instance of CameraGroupTypeRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): CameraGroupTypeRemoteDataSourceImpl {
    if (!CameraGroupTypeRemoteDataSourceImpl.instance) {
      CameraGroupTypeRemoteDataSourceImpl.instance = new CameraGroupTypeRemoteDataSourceImpl(
        httpService
      );
    }
    return CameraGroupTypeRemoteDataSourceImpl.instance;
  }

  async getCameraGroupTypes(): Promise<TDataResponseEOSS<TCameraGroupType[]>> {
    const { status, data } =
      await this.httpService.get<TCameraGroupType[]>('/camera/group/types/all');

    return {
      code: status,
      data: data.map((item: TCameraGroupType) => mapSnakeCaseToCamelCase(item))
    };
  }
}
