import { Button } from 'antd';
import { TCameraGroup } from 'models';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from '../cameraGroupPage.module.scss';

type Props = {
  isSubmitting: boolean;
  currentCameraGroup: TCameraGroup;
  onDeleteCurrentCameraGroup: () => void;
};

const DeleteAction: FC<Props> = ({
  isSubmitting,
  currentCameraGroup,
  onDeleteCurrentCameraGroup
}) => {
  const { t } = useTranslation();

  return (
    <div id="deviceGroupPage_deleteAction" className="d-flex flex-column">
      <div className="fs-12 fw-normal text-black mt-2 mb-3">
        <Trans
          i18nKey="cameraGroupPage.sections.delete.description"
          values={{ groupName: currentCameraGroup?.name ?? '' }}
        >
          <span className="fw-medium" />
        </Trans>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          disabled={isSubmitting}
          className={`${styles.actionBtn} btn-error`}
          onClick={() => {
            onDeleteCurrentCameraGroup();
            const event = new CustomEvent('close-sidebar-action');
            document.dispatchEvent(event);
          }}
        >
          <div className="fs-14 fw-semibold">{t('components.delete')}</div>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DeleteAction);
