import ArrowIcon from 'assets/icons/ArrowIcon';
import { TSortOrder } from 'models';

export const SortIcon = ({ sortOrder }: { sortOrder: TSortOrder }) => {
  return (
    <div id="sort-icon" className="d-flex align-items-center">
      {!sortOrder && (
        <>
          <ArrowIcon width={6} height={8} angle={0} />
          <ArrowIcon width={6} height={8} angle={180} />
        </>
      )}
      {sortOrder && <ArrowIcon width={6} height={8} angle={sortOrder === 'descend' ? 180 : 0} />}
    </div>
  );
};
