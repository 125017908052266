import { Modal, ModalFuncProps, notification } from 'antd';
import { CloseFillIcon, DevelopIcon, SubmittedIcon, WarningIcon } from 'assets/icons';
import { NOTIFICATION_DURATION } from 'constant';
import React, { createContext, useContext } from 'react';

type IUtilModal = {
  title: React.ReactNode;
  icon?: React.ReactNode | null;
  content: React.ReactNode;
  okText: React.ReactNode;
  cancelText: React.ReactNode;
  onOk: () => void | Promise<void>;
};

const DEFAULT_MODAL_TOP = 56;

interface IUtilContext {
  openNotification: (_: {
    type: 'success' | 'error' | 'info';
    title: string;
    description?: string;
    onClose?: () => void;
  }) => void;
  openModal: (
    args: Omit<ModalFuncProps, 'title' | 'icon' | 'content' | 'okText' | 'cancelText' | 'onOk'> &
      IUtilModal
  ) => void;
  destroy: () => void;
}

const UtilContext = createContext<IUtilContext>(null!);

notification.config({ top: 60 });
export function UtilProvider({ children }: { children: React.ReactNode }) {
  const [confirmationAPI, confirmationContextHolder] = Modal.useModal();

  function handleOpen({
    type,
    title,
    description,
    onClose
  }: {
    type: 'success' | 'error' | 'info';
    title: string;
    description?: string;
    onClose?: () => void;
  }) {
    notification.open({
      type,
      message: (
        <span
          style={{
            color: type === 'error' ? 'var(--sub-3)' : 'var(--primary-1)'
          }}
        >
          {title}
        </span>
      ),
      description,
      onClose,
      closeIcon: <CloseFillIcon />,
      duration: type !== 'error' ? NOTIFICATION_DURATION : null,
      icon:
        type === 'error' ? <WarningIcon /> : type === 'info' ? <DevelopIcon /> : <SubmittedIcon />
    });
  }

  const value: IUtilContext = {
    openNotification: ({
      type,
      title,
      description,
      onClose
    }: {
      type: 'success' | 'error' | 'info';
      title: string;
      description?: string;
      onClose?: () => void;
    }) => handleOpen({ type, title, description, onClose }),
    openModal: ({
      title,
      content,
      icon = null,
      okText,
      cancelText,
      style = {
        top: DEFAULT_MODAL_TOP
      },
      mask = false,
      styles,
      onOk,
      ...args
    }: Omit<
      ModalFuncProps,
      'title' | 'icon' | 'content' | 'okText' | 'cancelText' | 'onOk' | 'onCancel'
    > &
      IUtilModal) =>
      confirmationAPI.confirm({
        title,
        icon,
        content,
        okText,
        cancelText,
        style,
        mask,
        styles,
        onOk,
        ...args
      }),
    destroy: () => notification.destroy()
  };

  return (
    <UtilContext.Provider value={value}>
      {confirmationContextHolder}
      {children}
    </UtilContext.Provider>
  );
}

export function useAppUtil() {
  return useContext(UtilContext);
}
