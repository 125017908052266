import React, { useEffect, useRef } from 'react';
import { isValidValue } from 'utils/common';

/**
 * Accepts a function that contains imperative, possibly effectful code.
 *
 * Build in React useEffect hook v18
 */
export const useEffectOnce = (effect: React.EffectCallback, deps: React.DependencyList) => {
  const isCallbackExecuted = useRef<boolean>(false);

  useEffect(() => {
    if (isCallbackExecuted.current === false && deps.every((dep) => isValidValue(dep))) {
      effect();
      isCallbackExecuted.current = true;
    }
  }, deps);
};
