export function CheckCircle() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8536 29.8536L35.625 16.0821L38.4179 18.875L21.5 35.7929L11.5821 25.875L14.375 23.0821L21.1464 29.8536L21.5 30.2071L21.8536 29.8536ZM2.42913 34.552L2.42904 34.5518C1.14391 31.5755 0.5 28.3932 0.5 25C0.5 21.6068 1.14391 18.4245 2.42904 15.4482L2.42913 15.448C3.7168 12.462 5.46259 9.86951 7.66605 7.66605C9.86951 5.4626 12.462 3.71597 15.448 2.42663C18.4244 1.14311 21.6067 0.5 25 0.5C28.3932 0.5 31.5755 1.14308 34.5518 2.42654C37.5379 3.71588 40.1304 5.46254 42.3339 7.66605C44.5374 9.86951 46.2832 12.462 47.5709 15.448L47.571 15.4482C48.8561 18.4245 49.5 21.6068 49.5 25C49.5 28.3932 48.8561 31.5755 47.571 34.5518L47.5709 34.552C46.2832 37.538 44.5374 40.1305 42.3339 42.3339C40.1305 44.5374 37.538 46.2832 34.552 47.5709L34.5518 47.571C31.5755 48.8561 28.3932 49.5 25 49.5C21.6068 49.5 18.4245 48.8561 15.4482 47.571L15.448 47.5709C12.462 46.2832 9.86951 44.5374 7.66605 42.3339C5.46259 40.1305 3.7168 37.538 2.42913 34.552ZM25 45.5C30.7131 45.5 35.5703 43.5118 39.5411 39.5411C43.5118 35.5703 45.5 30.7131 45.5 25C45.5 19.2869 43.5118 14.4297 39.5411 10.4589C35.5703 6.48821 30.7131 4.5 25 4.5C19.2869 4.5 14.4297 6.48821 10.4589 10.4589C6.48821 14.4297 4.5 19.2869 4.5 25C4.5 30.7131 6.48821 35.5703 10.4589 39.5411C14.4297 43.5118 19.2869 45.5 25 45.5Z"
        fill="#4A61E3"
        stroke="#4A61E3"
      />
    </svg>
  );
}
