import { Button, Collapse, Spin } from 'antd';
import { useEyeviewSessionPageController } from './EyeviewSessionPageController';
import EyeviewSessionPanel from './components/EyeviewSessionPanel';
import { ExpandIcon } from 'assets/icons';
import styles from './eyeviewSessionPage.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function EyeviewSessionsPage() {
  const { listVASServer, isLoadingServers } = useEyeviewSessionPageController();
  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);

  const { t } = useTranslation();

  if (isLoadingServers) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className={`h-100 d-flex flex-column wrap-container overflow-y-auto ${styles.container}`}>
      <div>
        <Collapse
          activeKey={activeKeys}
          expandIconPosition="end"
          bordered={false}
          style={{ background: 'var(--primary-4)' }}
          expandIcon={(panelProps) => {
            return <ExpandIcon expanded={panelProps.isActive || false} />;
          }}
          onChange={(key) => setActiveKeys(key)}
          items={listVASServer.map((server) => {
            return {
              label: (
                <div className="py-3 px-4">
                  <div className="fs-14 fw-semibold">{server.name}</div>
                </div>
              ),
              key: server.id,
              className: 'mb-4',
              children: <EyeviewSessionPanel key={server.id} server={server} />
            };
          })}
        />
      </div>
      <div className="mb-6 text-end">
        <Button
          className={`${styles.actionBtn} btn-fill`}
          onClick={() => {
            if (activeKeys.length < listVASServer.length) {
              setActiveKeys(listVASServer.map((val) => val.id.toString()));
            } else {
              setActiveKeys([]);
            }
          }}
        >
          <div>
            {activeKeys.length < listVASServer.length
              ? t('eyeviewSessionPage.actions.expandAll')
              : t('eyeviewSessionPage.actions.collapseAll')}
          </div>
        </Button>
      </div>
    </div>
  );
}

export default EyeviewSessionsPage;
