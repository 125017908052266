import React, { FC } from 'react';

type Props = {
  className?: string;
  isReverse?: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseMove: () => void;
  onMouseLeave: () => void;
};

const CollapseButton: FC<Props> = ({
  className = '',
  isReverse = true,
  onClick,
  onMouseEnter,
  onMouseMove,
  onMouseLeave
}) => {
  if (!isReverse) {
    return (
      <div
        className={`${className} collapse-button cursor-pointer`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <svg
          width="31"
          height="32"
          viewBox="0 0 31 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_9517_23881)">
            <rect x="4" width="23" height="24" rx="11.5" fill="white" />
            <path
              d="M13 17L18 12L13 7"
              stroke="#4A61E3"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_9517_23881"
              x="0"
              y="0"
              width="31"
              height="32"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9517_23881"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9517_23881"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    );
  }

  return (
    <div
      className={`${className} collapse-button cursor-pointer`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_9517_22923)">
          <rect
            x="27"
            y="24"
            width="23"
            height="24"
            rx="11.5"
            transform="rotate(-180 27 24)"
            fill="white"
          />
          <path
            d="M18 7L13 12L18 17"
            stroke="#4A61E3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_9517_22923"
            x="0"
            y="0"
            width="31"
            height="32"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_9517_22923"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_9517_22923"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export { CollapseButton };
