import { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { TOOLTIP_DELAY } from 'constant';
import { TDevice, TSortOrder } from 'models';
import { Link, useSearchParams } from 'react-router-dom';
import { SmartTooltip, SortIcon } from 'presentation/components';
import {
  checkDeviceStatus,
  concatURLSearchParams,
  parseLocalUser,
  secondaryBVRServerDisplay,
  splitLastContact
} from 'utils/common';
import { EAdminTypeDisplayName, EDeviceStatus, EStorageKey } from 'enums';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import styles from './tableListDevice.module.scss';
import { useLoaderContext } from 'context/LoaderContext';

type Props = {
  isLoading: boolean;
  listDevices: TDevice[];
  isIPCamera: boolean;
  isThermalCamera: boolean;
  isThermalOpticalCamera: boolean;
  isUPSMonitor: boolean;
  deviceTypeName?: string;
  orderData?: {
    columnKey: string;
    descend: boolean;
  };
  onChangeSort?: (order?: string | null, columnKey?: string) => void;
};

function TableListDevice({
  listDevices,
  isLoading,
  isUPSMonitor,
  isIPCamera,
  isThermalCamera,
  isThermalOpticalCamera,
  deviceTypeName,
  orderData,
  onChangeSort
}: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const sortKey = searchParams.get('sortKey');
  const page = searchParams.get('page');
  const order = (
    searchParams.get('order')
      ? searchParams.get('order')
      : orderData
      ? orderData?.descend
        ? 'descend'
        : 'ascend'
      : null
  ) as TSortOrder;

  const { isDesktop } = useResponsive();

  const { loader } = useLoaderContext();

  const [clickedId, setClickedId] = useState<number>();

  useEffect(() => {
    return () => {
      setClickedId(undefined);
    };
  }, []);

  const isCustomerDomainAdmin =
    parseLocalUser(localStorage.getItem(EStorageKey.EOSS_CURRENT_USER))?.adminTypeDisplayName ===
    EAdminTypeDisplayName.DOMAIN_ADMIN;

  function checkGroupIsNew(serialNumber: string): boolean {
    const getItem = sessionStorage.getItem(EStorageKey.SERVICE_GROUPS_BINDING_KEYS);
    const listKeys: Array<string> = getItem ? JSON.parse(getItem) : [];
    if (listKeys.length > 0) {
      return Boolean(listKeys.find((key) => key === serialNumber));
    }
    return false;
  }

  const columns: ColumnsType<TDevice> = useMemo(() => {
    return [
      {
        title: t('devicePage.table.columns.model'),
        dataIndex: 'typeName',
        key: 'typeName',
        render: (value: string) => (
          <span data-name="typeName" className="text-nowrap">
            {value}
          </span>
        )
      },
      {
        title: t('devicePage.table.columns.serialNumber'),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        sortIcon: SortIcon,
        sortOrder: sortKey === 'serialNumber' ? order : null,
        sorter: true,
        showSorterTooltip: {
          placement: 'top'
        },
        render: (value, record) => {
          return (
            <Link
              className="text-nowrap"
              data-name="serialNumber"
              to={{
                pathname: `${record.id}`,
                search: searchText
                  ? concatURLSearchParams({
                      search: searchText,
                      page: page,
                      sortKey: sortKey,
                      order: order
                    })
                  : undefined
              }}
              style={{
                pointerEvents: clickedId === record.id ? 'none' : 'auto'
              }}
              onClick={() => {
                loader.start();
                setClickedId(record.id);
              }}
            >
              {value}
            </Link>
          );
        }
      },
      {
        title: t('devicePage.table.columns.version'),
        dataIndex: 'softwareVersion',
        key: 'softwareVersion',
        render: (value: string) => {
          return (
            <span data-name="softwareVersion" className="text-nowrap">
              {value}
            </span>
          );
        },
        showSorterTooltip: {
          placement: 'top'
        },
        sortIcon: SortIcon,
        sortOrder: sortKey === 'softwareVersion' ? order : null,
        sorter: true
      },
      {
        title: t('devicePage.table.columns.account'),
        dataIndex: 'accountName',
        key: 'accountName',
        render: (_, record) => <span data-name="accountName">{record?.account?.name ?? ''}</span>
      },
      {
        title: t('devicePage.table.columns.status'),
        key: 'status',
        render: (value, record) => {
          return checkDeviceStatus(record.lastContactAt) ? (
            <span
              data-name="status"
              className="text-nowrap fw-medium"
              style={{ color: 'var(--sub-5)' }}
            >
              {EDeviceStatus.ONLINE}
            </span>
          ) : (
            <span
              data-name="status"
              className="text-nowrap fw-medium"
              style={{ color: 'var(--sub-3)' }}
            >
              {EDeviceStatus.OFFLINE}
            </span>
          );
        },
        sortIcon: SortIcon,
        sortOrder: sortKey === 'status' ? order : null,
        sorter: true
      }
    ];
  }, [order, sortKey, page, searchText, deviceTypeName, clickedId]);

  const ipCameraColumns: ColumnsType<TDevice> = useMemo(
    () => [
      {
        title: t('devicePage.table.columns.bvrServer'),
        dataIndex: 'servers',
        key: 'servers',
        render: (
          value: Array<{
            id: number;
            name: string;
            publicIp: string;
          }>,
          record: TDevice
        ) => {
          const isCameraOnline = checkDeviceStatus(record.lastContactAt);

          const primaryBvrServer = value[0]?.publicIp;

          if (!primaryBvrServer) {
            return <span data-name="servers"></span>;
          }

          const secondaryServerDisplay = secondaryBVRServerDisplay({
            bvrConnectedIp: record.bvrConnected,
            secondaryBvrServerIp: value[1]?.publicIp,
            tertiaryBvrServerIp: value[2]?.publicIp
          });

          return (
            <span data-name="servers">
              <span
                style={{
                  color: `${
                    record.bvrConnected === primaryBvrServer && isCameraOnline ? 'var(--sub-5)' : ''
                  } `
                }}
              >
                {primaryBvrServer}
              </span>
              {secondaryServerDisplay && (
                <span>
                  {', '}
                  <span
                    style={{
                      color: `${
                        record.bvrConnected === secondaryServerDisplay && isCameraOnline
                          ? 'var(--sub-5)'
                          : ''
                      } `
                    }}
                  >
                    {secondaryServerDisplay}
                  </span>
                </span>
              )}
            </span>
          );
        },
        sortIcon: SortIcon,
        sortOrder: sortKey === 'servers' ? order : null,
        sorter: true
      }
    ],
    [order, sortKey, listDevices.length]
  );

  const upsColumns: ColumnsType<TDevice> = useMemo(() => {
    return [
      {
        title: t('devicePage.table.columns.upsStatus'),
        dataIndex: 'upsStatus',
        key: 'upsStatus',
        // TODO: Fill this column when BE support
        render: () => {
          return (
            <span data-name="upsStatus" className="text-nowrap">
              {'-'}
            </span>
          );
        }
        // TODO: Support sort UPS Status in EP2
        // sortIcon: SortIcon,
        // sortOrder: sortKey === 'upsStatus' ? order : null,
        // sorter: true
      }
    ];
  }, [order, sortKey, listDevices.length]);

  const lastColumn: ColumnsType<TDevice> = useMemo(() => {
    return [
      {
        title: t('devicePage.table.columns.lastContact'),
        dataIndex: 'lastContactAt',
        key: 'lastContactAt',
        render: (value: number) => {
          if (!value) {
            return <div data-name="lastContactAt">{''}</div>;
          }
          const { date, time } = splitLastContact(value);
          return (
            <div data-name="lastContactAt">
              <span>{date}</span>
              <span className="text-nowrap">{time}</span>
            </div>
          );
        },
        sortIcon: SortIcon,
        sortOrder: sortKey === 'lastContactAt' ? order : null,
        sorter: true
      },
      {
        title: t('devicePage.table.columns.location'),
        dataIndex: 'location',
        key: 'location',
        render: (value) => {
          return (
            <SmartTooltip
              name="location"
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              {value}
            </SmartTooltip>
          );
        },
        sortIcon: SortIcon,
        sortOrder: sortKey === 'location' ? order : null,
        sorter: true,
        ellipsis: {
          showTitle: false
        }
      }
    ];
  }, [order, sortKey]);

  const customColumns =
    (isIPCamera || isThermalCamera || isThermalOpticalCamera) && !isCustomerDomainAdmin
      ? ipCameraColumns
      : isUPSMonitor
      ? upsColumns
      : [];

  useEffect(() => {
    const findRow = document.querySelector('.new-group');
    if (findRow) {
      findRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [listDevices]);

  return (
    <Table
      id="devicePage_listingTable"
      loading={isLoading}
      className={styles.container}
      rowKey="id"
      tableLayout="auto"
      dataSource={listDevices}
      columns={[...columns, ...customColumns, ...lastColumn]}
      rowClassName={(record, index) => {
        return `${index % 2 ? 'row-even' : 'row-odd'} ${
          checkGroupIsNew(record.serialNumber) ? 'new-group' : ''
        }`;
      }}
      rowHoverable={false}
      scroll={
        listDevices.length > 0
          ? {
              x: 'auto',
              y: `calc(var(--app-height) - ${isDesktop ? '281px' : '271px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + tabs 36px + 30px (more space))
            }
          : {
              x: 'min-content'
            }
      }
      pagination={false}
      onChange={(_, __, sorter) => {
        const sorterValue = sorter as SorterResult<TDevice>;
        onChangeSort && onChangeSort(sorterValue.order, sorterValue.columnKey?.toString());
      }}
    />
  );
}

export default TableListDevice;
