import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from '../cameraGroupPage.module.scss';
import { ToggleOption } from 'presentation/components';
import { SegmentedValue } from 'antd/es/segmented';
import { Button, Form, FormInstance, Select } from 'antd';
import type { TSyncCameraConfig } from '../cameraGroupPage.types';
import { DropdownIcon } from 'assets/icons';
import type { TCameraBinding, TCameraGroup } from 'models';
import { EDeviceType } from 'enums';

type Props = {
  form: FormInstance<TSyncCameraConfig>;
  currentCameraGroup: TCameraGroup;
  displaySyncToggle: boolean;
  cameraBindingSwitchValue: string;
  deviceBindingExtraOptions: string[];
  currentCameraSelectedInSync?: TCameraBinding;
  isSyncSubmitting: boolean;
  onCameraBindingSwitchChange: (value: SegmentedValue) => void;
  onSyncCameraConfig: (value: string) => void;
};

const SyncAction: FC<Props> = ({
  form,
  currentCameraGroup,
  displaySyncToggle,
  cameraBindingSwitchValue,
  deviceBindingExtraOptions,
  currentCameraSelectedInSync,
  isSyncSubmitting,
  onCameraBindingSwitchChange,
  onSyncCameraConfig
}) => {
  const { t } = useTranslation();

  return (
    <div id="deviceGroupPage_syncAction" className={`${styles.selectWrapper} d-flex flex-column`}>
      {displaySyncToggle && (
        <ToggleOption
          className="mb-2"
          value={cameraBindingSwitchValue}
          stretch
          options={deviceBindingExtraOptions}
          onToggleChange={onCameraBindingSwitchChange}
        />
      )}
      <Form
        form={form}
        onFinish={(values: TSyncCameraConfig) => {
          const { cameraId } = values;
          if (cameraId) {
            onSyncCameraConfig(cameraId);
          }
        }}
      >
        <div className="fs-12 fw-normal text-black mt-2 mb-3">
          <Trans
            i18nKey="cameraGroupPage.sections.sync.description"
            values={{
              deviceType: currentCameraSelectedInSync
                ? (`${currentCameraSelectedInSync.cameraType
                    .at(0)
                    ?.toUpperCase()}${currentCameraSelectedInSync.cameraType
                    .slice(1)
                    .toLowerCase()}` as EDeviceType)
                : ''
            }}
          >
            <span className="fw-medium" />
          </Trans>
        </div>
        <Form.Item<TSyncCameraConfig> name="cameraId" className="mb-3">
          <Select
            className="w-100 mb-3"
            suffixIcon={<DropdownIcon color="var(--primary-1)" />}
            options={currentCameraGroup?.cameras
              .filter(
                (camera: TCameraBinding) =>
                  (camera.cameraType ?? '').toLowerCase() === cameraBindingSwitchValue.toLowerCase()
              )
              .map((value: TCameraBinding) => ({
                label: value.serialNumber,
                value: value.id
              }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option?: { label: string; value: string | number }) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button
            className={`${styles.actionBtn} btn-fill`}
            htmlType="submit"
            disabled={!currentCameraGroup?.cameras.length || isSyncSubmitting}
            onClick={() => {
              const event = new CustomEvent('close-sidebar-action');
              document.dispatchEvent(event);
            }}
          >
            <span className={styles.btnContent}>{t('components.sync')}</span>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(SyncAction);
