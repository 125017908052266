export const useProfilePageController = () => {
  const onUpdateProfile = async (params: any) => {
    return { success: false, error: '' };
  };

  const onChangePassword = async (params: any) => {
    return { success: false, error: '' };
  };

  return {
    profileData: {},
    onUpdateProfile,
    onChangePassword
  };
};
