type Props = {
  active?: boolean;
};

export function ServerIcon({ active = false }: Props) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_274_12411"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_274_12411)">
          <path
            d="M7.5 6C7.08333 6 6.72933 6.146 6.438 6.438C6.146 6.72933 6 7.08333 6 7.5C6 7.91667 6.146 8.27067 6.438 8.562C6.72933 8.854 7.08333 9 7.5 9C7.91667 9 8.27067 8.854 8.562 8.562C8.854 8.27067 9 7.91667 9 7.5C9 7.08333 8.854 6.72933 8.562 6.438C8.27067 6.146 7.91667 6 7.5 6ZM7.5 16C7.08333 16 6.72933 16.146 6.438 16.438C6.146 16.7293 6 17.0833 6 17.5C6 17.9167 6.146 18.2707 6.438 18.562C6.72933 18.854 7.08333 19 7.5 19C7.91667 19 8.27067 18.854 8.562 18.562C8.854 18.2707 9 17.9167 9 17.5C9 17.0833 8.854 16.7293 8.562 16.438C8.27067 16.146 7.91667 16 7.5 16ZM4 3H20C20.2833 3 20.5207 3.09567 20.712 3.287C20.904 3.479 21 3.71667 21 4V11C21 11.2833 20.904 11.5207 20.712 11.712C20.5207 11.904 20.2833 12 20 12H4C3.71667 12 3.47933 11.904 3.288 11.712C3.096 11.5207 3 11.2833 3 11V4C3 3.71667 3.096 3.479 3.288 3.287C3.47933 3.09567 3.71667 3 4 3ZM5 5V10H19V5H5ZM4 13H20C20.2833 13 20.5207 13.0957 20.712 13.287C20.904 13.479 21 13.7167 21 14V21C21 21.2833 20.904 21.5207 20.712 21.712C20.5207 21.904 20.2833 22 20 22H4C3.71667 22 3.47933 21.904 3.288 21.712C3.096 21.5207 3 21.2833 3 21V14C3 13.7167 3.096 13.479 3.288 13.287C3.47933 13.0957 3.71667 13 4 13ZM5 15V20H19V15H5Z"
            className="active-header-icon"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_1_38218" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_38218)">
        <path
          d="M7.5 6C7.08333 6 6.72933 6.146 6.438 6.438C6.146 6.72933 6 7.08333 6 7.5C6 7.91667 6.146 8.27067 6.438 8.562C6.72933 8.854 7.08333 9 7.5 9C7.91667 9 8.27067 8.854 8.562 8.562C8.854 8.27067 9 7.91667 9 7.5C9 7.08333 8.854 6.72933 8.562 6.438C8.27067 6.146 7.91667 6 7.5 6ZM7.5 16C7.08333 16 6.72933 16.146 6.438 16.438C6.146 16.7293 6 17.0833 6 17.5C6 17.9167 6.146 18.2707 6.438 18.562C6.72933 18.854 7.08333 19 7.5 19C7.91667 19 8.27067 18.854 8.562 18.562C8.854 18.2707 9 17.9167 9 17.5C9 17.0833 8.854 16.7293 8.562 16.438C8.27067 16.146 7.91667 16 7.5 16ZM4 3H20C20.2833 3 20.5207 3.09567 20.712 3.287C20.904 3.479 21 3.71667 21 4V11C21 11.2833 20.904 11.5207 20.712 11.712C20.5207 11.904 20.2833 12 20 12H4C3.71667 12 3.47933 11.904 3.288 11.712C3.096 11.5207 3 11.2833 3 11V4C3 3.71667 3.096 3.479 3.288 3.287C3.47933 3.09567 3.71667 3 4 3ZM5 5V10H19V5H5ZM4 13H20C20.2833 13 20.5207 13.0957 20.712 13.287C20.904 13.479 21 13.7167 21 14V21C21 21.2833 20.904 21.5207 20.712 21.712C20.5207 21.904 20.2833 22 20 22H4C3.71667 22 3.47933 21.904 3.288 21.712C3.096 21.5207 3 21.2833 3 21V14C3 13.7167 3.096 13.479 3.288 13.287C3.47933 13.0957 3.71667 13 4 13ZM5 15V20H19V15H5Z"
          className="inactive-header-icon"
        />
      </g>
    </svg>
  );
}

export function SmallServerIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1_33993"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="15"
        height="15"
      >
        <rect width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_33993)">
        <path
          d="M4.6875 3.75C4.42708 3.75 4.20583 3.84125 4.02375 4.02375C3.84125 4.20583 3.75 4.42708 3.75 4.6875C3.75 4.94792 3.84125 5.16917 4.02375 5.35125C4.20583 5.53375 4.42708 5.625 4.6875 5.625C4.94792 5.625 5.16917 5.53375 5.35125 5.35125C5.53375 5.16917 5.625 4.94792 5.625 4.6875C5.625 4.42708 5.53375 4.20583 5.35125 4.02375C5.16917 3.84125 4.94792 3.75 4.6875 3.75ZM4.6875 10C4.42708 10 4.20583 10.0912 4.02375 10.2738C3.84125 10.4558 3.75 10.6771 3.75 10.9375C3.75 11.1979 3.84125 11.4192 4.02375 11.6012C4.20583 11.7838 4.42708 11.875 4.6875 11.875C4.94792 11.875 5.16917 11.7838 5.35125 11.6012C5.53375 11.4192 5.625 11.1979 5.625 10.9375C5.625 10.6771 5.53375 10.4558 5.35125 10.2738C5.16917 10.0912 4.94792 10 4.6875 10ZM2.5 1.875H12.5C12.6771 1.875 12.8254 1.93479 12.945 2.05437C13.065 2.17437 13.125 2.32292 13.125 2.5V6.875C13.125 7.05208 13.065 7.20042 12.945 7.32C12.8254 7.44 12.6771 7.5 12.5 7.5H2.5C2.32292 7.5 2.17458 7.44 2.055 7.32C1.935 7.20042 1.875 7.05208 1.875 6.875V2.5C1.875 2.32292 1.935 2.17437 2.055 2.05437C2.17458 1.93479 2.32292 1.875 2.5 1.875ZM3.125 3.125V6.25H11.875V3.125H3.125ZM2.5 8.125H12.5C12.6771 8.125 12.8254 8.18479 12.945 8.30437C13.065 8.42437 13.125 8.57292 13.125 8.75V13.125C13.125 13.3021 13.065 13.4504 12.945 13.57C12.8254 13.69 12.6771 13.75 12.5 13.75H2.5C2.32292 13.75 2.17458 13.69 2.055 13.57C1.935 13.4504 1.875 13.3021 1.875 13.125V8.75C1.875 8.57292 1.935 8.42437 2.055 8.30437C2.17458 8.18479 2.32292 8.125 2.5 8.125ZM3.125 9.375V12.5H11.875V9.375H3.125Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
