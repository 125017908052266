import { TDataResponseEOSS, TLanguage, TLocalAuthProfile, TPermission } from 'models';
import { TAxiosClient } from 'services/axios';
import { IAuthRemoteDataSource, TLoginParams } from './AuthDataSource';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Implements the IAuthLocalDataSource interface, providing methods for managing authentication data in remote backend.
 *
 * **Singleton Pattern:** Employs the singleton pattern to ensure there's only one instance of this data source for consistency.
 *
 * @see IAuthRemoteDataSource
 * @author hung.nguyen@zien.vn
 */
export type TRemoteAuthProfile = {
  jwt: string;
  permissions: string[];
  admin_type_display_name: string;
  language: TLanguage;
};

export class AuthRemoteDataSourceImpl implements IAuthRemoteDataSource {
  httpService: TAxiosClient;

  private static instance: AuthRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Provides a globally accessible instance of the AuthRemoteDataSourceImpl class. Creates a new instance if one doesn't already exist.
   *
   * @returns {AuthRemoteDataSourceImpl} - The single instance of the AuthRemoteDataSourceImpl class.
   */
  public static getInstance(httpService: TAxiosClient): AuthRemoteDataSourceImpl {
    if (!AuthRemoteDataSourceImpl.instance) {
      AuthRemoteDataSourceImpl.instance = new AuthRemoteDataSourceImpl(httpService);
    }
    return AuthRemoteDataSourceImpl.instance;
  }

  async login({ username, password }: TLoginParams): Promise<TDataResponseEOSS<TLocalAuthProfile>> {
    const { data, status } = await this.httpService.post<TRemoteAuthProfile>(
      '/authentication/login',
      { username, password },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return {
      code: status,
      data: {
        ...data,
        username,
        adminTypeDisplayName: data?.admin_type_display_name
      }
    };
  }

  async getProfile(): Promise<TDataResponseEOSS<{ permissions: TPermission[] }>> {
    const { data, status } = await this.httpService.get<{ permissions: TPermission[] }>(
      '/admin/profile'
    );
    return {
      code: status,
      data: mapSnakeCaseToCamelCase(data)
    };
  }
}
