import { ReactElement, ReactNode } from 'react';
import styles from './emptyPage.module.scss';
import useResponsive from 'hooks/useResponsive';

type EmptyPageProps = {
  showEmpty?: boolean;
  title: string;
  subTitle: string;
  children: ReactNode;
  emptyIcon: ReactElement;
  minHeight?: number;
  className?: string;
};

export const EmptyPage = (props: EmptyPageProps) => {
  const { isDesktop } = useResponsive();
  const { showEmpty = true, title, subTitle, children, emptyIcon, minHeight, className } = props;
  return (
    <>
      {showEmpty && (
        <div
          className={`${styles.container} ${className}`}
          style={{ minHeight: isDesktop ? 490 : 380 }}
        >
          <div className={styles.iconContainer}>{emptyIcon}</div>
          <p className={styles.title}>{title}</p>
          <p className={styles.subTitle}>{subTitle}</p>
        </div>
      )}
      {!showEmpty && children}
    </>
  );
};
