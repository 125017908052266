import { useLoaderContext } from 'context/LoaderContext';
import { DomainGeneralPageView } from './components/DomainGeneralPageView';
import { useEffect } from 'react';
import { useEffectOnce } from 'hooks/useEffectOnce';

function EditDomainPageView() {
  const { loader, isActive } = useLoaderContext();

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  return (
    <>
      <DomainGeneralPageView editMode={true} />
    </>
  );
}

export default EditDomainPageView;
