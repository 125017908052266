import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './noPermissionPageView.module.scss';
import { usePermissionContext } from 'context/PermissionContext';

const NoPermissionIcon = () => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="80" cy="80" r="80" fill="#F46363" />
      <path
        d="M99.5 68.75H96.25V62.25C96.25 53.3125 88.9375 46 80 46C71.0625 46 63.75 53.3125 63.75 62.25V68.75H60.5C56.925 68.75 54 71.675 54 75.25V104.5C54 108.075 56.925 111 60.5 111H99.5C103.075 111 106 108.075 106 104.5V75.25C106 71.675 103.075 68.75 99.5 68.75ZM83.9585 102.23H82.2786H78.245H76.5651L77.6521 94.1477C76.1462 93.3186 75.125 91.7159 75.125 89.875C75.125 87.1826 77.3076 85 80 85C82.6924 85 84.875 87.1826 84.875 89.875C84.875 91.5116 84.0683 92.9589 82.8311 93.8429L83.9585 102.23ZM89.75 68.75H70.25V62.25C70.25 56.8875 74.6375 52.5 80 52.5C85.3625 52.5 89.75 56.8875 89.75 62.25V68.75Z"
        fill="#F46363"
      />
    </svg>
  );
};

type Props = {
  isSubPage?: boolean;
};

const NoPermissionPageView = (props: Props) => {
  const navigate = useNavigate();
  const { isSubPage = false } = props;
  const { permissionArray } = usePermissionContext();

  return (
    <div className={`${styles.container} animate__animated animate__fade h-100`}>
      <div className={isSubPage ? styles.subPageContainer : styles.rootContainer}>
        <Result
          status={'error'}
          title="No Permission!"
          subTitle="No Permission to use this frontend module!"
          className="text-center m-auto"
          icon={<NoPermissionIcon />}
        >
          {!isSubPage && (
            <button
              className="btn btn-outline py-1 px-6 m-1"
              onClick={() => permissionArray.length > 0 && navigate(`/${permissionArray[0]}`)}
            >
              Back
            </button>
          )}
        </Result>
      </div>
    </div>
  );
};

export default NoPermissionPageView;
