enum EAuthRoutes {
  LOGIN_PAGE = '/login',
  RESET_PASSWORD = '/reset-password'
}

enum EHomeRoutes {
  INDEX = '/'
}

export enum EAdminRoutes {
  LISTING = '/admin',
  CREATE = '/admin/create',
  DETAILED = '/admin/:adminUserId'
}

enum EDomainRoutes {
  LISTING = '/domain',
  CREATE = '/domain/create',
  DETAILED = '/domain/:domainId'
}

enum EAccountRoutes {
  LISTING = '/account',
  CREATE = '/account/create',
  DETAIL = '/account/:accountId'
}

enum ECameraGroupRoutes {
  LISTING = '/camera-group',
  CREATE = '/camera-group/create',
  DETAILED = '/camera-group/:cameraGroupId',
  CLONE = '/camera-group/:cameraGroupId/clone'
}

enum EUserRoutes {
  LISTING = '/user',
  CREATE = '/user/create',
  DETAIL = '/user/:userId',
  CLONE = '/user/:userId/clone'
}

enum EDevicesRoutes {
  INDEX = '/device',
  LISTING = '/device/:deviceType',
  DETAILED = '/device/:deviceType/:deviceId'
}

enum EEyeviewSessionsRoutes {
  LISTING = '/eyeview-sessions'
}

enum EServerRoutes {
  INDEX = '/server',
  LISTING = '/server/:serverType',
  DETAILED = '/server/:serverType/:serialNumber'
}

enum ESystemRoutes {
  INDEX = '/system',
  SERVICE = '/system/sys-service',
  PERMISSION = '/system/sys-permission',
  DEVICE_TYPE = '/system/sys-device-type',
  ACCOUNT_TYPE = '/system/sys-account-type',
  SERVER_TYPE = '/system/sys-server-type'
}

export const Routes = {
  EAuthRoutes,
  EHomeRoutes,
  EAdminRoutes,
  EDomainRoutes,
  EAccountRoutes,
  EDevicesRoutes,
  ECameraGroupRoutes,
  EUserRoutes,
  EEyeviewSessionsRoutes,
  EServerRoutes,
  ESystemRoutes
};
