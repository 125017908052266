import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode } from 'enums';
import { TCameraGroup, TPaginationEOSS } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { CameraGroupRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

export const useCameraGroupListingPageController = () => {
  const cameraGroupRepository = CameraGroupRepository(AxiosClient);

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { openNotification } = useAppUtil();

  const searchStr = searchParams.get('search');
  const accountId = searchParams.get('accountId');
  const pageNumber = searchParams.get('page');

  const [listCameraGroup, setListCameraGroup] = useState<{
    data: TCameraGroup[];
    isLoading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    isLoading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  const { refetchCount } = useOutletContext<{ refetchCount: number }>();

  async function handleFetchCameraGroupList({
    search,
    accountId,
    pageNumber
  }: {
    search: string;
    accountId?: number;
    pageNumber: number;
  }) {
    if (listCameraGroup.isLoading) {
      setListCameraGroup((prev) => ({
        ...prev,
        isLoading: false
      }));
      return;
    }
    setListCameraGroup((prev) => ({
      ...prev,
      isLoading: true
    }));
    try {
      const { data, code, page } = await cameraGroupRepository.getListCameraGroup({
        search,
        accountId,
        page: pageNumber
      });

      if (code === EHttpStatusCode.OK) {
        setListCameraGroup({
          data,
          isLoading: false,
          paging: page
            ? {
                ...page,
                pageNum: page.pageNum + 1
              }
            : {
                pageLimit: DEFAULT_LIMIT,
                pageNum: DEFAULT_PAGE_NUMBER,
                total: data.length,
                totalPage: Math.ceil(data.length / DEFAULT_LIMIT)
              }
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('accountPage.entity'),
        identifier: `ID ${accountId ?? ''}`,
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.search')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
      setListCameraGroup((prev) => ({
        ...prev,
        isLoading: false,
        paging: {
          pageLimit: DEFAULT_LIMIT,
          pageNum: DEFAULT_PAGE_NUMBER,
          total: 0,
          totalPage: 0
        }
      }));
    }
  }

  const handlePageChange = useCallback(
    (page: number) => {
      if (page > 0) {
        searchParams.set('page', page.toString());
        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams]
  );

  function refetchList() {
    if (!searchStr) return;
    handleFetchCameraGroupList({
      accountId: accountId ? Number(accountId) : undefined,
      search: searchStr,
      pageNumber: Number(pageNumber)
    });
  }

  useEffect(() => {
    if (searchStr && searchStr.length > 0) {
      handleFetchCameraGroupList({
        accountId: accountId ? Number(accountId) : undefined,
        search: searchStr,
        pageNumber: Number(pageNumber)
      });
    } else {
      setListCameraGroup({
        data: [],
        isLoading: false,
        paging: {
          pageLimit: DEFAULT_LIMIT,
          pageNum: DEFAULT_PAGE_NUMBER,
          total: 0,
          totalPage: 0
        }
      });
    }
  }, [searchStr, pageNumber, accountId]);

  useEffect(() => {
    if (refetchCount > 0) {
      refetchList();
    }
  }, [refetchCount]);

  useEffect(() => {
    const notANumber = Number.isNaN(Number(pageNumber));
    const isPositiveNumber = Number(pageNumber) < 1;
    const isDecimal = Math.floor(Number(pageNumber)) !== Number(pageNumber);

    if (pageNumber && (notANumber || isPositiveNumber)) {
      searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
      setSearchParams(searchParams);
    }
    if (pageNumber && !notANumber && isDecimal) {
      searchParams.set('page', `${Math.floor(Number(pageNumber))}`);
      setSearchParams(searchParams);
    }
  }, [pageNumber]);

  return {
    listCameraGroup,
    onPageChange: handlePageChange
  };
};
