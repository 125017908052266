import { Form } from 'antd';
import { EStatusInvalid } from 'enums';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserGeneral from '../../components/UserGeneral';
import UserPermission from '../../components/UserPermission';
import styles from './createEyeviewUserPage.module.scss';
import { useCreateEyeviewUserPageController } from './CreateEyeviewUserPageController';
import { TEyeviewUserField } from '../../EyeviewUserPageController';
import { TPostEyeviewUserPayload } from 'repositories/EyeviewUser/EyeviewUserRepository';
import { useAppUtil } from 'context/UtilContext';
import { FormAction } from 'presentation/components';

export default function CreateEyeviewUserPageView() {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { openModal } = useAppUtil();

  const {
    createEyeviewUserForm,
    selectedPermission,
    permissionData,
    accountList,
    listValidation,
    listLanguage,
    addPermission,
    removePermission,
    onCancel,
    onFormSubmit
  } = useCreateEyeviewUserPageController();

  const handleFormSubmit = async (value: TEyeviewUserField) => {
    const data: TPostEyeviewUserPayload = {
      accountId: Number(value.accountId),
      address: JSON.stringify({
        street: (value?.street ?? '').trim(),
        city: (value?.city ?? '').trim(),
        state: (value?.state ?? '').trim(),
        zip: (value?.zip ?? '').trim()
      }),
      comment: (value?.comment ?? '').trim(),
      contact: JSON.stringify({
        email: (value?.email ?? '').trim(),
        phone: (value?.phone ?? '').trim()
      }),
      firstName: value.firstName.trim(),
      invalid: value.invalid,
      languageId: value.languageId,
      lastName: value.lastName.trim(),
      password: value.password,
      username: value.username.trim(),
      permissionIds: permissionData.data.toString()
    };
    await onFormSubmit(data);
  };

  const handleConfirmSubmit = (values: TEyeviewUserField) => {
    setIsSubmitting(true);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('components.confirmationMessage', {
        action: t('components.create').toLowerCase(),
        entity: t('header.user').toLowerCase()
      }),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 340
        }
      },
      onOk: async () => {
        await handleFormSubmit(values);
        setIsSubmitting(false);
      },
      onCancel: () => setIsSubmitting(false)
    });
  };

  useEffect(() => {
    if (createEyeviewUserForm && listValidation.length > 0) {
      createEyeviewUserForm.setFieldValue('invalid', EStatusInvalid.VALID);
    }
  }, [listValidation]);

  useEffect(() => {
    if (createEyeviewUserForm && accountList.data.length > 0) {
      createEyeviewUserForm.setFieldValue('accountId', accountList.data[0].id);
    }
  }, [accountList]);

  useEffect(() => {
    if (listLanguage.length > 0) {
      createEyeviewUserForm.setFieldValue('languageId', listLanguage[0]?.id);
    }
  }, [listLanguage]);

  return (
    <div className={styles.container}>
      <Form
        form={createEyeviewUserForm}
        className="row gx-0 gx-md-5"
        colon={false}
        labelCol={{
          style: {
            width: 135
          }
        }}
        scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
        autoComplete="off"
        layout="horizontal"
        onFinish={(value: TEyeviewUserField) => handleConfirmSubmit(value)}
      >
        <div className="col-12 col-slg-8">
          <UserGeneral
            accountList={{
              data: accountList.data,
              loading: accountList.loading
            }}
            validationList={listValidation}
          />
        </div>

        <div className="col-12 col-slg-4">
          <UserPermission
            dataPermission={permissionData.data}
            addPermission={addPermission}
            removePermission={removePermission}
            listPermission={selectedPermission}
          />
        </div>

        <FormAction
          onCancel={onCancel}
          SubmitTypographyProps={{
            htmlType: 'submit',
            disabled: isSubmitting
          }}
        />
      </Form>
    </div>
  );
}
