import { TAdminUser, TAdminUserType, TDataResponseEOSS } from 'models';
import { TAxiosClient } from 'services/axios';
import { IAdministratorDataSource } from './AdministratorDataSource';
import { EStatusInvalid } from 'enums';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Represents the data transfer object (DTO) for an administrator administrator.
 * @interface {Object} TAdminUserDTO
 * @property {number} id - The unique identifier of the administrator.
 * @property {string} username - The username of the administrator.
 * @property {EStatusInvalid} invalid - The status of administrator's validity.
 * @property {Object} domain - The domain information of the administrator.
 * @property {number} domain.id - The unique identifier of the domain.
 * @property {string} domain.name - The name of the domain.
 * @property {number} [domain.invalid] - The invalid status of the domain (optional).
 * @property {string} [domain.comment] - Any additional comment related to the domain (optional).
 * @property {string} comment - Any comment related to the administrator.
 * @property {string} first_name - The first name of the administrator.
 * @property {string} last_name - The last name of the administrator.
 * @property {string} contact - The contact information of the administrator: Json Blob data.
 * @property {string | null} address - The address of the administrator (nullable): Json Blob data.
 * @property {Object} admin_type - The type of administrator administrator.
 * @property {number} admin_type.id - The unique identifier of the admin type.
 * @property {string} admin_type.name - The name of the admin type.
 * @property {string} admin_type.display_name - The display name of the admin type.
 * @property {string | null} admin_type.description - The description of the admin type (nullable).
 * @property {Array<Object>} admin_type.permissions - The permissions associated with the admin type.
 * @property {number} admin_type.permissions.id - The unique identifier of the permission.
 * @property {string} admin_type.permissions.name - The name of the permission.
 * @property {Array<Object>} adhoc_permissions - Any ad-hoc permissions associated with the user.
 * @property {number} adhoc_permissions.id - The unique identifier of the ad-hoc permission.
 * @property {string} adhoc_permissions.name - The name of the ad-hoc permission.
 */
type TAdminUserDTO = {
  id: number;
  username: string;
  invalid: EStatusInvalid;
  domain: {
    id: number;
    name: string;
    invalid?: number;
    comment?: string;
  } | null;
  comment: string | null;
  first_name: string;
  last_name: string;
  contact: string;
  address: string | null;
  admin_type: {
    id: number;
    name: string;
    display_name: string;
    description: string | null;
    permissions: {
      id: number;
      name: string;
    }[];
  };
  adhoc_permissions: {
    id: number;
    name: string;
  }[];
};

/**
 * Represents a remote data source implementation for handling administrator-related data.
 * @author hung.nguyen@zien.vn
 */
export class AdministratorRemoteDataSourceImpl implements IAdministratorDataSource {
  httpService: TAxiosClient;

  private static instance: AdministratorRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  public static getInstance(httpService: TAxiosClient): AdministratorRemoteDataSourceImpl {
    if (!AdministratorRemoteDataSourceImpl.instance) {
      AdministratorRemoteDataSourceImpl.instance = new AdministratorRemoteDataSourceImpl(
        httpService
      );
    }
    return AdministratorRemoteDataSourceImpl.instance;
  }

  async getListAdminUsers({
    search
  }: {
    search: string;
  }): Promise<TDataResponseEOSS<TAdminUser[]>> {
    const requestParams = new URLSearchParams({
      search: search
    });
    const { data, status } = await this.httpService.get<TAdminUserDTO[]>('/admin/search', {
      params: requestParams
    });
    return { data: data.map((val) => mapSnakeCaseToCamelCase(val)), code: status };
  }

  async getAdminUserById({ id }: { id: number }): Promise<TDataResponseEOSS<TAdminUser>> {
    const { data, status } = await this.httpService.get<TAdminUserDTO>(`/admin/${id}`);
    return {
      data: mapSnakeCaseToCamelCase(data),
      code: status
    };
  }

  async createAdminUser({
    admin_type_id,
    domain_id,
    first_name,
    language_id,
    last_name,
    address,
    invalid,
    password,
    username,
    comment,
    contact,
    adhoc_permission_ids
  }: {
    username: string;
    password: string;
    first_name: string;
    last_name: string;
    address: string;
    contact: string;
    admin_type_id: number;
    invalid: number;
    domain_id?: number | undefined;
    language_id: number;
    comment: string;
    adhoc_permission_ids: string;
  }): Promise<TDataResponseEOSS<TAdminUser>> {
    const { data, status } = await this.httpService.post<TAdminUserDTO>(
      '/admin',
      {
        admin_type_id,
        username,
        first_name,
        last_name,
        language_id,
        password,
        invalid,
        address,
        comment,
        contact,
        domain_id,
        adhoc_permission_ids
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return { data: mapSnakeCaseToCamelCase(data), code: status };
  }

  async updateAdminUser({
    id,
    admin_type_id,
    domain_id,
    first_name,
    language_id,
    last_name,
    address,
    password,
    username,
    comment,
    invalid,
    contact,
    adhoc_permission_ids
  }: {
    id: number;
    username: string;
    password?: string;
    first_name: string;
    last_name: string;
    address: string;
    contact: string;
    admin_type_id: number;
    invalid: number;
    domain_id?: number;
    language_id: number;
    comment: string;
    adhoc_permission_ids: string;
  }): Promise<TDataResponseEOSS<TAdminUser>> {
    const { data, status } = await this.httpService.put<TAdminUserDTO>(
      `/admin/${id}`,
      {
        admin_type_id,
        username,
        first_name,
        last_name,
        language_id,
        password,
        invalid,
        address,
        comment,
        contact,
        domain_id,
        adhoc_permission_ids
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return { data: mapSnakeCaseToCamelCase(data), code: status };
  }
}
