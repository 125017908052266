import { UserBlankIcon } from 'assets/icons';
import { EmptyPage, Pagination, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEyeviewUserListingPageController } from './EyeviewUserListingPageController';
import TableEyeviewUserListing from '../../components/TableEyeviewUserListing';

export default function EyeviewUserListingPageView() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const searchKey = searchParams.get('search');
  const { eyeviewUserList, onPageChange } = useEyeviewUserListingPageController();

  return (
    <>
      <Section title={t('userPage.sections.listing.title')}>
        <EmptyPage
          showEmpty={!searchKey}
          emptyIcon={<UserBlankIcon />}
          subTitle={t('userPage.sections.listing.emptyDescription')}
          title={t('components.getStarted')}
        >
          <TableEyeviewUserListing eyeviewUserList={eyeviewUserList} />
        </EmptyPage>
      </Section>
      <Pagination
        total={eyeviewUserList.paging.total}
        pageSize={eyeviewUserList.paging.pageLimit}
        initPageNumber={eyeviewUserList.paging.pageNum}
        onChange={(page: number) => onPageChange(page)}
      />
    </>
  );
}
