import { ICameraGroupDataSource } from 'data/CameraGroup/CameraGroupDataSource';
import { IEyeviewUserDataSource } from 'data/EyeviewUser/EyeviewUserDataSource';
import { EHttpStatusCode, EStatusInvalid } from 'enums';
import type {
  TAccount,
  TCameraGroup,
  TCameraGroupType,
  TDataResponseEOSS,
  TEyeviewUser,
  TSortOrder
} from 'models';
import { ICameraGroupRepository } from './CameraGroupRepository';
import { ICameraGroupTypeDataSource } from 'data/CameraGroupType/CameraGroupTypeDataSource';
import { IAccountDataSource } from 'data/Account/AccountDataSource';
import { convertAxiosErrorToApiError } from 'utils/common';

/**
 * Implementation of the Camera Group Repository interface.
 * @author giang.bui@zien.vn
 */
export class CameraGroupRepositoryImpl implements ICameraGroupRepository {
  cameraGroupDataSource: ICameraGroupDataSource;

  cameraGroupTypeDataSource: ICameraGroupTypeDataSource;

  accountDataSource: IAccountDataSource;

  eyeviewUserDataSource: IEyeviewUserDataSource;

  private static instance: CameraGroupRepositoryImpl;

  constructor(
    cameraGroupDataSource: ICameraGroupDataSource,
    accountDataSource: IAccountDataSource,
    eyeviewUserDataSource: IEyeviewUserDataSource,
    cameraGroupTypeDataSource: ICameraGroupTypeDataSource
  ) {
    this.cameraGroupDataSource = cameraGroupDataSource;
    this.accountDataSource = accountDataSource;
    this.eyeviewUserDataSource = eyeviewUserDataSource;
    this.cameraGroupTypeDataSource = cameraGroupTypeDataSource;
  }

  /**
   * Get an instance of CameraGroupRepositoryImpl.
   * @param cameraGroupDataSource - The camera group data source.
   * @param accountDataSource - The account data source.
   * @param eyeviewUserDataSource - The Eyeview User data source.
   * @param cameraGroupTypeDataSource - The camera group type data source.
   * @returns An instance of CameraGroupRepositoryImpl.
   */
  public static getInstance(
    cameraGroupDataSource: ICameraGroupDataSource,
    accountDataSource: IAccountDataSource,
    eyeviewUserDataSource: IEyeviewUserDataSource,
    cameraGroupTypeDataSource: ICameraGroupTypeDataSource
  ): CameraGroupRepositoryImpl {
    if (!CameraGroupRepositoryImpl.instance) {
      CameraGroupRepositoryImpl.instance = new CameraGroupRepositoryImpl(
        cameraGroupDataSource,
        accountDataSource,
        eyeviewUserDataSource,
        cameraGroupTypeDataSource
      );
    }
    return CameraGroupRepositoryImpl.instance;
  }

  async unassignedUsersFromCameraGroup({
    cameraGroupId,
    userIds
  }: {
    cameraGroupId: number;
    userIds: string[];
  }): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.unassignedUsersFromCameraGroup({
        camera_group_id: cameraGroupId,
        user_ids: userIds.toString()
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async assignEyeviewUserToCameraGroup({
    cameraGroupId,
    userId
  }: {
    cameraGroupId: number;
    userId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.assignEyeviewUserToCameraGroup({
        camera_group_id: cameraGroupId.toString(),
        user_id: userId.toString()
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListEyeviewUserByAccountName({
    accountName
  }: {
    accountName: string;
  }): Promise<TDataResponseEOSS<TEyeviewUser[]>> {
    try {
      const res = await this.eyeviewUserDataSource.getListEyeviewUser('*');
      return {
        ...res,
        data: res.data.filter(
          (value: TEyeviewUser) =>
            value.account.name === accountName && value.invalid === EStatusInvalid.VALID
        )
      };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async syncCameraConfig({
    cameraGroupId,
    cameraId
  }: {
    cameraGroupId: number;
    cameraId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.syncCameraConfig({
        camera_group_id: cameraGroupId.toString(),
        camera_id: cameraId.toString()
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async unbindCamerasFromCameraGroup({
    cameraSerials,
    groupsId
  }: {
    cameraSerials: string;
    groupsId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.unbindCamerasFromCameraGroup({
        camera_serials: cameraSerials,
        groups_id: groupsId
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateCameraById({
    cameraGroupId,
    name,
    cameraGroupTypeId,
    description,
    latitude,
    longitude,
    address,
    locationNumber
  }: {
    cameraGroupId: number;
    name: string;
    cameraGroupTypeId: number;
    description: string;
    latitude?: number;
    longitude?: number;
    address?: string;
    locationNumber: string | null;
  }): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.updateCameraById({
        camera_group_id: cameraGroupId,
        name,
        camera_group_type_id: cameraGroupTypeId,
        description,
        lat: latitude,
        lon: longitude,
        address,
        location_number: locationNumber
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async bindCameraToCameraGroup({
    serialNumbers,
    cameraGroupId
  }: {
    serialNumbers: string;
    cameraGroupId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.bindCameraToCameraGroup({
        serial_numbers: serialNumbers,
        camera_group_id: cameraGroupId
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async deleteCameraGroup(cameraGroupId: number): Promise<EHttpStatusCode> {
    try {
      const code = await this.cameraGroupDataSource.deleteCameraGroup(cameraGroupId);
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListCameraGroupType(): Promise<TDataResponseEOSS<TCameraGroupType[]>> {
    try {
      const res = await this.cameraGroupTypeDataSource.getCameraGroupTypes();
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getAllAccounts(): Promise<TDataResponseEOSS<TAccount[]>> {
    try {
      const res = await this.accountDataSource.getListAccounts('*');
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListValidEyeviewUser(): Promise<TDataResponseEOSS<TEyeviewUser[]>> {
    try {
      const res = await this.eyeviewUserDataSource.getListEyeviewUser('*');
      return {
        ...res,
        data: res.data.filter((data: TEyeviewUser) => data.invalid === EStatusInvalid.VALID)
      };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListCameraGroup({
    search,
    accountId,
    page,
    order
  }: {
    search: string;
    accountId?: number;
    page: number;
    order?: TSortOrder;
  }): Promise<TDataResponseEOSS<TCameraGroup[]>> {
    try {
      const res = await this.cameraGroupDataSource.getListCameraGroup({
        search,
        account_id: accountId,
        page: (page - 1).toString(),
        order
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async createCameraGroup({
    name,
    accountId,
    cameraGroupTypeId,
    comment,
    latitude,
    longitude,
    address,
    locationNumber
  }: {
    name: string;
    accountId: number;
    cameraGroupTypeId: number;
    comment: string;
    latitude?: number;
    longitude?: number;
    address?: string;
    locationNumber: string | null;
  }): Promise<{ code: EHttpStatusCode }> {
    try {
      const res = await this.cameraGroupDataSource.createCameraGroup({
        name,
        account_id: accountId,
        camera_group_type_id: cameraGroupTypeId,
        description: comment,
        lat: latitude,
        lon: longitude,
        address,
        location_number: locationNumber
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getCameraGroupById(cameraGroupId: number): Promise<TDataResponseEOSS<TCameraGroup>> {
    try {
      const res = await this.cameraGroupDataSource.getCameraGroupById(cameraGroupId);
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
