import { IAccountDataSource } from 'data/Account/AccountDataSource';
import { IPermissionDataSource } from 'data/Permission/PermissionDataSource';
import { IEyeviewUserDataSource } from 'data/EyeviewUser/EyeviewUserDataSource';
import type {
  TAccount,
  TCameraGroupAvailable,
  TDataResponseEOSS,
  TEyeviewUser,
  TEyeviewUserAddress,
  TEyeviewUserContact,
  TPermission
} from 'models';
import {
  IUserRepository,
  TPostEyeviewUserPayload,
  TPutEyeviewUserPayload
} from './EyeviewUserRepository';
import { EHttpStatusCode, EStatusInvalid } from 'enums';
import { convertAxiosErrorToApiError, isJsonString } from 'utils/common';
import { ICameraGroupDataSource } from 'data/CameraGroup/CameraGroupDataSource';
import { orderBy } from 'lodash';

/**
 * Implementation of the Eyeview User Repository interface.
 * @interface IUserRepository
 * @author giang.bui@zien.vn
 */
export class UserRepositoryImpl implements IUserRepository {
  eyeviewUserDataSource: IEyeviewUserDataSource;

  accountDataSource: IAccountDataSource;

  permissionDataSource: IPermissionDataSource;

  cameraGroupDataSource: ICameraGroupDataSource;

  private static instance: UserRepositoryImpl;

  constructor(
    eyeviewUserDataSource: IEyeviewUserDataSource,
    accountDataSource: IAccountDataSource,
    permissionDataSource: IPermissionDataSource,
    cameraGroupDataSource: ICameraGroupDataSource
  ) {
    this.eyeviewUserDataSource = eyeviewUserDataSource;
    this.accountDataSource = accountDataSource;
    this.permissionDataSource = permissionDataSource;
    this.cameraGroupDataSource = cameraGroupDataSource;
  }

  /**
   * Gets the singleton instance of UserRepositoryImpl.
   * @param {IEyeviewUserDataSource} eyeviewUserDataSource - The Eyeview User data source.
   * @param {IAccountDataSource} accountDataSource - The Account data source.
   * @param {IPermissionDataSource} permissionDataSource - The Permission data source.
   * @param {ICameraGroupDataSource} cameraGroupDataSource - The Camera Group data source.
   * @returns {UserRepositoryImpl} The singleton instance of UserRepositoryImpl.
   */
  public static getInstance(
    eyeviewUserDataSource: IEyeviewUserDataSource,
    accountDataSource: IAccountDataSource,
    permissionDataSource: IPermissionDataSource,
    cameraGroupDataSource: ICameraGroupDataSource
  ): UserRepositoryImpl {
    if (!UserRepositoryImpl.instance) {
      UserRepositoryImpl.instance = new UserRepositoryImpl(
        eyeviewUserDataSource,
        accountDataSource,
        permissionDataSource,
        cameraGroupDataSource
      );
    }
    return UserRepositoryImpl.instance;
  }

  async unassignCameraGroupsFromEyeviewUser({
    eyeviewUserId,
    cameraGroupIds
  }: {
    eyeviewUserId: number;
    cameraGroupIds: string[];
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.cameraGroupDataSource.unassignCameraGroupsFromEyeviewUser({
        camera_group_ids: cameraGroupIds.toString(),
        eyeview_user_id: eyeviewUserId
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListCameraGroupByUserId({
    search,
    userId,
    page
  }: {
    search?: string | undefined;
    userId: number;
    page?: number | undefined;
  }): Promise<TDataResponseEOSS<TCameraGroupAvailable>> {
    try {
      const res = await this.cameraGroupDataSource.getListCameraGroupsAvailable({
        userId,
        page,
        search
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getAllValidAccountList(): Promise<TDataResponseEOSS<TAccount[]>> {
    try {
      const res = await this.accountDataSource.getListAccounts('*');
      return {
        ...res,
        data: res.data.filter((value: TAccount) => value.invalid === EStatusInvalid.VALID)
      };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListEyeviewUser(search: string): Promise<TDataResponseEOSS<TEyeviewUser[]>> {
    try {
      const res = await this.eyeviewUserDataSource.getListEyeviewUser(search);
      return {
        ...res,
        data: orderBy(res.data, 'username', 'asc')
      };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getEyeviewUserById(id: number): Promise<TDataResponseEOSS<TEyeviewUser>> {
    try {
      const res = await this.eyeviewUserDataSource.getEyeviewUserById(id);
      const data = res.data;
      const address: TEyeviewUserAddress =
        data.address && isJsonString(data.address)
          ? JSON.parse(data.address)
          : {
              city: '',
              state: '',
              street: '',
              zip: ''
            };
      const contact: TEyeviewUserContact =
        data.contact && isJsonString(data.contact)
          ? JSON.parse(data.contact)
          : {
              email: '',
              phone: ''
            };
      return {
        ...res,
        data: {
          ...data,
          accountId: data.account.id,
          address,
          contact,
          languageId: data.language.id,
          password: '',
          permissionIds: (data.permissions ?? []).map(
            (permission: { id: number; name: string }) => permission.id
          )
        }
      };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async createEyeviewUser(payload: TPostEyeviewUserPayload): Promise<EHttpStatusCode> {
    try {
      const res = await this.eyeviewUserDataSource.createEyeviewUser({
        account_id: payload.accountId.toString(),
        address: payload.address,
        comment: payload.comment,
        contact: payload.contact,
        first_name: payload.firstName,
        invalid: payload.invalid.toString(),
        language_id: payload.languageId.toString(),
        last_name: payload.lastName,
        password: payload.password,
        username: payload.username,
        permission_ids: payload.permissionIds
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async editEyeviewUser(id: number, payload: TPutEyeviewUserPayload): Promise<EHttpStatusCode> {
    try {
      const code = await this.eyeviewUserDataSource.editEyeviewUser(id, {
        account_id: payload.accountId.toString(),
        address: payload.address,
        comment: payload.comment,
        contact: payload.contact,
        first_name: payload.firstName,
        invalid: payload.invalid.toString(),
        language_id: payload.languageId.toString(),
        last_name: payload.lastName,
        password: payload.password,
        permission_ids: payload.permissionIds
      });
      return code;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async cloneUser(payload: TEyeviewUser): Promise<TDataResponseEOSS<TEyeviewUser>> {
    try {
      const res = await this.eyeviewUserDataSource.cloneUser(payload);
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListEyeviewPermission(): Promise<TDataResponseEOSS<TPermission[]>> {
    try {
      const res = await this.permissionDataSource.getListEyeviewPermission();
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async assignCameraGroupToEyeviewUser({
    cameraGroupId,
    eyeviewUserId
  }: {
    cameraGroupId: string;
    eyeviewUserId: string;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.cameraGroupDataSource.assignEyeviewUserToCameraGroup({
        camera_group_id: cameraGroupId,
        user_id: eyeviewUserId
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
