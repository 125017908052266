import { DomainGeneralPageView } from './components/DomainGeneralPageView';

function CreateDomainPageView() {
  return (
    <>
      <DomainGeneralPageView editMode={false} />
    </>
  );
}

export default CreateDomainPageView;
