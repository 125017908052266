type ArrowIconProps = {
  width?: number;
  height?: number;
  color?: string;
  angle?: number;
};

const ArrowIcon = (props: ArrowIconProps) => {
  const { width = 9, height = 9, color = '#4A61E3', angle = 0 } = props;
  return (
    <svg
      width={width}
      height={height}
      style={{ transform: `rotate(${angle}deg)` }}
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14636 0.146453C2.34163 -0.0488175 2.6582 -0.0488175 2.85347 0.146453L4.85347 2.14645C5.0487 2.34172 5.0487 2.65829 4.85347 2.85356C4.6582 3.04879 4.34163 3.04879 4.14636 2.85356L2.99996 1.70716V7.50006C2.99996 7.77619 2.77609 8.00006 2.49996 8.00006C2.22383 8.00006 1.99996 7.77619 1.99996 7.50006V1.70716L0.853562 2.85356C0.658292 3.04879 0.341722 3.04879 0.146453 2.85356C-0.0488175 2.65829 -0.0488175 2.34172 0.146453 2.14645L2.14636 0.146453Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
