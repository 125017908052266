import { useEOSSContext } from 'context/EOSSContext';
import { useEffect, useState } from 'react';

interface DataTypeService {
  id: number;
  key?: string;
  children?: DataTypeService[];
  name: string;
}

export const useServicePageController = () => {
  const { listServices, refetchListServices } = useEOSSContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refetchListServices();
      setIsLoading(false);
    })();
  }, []);

  function removeEmptyChildren(nodes: DataTypeService[]): DataTypeService[] {
    return nodes.map((node) => {
      const { children, ...rest } = node;

      if (children && children.length > 0) {
        return {
          ...rest,
          children: removeEmptyChildren(children)
        };
      }

      return { ...rest };
    });
  }

  const transformData = (data: DataTypeService[]): DataTypeService[] => {
    const map: { [id: string]: DataTypeService } = {};
    const roots: DataTypeService[] = [];

    data.forEach((node) => {
      const parts = node.name.split('::');
      const name = parts.pop() || '';
      const newNode: DataTypeService = {
        ...node,
        name: name,
        key: node.name,
        children: []
      };

      const parentNode = parts.length > 0 ? map[parts.join('::')] : null;

      if (parentNode) {
        parentNode.children?.push(newNode);
      } else {
        roots.push(newNode);
      }

      map[node.name] = newNode;
    });
    return removeEmptyChildren(roots);
  };

  return {
    isLoading,
    listServices: transformData(listServices)
  };
};
