import { Form } from 'antd';
import { DATETIME_FORMAT, DEFAULT_PAGE_NUMBER, LIVE_ONLY_SERVICE_SUFFIX } from 'constant';
import { useEOSSContext } from 'context/EOSSContext';
import { useLoaderContext } from 'context/LoaderContext';
import { useAppUtil } from 'context/UtilContext';
import dayjs from 'dayjs';
import { EDeviceType, EHttpStatusCode, EServerType, EThermalCameraType, Routes } from 'enums';
import { useEffectOnce } from 'hooks/useEffectOnce';
import type { TAccount, TDevice, TCamera, TServer, TCameraGroupAssign } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { DeviceRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import {
  convertToValidString,
  checkDeviceStatus,
  handleApiError,
  mapToServerType,
  isValidValue,
  getDeviceGroupBinding
} from 'utils/common';

export type FormInformationFieldType = {
  account: {
    label: string;
    value: number;
  };
  formFactor: string;
  usageStartAt: string;
  bvrServerPrimary: number;
  bvrServerSecondary: number;
  bvrServerTertiary: number;
  localIPAddress: string;
  serialNumber: string;
  mac: string;
  soc: string;
  poe: string;
  modelData: string;
  opticalService: number;
  thermalService: number;
  thermalAnalyticService: number;
  opticalName: string;
  thermalName: string;
  opticalLens: string;
  thermalLens: string;
  location: string;
  comment: string;
  lineVolt: number;
  batteryVolt: number;
  load: number;
  batteryCharge: number;
  batteryDate: string;
  AIModelNameVersion: string; // TODO: Fill it when BE implemented,
  attachedCamera: string; // TODO: Fill it when BE implemented,
};

export type FormSystemRuntimeStatsFieldType = {
  cpuUtil: string;
  sdCardUtil: string;
  kernel: string;
  memUtil: string;
  cpuTemp: string;
};

export type FormBARCStatsFieldType = {
  status: string;
  curThrpt: string;
  maxThrpt: string;
  minThrpt: string;
  bufferSz: string;
  barcFrame: string;
};

export type FormUPSStatsFieldType = {
  lineVolt: number;
  batteryVolt: number;
  load: number;
  batteryCharge: number;
  batteryDate: string;
};

interface DataTypeService {
  id: number;
  code?: string;
  key?: React.ReactNode;
  children?: DataTypeService[];
  name: string;
}

export const useDeviceDetailPageController = () => {
  const { t } = useTranslation();
  const deviceRepository = DeviceRepository(AxiosClient);
  const { loader, isActive } = useLoaderContext();
  const {
    listServices,
    listServerType,
    listDeviceType,
    refetchListServices,
    refetchListServerType
  } = useEOSSContext();
  const currentDeviceLoader = useLoaderData() as TDevice;
  const [otherDeviceGroupsAssignment, setOtherDeviceGroupAssignment] = useState<{
    data: TCameraGroupAssign[];
    loading: boolean;
  }>({ data: [], loading: false });

  const {
    listAccountsService,
    bindGroupAction,
    unAssignAction,
    listAccountsValid,
    scrollToTop,
    onSetCurrentDevice,
    onResetReplaceActionLoading
  } = useOutletContext<{
    bindGroupAction: {
      isProcessing: boolean;
      isSuccess: boolean;
      isLoading: boolean;
      payload?: AnyObject;
    };
    unAssignAction: { isProcessing: boolean; isSuccess: boolean };
    listAccountsService: TAccount[];
    listAccountsValid: TAccount[];
    onSetCurrentDevice: (device: TDevice | null) => void;
    scrollToTop: () => void;
    onResetReplaceActionLoading: () => void;
  }>();

  const [formInformation] = Form.useForm<FormInformationFieldType>();

  const { openNotification, openModal } = useAppUtil();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listServicePackage, setListServicePackage] = useState<DataTypeService[]>([]);
  const [listServerBVR, setListServerBVR] = useState<TServer[]>([]);
  const [connectedBVR, setConnectedBVR] = useState<number[]>([]);
  const [camerasSelectedRow, setCamerasSelectedRow] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listAssignedCameraGroups, setListAssignedCameraGroups] = useState<
    { id: number; name: string; cameraNames: string[] }[]
  >(getDeviceGroupBinding(currentDeviceLoader));

  const [listIdsUnassign, setListIdsUnassign] = useState<number[]>([]);

  const [currentDevice, setCurrentDevice] = useState<TDevice>(currentDeviceLoader);

  const isIPCamera = currentDevice?.typeName === EDeviceType.IP_CAMERA;
  const isAstro = currentDevice?.typeName === EDeviceType.ASTRO_CAMERA;
  const isThermalCamera = currentDevice?.typeName === EDeviceType.THERMAL_CAMERA;
  const isThermalOpticalCamera = currentDevice?.typeName === EDeviceType.THERMAL_OPTICAL_CAMERA;
  const isUPSMonitor = currentDevice?.typeName === EDeviceType.UPS_MONITOR;

  function removeEmptyChildren(nodes: DataTypeService[]): DataTypeService[] {
    return nodes.map((node) => {
      const { children, ...rest } = node;

      if (children && children.length > 0) {
        return {
          ...rest,
          children: removeEmptyChildren(children)
        };
      }

      return { ...rest };
    });
  }

  const transformData = (data: DataTypeService[]): DataTypeService[] => {
    const map: { [id: string]: DataTypeService } = {};
    const roots: DataTypeService[] = [];

    data.forEach((node) => {
      const parts = node.name.split('::');
      const name = parts.pop() || '';
      const newNode: DataTypeService = {
        ...node,
        name: name,
        key: node.name.toLowerCase(),
        children: []
      };

      const parentNode = parts.length > 0 ? map[parts.join('::')] : null;

      if (parentNode) {
        parentNode.children?.push(newNode);
      } else {
        roots.push(newNode);
      }

      map[node.name] = newNode;
    });
    return removeEmptyChildren(roots);
  };

  async function handleRefetchCameraGroupBinding() {
    try {
      if (!currentDevice) return;
      const data = await deviceRepository.getDeviceById({ deviceId: currentDevice.id });

      if (data && data?.cameras && data.cameras.length > 0) {
        const groupData = getDeviceGroupBinding(data);
        setListAssignedCameraGroups(groupData);
      }
      // Scroll to Camera Group Binding when Bind Camera Group to Device Success
    } catch (error) {
      const message = handleApiError({
        entity: t('devicePage.entity'),
        action: 'get',
        t,
        apiErrorResponse: error as ApiErrorResponse,
        identifier: currentDevice.id.toString()
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.entity')}`,
        description: message
      });
    }
  }

  async function handleFetchOtherDeviceGroupsAssignment(location: string) {
    try {
      if (!currentDevice) return;

      setOtherDeviceGroupAssignment({
        data: [],
        loading: true
      });

      const data = await deviceRepository.getListDeviceGroupByLocation({
        page: 0,
        location: location,
        accountId: currentDevice.account.id
      });

      setOtherDeviceGroupAssignment({
        data: data.data,
        loading: false
      });
    } catch (error) {
      setOtherDeviceGroupAssignment({
        data: [],
        loading: false
      });
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.sections.bindCameraGroup.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
        description: message
      });
    }
  }

  async function handleRefetchDeviceAccount() {
    try {
      if (!currentDevice) return;
      const data = await deviceRepository.getDeviceById({ deviceId: currentDevice.id });
      if (data) {
        setCurrentDevice((prev) => {
          return {
            ...prev,
            account: data.account,
            cameras: prev.cameras.map((val) => {
              const findCamera = data.cameras.find((camera) => camera?.id === val?.id);
              return {
                ...val,
                service: findCamera ? findCamera.service : null,
                thermogService: findCamera ? findCamera.thermogService : null,
                groups: findCamera ? findCamera.groups : []
              };
            }),
            servers: data.servers
          };
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.id.toString()
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('devicePage.entity')}`,
        description: message
      });
    }
  }

  async function handleUpdateDeviceBasic({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const locationField = formInformation.getFieldValue('location');
      const commentField = formInformation.getFieldValue('comment');
      const formFactorField = formInformation.getFieldValue('formFactor');

      const currentLocation = currentDevice.location ?? '';
      const currentComment = currentDevice.comment ?? '';
      const currentFormFactor = currentDevice.formFactor ?? '';

      const isLocationChanged = locationField !== currentLocation;
      const isCommentChanged = commentField !== currentComment;
      const isFormFactorChanged = formFactorField !== currentFormFactor;

      const isChanged = isLocationChanged || isCommentChanged || isFormFactorChanged;

      if (isChanged) {
        const status = await deviceRepository.updateDeviceBasic({
          deviceId: currentDevice.id,
          comment: commentField.trim(),
          location: locationField.trim(),
          form: formFactorField.trim()
        });
        return status;
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'update',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('devicePage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function handleUpdateDeviceAccount({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const accountField = formInformation.getFieldValue('account') as {
        label: string;
        value: number;
      };
      const isAccountChanged = accountField.value !== currentDevice.account.id;

      if (isAccountChanged) {
        const status = await deviceRepository.updateDeviceAccount({
          deviceId: currentDevice.id,
          accountId: accountField.value
        });
        return status;
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'update',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('devicePage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function handleUpdateDeviceServers({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const bvrServerPrimaryField = formInformation.getFieldValue('bvrServerPrimary');
      const bvrServerSecondaryField = formInformation.getFieldValue('bvrServerSecondary');
      const bvrServerTertiaryField = formInformation.getFieldValue('bvrServerTertiary');

      const currentServerPrimary = currentDevice.servers[0]?.id ?? -1;
      const currentServerSecondary = currentDevice.servers[1]?.id ?? -1;
      const currentServerTertiary = currentDevice.servers[2]?.id ?? -1;

      const isBvrServerPrimaryChanged = bvrServerPrimaryField !== currentServerPrimary;

      const isBvrServerSecondaryChanged = bvrServerSecondaryField !== currentServerSecondary;

      const isBvrServerTertiaryChanged = bvrServerTertiaryField !== currentServerTertiary;

      const isChanged =
        isBvrServerPrimaryChanged || isBvrServerSecondaryChanged || isBvrServerTertiaryChanged;

      if (isChanged) {
        const status = await deviceRepository.updateDeviceServers({
          deviceId: currentDevice.id,
          serverIds: [bvrServerPrimaryField, bvrServerSecondaryField, bvrServerTertiaryField]
            .filter((id) => id !== -1)
            .toString()
        });
        return status;
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'update',
        entity: t('devicePage.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('devicePage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function handleUpdateDeviceCamera({
    currentDevice
  }: {
    currentDevice: TDevice;
  }): Promise<PromiseSettledResult<EHttpStatusCode>[]> {
    const res = await Promise.allSettled(
      currentDevice.cameras.map(async (camera) => {
        try {
          const isOptical =
            (camera?.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.OPTICAL;
          const lensField = formInformation.getFieldValue(
            isOptical ? 'opticalLens' : 'thermalLens'
          );
          const cameraNameField = formInformation.getFieldValue(
            isOptical ? 'opticalName' : 'thermalName'
          );
          const serviceField = formInformation.getFieldValue(
            isOptical ? 'opticalService' : 'thermalService'
          );
          const thermalAnalyticServiceField = !isOptical
            ? formInformation.getFieldValue('thermalAnalyticService')
            : -1;

          const currentLens = camera.lens ?? '';
          const currentCameraName = camera.name ?? '';
          const currentService = camera.service ? camera.service.id : -1;
          const currentThermalAnalyticService = camera.thermogService
            ? camera.thermogService.id
            : -1;

          const isLensChanged = lensField !== currentLens;
          const isCameraNameChanged = cameraNameField !== currentCameraName;
          const isServiceChanged = serviceField !== currentService;
          const isThermalServiceChanged =
            thermalAnalyticServiceField !== currentThermalAnalyticService;

          const isChanged =
            isLensChanged || isCameraNameChanged || isServiceChanged || isThermalServiceChanged;
          if (isChanged) {
            const status = await deviceRepository.updateCamera({
              cameraId: camera.id,
              lens: lensField.trim(),
              name: cameraNameField.trim(),
              serviceId: serviceField !== -1 ? serviceField : null,
              thermogServiceId:
                thermalAnalyticServiceField !== -1 ? thermalAnalyticServiceField : null
            });
            return status;
          }
          return EHttpStatusCode.NOT_MODIFIED;
        } catch (error) {
          const err = error as ApiErrorResponse;
          const message = handleApiError({
            apiErrorResponse: err,
            action: 'update',
            entity: t('devicePage.cameraEntity'),
            t,
            identifier: currentDevice.serialNumber
          });
          openNotification({
            type: 'error',
            title: `${t('actions.update')} ${t('devicePage.cameraEntity')}`,
            description: message
          });
          return err.status;
        }
      })
    );

    return res;
  }

  async function handleUnbindCameraGroup({ currentDevice }: { currentDevice: TDevice }) {
    try {
      const isChanged = listIdsUnassign.length > 0;
      if (isChanged) {
        const status = await deviceRepository.unbindCameraGroups({
          deviceId: currentDevice.id,
          cameraGroupIds: listIdsUnassign.toString()
        });
        return status;
      }
      return EHttpStatusCode.NOT_MODIFIED;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'other',
        entity: t('devicePage.sections.bindCameraGroup.entity'),
        t,
        identifier: currentDevice.serialNumber
      });
      openNotification({
        type: 'error',
        title: `${t('actions.bind')} ${t('devicePage.sections.bindCameraGroup.entity')}`,
        description: message
      });
      handleRefetchCameraGroupBinding();
      return err.status;
    }
  }

  const isLiveOnlyServiceChange = (
    serviceId: number,
    listServicePackage: DataTypeService[]
  ): boolean => {
    const flatService = (
      listServicePackage: DataTypeService[]
    ): Array<{ id: number; code?: string }> => {
      return listServicePackage.reduce(
        (acc: Array<{ id: number; code?: string }>, curr: DataTypeService) => {
          const data = [...acc, { id: curr.id, code: curr.code }];
          if (curr.children && curr.children.length > 0) {
            return [...data, ...flatService(curr.children)];
          }
          return data;
        },
        []
      );
    };

    const flatServiceList = flatService(listServicePackage);

    const code = flatServiceList.find((service) => service.id === serviceId)?.code;

    // Code exist && code is live only (end with triple zero ('000'))
    return !!code && code.endsWith(LIVE_ONLY_SERVICE_SUFFIX);
  };

  async function handleSubmit() {
    if (!currentDevice || isSubmitting) return;
    setIsSubmitting(true);

    const isServiceSetToLiveOnly = isLiveOnlyServiceChange(
      formInformation.getFieldValue('opticalService'),
      listServicePackage
    );

    const isChangeAnyBVRServer =
      formInformation.getFieldValue('bvrServerPrimary') !== (currentDevice?.servers[0]?.id ?? -1) ||
      formInformation.getFieldValue('bvrServerSecondary') !==
        (currentDevice?.servers[1]?.id ?? -1) ||
      formInformation.getFieldValue('bvrServerTertiary') !== (currentDevice?.servers[2]?.id ?? -1);

    const additionalMessage =
      isServiceSetToLiveOnly && isChangeAnyBVRServer
        ? t('devicePage.modal.bothChange')
        : isServiceSetToLiveOnly
        ? t('devicePage.modal.setLiveOnly')
        : isChangeAnyBVRServer
        ? t('devicePage.modal.changeBVR')
        : '';

    openModal({
      title: t('components.confirmationTitle'),
      content: `${additionalMessage} ${t('devicePage.modal.action', {
        action: t('actions.update').toLowerCase(),
        entity: t('header.devices').toLowerCase()
      })}`,
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      onOk: async () => {
        const [
          resUpdateDeviceBasic,
          resUpdateDeviceAccount,
          resUpdateDeviceServer,
          resUpdateDeviceCamera,
          resUnbindCameraGroup
        ] = await Promise.allSettled([
          handleUpdateDeviceBasic({ currentDevice }),
          handleUpdateDeviceAccount({ currentDevice }),
          handleUpdateDeviceServers({ currentDevice }),
          handleUpdateDeviceCamera({ currentDevice }),
          handleUnbindCameraGroup({ currentDevice })
        ]);
        const isUpdateDeviceBasicFailed =
          resUpdateDeviceBasic.status === 'fulfilled' &&
          resUpdateDeviceBasic.value !== EHttpStatusCode.OK &&
          resUpdateDeviceBasic.value !== EHttpStatusCode.NOT_MODIFIED;
        const isDeviceBasicUnChanged =
          resUpdateDeviceBasic.status === 'fulfilled' &&
          resUpdateDeviceBasic.value === EHttpStatusCode.NOT_MODIFIED;

        const isUpdateDeviceAccountFailed =
          resUpdateDeviceAccount.status === 'fulfilled' &&
          resUpdateDeviceAccount.value !== EHttpStatusCode.OK &&
          resUpdateDeviceAccount.value !== EHttpStatusCode.NOT_MODIFIED;
        const isDeviceAccountUnChanged =
          resUpdateDeviceAccount.status === 'fulfilled' &&
          resUpdateDeviceAccount.value === EHttpStatusCode.NOT_MODIFIED;

        const isUpdateDeviceServerFailed =
          resUpdateDeviceServer.status === 'fulfilled' &&
          resUpdateDeviceServer.value !== EHttpStatusCode.OK &&
          resUpdateDeviceServer.value !== EHttpStatusCode.NOT_MODIFIED;
        const isDeviceServerUnChanged =
          resUpdateDeviceServer.status === 'fulfilled' &&
          resUpdateDeviceServer.value === EHttpStatusCode.NOT_MODIFIED;

        const isUpdateDeviceCameraFailed =
          resUpdateDeviceCamera.status === 'fulfilled' &&
          resUpdateDeviceCamera.value.filter(
            (status) =>
              status.status === 'fulfilled' &&
              status.value !== EHttpStatusCode.OK &&
              status.value !== EHttpStatusCode.NOT_MODIFIED
          ).length > 0;
        const isDeviceCameraUnChanged =
          resUpdateDeviceCamera.status === 'fulfilled' &&
          (resUpdateDeviceCamera.value.length <= 0 ||
            resUpdateDeviceCamera.value.filter(
              (status) =>
                status.status === 'fulfilled' && status.value === EHttpStatusCode.NOT_MODIFIED
            ).length > 0);

        const isUnbindCameraGroupFailed =
          resUnbindCameraGroup.status === 'fulfilled' &&
          resUnbindCameraGroup.value !== EHttpStatusCode.OK &&
          resUnbindCameraGroup.value !== EHttpStatusCode.NOT_MODIFIED;
        const isCameraGroupUnChanged =
          resUnbindCameraGroup.status === 'fulfilled' &&
          resUnbindCameraGroup.value === EHttpStatusCode.NOT_MODIFIED;

        const isDeviceUnChanged =
          isDeviceBasicUnChanged &&
          isDeviceAccountUnChanged &&
          isDeviceServerUnChanged &&
          isDeviceCameraUnChanged &&
          isCameraGroupUnChanged;

        const isHasFailed =
          isUpdateDeviceAccountFailed ||
          isUpdateDeviceBasicFailed ||
          isUpdateDeviceCameraFailed ||
          isUpdateDeviceServerFailed ||
          isUnbindCameraGroupFailed;

        const isSuccessfully = !isHasFailed;

        if (isSuccessfully) {
          openNotification({
            type: 'success',
            title: `${t('actions.update')} ${t('devicePage.entity')}`,
            description: `${t('components.success')}!`
          });
        }

        if (isDeviceUnChanged || isSuccessfully) {
          if (!searchParams.get('page')) {
            searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
            setSearchParams(searchParams);
          }
          navigate({
            pathname: `${Routes.EDevicesRoutes.INDEX}/${(
              currentDevice.typeName ?? ''
            ).toLowerCase()}`,
            search: searchParams.toString()
          });
        }
        setIsSubmitting(false);
      },
      onCancel: () => {
        setIsSubmitting(false);
      }
    });
  }

  async function fetchListServer() {
    try {
      const bvrServer = listServerType.find(
        (server) => mapToServerType(server?.name) === EServerType.BVR
      );
      if (!bvrServer) return;
      const res = await deviceRepository.getListServer({ typeId: bvrServer?.id });
      setListServerBVR(res);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('serverPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('serverPage.entity')}`,
        description: message
      });
    }
  }

  const handleListIdsUnassignChange = (value: number[]) => {
    setListIdsUnassign(value);
  };

  const checkIsRecording = ({
    device,
    type
  }: {
    device: TDevice;
    type: EThermalCameraType;
  }): boolean => {
    if (device && device?.lastContactAt) {
      const camera = device.cameras.find((c) => c?.cameraTypeName?.toLowerCase() === type);

      if (camera) {
        const isDeviceOnline = checkDeviceStatus(camera?.lastContact);
        const isBVRConnected = device?.bvrConnected ? device.bvrConnected.length > 0 : false;
        return isDeviceOnline && isBVRConnected;
      }
    }
    return false;
  };

  const checkIsOnline = ({
    device,
    type
  }: {
    device: TDevice;
    type: EThermalCameraType;
  }): boolean => {
    if (device && device?.lastContactAt) {
      const camera = device.cameras.find((c) => c?.cameraTypeName?.toLowerCase() === type);
      if (camera) {
        const isDeviceOnline = checkDeviceStatus(camera?.lastContact);

        return isDeviceOnline;
      }
    }
    return false;
  };

  const handleCamerasGroupBindingSelectedChange = (keys: React.Key[]) =>
    setCamerasSelectedRow(keys);

  useEffect(() => {
    refetchListServices();
    refetchListServerType();
    scrollToTop();
  }, []);

  useEffect(() => {
    if (bindGroupAction.isSuccess) {
      if (bindGroupAction.payload) {
        handleFetchOtherDeviceGroupsAssignment(bindGroupAction.payload.deviceGroupName);
        formInformation.setFieldValue('location', bindGroupAction.payload.deviceGroupName);
      }
      handleRefetchCameraGroupBinding();
    }
  }, [bindGroupAction.isSuccess, bindGroupAction.payload]);

  useEffect(() => {
    if (unAssignAction.isSuccess) {
      handleRefetchDeviceAccount();
      // Clear list assigned camera group binding when change device customer owner
      setListAssignedCameraGroups([]);
    }
  }, [unAssignAction.isSuccess]);

  useEffect(() => {
    setListServicePackage(transformData(listServices));
  }, [listServices]);

  useEffect(() => {
    if (currentDevice) {
      onSetCurrentDevice(currentDevice);
    }
  }, [currentDevice]);

  useEffect(() => {
    if (listServerType.length > 0) {
      fetchListServer();
    }
  }, [listServerType]);

  const getModelData = () => {
    const getModelDataFromCamera = (camera: TCamera) => {
      const res = [];
      res.push(camera?.typeName && camera.typeName.length > 0 ? camera.typeName : '-');
      res.push(
        camera?.productSerial && camera?.productSerial.length > 0 ? camera.productSerial : '-'
      );
      res.push(camera?.firmware && camera?.firmware.length > 0 ? camera.firmware : '-');

      const hasData = res.filter((value: string) => value !== '-').length > 0;

      return hasData ? res.join('_') : '-';
    };

    try {
      const deviceCameras = currentDevice?.cameras;
      if (currentDevice?.typeName === EDeviceType.THERMAL_OPTICAL_CAMERA) {
        const modelResult: Array<string> = [];
        if (deviceCameras && deviceCameras.length > 0) {
          const opticalCamera = deviceCameras.find(
            (c) => c?.cameraTypeName === EThermalCameraType.OPTICAL
          );
          const thermalCamera = deviceCameras.find(
            (c) => c?.cameraTypeName === EThermalCameraType.THERMAL
          );
          opticalCamera && modelResult.push(getModelDataFromCamera(opticalCamera));
          thermalCamera && modelResult.push(getModelDataFromCamera(thermalCamera));
        }

        return modelResult.join('\n');
      } else {
        if (deviceCameras && deviceCameras.length > 0) {
          const camera = deviceCameras[0];
          if (camera) {
            return getModelDataFromCamera(camera);
          }
          return '-';
        }
      }
      return '-';
    } catch (error) {
      return '-';
    }
  };

  useEffect(() => {
    if (currentDevice && currentDevice?.cameras) {
      const opticalCamera = currentDevice.cameras.find(
        (value: TCamera) =>
          (value?.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.OPTICAL
      );
      const thermalCamera = currentDevice.cameras.find(
        (value: TCamera) =>
          (value?.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.THERMAL
      );

      // Input field
      formInformation.setFieldsValue({
        modelData: isAstro
          ? convertToValidString({
              value: currentDevice?.soc
            })
          : getModelData(),
        serialNumber: convertToValidString({
          value: currentDevice?.serialNumber
        }),
        localIPAddress:
          currentDevice?.localIp || currentDevice?.publicIp || currentDevice?.serviceProvider
            ? `${convertToValidString({
                value: currentDevice?.localIp
              })}/${convertToValidString({
                value: currentDevice?.publicIp
              })}/${convertToValidString({
                value: currentDevice?.serviceProvider
              })}`
            : '-',
        soc:
          currentDevice?.soc || currentDevice?.ram
            ? `${convertToValidString({
                value: currentDevice?.soc
              })}/${convertToValidString({
                value: currentDevice?.ram
              })}`
            : '-',
        poe: convertToValidString({
          value: currentDevice?.poe
        }),
        mac: convertToValidString({
          value: currentDevice?.mac
        }),
        usageStartAt: currentDevice?.usageStartAt
          ? dayjs.unix(currentDevice.usageStartAt).format(DATETIME_FORMAT)
          : '-',
        formFactor: convertToValidString({
          value: currentDevice?.formFactor,
          defaultValue: ''
        }),
        location: convertToValidString({
          value: currentDevice?.location,
          defaultValue: ''
        }),
        comment: convertToValidString({
          value: currentDevice?.comment,
          defaultValue: ''
        }),
        // AIModelNameVersion: '-', ERRP-212: Hide this field, Request by Josh 2024-Sep-06,
        attachedCamera: convertToValidString({
          value: currentDevice?.astroCamera
        }),
        lineVolt: currentDevice?.data?.lineVolt,
        batteryVolt: currentDevice?.data?.batteryVolt,
        load: currentDevice?.data?.load,
        batteryCharge: currentDevice?.data?.batteryCharge,
        batteryDate: currentDevice?.data?.batteryDate,
        opticalName: convertToValidString({
          value: opticalCamera?.name,
          defaultValue: ''
        }),
        thermalName: convertToValidString({
          value: thermalCamera?.name,
          defaultValue: ''
        }),
        opticalLens: convertToValidString({
          value: opticalCamera?.lens,
          defaultValue: ''
        }),
        thermalLens: convertToValidString({
          value: thermalCamera?.lens,
          defaultValue: ''
        })
      });
    }
  }, [currentDevice, isAstro]);

  useEffect(() => {
    if (
      (isAstro || isUPSMonitor) &&
      currentDevice?.location &&
      currentDevice?.location?.trim().length > 0
    ) {
      handleFetchOtherDeviceGroupsAssignment(currentDevice?.location?.trim() ?? '');
    }
  }, [currentDevice, isAstro, isUPSMonitor]);

  useEffect(() => {
    if (listAccountsService.length > 0) {
      formInformation.setFieldsValue({
        account: {
          label: `${currentDevice?.account?.name}`,
          value: currentDevice?.account?.id
        }
      });
    }
  }, [listAccountsService, currentDevice]);

  useEffect(() => {
    if (currentDevice?.cameras && listServicePackage.length > 0) {
      const opticalCamera = currentDevice.cameras.find(
        (value: TCamera) =>
          (value?.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.OPTICAL
      );
      const thermalCamera = currentDevice.cameras.find(
        (value: TCamera) =>
          (value?.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.THERMAL
      );
      const thermalAnalyticCamera = currentDevice.cameras.find(
        (value: TCamera) =>
          (value?.cameraTypeName ?? '').toLowerCase() === EThermalCameraType.THERMAL
      );

      formInformation.setFieldsValue({
        opticalService: opticalCamera?.service ? opticalCamera.service?.id : -1,
        thermalService: thermalCamera?.service ? thermalCamera.service?.id : -1,
        thermalAnalyticService: thermalAnalyticCamera?.thermogService
          ? thermalAnalyticCamera?.thermogService?.id
          : -1
      });
    }
  }, [currentDevice, listServicePackage]);

  useEffect(() => {
    if (listServerBVR.length > 0) {
      formInformation.setFieldsValue({
        bvrServerPrimary: currentDevice?.servers[0]?.id ?? -1,
        bvrServerSecondary: currentDevice?.servers[1]?.id ?? -1,
        bvrServerTertiary: currentDevice?.servers[2]?.id ?? -1
      });
      const bvrConnectedId = [] as number[];
      isValidValue(currentDevice?.servers[0]?.id) &&
        bvrConnectedId.push(currentDevice?.servers[0]?.id);
      isValidValue(currentDevice?.servers[1]?.id) &&
        bvrConnectedId.push(currentDevice?.servers[1]?.id);
      isValidValue(currentDevice?.servers[2]?.id) &&
        bvrConnectedId.push(currentDevice?.servers[2]?.id);
      setConnectedBVR(bvrConnectedId);
    }
  }, [listServerBVR, currentDevice]);

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  useEffect(() => {
    setCurrentDevice(currentDeviceLoader);
    setListAssignedCameraGroups(getDeviceGroupBinding(currentDeviceLoader));
    onResetReplaceActionLoading();
  }, [currentDeviceLoader]);

  return {
    isIPCamera,
    isAstro,
    isThermalCamera,
    isThermalOpticalCamera,
    isUPSMonitor,
    camerasSelectedRow,
    connectedBVR,
    isAccountInvalid:
      listAccountsValid.length > 0 &&
      currentDevice &&
      !listAccountsValid.find((account) => account?.id === currentDevice?.account?.id),
    bindGroupAction,
    currentDevice,
    listDeviceType,
    formInformation,
    listServicePackage,
    listServerBVR,
    isOpticalRecording: checkIsRecording({
      device: currentDevice,
      type: EThermalCameraType.OPTICAL
    }),
    isThermalRecording: checkIsRecording({
      device: currentDevice,
      type: EThermalCameraType.THERMAL
    }),
    isOpticalOnline: checkIsOnline({
      device: currentDevice,
      type: EThermalCameraType.OPTICAL
    }),
    isThermalOnline: checkIsOnline({
      device: currentDevice,
      type: EThermalCameraType.THERMAL
    }),
    listAssignedCameraGroups,
    listAccountsService,
    otherDeviceGroupsAssignment,
    onSubmit: handleSubmit,
    onListIdsUnassignChange: handleListIdsUnassignChange,
    onCamerasGroupBindingSelectedChange: handleCamerasGroupBindingSelectedChange,
    onResetReplaceActionLoading
  };
};
