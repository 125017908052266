export function ArrowBreadcrumbIcon() {
  return (
    <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64886 0.0136719L21.7191 19.4843L5.1354 40.0457H0L16.5593 19.5155L0.453622 0.0150572L5.64886 0.0136719Z"
        fill="white"
      />
    </svg>
  );
}
