import { ConfigProvider } from 'antd';
import { FONT_FAMILY } from 'constant';
import { AuthProvider } from 'context/AuthContext';
import { EOSSProvider } from 'context/EOSSContext';
import { LoaderProvider } from 'context/LoaderContext';
import { UtilProvider } from 'context/UtilContext';
import { PermissionProvider } from 'context/PermissionContext';
import { useUpdateViewHeight } from 'hooks/useUpdateViewHeight';
import i18n from 'localization/i18n';
import { I18nextProvider } from 'react-i18next';
import WrapRouterProvider from 'router';

function App() {
  useUpdateViewHeight();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: FONT_FAMILY
        }
      }}
    >
      <I18nextProvider i18n={i18n}>
        <UtilProvider>
          <LoaderProvider>
            <AuthProvider>
              <PermissionProvider>
                <EOSSProvider>
                  <WrapRouterProvider />
                </EOSSProvider>
              </PermissionProvider>
            </AuthProvider>
          </LoaderProvider>
        </UtilProvider>
      </I18nextProvider>
    </ConfigProvider>
  );
}

export default App;
