import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TOOLTIP_DELAY } from 'constant';
import { EDeviceStatus } from 'enums';
import type { TDataStats, TDevice } from 'models';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkDeviceStatus,
  isValidStringOrNumber,
  secondaryBVRServerDisplay,
  splitLastContact
} from 'utils/common';
import styles from './tableDeviceStatus.module.scss';
import { SmartTooltip } from 'presentation/components';
import useResponsive from 'hooks/useResponsive';

type Props = {
  listDevice: TDevice[];
  isIPCamera: boolean;
  isThermalCamera: boolean;
  isThermalOpticalCamera: boolean;
  isUPSMonitor: boolean;
  isAstroDevice: boolean;
  isDomainCustomerAdmin: boolean;
};

function TableDeviceStatus({
  listDevice,
  isIPCamera,
  isThermalCamera,
  isThermalOpticalCamera,
  isUPSMonitor,
  isAstroDevice,
  isDomainCustomerAdmin
}: Props) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const columns: ColumnsType<TDevice> = useMemo(() => {
    return [
      {
        title: t('devicePage.table.columns.cameraType'),
        dataIndex: 'typeName',
        key: 'typeName',
        render: (value: string) => (
          <span data-name="typeName" className="text-nowrap">
            {value && value.length > 0 ? value : '-'}
          </span>
        ),
        visibility: true
      },
      {
        title: t('devicePage.table.columns.serialNumber'),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        render: (value: string) => (
          <span
            data-name="serialNumber"
            className="text-nowrap"
            style={{
              color: 'var(--primary-1)'
            }}
          >
            {value && value.length > 0 ? value : '-'}
          </span>
        ),
        visibility: true
      },
      {
        title: () => t('devicePage.table.columns.version'),
        dataIndex: 'softwareVersion',
        key: 'softwareVersion',
        render: (value: string, record: TDevice) => {
          /*
          To determine if the software version should be displayed in red, please use the following logic: 
          DeviceMacAddress =~ /b8:27/ && DeviceSoftwareVersion =~ /pi4/
          Now check with Camera Device and valid version string (not null, not undefined, and not empty string), except Astro & UPS Monitor
          */
          const isMismatchOS =
            isValidStringOrNumber(value) &&
            (isIPCamera || isThermalCamera || isThermalOpticalCamera)
              ? !record.mac?.includes('b8:27') && !record.softwareVersion?.includes('pi4')
              : false;

          if (isMismatchOS) {
            return (
              <Tooltip
                trigger={isMobile ? ['click'] : ['hover']}
                destroyTooltipOnHide
                title="Misplaced OS on current device"
                mouseEnterDelay={TOOLTIP_DELAY}
                arrow={false}
                placement="top"
              >
                <span
                  data-name="softwareVersion"
                  className="text-nowrap"
                  style={{ color: 'var(--sub-3)' }}
                >
                  {value && value.length > 0 ? value : '-'}
                </span>
              </Tooltip>
            );
          }
          return (
            <span data-name="softwareVersion" className="text-nowrap">
              {value && value.length > 0 ? value : '-'}
            </span>
          );
        },
        visibility: true
      },
      {
        title: () => t('devicePage.table.columns.account'),
        dataIndex: 'accountName',
        key: 'accountName',
        render: (_: string, record: TDevice) => (
          <span data-name="accountName" className="text-nowrap">
            {record.account.name.length > 0 ? record.account.name : '-'}
          </span>
        ),
        visibility: true
      },
      {
        title: () => t('devicePage.table.columns.status'),
        key: 'status',
        render: (_: number, record: TDevice) => {
          return checkDeviceStatus(record.lastContactAt) ? (
            <span
              data-name="status"
              className="text-nowrap fw-medium"
              style={{ color: 'var(--sub-5)' }}
            >
              {EDeviceStatus.ONLINE}
            </span>
          ) : (
            <span
              data-name="status"
              className="text-nowrap fw-medium"
              style={{ color: 'var(--sub-3)' }}
            >
              {EDeviceStatus.OFFLINE}
            </span>
          );
        },
        visibility: true
      },
      {
        title: () => t('devicePage.table.columns.bvrServer'),
        dataIndex: 'servers',
        key: 'servers',
        render: (
          value: Array<{
            id: number;
            name: string;
            publicIp: string;
            ip: string;
          }>,
          record: TDevice
        ) => {
          const isDeviceOnline = checkDeviceStatus(record.lastContactAt);

          const primaryBvrServer = value[0]?.publicIp;

          if (!primaryBvrServer) {
            return <span data-name="servers">{'-'}</span>;
          }

          const secondaryServerDisplay = secondaryBVRServerDisplay({
            bvrConnectedIp: record.bvrConnected,
            secondaryBvrServerIp: value[1]?.publicIp,
            tertiaryBvrServerIp: value[2]?.publicIp
          });

          return (
            <span data-name="servers">
              <span
                style={{
                  color: `${
                    record.bvrConnected === primaryBvrServer && isDeviceOnline ? 'var(--sub-5)' : ''
                  } `
                }}
              >
                {primaryBvrServer}
              </span>
              {secondaryServerDisplay && (
                <span>
                  {', '}
                  <span
                    style={{
                      color: `${
                        record.bvrConnected === secondaryServerDisplay && isDeviceOnline
                          ? 'var(--sub-5)'
                          : ''
                      } `
                    }}
                  >
                    {secondaryServerDisplay}
                  </span>
                </span>
              )}
            </span>
          );
        },
        visibility:
          !isDomainCustomerAdmin && (isIPCamera || isThermalCamera || isThermalOpticalCamera)
      },
      {
        title: () => t('devicePage.table.columns.lastContact'),
        dataIndex: 'lastContactAt',
        key: 'lastContactAt',
        render: (value: number) => {
          if (value) {
            const { date, time } = splitLastContact(value);
            return (
              <div data-name="lastContactAt">
                <span>{date}</span>
                <span className="text-nowrap">{time}</span>
              </div>
            );
          }
          return <span data-name="lastContactAt">{'-'}</span>;
        },
        visibility: true
      },
      {
        title: () => t('devicePage.table.columns.upsStatus'),
        dataIndex: 'data',
        key: 'data',
        render: (value: TDataStats) => {
          return <span data-name="data">{value.upsStatus ?? '-'}</span>;
        },
        visibility: isUPSMonitor
      },
      {
        title: () => t('devicePage.table.columns.location'),
        dataIndex: 'location',
        key: 'location',
        render: (value: string) => {
          return (
            <SmartTooltip
              name="location"
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              {value.length > 0 ? value : '-'}
            </SmartTooltip>
          );
        },
        visibility: true
      }
    ].filter((value) => value.visibility);
  }, [
    isMobile,
    isIPCamera,
    isThermalCamera,
    isThermalOpticalCamera,
    isUPSMonitor,
    isDomainCustomerAdmin
  ]);

  // Request changes 30/05/2024: Remove MAX THERMAL TEMP from SIR1000 table
  // const thermalColumns: ColumnsType<TDevice> = useMemo(() => {
  //   return [
  //     {
  //       title: () => t('devicePage.table.columns.thermalTemp'),
  //       dataIndex: 'thermalTemp',
  //       key: 'thermalTemp',
  //       render: () => {
  //         return '80°C';
  //       }
  //     }
  //   ];
  // }, []);

  return (
    <Table
      id="devicePage_deviceStatusTable"
      rowKey={'id'}
      className={styles.container}
      dataSource={listDevice}
      tableLayout="auto"
      columns={columns}
      rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      pagination={false}
      rowHoverable={false}
      scroll={{
        x: listDevice.length > 0 ? 'auto' : 'min-content'
      }}
    />
  );
}

export default TableDeviceStatus;
