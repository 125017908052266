import { Button, Form, Input } from 'antd';
import { AddIconNoOutline, DropdownIcon } from 'assets/icons';
import { ERoleKey, ESubFeatureKey, Routes } from 'enums';
import {
  LeftColumn,
  PermissionWrapper,
  RightColumn,
  SearchSelect,
  Section
} from 'presentation/components';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useMatch } from 'react-router-dom';
import { useEyeviewUserPageController } from './EyeviewUserPageController';
import { useEffect, useRef } from 'react';
import { useAppUtil } from 'context/UtilContext';
import { usePathPermission } from 'hooks/usePermission';
import { EStatusInvalid } from 'enums';
import useFlag from 'hooks/useFlag';
import type { TCameraGroupAssign } from 'models';

const EyeviewUserPageView = () => {
  const { t } = useTranslation();

  const {
    searchFrom,
    searchValue,
    selectedRegions,
    cameraGroupIdSelected,
    currentUser,
    searchString,
    listAvailableCameraGroupsAssignment,
    refetchCountRef,
    listCameraGroupAssignment,
    listCameraGroupRowSelected,
    onSearchSubmit,
    onAssignSubmit,
    onClickCreateUser,
    onFetchAccountList,
    onChangeSearch,
    onCameraGroupIdSelected,
    onListCameraGroupRowSelectedChange,
    onListCameraGroupChange,
    onSearchValueChange,
    onSelectedRegionsChange
  } = useEyeviewUserPageController();

  const { openModal } = useAppUtil();

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);
  const [isSubmitting, markAsSubmitting, markAsNotSubmitting] = useFlag(false);

  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  const isInEyeviewUserDetailPage = useMatch(Routes.EUserRoutes.DETAIL);
  const allowedAssignEyeviewUserToCameraGroup =
    usePathPermission(
      Routes.ECameraGroupRoutes.DETAILED,
      ERoleKey.UPDATE,
      ESubFeatureKey.ASSIGNMENT
    ).allowed &&
    isInEyeviewUserDetailPage &&
    currentUser?.invalid === EStatusInvalid.VALID;

  // 2024-02-09 Feedback: Create Action should be shown in both Listing and Detail page
  const isInListingPage = useMatch(Routes.EUserRoutes.LISTING);
  // check create because useMatch understands /user/create as /user/:adminUserId
  const canShowCreateAction =
    isInListingPage ||
    (isInEyeviewUserDetailPage && isInEyeviewUserDetailPage.pathname != Routes.EUserRoutes.CREATE);

  const handleAssignEyeviewUserSubmit = () => {
    const toGroup = listAvailableCameraGroupsAssignment.data.find(
      (group) => group?.id === cameraGroupIdSelected
    );
    markAsSubmitting();
    openModal({
      title: t('components.confirmationTitle'),
      content: (
        <>
          <div>
            {t('userPage.confirmModal.assign')}
            {t('header.cameraGroups').toLowerCase()}{' '}
            <span className="fw-semibold">{toGroup?.name ?? ''}</span>
          </div>
          <div>
            {t('components.to')} {t('header.user').toLowerCase()}{' '}
            <span className="fw-semibold">{currentUser.username}</span>
            {'?'}
          </div>
        </>
      ),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 'auto'
        }
      },
      width: 'fit-content',
      onOk: () =>
        onAssignSubmit(() => {
          markAsNotSubmitting();
        }),
      onCancel: () => markAsNotSubmitting()
    });
  };

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  return (
    <div className="wrap-container overflow-hidden h-100">
      <div className="h-100 w-100 position-relative">
        <LeftColumn
          withCollapse
          leftColumnOpen={leftColumnOpen}
          onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
          onMouseMove={() => toolTipRef.current?.onShowTooltip()}
          onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
        >
          <Section title={t('userPage.sections.search.title')} classNameBody="p-4">
            <Form
              id="eyeviewUserPage_searchAction"
              layout="vertical"
              form={searchFrom}
              initialValues={{ searchString }}
              onFinish={onSearchSubmit}
            >
              <Form.Item className="mb-2" name="searchString">
                <Input className="search-input" onChange={onChangeSearch} allowClear />
              </Form.Item>
              <div className="fs-12 fw-normal text-black mt-2 mb-3">
                {t('userPage.sections.search.instruction')}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="btn-action btn-fill"
                  htmlType="submit"
                  onClick={() => {
                    const event = new CustomEvent('close-sidebar-action');
                    document.dispatchEvent(event);
                  }}
                >
                  <span className="btn-content fs-14 fw-semibold">{t('components.search')}</span>
                </Button>
              </div>
            </Form>
          </Section>
          {canShowCreateAction && (
            <PermissionWrapper.Create>
              <Section title={t('userPage.sections.create.title')} classNameBody="px-4 py-5">
                <Button
                  id="eyeviewUserPage_createAction"
                  className="btn-create w-100 d-flex justify-content-center align-items-center cursor-pointer"
                  onClick={() => {
                    onClickCreateUser();
                    const event = new CustomEvent('close-sidebar-action');
                    document.dispatchEvent(event);
                  }}
                >
                  <div className="h-100 d-flex align-items-center">
                    <AddIconNoOutline color="var(--primary-1)" />
                  </div>
                  <div className="btn-create-content d-flex align-items-center fs-14 fw-semibold ms-2 h-100">
                    {t('components.create')}
                  </div>
                </Button>
              </Section>
            </PermissionWrapper.Create>
          )}
          {/* {isShowCloneSection && (
                <PermissionWrapper.Update>
                  <Section title={t('userPage.sections.clone.title')} classNameBody="px-4 py-5">
                    <span className={'mt-2 mb-3 fw-normal'}>
                      {t('userPage.sections.clone.description')}
                      <span style={{ color: 'var(--primary-1)' }}>{selectedUser?.username}</span>
                    </span>
                    <div className="d-flex justify-content-end">
                      <Button
                        htmlType="submit"
                        className="btn-fill d-inline-flex"
                        style={{ height: 29 }}
                        onClick={onClickCloneUser}
                      >
                        {t('components.clone')}
                      </Button>
                    </div>
                  </Section>
                </PermissionWrapper.Update>
              )} */}
          {currentUser &&
            allowedAssignEyeviewUserToCameraGroup &&
            (listAvailableCameraGroupsAssignment.loading ||
              listAvailableCameraGroupsAssignment.data.length > 0) && (
              <Section
                title={t('userPage.sections.assignCameraGroup.title')}
                classNameBody="px-4 pt-3 pb-4"
              >
                <div
                  id="eyeviewUserPage_deviceGroupAssignmentAction"
                  className="d-flex flex-column"
                >
                  <div className="fs-12 fw-normal text-black mt-2 mb-3">
                    <Trans
                      i18nKey={'userPage.sections.assignCameraGroup.description'}
                      values={{
                        username: currentUser?.username
                      }}
                    >
                      <span className="fw-medium" />
                    </Trans>
                  </div>
                  <SearchSelect<TCameraGroupAssign>
                    className="w-100 mb-3"
                    searchValue={searchValue}
                    selectedRegions={selectedRegions}
                    placeholder={
                      listAvailableCameraGroupsAssignment.loading
                        ? t('components.loading')
                        : t('devicePage.sections.dropDownSearchDeviceGroup.title')
                    }
                    value={cameraGroupIdSelected}
                    loading={listAvailableCameraGroupsAssignment.loading}
                    options={listAvailableCameraGroupsAssignment.data}
                    suffixIcon={
                      listAvailableCameraGroupsAssignment.loading ? undefined : <DropdownIcon />
                    }
                    style={{
                      pointerEvents: listAvailableCameraGroupsAssignment.loading ? 'none' : 'unset'
                    }}
                    onSelectChange={(value: number | null) => {
                      onCameraGroupIdSelected(value);
                    }}
                    onSearchValueChange={onSearchValueChange}
                    onSelectedRegionsChange={onSelectedRegionsChange}
                  />
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn-fill"
                      disabled={
                        !cameraGroupIdSelected ||
                        isSubmitting ||
                        listAvailableCameraGroupsAssignment.loading
                      }
                      onClick={() => {
                        handleAssignEyeviewUserSubmit();
                        const event = new CustomEvent('close-sidebar-action');
                        document.dispatchEvent(event);
                      }}
                    >
                      <span className="fs-14 fw-semibold">{t('components.assign')}</span>
                    </Button>
                  </div>
                </div>
              </Section>
            )}
        </LeftColumn>
        <RightColumn
          toolTipRef={toolTipRef}
          leftColumnOpen={leftColumnOpen}
          onSideBarClick={() => {
            leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
          }}
        >
          <Outlet
            context={{
              refetchCount: refetchCountRef.current,
              listCameraGroupAssignment,
              listCameraGroupRowSelected,
              onFetchAccountList,
              onListCameraGroupRowSelectedChange,
              onListCameraGroupChange
            }}
          />
        </RightColumn>
      </div>
    </div>
  );
};
export default EyeviewUserPageView;
