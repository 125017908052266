import { Form, Input } from 'antd';
import { TDevice } from 'models';
import { TDynamicResponse } from 'models/DynamicResponse';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MAXIMUM_DRAM_UTIL } from 'constant';
import type { FormSystemRuntimeStatsFieldType } from '../DeviceDetailPageController';
import { FormInputTooltip } from 'presentation/components';
import useResponsive from 'hooks/useResponsive';

type Props = {
  fieldDisplayNameMappings: TDynamicResponse | undefined;
  currentDevice: TDevice;
};

function FormSystemRuntime({ currentDevice }: Props) {
  const [formSystemRuntimeStats] = Form.useForm<FormSystemRuntimeStatsFieldType>();
  const { t } = useTranslation();
  const dramUtilIsDanger = (currentDevice.systemData.memUtil ?? 0) >= MAXIMUM_DRAM_UTIL;

  const { isTablet, isSemiDesktop } = useResponsive();

  useEffect(() => {
    formSystemRuntimeStats.setFieldsValue({
      cpuUtil: currentDevice?.systemData?.cpuUtil
        ? `${currentDevice.systemData.cpuUtil.toString()}%`
        : '',
      memUtil: currentDevice?.systemData?.memUtil
        ? `${currentDevice.systemData.memUtil.toString()}%`
        : '',
      sdCardUtil: currentDevice?.systemData?.sdUtil
        ? `${currentDevice.systemData.sdUtil.toString()}%`
        : '',
      cpuTemp: currentDevice?.systemData?.cpuTemp ? `${currentDevice.systemData.cpuTemp}°C` : '',
      kernel: currentDevice?.kernel ?? ''
    });
  }, [currentDevice]);

  return (
    <Form
      id="devicePage_systemRuntimeStatsForm"
      form={formSystemRuntimeStats}
      layout={isTablet || isSemiDesktop ? 'horizontal' : 'vertical'}
      style={{ width: '100%' }}
      labelCol={{
        style: {
          width: 125
        }
      }}
      scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
    >
      <div className="row">
        {!!formSystemRuntimeStats.getFieldValue('cpuUtil') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="cpuUtil">
              <Form.Item<FormSystemRuntimeStatsFieldType>
                name="cpuUtil"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceSystemRuntime.cpuUtil')}
                  </span>
                }
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formSystemRuntimeStats.getFieldValue('memUtil') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="memUtil">
              <Form.Item<FormSystemRuntimeStatsFieldType>
                name="memUtil"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceSystemRuntime.dramUtil')}
                  </span>
                }
                colon={false}
              >
                <Input
                  className="text-truncate"
                  disabled
                  style={{
                    backgroundColor: dramUtilIsDanger ? 'var(--sub-6)' : '',
                    borderColor: dramUtilIsDanger ? 'var(--sub-3)' : ''
                  }}
                />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formSystemRuntimeStats.getFieldValue('sdCardUtil') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="sdCardUtil">
              <Form.Item<FormSystemRuntimeStatsFieldType>
                name="sdCardUtil"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceSystemRuntime.sdCardUtil')}
                  </span>
                }
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formSystemRuntimeStats.getFieldValue('cpuTemp') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="cpuTemp">
              <Form.Item<FormSystemRuntimeStatsFieldType>
                name="cpuTemp"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceSystemRuntime.cpuTemp')}
                  </span>
                }
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formSystemRuntimeStats.getFieldValue('kernel') && (
          <div className="col-12">
            <FormInputTooltip name="kernel">
              <Form.Item<FormSystemRuntimeStatsFieldType>
                name="kernel"
                label={
                  <span className="text-truncate">
                    {t('devicePage.sections.deviceSystemRuntime.kernel')}
                  </span>
                }
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
      </div>
    </Form>
  );
}

export default FormSystemRuntime;
