import { useCameraGroupCreatePageController } from './CameraGroupCreatePageController';
import { FormAction, Section } from 'presentation/components';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { General } from '../components';
import type { TCameraGroupGeneralFieldType } from '../cameraGroupPage.types';
import type { TAccount, TCameraGroupType } from 'models';
import { useAppUtil } from 'context/UtilContext';

function CameraGroupCreatePageView() {
  const { createCameraGroupForm, cameraGroupTypes, accountList, onFormSubmit, onCancelClick } =
    useCameraGroupCreatePageController();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { openModal } = useAppUtil();

  const { t } = useTranslation();

  const handleConfirmSubmit = (value: TCameraGroupGeneralFieldType) => {
    const name = value.name.trim();
    if (name.length < 8 || name.length > 64) {
      createCameraGroupForm.setFields([
        {
          name: 'name',
          errors: [t('forms.cameraGroupName.pattern')]
        }
      ]);
    } else {
      setIsSubmitting(true);
      openModal({
        title: t('components.confirmationTitle'),
        content: t('components.confirmationMessage', {
          action: t('components.create').toLowerCase(),
          entity: t('cameraGroupPage.entity').toLowerCase()
        }),
        okText: `${t('components.ok')}`,
        cancelText: `${t('components.cancel')}`,
        onOk: async () => {
          await onFormSubmit(value, () => setIsSubmitting(false));
          setIsSubmitting(false);
        },
        onCancel: () => setIsSubmitting(false),
        styles: {
          content: {
            width: 380
          }
        }
      });
    }
  };

  return (
    <div>
      <Section title={t('cameraGroupPage.sections.general.title')} classNameBody="p-4">
        <Form
          form={createCameraGroupForm}
          layout="horizontal"
          scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
          onFinish={(value: TCameraGroupGeneralFieldType) => handleConfirmSubmit(value)}
          labelCol={{
            style: {
              width: 130
            }
          }}
          colon={false}
        >
          <General<TCameraGroupType, TAccount>
            className="col-12 col-md-8"
            accountList={accountList}
            cameraGroupTypes={cameraGroupTypes}
          />
        </Form>
      </Section>
      <FormAction
        onCancel={onCancelClick}
        onSubmit={() => createCameraGroupForm.submit()}
        SubmitTypographyProps={{
          disabled: isSubmitting || accountList.loading || cameraGroupTypes.loading
        }}
      />
    </div>
  );
}

export default CameraGroupCreatePageView;
