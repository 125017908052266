import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { DomainRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { EHttpStatusCode, EStatusInvalid, Routes } from 'enums';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import type { TDomain } from 'models';
import { useEffect, useState } from 'react';
import { useAppUtil } from 'context/UtilContext';
import { handleApiError } from 'utils/common';
import { ApiErrorResponse } from 'models/ApiError';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { useLoaderContext } from 'context/LoaderContext';
import { useEffectOnce } from 'hooks/useEffectOnce';

/**
 * Represents the structure of domain form field values.
 */
export type DomainFieldType = {
  domainName: string;
  invalid: number;
  comment: string;
};

/**
 * A Controller for managing domain creation functionality.
 * @returns {Object} An object containing currentDomain, formGeneral, and handleSubmit function.
 */
const useCreationDomainController = () => {
  const { loader, isActive } = useLoaderContext();

  const [formGeneral] = Form.useForm();
  const { t } = useTranslation();
  const domainRepository = DomainRepository(AxiosClient);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDomain = useLoaderData() as TDomain;
  const { openNotification, openModal } = useAppUtil();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (currentDomain) {
      formGeneral.setFieldsValue({
        domainName: currentDomain.name,
        invalid: currentDomain.invalid,
        comment: currentDomain.comment
      });
    }
  }, [currentDomain]);

  /**
   * Handles creating a new domain with the provided data.
   * @param {string} name - The name of the domain.
   * @param {string} [comment=''] - The comment for the domain.
   * @param {EStatusInvalid} invalid - The status of the domain.
   */
  async function handleCreateDomain({
    name,
    comment = '',
    invalid
  }: {
    name: string;
    comment?: string;
    invalid: EStatusInvalid;
  }) {
    const domainDataOrError = await domainRepository.addDomain({
      name: name.trim(),
      comment: comment?.trim(),
      invalid
    });

    setIsSubmitting(false);

    if (domainDataOrError.isLeft()) {
      const err = domainDataOrError.error;

      const message = handleApiError({
        apiErrorResponse: err as ApiErrorResponse,
        action: 'create',
        entity: t('domainPage.entity'),
        identifier: name,
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.create')} ${t('domainPage.entity')}`,
        description: message
      });
      return;
    }

    // domainDataOrError is Right
    const { code, data } = domainDataOrError.value;

    if (code === EHttpStatusCode.OK) {
      openNotification({
        type: 'success',
        title: `${t('actions.create')} ${t('domainPage.entity')}`,
        description: `${t('components.success')}`
      });
      searchParams.set('search', data.name.trim());
      setSearchParams(searchParams);
      navigate({ pathname: Routes.EDomainRoutes.LISTING, search: searchParams.toString() });
    }
  }

  /**
   * Handles editing an existing domain with the provided data.
   * @param {number} id - The ID of the domain to edit.
   * @param {string} name - The new name for the domain.
   * @param {string} [comment=''] - The new comment for the domain.
   * @param {EStatusInvalid} invalid - The new status of the domain.
   */
  async function handleEditDomain(
    id: number,
    {
      name,
      comment = '',
      invalid
    }: {
      name: string;
      comment?: string;
      invalid: EStatusInvalid;
    }
  ) {
    const domainDataOrError = await domainRepository.editDomain(id, {
      name: name.trim(),
      comment: comment?.trim(),
      invalid
    });

    setIsSubmitting(false);

    if (domainDataOrError.isLeft()) {
      const err = domainDataOrError.error;
      const message = handleApiError({
        apiErrorResponse: err as ApiErrorResponse,
        action: 'update',
        entity: t('domainPage.entity'),
        t,
        identifier: `ID ${id.toString()}`
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('domainPage.entity')}`,
        description: message
      });
      return;
    }

    const { code } = domainDataOrError.value;

    if (code === EHttpStatusCode.OK) {
      openNotification({
        type: 'success',
        title: `${t('actions.update')} ${t('domainPage.entity')}`,
        description: `${t('components.success')}`
      });
      if (!searchParams.get('page')) {
        searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
        setSearchParams(searchParams);
      }
      navigate({ pathname: Routes.EDomainRoutes.LISTING, search: searchParams.toString() });
    }
  }

  /**
   * Handles submitting the domain form data.
   * @param {DomainFieldType} value - The domain form field values.
   * @param {boolean} editMode - Flag indicating whether it's in edit mode.
   */
  async function handleSubmit(value: DomainFieldType, editMode: boolean) {
    if (isSubmitting) return;
    const requestData = {
      name: value.domainName,
      comment: value.comment,
      invalid: value.invalid
    };
    setIsSubmitting(true);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('components.confirmationMessage', {
        action: editMode ? t('actions.update').toLowerCase() : t('components.create').toLowerCase(),
        entity: t('header.domain').toLowerCase()
      }),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      okButtonProps: { style: { padding: '0 30px', marginLeft: 10 } },
      cancelButtonProps: { style: { padding: '0 16px' } },
      onOk: async () => {
        if (editMode) {
          await handleEditDomain(currentDomain.id, requestData);
        } else {
          await handleCreateDomain(requestData);
        }
      },
      width: 300,
      onCancel: () => {
        setIsSubmitting(false);
      }
    });
  }

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  return {
    currentDomain,
    formGeneral,
    handleSubmit
  };
};

export default useCreationDomainController;
