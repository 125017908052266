import { TDataResponseEOSS } from 'models';
import { TEyeviewUserConnected, TLogOptions } from 'models/EyeviewSession';
import { TAxiosClient } from 'services/axios';
import { IEyeviewSessionDataSource } from './EyeviewSessionDataSource';
import { EHttpStatusCode } from 'enums';

/**
 * Represents options for logging in Eyeview.
 */
type TLogOptionsDTO = {
  Archive: boolean;
  Vcr: boolean;
  Stop: boolean;
  User_Pref: boolean;
  Clock: boolean;
  Properties: boolean;
  Cleanup: boolean;
  Protobuf: boolean;
  Login_Logout: boolean;
  Init_Stop: boolean;
  Export: boolean;
  JP2_Codec: boolean;
  Servlet: boolean;
  Gui: boolean;
  Pool: boolean;
  Db: boolean;
  Debug: boolean;
  Live: boolean;
  Range: boolean;
  Thread: boolean;
  Play: boolean;
  Nats: boolean;
  Performance: boolean;
  Frame_Processing: boolean;
  Bvr: boolean;
};

/**
 * Represents a remote data source implementation for Eyeview session data.
 * @author hung.nguyen@zien.vn
 */
export class EyeviewSessionRemoteDataSourceImpl implements IEyeviewSessionDataSource {
  httpService: TAxiosClient;

  private static instance: EyeviewSessionRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of EyeviewSessionRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {EyeviewSessionRemoteDataSourceImpl} The singleton instance of EyeviewSessionRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): EyeviewSessionRemoteDataSourceImpl {
    if (!EyeviewSessionRemoteDataSourceImpl.instance) {
      EyeviewSessionRemoteDataSourceImpl.instance = new EyeviewSessionRemoteDataSourceImpl(
        httpService
      );
    }
    return EyeviewSessionRemoteDataSourceImpl.instance;
  }

  /**
   * Retrieves the list of Eyeview users connected to a specific server.
   * @param {number} serverId - The ID of the server to retrieve connected users for.
   * @returns {Promise<TDataResponseEOSS<TEyeviewUserConnected>>} A promise that resolves with the response containing the status code and user data.
   */
  async getEyeviewUsersConnected({
    serverId
  }: {
    serverId: number;
  }): Promise<TDataResponseEOSS<TEyeviewUserConnected>> {
    const { data, status } = await this.httpService.get<TEyeviewUserConnected>(
      `/eyeview/server/${serverId}`
    );
    return {
      code: status,
      data: data
    };
  }

  /**
   * Logs out an account from a specific server.
   * @param {string} account_name - The name of the account to log out.
   * @param {number} server_id - The ID of the server from which to log out the account.
   * @returns {Promise<EHttpStatusCode>} A promise that resolves with the HTTP status code indicating the success of the logout operation.
   */
  async logoutAccount({
    account_name,
    server_id
  }: {
    account_name: string;
    server_id: number;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.delete(
      `/eyeview/server/${server_id}/disconnect/account`,
      {
        headers: {
          'content-type': 'multipart/form-data'
        },
        data: {
          account_name
        }
      }
    );
    return status;
  }

  /**
   * Logs out an Eyeview user from a specific server session.
   * @param {string} client_id - The ID of the client to log out.
   * @param {number} server_id - The ID of the server from which to log out the user.
   * @returns {Promise<EHttpStatusCode>} A promise that resolves with the HTTP status code indicating the success of the logout operation.
   */
  async logoutEyeviewUser({
    client_id,
    server_id
  }: {
    client_id: string;
    server_id: number;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.delete(
      `/eyeview/server/${server_id}/disconnect/session`,
      {
        headers: {
          'content-type': 'multipart/form-data'
        },
        data: {
          client_id
        }
      }
    );
    return status;
  }

  /**
   * Retrieves the log options for a specific server.
   * @param {number} serverId - The ID of the server to retrieve log options for.
   * @returns {Promise<TLogOptions>} A promise that resolves with the log options for the server.
   */
  async getLogOptions({ serverId }: { serverId: number }): Promise<TLogOptions> {
    const { data } = await this.httpService.get<TLogOptionsDTO>(
      `/eyeview/server/${serverId}/log/options`
    );
    return data;
  }

  /**
   * Updates the log options for a specific server.
   * @param {number} serverId - The ID of the server to update log options for.
   * @param {TLogOptions} options - The log options to update.
   * @returns {Promise<EHttpStatusCode>} A promise that resolves with the HTTP status code indicating the success of the update operation.
   */
  async updateLogOptions({
    serverId,
    options
  }: {
    serverId: number;
    options: TLogOptions;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put<TLogOptionsDTO>(
      `/eyeview/server/${serverId}/log/options`,
      { ...options }
    );
    return status;
  }
}
