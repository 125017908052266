import { useCallback, useEffect, useState } from 'react';

export function useHorizontalScroll() {
  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const ref = useCallback((nodeElement: HTMLDivElement) => {
    setNode(nodeElement);
  }, []);

  const handleMouseWheel = useCallback(
    (e: WheelEvent) => {
      if (node) {
        node.scrollLeft += e.deltaY;
      }
    },
    [node]
  );

  useEffect(() => {
    if (!node) return;

    node.addEventListener('wheel', handleMouseWheel);

    return () => {
      node.removeEventListener('wheel', handleMouseWheel);
    };
  }, [node]);

  return {
    ref
  };
}
