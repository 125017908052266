import { EHttpStatusCode } from 'enums';
import type { TDataResponseEOSS, TEyeviewUser } from 'models';
import { TAxiosClient } from 'services/axios';
import {
  IEyeviewUserDataSource,
  TCreateEyeviewUserPayload,
  TEditEyeviewUserPayload,
  TEyeviewUserResponse
} from './EyeviewUserDataSource';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Represents a remote data source implementation for handling Eyeview-user-related data.
 * @author giang.bui@zien.vn
 */
export class UserRemoteDataSourceImpl implements IEyeviewUserDataSource {
  httpService: TAxiosClient;

  private static instance: UserRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of UserRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {UserRemoteDataSourceImpl} The singleton instance of UserRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): UserRemoteDataSourceImpl {
    if (!UserRemoteDataSourceImpl.instance) {
      UserRemoteDataSourceImpl.instance = new UserRemoteDataSourceImpl(httpService);
    }
    return UserRemoteDataSourceImpl.instance;
  }

  async getEyeviewUserById(id: number): Promise<TDataResponseEOSS<TEyeviewUserResponse>> {
    const { data, status } = await this.httpService.get<TEyeviewUserResponse>(`eyeview/user/${id}`);
    return {
      code: status,
      data: mapSnakeCaseToCamelCase(data)
    };
  }

  async getListEyeviewUser(search: string): Promise<TDataResponseEOSS<TEyeviewUser[]>> {
    const {
      data: { users },
      status
    } = await this.httpService.get<{ users: TEyeviewUserResponse[] }>('eyeview/user', {
      params: { search }
    });

    return {
      code: status,
      data: users.map((user: TEyeviewUserResponse) => mapSnakeCaseToCamelCase(user))
    };
  }

  async createEyeviewUser(params: TCreateEyeviewUserPayload): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TEyeviewUserResponse>>(
      'eyeview/user',
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async editEyeviewUser(id: number, params: TEditEyeviewUserPayload): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put<TDataResponseEOSS<TEyeviewUserResponse>>(
      `eyeview/user/${id}`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async cloneUser(params: TEyeviewUser): Promise<TDataResponseEOSS<TEyeviewUser>> {
    const { data, status } = await this.httpService.post<TEyeviewUserResponse>(
      `eyeview/user/${params.id}/clone`,
      params
    );
    return {
      code: status,
      data: mapSnakeCaseToCamelCase(data)
    };
  }
}
