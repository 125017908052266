export const ZIP_CODE_VALIDATION_REGEX = /^\d{5}(-\d{4})?$/; // TODO: Support international regex for zip code soon
export const URL_VALIDATION_REGEX =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
export const IP_VALIDATION_REGEX =
  /^(?!0\.0\.0\.0|255\.255\.255\.255)((((2([0-4][0-9]|5[0-5]))|1[0-9]{2}|[0-9]{1,2})\.){3}(((2([0-4][0-9]|5[0-5]))|1[0-9]{2}|[0-9]{1,2})))$/;
export const VERSION_REGEX = /^[1-9]\d*$/;
// export const PASSWORD_VALIDATION_REGEX =
//   /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[`~!@#$%^&*(\\)\-\\+{}[\]|\\:;'",.?/<>_])[A-Za-z\d`~!@#$%^&*(\\)\-\\+{}[\]|\\:;'",.?/<>_]{8,}$/;

export const PASSWORD_VALIDATION_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;
export const UPPERCASE_AND_LOWERCASE = /^(?=.*[a-z])(?=.*[A-Z])/;
export const CONTAINER_NUMBER = /^(?=.*[0-9])/;
export const PASSWORD_SPECIAL_CHARACTER = /^[!@#$%^&*]*$/;
export const USERNAME_VALIDATION_REGEX = /^[a-z0-9-_]{4,32}$/; //Matches a string of lowercase letters, numbers, underscores, and hyphens between 4 and 32 characters long.
export const COMMENT_REGEX = /^.{0,250}$/;
export const COMMON_NAME_REGEX = /^[a-zA-Z0-9\-_]{4,32}$/;
export const EMAIL_REGEX = /^\s*([^\s@]+@[^\s@]+\.[^\s@]+)\s*$/; // Email regex allowing white space
export const CAMERA_GROUP_NAME_REGEX = /^[a-zA-Z0-9-_: ]{8,64}$/;
export const LAT_REGEX = /^\(?[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/;
export const LONG_REGEX = /^\s?[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\)?$/;
