import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode } from 'enums';
import { TDomain, TPaginationEOSS, TSortOrder } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { DomainRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

/**
 * Controller for the Domain Listing page.
 * @returns {{
 *   fetchListDevices: (search?: string) => Promise<void>,
 *   listDomains: {
 *     data: TDomain[],
 *     isLoading: boolean,
 *     paging: TPaginationEOSS
 *   },
 *   domainId: string,
 *   sortKey: string | null,
 *   order: TSortOrder | null,
 *   searchParams: URLSearchParams,
 *   searchText: string | null,
 *   page: string | null,
 *   setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>
 * }}
 */
export const useDomainListingPageController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const sortKey = searchParams.get('sortKey');
  const order = searchParams.get('order') as TSortOrder;
  const page = searchParams.get('page');
  const { t } = useTranslation();
  const { openNotification } = useAppUtil();

  const domainRepository = DomainRepository(AxiosClient);
  const { domainId } = useParams<{ domainId: string }>();
  const { refetchCount } = useOutletContext<{ refetchCount: number }>();

  const [listDomains, setListDomains] = useState<{
    data: TDomain[];
    isLoading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    isLoading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: Number(page) || DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  async function handleFetchList(search?: string) {
    if (listDomains.isLoading) return;
    setListDomains((prev) => {
      return {
        ...prev,
        isLoading: true
      };
    });

    try {
      const { data, code } = await domainRepository.getListDomain({
        search
      });

      if (code === EHttpStatusCode.OK) {
        setListDomains((prev) => {
          return {
            ...prev,
            isLoading: false,
            data: data,
            paging: {
              ...prev.paging,
              total: data.length,
              pageLimit: DEFAULT_LIMIT
            }
          };
        });
      }
    } catch (err) {
      const message = handleApiError({
        apiErrorResponse: err as ApiErrorResponse,
        action: 'get',
        entity: t('domainPage.entity'),
        t
      });

      openNotification({
        type: 'error',
        title: `${t('actions.search')} ${t('domainPage.entity')}`,
        description: message
      });
    }
  }

  function refetchList() {
    if (!searchText) return;
    handleFetchList(searchText === '*' ? '' : searchText || '');
  }

  useEffect(() => {
    if (refetchCount > 0) {
      refetchList();
    }
  }, [refetchCount]);

  useEffect(() => {
    if (searchText) {
      handleFetchList(searchText === '*' ? '' : searchText || '');
    } else {
      setListDomains({
        data: [],
        isLoading: false,
        paging: { pageLimit: DEFAULT_LIMIT, pageNum: DEFAULT_PAGE_NUMBER, total: 0, totalPage: 0 }
      });
    }
  }, [searchText]);

  useEffect(() => {
    if (page) {
      setListDomains((prev) => {
        return {
          ...prev,
          paging: {
            ...prev.paging,
            pageNum: Number(page)
          }
        };
      });
    }
  }, [page]);

  return {
    fetchListDevices: handleFetchList,
    listDomains,
    domainId,
    sortKey,
    order,
    searchParams,
    searchText,
    page,
    setSearchParams
  };
};
