import { EStorageKey } from 'enums';
import { IAuthLocalDataSource } from './AuthDataSource';

/**
 * Implements the IAuthLocalDataSource interface, providing methods for storing and managing authentication data in local storage.
 *
 * **Singleton Pattern:** Employs the singleton pattern to ensure there's only one instance of this data source for consistency.
 *
 * @see IAuthLocalDataSource
 * @author hung.nguyen@zien.vn
 */
export class AuthLocalDataSourceImpl implements IAuthLocalDataSource {
  private static instance: AuthLocalDataSourceImpl;

  /**
   * Provides a globally accessible instance of the AuthLocalDataSourceImpl class. Creates a new instance if one doesn't already exist.
   *
   * @returns {AuthLocalDataSourceImpl} - The single instance of the AuthLocalDataSourceImpl class.
   */
  public static getInstance(): AuthLocalDataSourceImpl {
    if (!AuthLocalDataSourceImpl.instance) {
      AuthLocalDataSourceImpl.instance = new AuthLocalDataSourceImpl();
    }
    return AuthLocalDataSourceImpl.instance;
  }

  /**
   * Stores an access token in local storage.
   *
   * @param {string} accessToken - The access token to store.
   */
  saveToken(accessToken: string) {
    localStorage.setItem(EStorageKey.EOSS_TOKEN, accessToken);
  }

  /**
   * Stores the accessToken expiration time (in Unix timestamp format) in local storage.
   *
   * @param {number} unixTime - The expiration time in Unix timestamp format.
   */
  saveExpTime(unixTime: number) {
    localStorage.setItem(EStorageKey.EOSS_EXP_TIME, JSON.stringify(unixTime));
  }
}
