import { Button, Form, FormInstance, Input, Select } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../cameraGroupPage.module.scss';
import { TAccount } from 'models';
import { TCameraGroupSearch } from '../cameraGroupPage.types';
import { orderBy } from 'lodash';
import { DropdownIcon } from 'assets/icons';

type Props = {
  form: FormInstance<TCameraGroupSearch>;
  accountList: {
    data: TAccount[];
    loading: boolean;
  };
  onSearchSubmit: (value: TCameraGroupSearch) => void;
  onSearchChange: (value: string) => void;
};

const SearchAction: FC<Props> = ({ form, accountList, onSearchSubmit, onSearchChange }) => {
  const { t } = useTranslation();

  return (
    <Form
      id="deviceGroupPage_searchAction"
      form={form}
      layout="vertical"
      onFinish={onSearchSubmit}
      className={styles.selectWrapper}
    >
      <Form.Item<TCameraGroupSearch>
        name="accountId"
        label={t('forms.accountFilter.label')}
        className="mb-2"
      >
        <Select
          options={[
            {
              label: '-',
              value: -1
            },
            ...orderBy(
              accountList.data.map((value: TAccount) => ({
                label: value.name,
                value: value.id
              })),
              'label',
              ['asc']
            )
          ]}
          suffixIcon={accountList.loading ? undefined : <DropdownIcon color="var(--primary-1)" />}
          loading={accountList.loading}
          showSearch
          optionFilterProp="children"
          filterOption={(input: string, option?: { label: string; value: string | number }) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item<TCameraGroupSearch> name="groupName" label={t('forms.cameraGroupName.label')}>
        <Input
          type="search"
          className={'search-input'}
          allowClear
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
        />
      </Form.Item>
      <div className="d-flex justify-content-end">
        <Button
          className="btn-action btn-fill"
          htmlType="submit"
          onClick={() => {
            const event = new CustomEvent('close-sidebar-action');
            document.dispatchEvent(event);
          }}
        >
          <span className="fs-14 fw-semibold">{t('components.search')}</span>
        </Button>
      </div>
    </Form>
  );
};

export default React.memo(SearchAction);
