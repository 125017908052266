import { useAuthContext } from 'context/AuthContext';
import { useLoaderContext } from 'context/LoaderContext';
import { TLoginParams } from 'data/Auth/AuthDataSource';

export const useLoginPageController = () => {
  const { login } = useAuthContext();
  const { loader } = useLoaderContext();

  async function handleOnLogin(params: TLoginParams) {
    loader.start();
    const res = await login(params);
    loader.complete();
    return res;
  }
  return {
    login: handleOnLogin
  };
};
