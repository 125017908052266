import { useAppUtil } from 'context/UtilContext';
import type { TServer } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeviewSessionRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

export const useEyeviewSessionPageController = () => {
  const eyeviewSessionRepository = EyeviewSessionRepository(AxiosClient);
  const { openNotification } = useAppUtil();
  const [listVASServer, setListVASSever] = useState<TServer[]>([]);
  const [isLoadingServers, setIsLoadingServers] = useState<boolean>(false);
  const { t } = useTranslation();

  async function fetchListVASServer() {
    try {
      if (isLoadingServers) return;
      setIsLoadingServers(true);
      const data = await eyeviewSessionRepository.getListVASServer();
      setListVASSever(data);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('serverPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('serverPage.entity')}}`,
        description: message
      });
    } finally {
      setIsLoadingServers(false);
    }
  }

  useEffect(() => {
    fetchListVASServer();
  }, []);

  return {
    listVASServer,
    isLoadingServers
  };
};
