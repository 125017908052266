import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEOSSContext } from 'context/EOSSContext';
import { orderBy } from 'lodash';
import { TAccountType } from 'models';
import { Section } from 'presentation/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './tableAccountType.module.scss';

function AccountTypePageView() {
  const { listAccountType, refetchListAccountType } = useEOSSContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const columns: ColumnsType<TAccountType> = [
    {
      title: t('systemPage.table.columns.id'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (val) => (
        <span className="fs-13 fw-medium" style={{ color: 'var(--primary-1)' }}>
          {val}
        </span>
      )
    },
    {
      title: t('systemPage.table.columns.name'),
      dataIndex: 'name',
      key: 'name',
      render(value, record) {
        return (
          <span className="fs-13 fw-medium" style={{ color: 'var(--primary-1)' }}>
            {record.description} ({record.name})
          </span>
        );
      }
    }
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refetchListAccountType();
      setIsLoading(false);
    })();
  }, []);

  return (
    <Section title={t('systemPage.sections.accountType.title')}>
      <Table
        id="customerPage_listCustomerTypesTable"
        className={styles.container}
        loading={isLoading}
        pagination={false}
        rowHoverable={false}
        tableLayout="auto"
        scroll={{
          x: listAccountType.length > 0 ? 'auto' : 'min-content'
        }}
        columns={columns}
        rowKey="id"
        dataSource={isLoading ? [] : orderBy(listAccountType, 'id', 'asc')}
        rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      />
    </Section>
  );
}

export default AccountTypePageView;
