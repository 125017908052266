export default {
  systemPage: {
    breadcrumb: {
      index: 'System Management',
      service: 'Service',
      permission: 'Permission',
      deviceType: 'Device Type',
      accountType: 'Customer Type',
      serverType: 'Server Type'
    },
    sections: {
      service: {
        title: 'Service List'
      },
      permission: {
        title: 'Permission List'
      },
      deviceType: {
        title: 'Device Type List'
      },
      accountType: {
        title: 'Customer Type List'
      },
      serverType: {
        title: 'Server Type List'
      }
    },
    table: {
      columns: {
        id: 'id',
        name: 'name',
        service: 'service',
        comment: 'comment'
      }
    },
    tab: {
      service: 'Service',
      permission: 'Permission',
      deviceType: 'Device Type',
      accountType: 'Customer Type',
      serverType: 'Server Type'
    }
  }
};
