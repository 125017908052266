export default {
  accountPage: {
    breadcrumb: {
      listing: 'Customer Management',
      create: 'Create Customer'
    },
    sections: {
      search: {
        title: 'Search Customer',
        instruction: 'Search by domain or customer name',
        instructionForDomainAdmin: 'Search by customer name'
      },
      create: {
        title: 'Create Customer'
      },
      assignServiceDevice: {
        title: 'Assign Service Device',
        description: 'Assign a new Service device to customer',
        placeholder: 'Device Serial Number'
      },
      assignReferenceDevice: {
        title: 'Assign Reference Device',
        description: 'Assign a new reference device to customer',
        placeholder: 'Device Serial Number'
      },
      listAccount: {
        title: 'Customer List'
      },
      billing: {
        title: 'Billing'
      },
      contacts: {
        title: 'Contact',
        actionButton: 'Add Contact'
      },
      devicesSummary: {
        title: 'Service Device Summary',
        description:
          'A device summary table shows the number of each device type based on different statuses, such as total, connected, and disconnected. Clicking on the device type name will navigate to the Device Listing page, where the list of devices filtered by the customer name will be displayed.',
        entity: 'Service Device Summary'
      },
      serviceDevice: {
        title: 'Service Device List',
        entity: 'Service Device'
      },
      referenceDevice: {
        title: 'Device Reference List',
        entity: 'Device Reference'
      },
      general: {
        title: 'General'
      },
      deleteIcon: {
        tooltip: 'Delete this contact'
      }
    },
    entity: 'Customer',
    accountListingTable: {
      account: 'Customer',
      domain: 'Domain',
      name: 'Name',
      valid: 'Valid',
      changed: 'Changed',
      created: 'Created',
      comment: 'Comment',
      accountType: 'Customer Type',
      invalidPageNo: 'Invalid Page Number',
      type: 'Type',
      serialNumber: 'Serial Number',
      version: 'Version',
      status: 'Status',
      bvr: 'BVR Server',
      vas: 'VAS Server',
      lastContacted: 'Last contacted',
      ups: 'Ups',
      bound: 'Bound'
    },
    deviceReferenceTable: {
      serialNumber: 'serial Number'
    },
    deviceSummaryTable: {
      name: 'Name',
      total: 'Total',
      connected: 'Connected',
      disconnected: 'Disconnected',
      alarms: 'Alarms'
    },
    serviceDevicesTable: {
      type: 'Type',
      serialNumber: 'Serial Number',
      version: 'Version',
      domain: 'Domain',
      status: 'Status',
      bvr: 'BVR Server',
      vas: 'VAS Server',
      lastContacted: 'Last Contacted',
      ups: 'UPS'
    },
    emptyTable: {
      title: 'Get Started',
      subTitle: 'By searching customer'
    },
    modal: {
      title: 'eoss.eyecast.com says',
      content: {
        create: 'Are you sure to create this Customer?',
        edit: 'Are you sure to submit this Customer?'
      }
    },
    '404': {
      title: 'Not found',
      subTitle: 'Customer with this ID has not existed yet'
    },
    confirmModal: {
      assign: 'Are you sure to assign service device'
    },
    inventoryAccountEntity: 'inventory customer'
  }
};
