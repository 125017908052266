export default {
  components: {
    bind: 'Bind',
    sync: 'Sync',
    search: 'Search',
    create: 'Create',
    all: 'All',
    save: 'Save',
    cancel: 'Cancel',
    ok: 'Ok',
    submitted: 'Submitted',
    back: 'Back',
    apply: 'Apply',
    clear: 'Clear',
    submit: 'Submit',
    delete: 'Delete',
    deleted: 'Deleted',
    clone: 'Clone',
    enable: 'Enable',
    enabled: 'Enabled',
    disable: 'Disable',
    disabled: 'Disabled',
    assign: 'Assign',
    assigned: 'Assigned',
    unassign: 'Unassign',
    success: 'Successfully',
    fail: 'Failure',
    add: 'Add',
    getStarted: 'Get Started',
    collapse: 'Collapse',
    expand: 'Expand',
    from: 'from',
    replace: 'Replace',
    to: 'to',
    rules: {
      password: {
        leastCharacters: 'Minimum of 10 characters.',
        textTransform: 'Uppercase and lowercase letters.',
        letters: 'At least one number.',
        specialCharacters:
          'At least one of the following special characters: !, @, #, $, %, ^, &, *',
        spaceNotAllowed: 'Space is not allowed.'
      },
      lengthCharacters: {
        maximum16: 'This field must not be greater than 16 characters long.',
        maximum32: 'This field must not be greater than 32 characters long.',
        maximum64: 'This field must not be greater than 64 characters long.',
        maximum128: 'This field must not be greater than 128 characters long.',
        maximum256: 'This field must not be greater than 256 characters long.'
      }
    },
    loading: 'Loading',
    generatePassword: 'Generate Password',
    valid: 'valid',
    invalid: 'invalid',
    confirmationMessage: 'Are you sure to {{action}} this {{entity}}?',
    confirmationTitle: 'eoss.eyecast.com says',
    logout: 'logout',
    serialCopied: 'Serial number copied!',
    loginCopied: 'Login copied!',
    domainCopied: 'Domain name copied!',
    accountNumberCopied: 'Customer number copied!',
    cameraGroupNameCopied: 'Device group name copied!',
    usernameCopied: 'Username copied!',
    copied: 'Copied',
    sessionExpired: 'Session Expired',
    comingSoon: 'Coming soon...',
    notFound: 'Not Found',
    passwordCriteria: 'Password criteria',
    filterRegion: 'Filtered by Region',
    noRegionSelected: 'No region selected'
  },
  actions: {
    create: 'Create',
    get: 'Get',
    update: 'Update',
    delete: 'Delete',
    bind: 'Bind',
    sync: 'Sync',
    clone: 'Clone',
    assign: 'Assign',
    unassign: 'Unassign',
    scan: 'Scan',
    import: 'Import',
    export: 'Export',
    reboot: 'Reboot',
    run: 'Run',
    search: 'Search',
    login: 'Login'
  },
  eossContext: {
    permission: 'Permission',
    serverType: 'Server type',
    accountType: 'Customer type',
    deviceType: 'Device type',
    language: 'Language',
    services: 'Services',
    fieldDisplayMapping: 'Field display mapping'
  }
};
