import orderBy from 'lodash/orderBy';
import { TService } from 'models';
import { TAxiosClient } from 'services/axios';
import { IServiceDataSource } from './ServiceDataSource';

/**
 * Represents a remote data source implementation for handling service data.
 * @author hung.nguyen@zien.vn
 */
export class ServiceRemoteDataSourceImpl implements IServiceDataSource {
  httpService: TAxiosClient;

  private static instance: ServiceRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of ServiceRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {ServiceRemoteDataSourceImpl} The singleton instance of ServiceRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): ServiceRemoteDataSourceImpl {
    if (!ServiceRemoteDataSourceImpl.instance) {
      ServiceRemoteDataSourceImpl.instance = new ServiceRemoteDataSourceImpl(httpService);
    }
    return ServiceRemoteDataSourceImpl.instance;
  }

  async getListServices(): Promise<TService[]> {
    const { data } = await this.httpService.get<TService[]>('/services/all');
    return orderBy(data, 'id', 'asc');
  }
}
