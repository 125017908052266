import { TCameraGroupAvailable, TCameraGroup, TDataResponseEOSS, TEyeviewUser } from 'models';
import { ICameraGroupDataSource } from './CameraGroupDataSource';
import { TAxiosClient } from 'services/axios';
import { mapSnakeCaseToCamelCase } from 'utils/common';
import { EHttpStatusCode } from 'enums';
import { DEFAULT_PAGE_NUMBER, INFINITE_SIZE_FETCHING } from 'constant';

/**
 * Represents a remote data source implementation for handling camera-group-related data.
 * @author giang.bui@zien.vn
 */
export class CameraGroupRemoteDataSourceImpl implements ICameraGroupDataSource {
  httpService: TAxiosClient;

  private static instance: CameraGroupRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  async assignEyeviewUserToCameraGroup({
    camera_group_id,
    user_id
  }: {
    camera_group_id: string;
    user_id: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TCameraGroup>>(
      `camera/group/${camera_group_id}/user`,
      {
        user_id
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async unassignedUsersFromCameraGroup({
    camera_group_id,
    user_ids
  }: {
    camera_group_id: number;
    user_ids: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put<TDataResponseEOSS<TCameraGroup>>(
      `camera/group/${camera_group_id}/user`,
      {
        user_ids
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  /**
   * Gets the singleton instance of CameraGroupRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {CameraGroupRemoteDataSourceImpl} The singleton instance of CameraGroupRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): CameraGroupRemoteDataSourceImpl {
    if (!CameraGroupRemoteDataSourceImpl.instance) {
      CameraGroupRemoteDataSourceImpl.instance = new CameraGroupRemoteDataSourceImpl(httpService);
    }
    return CameraGroupRemoteDataSourceImpl.instance;
  }

  async unassignCameraGroupsFromEyeviewUser({
    eyeview_user_id,
    camera_group_ids
  }: {
    eyeview_user_id: number;
    camera_group_ids: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TEyeviewUser>>(
      '/camera/group/remove/user',
      {
        user_id: eyeview_user_id,
        group_ids: camera_group_ids
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );

    return status;
  }

  async assignCameraGroupToEyeviewUser({
    camera_group_id,
    user_id
  }: {
    camera_group_id: number;
    user_id: number;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TEyeviewUser>>(
      `/camera/group/${camera_group_id}/user`,
      {
        user_id
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );

    return status;
  }

  async syncCameraConfig({
    camera_group_id,
    camera_id
  }: {
    camera_group_id: string;
    camera_id: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TCameraGroup>>(
      `/camera/group/${camera_group_id}/sync/cameras`,
      {
        camera_id
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );

    return status;
  }

  async unbindCamerasFromCameraGroup({
    camera_serials,
    groups_id
  }: {
    camera_serials: string;
    groups_id: number;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put<TDataResponseEOSS<TCameraGroup>>(
      `/camera/group/${groups_id}/camera`,
      {
        serials: camera_serials
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async updateCameraById({
    camera_group_id,
    name,
    camera_group_type_id,
    description,
    lat,
    lon,
    address,
    location_number
  }: {
    camera_group_id: number;
    name: string;
    camera_group_type_id: number;
    description: string;
    lat?: number;
    lon?: number;
    address?: string;
    location_number: string | null;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put<TDataResponseEOSS<TCameraGroup>>(
      `/camera/group/${camera_group_id}`,
      {
        name,
        camera_group_type_id,
        description,
        lat,
        lon,
        address,
        location_number
      },
      {
        headers: {
          'content-type': 'application/json'
        }
      }
    );
    return status;
  }

  async bindCameraToCameraGroup({
    serial_numbers,
    camera_group_id
  }: {
    serial_numbers: string;
    camera_group_id: number;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TCameraGroup>>(
      `/camera/group/${camera_group_id}/camera`,
      {
        serials: serial_numbers
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async deleteCameraGroup(camera_group_id: number): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.delete<TDataResponseEOSS<TCameraGroup>>(
      `/camera/group/${camera_group_id}`
    );
    return status;
  }

  async getListCameraGroup({
    search,
    account_id,
    page,
    excluded_type_ids
  }: {
    search: string;
    account_id?: number;
    page: string;
    excluded_type_ids?: string;
  }): Promise<TDataResponseEOSS<TCameraGroup[]>> {
    const requestParams = new URLSearchParams({
      val: search,
      page
    });

    account_id && requestParams.append('account_id', account_id.toString());
    excluded_type_ids && requestParams.append('excluded_type_ids', excluded_type_ids);

    const { status, data } = await this.httpService.get<{
      groups: TCameraGroup[];
      current_page: number;
      page_size: number;
      total_pages: number;
      total_elements: number;
    }>('/camera/group/search', {
      params: requestParams
    });
    return {
      code: status,
      data: data.groups.map((item: TCameraGroup) => mapSnakeCaseToCamelCase(item)),
      page: {
        pageLimit: data.page_size,
        pageNum: data.current_page,
        total: data.total_elements,
        totalPage: data.total_pages
      }
    };
  }

  async createCameraGroup({
    name,
    account_id,
    camera_group_type_id,
    description,
    address,
    lat,
    lon,
    location_number
  }: {
    name: string;
    account_id: number;
    camera_group_type_id: number;
    description: string;
    lat: number;
    lon: number;
    address: string;
    location_number: string | null;
  }): Promise<{ code: EHttpStatusCode }> {
    const { status } = await this.httpService.post<TDataResponseEOSS<TCameraGroup>>(
      '/camera/group/',
      {
        name,
        account_id,
        camera_group_type_id,
        description,
        lon,
        lat,
        address,
        location_number
      },
      {
        headers: {
          'content-type': 'application/json'
        }
      }
    );
    return {
      code: status
    };
  }

  async getCameraGroupById(camera_group_id: number): Promise<TDataResponseEOSS<TCameraGroup>> {
    const { data, status } = await this.httpService.get<TCameraGroup>(
      `/camera/group/${camera_group_id}`
    );
    return {
      code: status,
      data: mapSnakeCaseToCamelCase(data)
    };
  }

  async bindDeviceToGroup({
    groupId,
    serialNumbers
  }: {
    groupId: number;
    serialNumbers: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post(
      `/camera/group/${groupId}/camera`,
      {
        serials: serialNumbers
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async getListCameraGroupsAvailable({
    deviceId,
    page,
    search,
    userId
  }: {
    deviceId?: number;
    userId?: number;
    page?: number;
    search?: string;
  }): Promise<TDataResponseEOSS<TCameraGroupAvailable>> {
    const requestParams = new URLSearchParams({
      page: page ? page.toString() : (DEFAULT_PAGE_NUMBER - 1).toString(),
      size: `${INFINITE_SIZE_FETCHING}`,
      search: search ? search : '*',
      sort: 'name,asc',
      ...(deviceId ? { id: deviceId.toString() } : {}),
      ...(userId ? { id: userId.toString() } : {})
    });
    if (deviceId) {
      const { data, status } = await this.httpService.get<TCameraGroupAvailable>(
        '/camera/group/available/by/device',
        {
          params: requestParams
        }
      );
      return {
        data,
        code: status
      };
    }
    // default return list camera groups available with userId.
    const { data, status } = await this.httpService.get<TCameraGroupAvailable>(
      '/camera/group/available/by/user',
      {
        params: requestParams
      }
    );
    return {
      data,
      code: status
    };
  }
}
