export const ServerPageEmptyIcon = () => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 48.8945C49.3431 48.8945 48 50.2377 48 51.8945V74.4903C48 76.1471 49.3431 77.4903 51 77.4903H109C110.657 77.4903 112 76.1471 112 74.4903V51.8945C112 50.2377 110.657 48.8945 109 48.8945H51ZM62.2995 69.3178C65.6837 69.3178 68.4272 66.5744 68.4272 63.1902C68.4272 59.8059 65.6837 57.0625 62.2995 57.0625C58.9153 57.0625 56.1719 59.8059 56.1719 63.1902C56.1719 66.5744 58.9153 69.3178 62.2995 69.3178Z"
        fill="#888C90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 82.9375C49.3431 82.9375 48 84.2806 48 85.9375V108.533C48 110.19 49.3431 111.533 51 111.533H109C110.657 111.533 112 110.19 112 108.533V85.9375C112 84.2806 110.657 82.9375 109 82.9375H51ZM62.2995 103.365C65.6837 103.365 68.4272 100.621 68.4272 97.237C68.4272 93.8528 65.6837 91.1094 62.2995 91.1094C58.9153 91.1094 56.1719 93.8528 56.1719 97.237C56.1719 100.621 58.9153 103.365 62.2995 103.365Z"
        fill="#888C90"
      />
    </svg>
  );
};
