import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TDeviceSummary } from 'models';
import { Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import styles from './devicesSummary.module.scss';
import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'enums';
import { useLoaderContext } from 'context/LoaderContext';

export type TDeviceSummaryWithDisplayName = TDeviceSummary & {
  typeAndDisplayName: [string, string]; // tuple: 0 is typeName, 1 is displayName
};

type Props = {
  data: TDeviceSummaryWithDisplayName[];
  loading: boolean;
  accountName: string;
};

export const DevicesSummary = memo(({ data, loading, accountName }: Props) => {
  const { t } = useTranslation();

  const { loader } = useLoaderContext();

  const [clickedDeviceName, setClickedDeviceName] = useState<string>();

  useEffect(() => {
    return () => {
      setClickedDeviceName(undefined);
    };
  }, []);

  const columns: ColumnsType<TDeviceSummaryWithDisplayName> = [
    {
      title: t('accountPage.deviceSummaryTable.name').toUpperCase(),
      dataIndex: 'typeAndDisplayName',
      key: 'typeAndDisplayName',
      render: (typeAndDisplayName) => (
        <Link
          to={`${
            Routes.EDevicesRoutes.INDEX
          }/${typeAndDisplayName[0].toLowerCase()}?search=${accountName}&page=1`}
          style={{
            pointerEvents:
              clickedDeviceName === typeAndDisplayName[0] && accountName ? 'none' : 'auto'
          }}
          onClick={() => {
            loader.start();
            setClickedDeviceName(typeAndDisplayName[0]);
          }}
        >
          {typeAndDisplayName[1]}
        </Link>
      ),
      width: 200
    },
    {
      title: t('accountPage.deviceSummaryTable.total').toUpperCase(),
      dataIndex: 'total',
      key: 'total',
      width: 100
    },
    {
      title: t('accountPage.deviceSummaryTable.connected').toUpperCase(),
      dataIndex: 'online',
      key: 'online',
      width: 100
    },
    {
      title: t('accountPage.deviceSummaryTable.disconnected').toUpperCase(),
      dataIndex: 'offline',
      key: 'offline'
    }
    // TODO: comment alarm for EP1, maybe coming soon
    // {
    //   title: t('accountPage.deviceSummaryTable.alarms').toUpperCase(),
    //   dataIndex: 'alarm',
    //   key: 'alarm'
    // }
  ];

  return (
    <Section
      className={styles.container}
      title={t('accountPage.sections.devicesSummary.title')}
      allowCollapse
      expandedByDefault
      keyPanel="customer-device-summary"
    >
      <>
        <div className="fs-12 fw-light text-black p-4">
          <div className={styles.subTitle}>
            {t('accountPage.sections.devicesSummary.description')}
          </div>
        </div>
        <Table
          id="customerPage_deviceSummaryTable"
          tableLayout="fixed"
          dataSource={data}
          rowHoverable={false}
          loading={loading}
          rowKey={(record) => record.typeName}
          columns={columns}
          rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
          pagination={false}
          scroll={
            data.length > 0
              ? {
                  x: 'auto'
                }
              : {}
          }
        />
      </>
    </Section>
  );
});
