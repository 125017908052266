import { Form } from 'antd';
import styles from './adminCreatePage.module.scss';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { EStatusInvalid, Routes } from 'enums';
import { useAdminCreatePageController } from './AdminCreatePageController';
import { useLayoutEffect } from 'react';
import AdminGeneral from './components/AdminGeneral';
import PermissionSection from './components/PermissionSection';
import { FormAction } from 'presentation/components';

function AdminCreatePageView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    form,
    listLanguage,
    listPermission,
    listAdminType,
    currentAdminType,
    listPermissionsAdditional,
    isDomainAdminType,
    listDomains,
    setListPermissionsAdditional,
    handleSubmit
  } = useAdminCreatePageController();

  const { scrollToTop } = useOutletContext<{ scrollToTop: () => void }>();

  useLayoutEffect(() => scrollToTop(), []);

  function handleSelectAdditionalPermission(option: { label: string; value: number }) {
    setListPermissionsAdditional((prev) =>
      prev.map((permission) => {
        if (permission.id === option.value) {
          return {
            ...permission,
            id: option.value,
            displayName: option.label
          };
        }
        return permission;
      })
    );
  }

  function handleRemovePermission(id: number) {
    setListPermissionsAdditional((prev) => prev.filter((permission) => permission.id !== id));
  }

  return (
    <div className={styles.container}>
      <Form
        form={form}
        layout="horizontal"
        className="row gx-0 gx-md-5"
        colon={false}
        scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
        initialValues={{
          invalid: EStatusInvalid.VALID
        }}
        labelCol={{
          style: {
            width: 135
          }
        }}
        onFinish={handleSubmit}
      >
        <div className="col-12 col-slg-8">
          <AdminGeneral
            listLanguage={listLanguage}
            isDomainAdminType={isDomainAdminType}
            listDomains={listDomains}
            listAdminTypes={listAdminType}
          />
        </div>

        {currentAdminType && (
          <div className="col-12 col-slg-4">
            <PermissionSection
              currentAdminType={currentAdminType}
              isDomainAdminType={isDomainAdminType}
              listPermission={listPermission}
              listPermissionsAdditional={listPermissionsAdditional}
              onRemovePermission={handleRemovePermission}
              onSelectPermission={handleSelectAdditionalPermission}
            />
          </div>
        )}

        <FormAction
          onCancel={() => {
            navigate(
              {
                pathname: Routes.EAdminRoutes.LISTING,
                search: searchParams.toString()
              },
              { replace: true }
            );
          }}
          onSubmit={() => {
            form.submit();
          }}
        />
      </Form>
    </div>
  );
}

export default AdminCreatePageView;
