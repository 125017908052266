import { Form } from 'antd';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { Routes } from 'enums';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

/**
 * Controller for managing state and actions related to the Domain Page.
 * @returns {{
 *    form: any,
 *    searchParams: URLSearchParams,
 *    refetchCountRef: React.MutableRefObject<number>,
 *    handleSearch: (searchTextVal: string) => void,
 *    handleResetSearch: () => void
 * }}
 */
export const useDomainPageController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const searchText = searchParams.get('search');
  const { domainId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const refetchCountRef = useRef<number>(0);

  function handleSearch(searchTextVal: string) {
    if (searchTextVal) {
      searchParams.set('search', searchTextVal);
      searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());

      if (!domainId && searchTextVal === searchText) {
        refetchCountRef.current += 1;
      } else {
        refetchCountRef.current = 0;
      }

      navigate({ pathname: Routes.EDomainRoutes.LISTING, search: searchParams.toString() });
    } else {
      navigate({ pathname: Routes.EDomainRoutes.LISTING });
    }
  }

  function handleResetSearch() {
    searchParams.delete('search');
    searchParams.delete('sortKey');
    searchParams.delete('order');
    searchParams.delete('page');
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (form) {
      form.setFieldValue('searchText', searchText);
    }
  }, [searchText, form, location]);

  return {
    form,
    searchParams,
    refetchCountRef,
    handleSearch,
    handleResetSearch
  };
};
