import { Button, Checkbox, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Routes } from 'enums';
import { TEyeviewUser } from 'models';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserGeneral from '../../components/UserGeneral';
import styles from './cloneEyeviewUserPage.module.scss';
import { useCloneEyeviewUserPageController } from './CloneEyeviewUserPageController';
import { useAppUtil } from 'context/UtilContext';

export default function CloneEyeviewUserPageView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formInstance] = useForm<TEyeviewUser>();
  const [searchParams] = useSearchParams();
  const { userData, dataAccount, listValidation, cloneUser } = useCloneEyeviewUserPageController();
  const { openNotification } = useAppUtil();

  const { openModal } = useAppUtil();

  const goBackListingPage = () => {
    navigate(
      {
        pathname: Routes.EUserRoutes.LISTING,
        search: searchParams.toString()
      },
      { replace: true }
    );
  };

  const onFormSubmit = async (_values: TEyeviewUser) => {
    const { success, error } = await cloneUser(_values);
    if (success) {
      openNotification({
        type: 'success',
        title: `${t('actions.clone')} ${t('userPage.entity')}`,
        description: `${t('components.success')}`
      });
      goBackListingPage();
    } else {
      openNotification({
        type: 'error',
        title: `${t('actions.clone')} ${t('userPage.entity')}`,
        description: error ?? ''
      });
    }
  };

  const onClickCloneUser = (_values: TEyeviewUser) => {
    openModal({
      title: t('components.confirmationMessage'),
      content: t('userPage.confirmModal.addContent'),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      okButtonProps: { style: { padding: '0 30px', marginLeft: 10 } },
      cancelButtonProps: { style: { padding: '0 16px', color: 'var(--primary-2)' } },
      onOk: () => onFormSubmit(_values),
      width: 300
    });
  };

  const onClickCancel = () => goBackListingPage();

  useEffect(() => {
    formInstance.setFieldsValue(userData);
    formInstance.setFieldValue('username', '');
  }, [userData]);

  return (
    <div
      className={`${styles.container} h-100 animate__animated animate__fadeIn  overflow-y-auto custom-scrollbar`}
    >
      <Form
        name="user-clone-form"
        form={formInstance}
        autoComplete="off"
        layout="vertical"
        onFinish={onClickCloneUser}
      >
        <UserGeneral
          disableFields={['accountId']}
          accountList={dataAccount}
          validationList={listValidation}
        >
          <div className="my-1 d-block">
            <Form.Item name={'clonePermissions'} valuePropName="checked" className="mb-1">
              <Checkbox className={styles.checkboxContent}>
                {t('userPage.component.clonePermission')}
              </Checkbox>
            </Form.Item>
            <Form.Item name={'cloneCameraGroups'} valuePropName="checked" className="mb-1">
              <Checkbox className={styles.checkboxContent}>
                {t('userPage.component.cloneCameraGroup')}
              </Checkbox>
            </Form.Item>
          </div>
        </UserGeneral>

        <div className="d-flex justify-content-end">
          <Form.Item>
            <Button
              disabled={false}
              key={'cancel-btn'}
              className={'btn-outline me-3'}
              onClick={onClickCancel}
            >
              <span>{t('components.cancel')}</span>
            </Button>
          </Form.Item>
          <Form.Item>
            <Button key={'save-btn'} htmlType="submit" className={'btn-fill me-3'}>
              <span>{t('components.submit')}</span>
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
