export function LogoutIcon({ isMobile }: { isMobile?: boolean }) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.84 3.44471L16.665 5.49176C17.9867 6.24091 19.0802 7.29873 19.8398 8.56267C20.5993 9.82661 20.9989 11.2538 21 12.7059C21 14.9524 20.0518 17.107 18.364 18.6955C16.6761 20.284 14.387 21.1765 12 21.1765C9.61306 21.1765 7.32387 20.284 5.63604 18.6955C3.94822 17.107 3.00001 14.9524 3.00001 12.7059C3.00001 9.64235 4.74 6.97412 7.32 5.47765L5.16 3.44471C3.56676 4.47915 2.2646 5.86263 1.3657 7.47598C0.466796 9.08933 -0.0018926 10.8842 5.74374e-06 12.7059C5.74374e-06 15.7013 1.26429 18.574 3.51472 20.692C5.76516 22.8101 8.81741 24 12 24C15.1826 24 18.2348 22.8101 20.4853 20.692C22.7357 18.574 24 15.7013 24 12.7059C24 8.86588 21.96 5.47765 18.84 3.44471ZM13.5 0H10.5V14.1176H13.5"
        fill={isMobile ? 'black' : '#F9FAFD'}
      />
    </svg>
  );
}
