import Components from './common/Components/en';
import Forms from './common/Form/en';
import Errors from './common/Error/en';
import Header from './common/Header/en';

import Account from './pages/Account/en';
import AdminUser from './pages/AdminUser/en';
import Domain from './pages/Domain/en';
import EyeviewSession from './pages/EyeviewSession/en';
import Server from './pages/Server/en';
import System from './pages/System/en';
import EyeviewUser from './pages/EyeviewUser/en';
import Device from './pages/Device/en';
import CameraGroup from './pages/CameraGroup/en';

const en = {
  form: {
    username: {
      label: 'Username:',
      placeholder: 'Username',
      rules: {
        required: 'Username required',
        pattern: 'Username 6-18 characters long. It can only contain letters, number and underscore'
      }
    },
    accountId: {
      label: 'Account:'
    },
    accountName: {
      label: 'Name:',
      rules: {
        required: 'Customer name cannot be empty'
      }
    },
    accountType: {
      label: 'Account Type:',
      placeholder: 'Account type',
      rules: {
        required: 'Account type cannot be empty'
      }
    },
    contactEmail: {
      label: 'Email Address:',
      placeholder: 'Email Address',
      rules: {
        required: 'Email can not be empty',
        pattern: 'Invalid Email address'
      }
    },
    contactPhone: {
      label: 'Phone Number:',
      placeholder: 'Phone Number',
      rules: {
        required: 'Phone number can not be empty',
        pattern: 'Invalid Phone number'
      }
    },
    billingRange: {
      start: {
        label: 'Start:',
        rules: {
          mustBeBefore: 'Start date must be before End date'
        }
      },
      end: {
        label: 'End:',
        rules: {
          mustBeAfter: 'End date must be after Start date'
        }
      }
    },
    billingEnd: {
      label: 'End',
      placeholder: 'End'
    },
    billingDiscount: {
      label: 'Discount:',
      placeholder: 'Discount'
    },
    billingStateTax: {
      label: 'State Tax:',
      placeholder: 'State Tax'
    },
    billingLocalTax: {
      label: 'Local Tax:',
      placeholder: 'Local Tax'
    },
    billingAccountBalance: {
      label: 'Account Balance',
      placeholder: 'Account Balance'
    },
    password: {
      label: 'Password:',
      placeholder: 'Password',
      rules: {
        required: 'Password required',
        pattern: 'Password does not meet requirement'
      }
    },
    firstName: {
      label: 'First Name:',
      placeholder: 'First Name',
      rules: {
        required: 'First Name cannot be empty'
      }
    },
    lastName: {
      label: 'Last Name:',
      placeholder: 'Last Name',
      rules: {
        required: 'Last Name cannot be empty'
      }
    },
    emailAddress: {
      label: 'Email:',
      placeholder: 'Email',
      rules: {
        required: 'Email required',
        pattern: 'The input is not valid E-mail!'
      }
    },
    phone: {
      label: 'Phone Number:',
      placeholder: 'Phone',
      rules: {
        required: 'Phone cannot be empty',
        pattern: 'Invalid phone number'
      }
    },
    position: {
      label: 'Position:',
      placeholder: 'Position',
      rules: {
        required: 'Position cannot be empty'
      }
    },
    street: {
      label: 'Street:',
      placeholder: 'Street',
      rules: {
        required: 'Street cannot be empty'
      }
    },
    city: {
      label: 'City:',
      placeholder: 'City',
      rules: {
        required: 'City cannot be empty'
      }
    },
    state: {
      label: 'State:',
      placeholder: 'State',
      rules: {
        required: 'State cannot be empty'
      }
    },
    zip: {
      label: 'Zip:',
      placeholder: 'Zip Code',
      rules: {
        required: 'Zip Code cannot be empty',
        pattern: 'Not a valid zip code'
      }
    },
    valid: {
      label: 'Valid:',
      rules: {
        required: 'Valid cannot be empty'
      }
    },
    language: {
      label: 'Language',
      placeholder: 'Select your language',
      rules: {
        required: 'Language cannot be empty'
      }
    },
    currentPassword: {
      label: 'Current Password',
      placeholder: 'Enter your Current Password',
      rules: {
        required: 'Current Password cannot be empty'
      }
    },
    newPassword: {
      label: 'New Password',
      placeholder: 'Enter your New Password',
      rules: {
        required: 'New Password cannot be empty',
        pattern: 'Password does not meet requirement'
      }
    },
    confirmNewPassword: {
      label: 'Confirm Password:',
      placeholder: 'Confirm your New Password',
      rules: {
        required: 'Confirm Password cannot be empty',
        notMatching: 'Not matching password'
      }
    },
    account: {
      label: 'Account:',
      placeholder: 'Select',
      rules: {
        required: 'Please select account',
        fieldEmpty: 'Field cannot be empty'
      }
    },
    serialNumber: {
      label: 'Serial number',
      placeholder: 'Enter serial number',
      rules: {
        required: 'Enter Serial number'
      }
    },
    serverName: {
      label: 'Server Name',
      placeholder: 'Enter server name',
      rules: {
        required: 'Please input server name!'
      }
    },
    ipAddress: {
      label: 'IP Address',
      placeholder: 'Enter IP address',
      rules: {
        pattern: 'Invalid IP Address'
      }
    },
    internalIPAddress: {
      label: 'Internal IP Address',
      placeholder: 'Enter internal IP address',
      rules: {
        pattern: 'Invalid IP Address'
      }
    },
    apiURL: {
      label: 'API Url',
      placeholder: 'Enter API Url',
      rules: {
        pattern: 'Invalid URL'
      }
    },
    version: {
      label: 'Version',
      placeholder: 'Enter version',
      rules: {
        required: 'Please input version!',
        pattern: 'The version field must be a number'
      }
    },
    name: {
      label: 'Name',
      placeholder: 'Name',
      rules: {
        required: 'This field is required'
      }
    },
    dataCenter: {
      label: 'Data Center',
      placeholder: 'Select Data Center',
      rules: {
        required: 'Please select Data Center'
      }
    },
    status: {
      label: 'Valid',
      placeholder: 'Valid',
      rules: {
        required: 'This field is required'
      }
    },
    comment: {
      label: 'Comment:',
      placeholder: 'Comment'
    },
    domain: {
      label: 'Domain:',
      placeholder: 'Domain',
      rules: {
        required: 'Domain cannot be empty'
      }
    },
    url: {
      label: 'URL:',
      placeholder: 'URL',
      rules: {
        required: 'URL cannot be empty',
        validation: 'Not a valid URL'
      }
    },
    userType: {
      label: 'User type',
      placeholder: 'User type'
    },
    type: {
      label: 'Type',
      placeholder: 'Select server type',
      rules: {
        required: 'Please select server type!'
      }
    },
    description: {
      label: 'Description',
      placeholder: 'Description'
    },
    address: {
      label: 'Address',
      placeholder: 'Address'
    },
    isp: {
      label: 'ISP',
      placeholder: 'ISP'
    },
    subnet: {
      label: 'Subnet',
      placeholder: 'Subnet'
    },
    support: {
      label: 'Support',
      placeholder: 'Support'
    },
    portal: {
      label: 'Portal',
      placeholder: 'Portal'
    }
  },

  loginPage: {
    welcome: 'Welcome to Eyecast Internet Video Surveillance Service',
    title: 'Login',
    btnLogin: 'Login',
    tokenExpired: 'Your token has expired'
  },
  resetPasswordPage: {
    resetYourPassword: 'Reset your password',
    enterEmail: 'Enter your Email to reset',
    btnSubmit: 'Submit',
    rulePassword:
      'At least 12 characters.\nA mixture of both uppercase and lowercase letters.\nA mixture of letters and numbers.\nInclusion of at least one special character, e.g.,!@#?',
    forgotPassword: 'Forgot your password?',
    sendEmailSuccess: 'System will send an email to user mail address, please check it!',
    passwordHasBeenReset: 'Your password has been reset.',
    emailHasNotBeenRegistered: 'Email has not been registered yet.',
    requestIdExpired: 'Your request ID has expired. Please request reset password again.',
    resetLinkSent: 'The reset password link has been sent.',
    sentTo: 'The reset link has been sent to',
    backToLogin: 'Back to Login'
  },
  profilePage: {
    label: 'Profile',
    personalInfo: {
      label: 'Personal info',
      general: 'General',
      address: 'Address',
      language: 'Language',
      selectLanguageDesc: 'Select your frontend language',
      updateProfileSuccess: 'Update profile success'
    },
    password: {
      label: 'Password',
      changePassword: 'Change Password',
      changePasswordSuccess: 'Change password success',
      changePasswordDesc1: 'At least 12 characters.',
      changePasswordDesc2: 'A mixture of both uppercase and lowercase letters.',
      changePasswordDesc3: 'A mixture of letters and numbers.',
      changePasswordDesc4: 'Inclusion of at least one special character, e.g., ! @ # ? ]'
    }
  },

  tooltip: {
    refresh: 'Refresh'
  },
  welcome: {
    title: 'Welcome to EOSS!',
    description: 'Eyecast Operations Support Service'
  },
  ...Header,
  ...Components,
  ...Forms,
  ...Errors,
  ...AdminUser,
  ...Account,
  ...Domain,
  ...Server,
  ...EyeviewUser,
  ...System,
  ...EyeviewSession,
  ...CameraGroup,
  ...Device
};

export default en;
