import { useEffect, useMemo, useState } from 'react';
import { Section } from 'presentation/components';
import TableListVRSServer from './TableListVRSServer';
import TableListEyeviewUserSessions from './TableListEyeviewUserSessions';
import TableListEyeviewSessionByAccount from './TableListEyeviewSessionByAccount';
import { useTranslation } from 'react-i18next';
import { TServer } from 'models';
import { useEyeviewSessionPanelController } from './EyeviewSessionPanelController';
import { uniq } from 'lodash';
import LoggingSection from './LoggingSection';
import { TLoggingOption } from './eyeviewSessionPage.types';
import { TLogOptions } from 'models/EyeviewSession';

type Props = {
  server: TServer;
};

function EyeviewSessionPanel({ server }: Props) {
  const { t } = useTranslation();
  const {
    isAllowUpdateEyeviewSessionLogging,
    isAllowViewEyeviewSessionLogging,
    connections,
    isOnline,
    isLoadingGetConnections,
    totalAccounts,
    options,
    totalUsers,
    isLoadingListOptions,
    isUpdatingLogging,
    logoutUser,
    logoutAccount,
    updateLogOptions,
    onLoggingOptionsRefresh
  } = useEyeviewSessionPanelController({
    serverId: server.id
  });

  const updateListOptions = () => {
    if (options) {
      return Object.entries(options).map(([key, val]) => {
        return {
          option: key,
          value: val
        };
      });
    }
    return [];
  };

  const [listOptions, setListOptions] = useState<TLoggingOption[]>(updateListOptions);

  const canShowLoggingReloadButton = useMemo(() => {
    // Feedback 2024-02-09: Add a revert button to the logging options (reset the selections to what was loaded), if the selection is changed. No revert button if there is no change.
    if (options !== null && listOptions !== null) {
      // Check if any of the options is different from the current options
      // If so, show the reload button. Otherwise, hide it.
      const isDifferent = listOptions.some(
        (val) => options && options[val.option as keyof TLogOptions] !== val.value
      );
      return isDifferent;
    }
    return false;
  }, [options, listOptions]);

  useEffect(() => {
    setListOptions(updateListOptions());
  }, [options]);

  return (
    <div className="p-4 border rounded-bottom">
      <Section>
        <TableListVRSServer
          isLoading={isLoadingGetConnections}
          isOnline={isOnline}
          server={server}
          totalUsers={totalUsers}
          totalAccounts={totalAccounts}
        />
      </Section>
      {isOnline && (
        <Section title={t('eyeviewSessionPage.sections.eyeviewUser.title')}>
          <TableListEyeviewUserSessions
            isLoading={isLoadingGetConnections}
            listUsers={connections}
            onClickLogout={(userId) => logoutUser({ userId })}
          />
        </Section>
      )}
      {isOnline && (
        <Section title={t('eyeviewSessionPage.sections.account.title')}>
          <TableListEyeviewSessionByAccount
            isLoading={isLoadingGetConnections}
            listAccounts={uniq(connections.map((val) => val.subscriber)).map((val) => {
              return {
                accountName: val
              };
            })}
            onClickLogout={(accountName) => logoutAccount({ accountName })}
          />
        </Section>
      )}

      {isAllowViewEyeviewSessionLogging && isOnline && options && (
        <Section
          title={t('eyeviewSessionPage.sections.logging.title')}
          className="mb-0"
          onClickRefresh={
            canShowLoggingReloadButton
              ? () => {
                  onLoggingOptionsRefresh({ serverId: server.id });
                }
              : undefined
          }
        >
          <LoggingSection
            disable={!isAllowUpdateEyeviewSessionLogging}
            isLoading={isLoadingListOptions}
            listOptions={listOptions}
            onSubmit={(newListOptions) => updateLogOptions({ listOptions: newListOptions })}
            isUpdatingLogging={isUpdatingLogging}
            onLoggingOptionSelected={(currentListOptions) => setListOptions(currentListOptions)}
          />
        </Section>
      )}
    </div>
  );
}

export default EyeviewSessionPanel;
