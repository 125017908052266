export function UserBlankIcon() {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0654 61C64.6027 61 60.9385 64.6312 60.9385 69.0796C60.9385 73.528 64.6027 77.1592 69.0654 77.1592C73.5536 77.1592 77.2238 73.5279 77.2238 69.0796C77.2238 64.6312 73.5536 61 69.0654 61Z"
        fill="#888C90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0699 85.3106C72.9469 85.3106 76.823 85.8783 80.0295 86.9939C80.0297 86.9939 80.03 86.9939 80.0303 86.9939C82.5364 87.8641 84.6224 89.0593 86.0213 90.4728C87.4201 91.8862 88.1622 93.4539 88.1622 95.3974V98.5H50V95.3974C50 93.1699 50.9662 91.4146 52.7737 89.8723C54.5812 88.3301 57.2491 87.1047 60.3368 86.3262C63.0384 85.645 66.0544 85.3082 69.0699 85.3106Z"
        fill="#888C90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.4144 85.328C94.3817 85.3632 97.3321 85.7269 99.9665 86.4209C102.977 87.214 105.565 88.4358 107.315 89.9603C109.066 91.4847 110 93.2123 110 95.3981V98.5008H91.162V95.3981C91.162 92.642 89.9949 90.2234 88.1541 88.3633C87.1835 87.3826 86.0323 86.5409 84.75 85.8196C86.8945 85.4683 89.1578 85.3011 91.4144 85.328Z"
        fill="#888C90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.9347 61C86.4719 61 82.8086 64.6312 82.8086 69.0796C82.8086 73.5279 86.4719 77.1592 90.9347 77.1592C95.3973 77.1592 99.0607 73.528 99.0607 69.0796C99.0607 64.6312 95.3974 61 90.9347 61Z"
        fill="#888C90"
      />
    </svg>
  );
}
