import { useEffect, useRef } from 'react';
import { Form } from 'antd';
import { Routes } from 'enums';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import useResponsive from 'hooks/useResponsive';

export const useAdminPageController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const refetchCountRef = useRef<number>(0);
  const { adminUserId } = useParams();

  function handleSearch(searchTextVal: string | null) {
    if (searchTextVal) {
      searchParams.set('search', searchTextVal);
      searchParams.set('page', DEFAULT_PAGE_NUMBER.toString());

      if (!adminUserId && searchText === searchTextVal) {
        refetchCountRef.current += 1;
      } else {
        refetchCountRef.current = 0;
      }
    }

    navigate({ pathname: Routes.EAdminRoutes.LISTING, search: searchParams.toString() });
  }

  function handleResetSearch() {
    if (!isDesktop) return;
    searchParams.delete('search');
    searchParams.delete('page');
    searchParams.delete('sortKey');
    searchParams.delete('order');
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (form) {
      form.setFieldValue('searchText', searchText);
    }
  }, [searchText, form, location]);

  return { form, refetchCountRef, handleSearch, handleResetSearch };
};
