export default {
  header: {
    dashboard: 'Dashboard',
    domain: 'Domain',
    account: 'Customer',
    user: 'Eyeview User',
    devices: 'Device',
    cameraGroups: 'Device Group',
    aiAlerts: 'AI Alerts',
    servers: 'Server',
    system: 'System',
    admin: 'Administrator',
    eyeviewSession: 'Eyeview Session Mgmt',
    profile: 'Profile',
    logout: 'Logout'
  }
};
