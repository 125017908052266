import { useEOSSContext } from 'context/EOSSContext';
import type { TAccount, TCameraGroupAssign, TError, TEyeviewUser, TPermission } from 'models';
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useRouteLoaderData,
  useSearchParams
} from 'react-router-dom';
import { EyeviewUserRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { getError, handleApiError } from 'utils/common';
import { EHttpStatusCode, Routes } from 'enums';
import type { TPutEyeviewUserPayload } from 'repositories/EyeviewUser/EyeviewUserRepository';
import { useAppUtil } from 'context/UtilContext';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { ApiErrorResponse } from 'models/ApiError';
import { DEFAULT_PAGE_NUMBER } from 'constant';
import { useLoaderContext } from 'context/LoaderContext';
import { useEffectOnce } from 'hooks/useEffectOnce';

export const useEditEyeviewUserPageController = () => {
  const navigate = useNavigate();
  const eyeviewUserRepository = EyeviewUserRepository(AxiosClient);

  const { loader, isActive } = useLoaderContext();

  const { t } = useTranslation();
  const { openNotification } = useAppUtil();
  const currentUser = useRouteLoaderData(Routes.EUserRoutes.DETAIL) as TEyeviewUser;

  const {
    listCameraGroupAssignment,
    listCameraGroupRowSelected,
    onFetchAccountList,
    onListCameraGroupRowSelectedChange,
    onListCameraGroupChange
  } = useOutletContext<{
    listCameraGroupAssignment: {
      data: TCameraGroupAssign[];
      loading: boolean;
    };
    listCameraGroupRowSelected: React.Key[];
    onFetchAccountList: () => Promise<{ success: boolean; data: TAccount[] }>;
    onListCameraGroupRowSelectedChange: (value: React.Key[]) => void;
    onListCameraGroupChange: (value: { data: TCameraGroupAssign[]; loading: boolean }) => void;
  }>();

  const [permissionData, setPermissionData] = useState<{
    data: TPermission[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });

  const [selectedPermissions, setSelectedPermissions] = useState<TPermission[]>([]);

  const [accountList, setAccountList] = useState<{
    data: TAccount[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });
  const [currentEyeviewUser, setCurrentEyeviewUser] = useState({} as TEyeviewUser);

  const { userId } = useParams<{ userId: string }>();
  const { listValidation, listLanguage } = useEOSSContext();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const selectedPermissionIds = currentEyeviewUser?.permissionIds || [];
    const selectedPermissions = [];
    for (let i = 0; i < selectedPermissionIds.length; i++) {
      const selectedPermission = permissionData.data.find(
        (p) => p?.id === selectedPermissionIds[i]
      );
      if (selectedPermission) {
        selectedPermissions.push(selectedPermission);
      }
    }
    setSelectedPermissions(selectedPermissions);
  }, [currentEyeviewUser?.permissionIds, permissionData]);

  const addPermission = (value: number) => {
    setSelectedPermissions((prev) => {
      const newPermission = [...prev];
      newPermission.push(permissionData.data.find((p) => p?.id === value) as TPermission);
      return newPermission;
    });
  };

  const removePermission = (value: number) => {
    setSelectedPermissions((prev) => prev.filter((p) => p?.id !== value));
  };

  const handleUnassignedCameraGroupsFromEyeviewUser = async () => {
    if (!currentEyeviewUser.id) return EHttpStatusCode.NOT_FOUND;
    onListCameraGroupChange({
      data: listCameraGroupAssignment.data,
      loading: true
    });
    try {
      if (listCameraGroupAssignment.data.length === listCameraGroupRowSelected.length) {
        return EHttpStatusCode.NOT_MODIFIED;
      }
      const cameraGroupIds = listCameraGroupAssignment.data
        .filter((value: TCameraGroupAssign) => !listCameraGroupRowSelected.includes(value.id))
        .map((value: TCameraGroupAssign) => value.id.toString());
      if (!cameraGroupIds.length) return EHttpStatusCode.OK;
      const code = await eyeviewUserRepository.unassignCameraGroupsFromEyeviewUser({
        cameraGroupIds,
        eyeviewUserId: currentEyeviewUser.id
      });
      if (code === EHttpStatusCode.OK) {
        onListCameraGroupChange({
          data: listCameraGroupAssignment.data,
          loading: false
        });
      }
      return code;
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('userPage.entity'),
        t,
        identifier: `ID ${currentUser.id.toString()}`
      });
      openNotification({
        type: 'error',
        title: `${t('actions.unassign')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
      onListCameraGroupChange({
        data: listCameraGroupAssignment.data,
        loading: false
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleEditEyeviewUser = async (params: TPutEyeviewUserPayload) => {
    try {
      const oldData: TPutEyeviewUserPayload = {
        accountId: currentUser.account.id,
        address: JSON.stringify(currentUser.address),
        comment: currentUser.comment ?? '',
        contact: JSON.stringify(currentUser.contact),
        firstName: currentUser.firstName,
        invalid: currentUser.invalid,
        languageId: currentUser.language.id,
        lastName: currentUser.lastName,
        permissionIds: currentUser.permissionIds.toString()
      };

      if (!params.password) {
        delete params.password;
      }

      if (isEqual(oldData, params)) {
        return EHttpStatusCode.NOT_MODIFIED;
      }

      const code = await eyeviewUserRepository.editEyeviewUser(Number(userId), {
        ...params,
        password: params.password && params.password.length > 0 ? params.password : undefined
      });
      return code;
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'update',
        entity: t('userPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.update')} ${t('userPage.entity')}`,
        description: message
      });
      return EHttpStatusCode.BAD_REQUEST;
    }
  };

  const handleGetEyeviewPermission = async () => {
    if (permissionData.loading) return;
    try {
      const { code, data } = await eyeviewUserRepository.getListEyeviewPermission();
      if (code === EHttpStatusCode.OK) {
        setPermissionData({
          data,
          loading: false
        });
      }
    } catch (error) {
      const { message } = getError<TError>(error as string);
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('userPermissions.entity')}`,
        description: message
      });
    } finally {
      setPermissionData((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handleRefetchCameraGroupAssignment = async () => {
    if (!userId) return;
    onListCameraGroupChange({
      data: listCameraGroupAssignment.data,
      loading: true
    });
    try {
      const { code, data } = await eyeviewUserRepository.getEyeviewUserById(Number(userId));
      if (code === EHttpStatusCode.OK) {
        onListCameraGroupChange({
          data: data.groups,
          loading: false
        });
        onListCameraGroupRowSelectedChange(
          data.groups.map((group: TCameraGroupAssign) => group.id)
        );
      }
    } catch (error) {
      onListCameraGroupChange({
        data: listCameraGroupAssignment.data,
        loading: true
      });
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('userPage.entity'),
        t,
        identifier: `ID ${currentUser.id.toString()}`
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
    }
  };

  const handleFormSubmit = async (params: TPutEyeviewUserPayload) => {
    const allSettledResult = await Promise.allSettled([
      handleEditEyeviewUser(params),
      handleUnassignedCameraGroupsFromEyeviewUser()
    ]);
    const isSuccessfully = allSettledResult.some(
      (result) => result.status === 'fulfilled' && result.value === EHttpStatusCode.OK
    );

    const isUnchanged = allSettledResult.every(
      (result) => result.status === 'fulfilled' && result.value === EHttpStatusCode.NOT_MODIFIED
    );

    if (isSuccessfully || isUnchanged) {
      openNotification({
        type: 'success',
        title: `${t('actions.update')} ${t('userPage.entity')}`,
        description: `${t('components.success')}`
      });
    } else {
      handleRefetchCameraGroupAssignment();
    }

    if (isUnchanged || isSuccessfully) {
      if (!searchParams.get('page')) {
        searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
        setSearchParams(searchParams);
      }
      navigate({ pathname: Routes.EUserRoutes.LISTING, search: searchParams.toString() });
    }
  };

  const handleCancelClick = () => {
    if (!searchParams.get('page')) {
      searchParams.set('page', `${DEFAULT_PAGE_NUMBER}`);
      setSearchParams(searchParams);
    }
    navigate(
      {
        pathname: Routes.EUserRoutes.LISTING,
        search: searchParams.toString()
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (currentUser) {
      handleGetEyeviewPermission();
      setAccountList((prev) => ({
        ...prev,
        loading: true
      }));
      onFetchAccountList()
        .then(({ success, data }: { success: boolean; data: TAccount[] }) => {
          if (success) {
            setAccountList({
              data,
              loading: false
            });
          }
        })
        .finally(() => {
          setAccountList((prev) => ({
            ...prev,
            loading: false
          }));
        });
      setCurrentEyeviewUser(currentUser);
      onListCameraGroupChange({
        data: currentUser.groups,
        loading: false
      });
      onListCameraGroupRowSelectedChange(
        currentUser.groups.map((group: TCameraGroupAssign) => group.id)
      );
    }
  }, [currentUser]);

  useEffectOnce(() => {
    if (isActive) {
      loader.complete();
    }
  }, [isActive]);

  return {
    currentUser,
    currentEyeviewUser,
    selectedPermission: selectedPermissions,
    dataPermission: permissionData.data,
    accountList,
    listValidation,
    listLanguage,
    listCameraGroupAssignment,
    listCameraGroupRowSelected,
    addPermission,
    removePermission,
    onFormSubmit: handleFormSubmit,
    onCancel: handleCancelClick,
    onListCameraGroupRowSelectedChange
  };
};
