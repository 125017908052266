import { Table } from 'antd';
import { TCameraGroup, TPaginationEOSS } from 'models';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './tableCameraGroupListing.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { checkDeviceStatus } from 'utils/common';
import { EDeviceType } from 'enums';
import { TOOLTIP_DELAY } from 'constant';
import useResponsive from 'hooks/useResponsive';
import { SmartTooltip } from 'presentation/components';
import { useLoaderContext } from 'context/LoaderContext';

type Props = {
  listCameraGroup: {
    data: TCameraGroup[];
    isLoading: boolean;
    paging: TPaginationEOSS;
  };
};

const TableCameraGroupListing: FC<Props> = ({ listCameraGroup }) => {
  const { t } = useTranslation();

  const { loader } = useLoaderContext();

  const [clickedId, setClickedId] = useState<number>();

  const { isDesktop } = useResponsive();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    return () => {
      setClickedId(undefined);
    };
  }, []);

  const columns: ColumnsType<TCameraGroup> = useMemo(
    () => [
      {
        title: t('cameraGroupPage.cameraGroupListingTable.cameraGroupName'),
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => {
          return (
            <Link
              data-name="name"
              to={`${record.id}?${searchParams.toString()}`}
              style={{
                pointerEvents: record.id === clickedId ? 'none' : 'auto'
              }}
              onClick={() => {
                loader.start();
                setClickedId(record.id);
              }}
            >
              {value}
            </Link>
          );
        }
      },
      {
        title: t('cameraGroupPage.cameraGroupListingTable.camera.title'),
        dataIndex: 'cameras',
        key: 'cameras',
        render: (value: Array<{ lastContactAt: number }>) => {
          const numberOfOnline = value.reduce(
            (acc: number, { lastContactAt }: { lastContactAt: number }) => {
              const isOnline = checkDeviceStatus(lastContactAt);
              return isOnline ? acc + 1 : acc;
            },
            0
          );

          return (
            <Trans
              i18nKey="cameraGroupPage.cameraGroupListingTable.camera.render"
              values={{ numberOfOnline, length: value.length }}
            >
              <span data-name="cameras" style={{ color: 'var(--primary-1)' }} />
            </Trans>
          );
        }
      },
      {
        title: t('cameraGroupPage.cameraGroupListingTable.ups'),
        dataIndex: 'devices',
        key: 'devices',
        render: (value: Array<{ lastContactAt: number; typeName: string }>) => {
          const upsCount = value.filter(
            (value: { lastContactAt: number; typeName: string }) =>
              value.typeName === EDeviceType.UPS_MONITOR
          ).length;

          return <span data-name="devices">{upsCount > 0 ? upsCount : '-'}</span>;
        }
      },
      {
        title: t('cameraGroupPage.cameraGroupListingTable.accountName'),
        dataIndex: 'accountName',
        key: 'accountName',
        render: (value: string) => <span data-name="accountName">{value}</span>
      },
      {
        title: t('cameraGroupPage.cameraGroupListingTable.comment'),
        dataIndex: 'comment',
        render: (value) => {
          return (
            <SmartTooltip
              name="comment"
              delayTime={TOOLTIP_DELAY}
              TypographyComponentProps={{
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}
              TooltipComponentProps={{
                trigger: 'hover'
              }}
            >
              {value}
            </SmartTooltip>
          );
        },
        ellipsis: {
          showTitle: false
        }
      }
    ],
    [searchParams, clickedId]
  );

  return (
    <Table
      id="deviceGroupPage_listingTable"
      tableLayout="auto"
      dataSource={listCameraGroup.data}
      className={styles.container}
      loading={listCameraGroup.isLoading}
      rowKey={(record) => record.id}
      rowHoverable={false}
      columns={columns}
      rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      scroll={
        listCameraGroup.data.length > 0
          ? {
              x: 'auto',
              y: `calc(var(--app-height) - ${isDesktop ? '261px' : '251px'})` // 237px | 227px: not magic number (header 57px + breadcrumb 28px + padding 16px + header section 30px + header table 40px + pagination 44px + 30px (more space))
            }
          : {
              x: 'min-content'
            }
      }
      pagination={{
        pageSize: listCameraGroup.paging.pageLimit,
        current: listCameraGroup.paging.pageNum,
        total: listCameraGroup.paging.total
      }}
    />
  );
};

export default React.memo(TableCameraGroupListing);
