import type { TAxiosClient } from 'services/axios';
import {
  IAccountDataSource,
  TAssignDeviceToAccountPayload,
  TCreateAccountPayload,
  TEditAccountPayload
} from './AccountDataSource';
import type { TAccount, TDataResponseEOSS, TDeviceSummary } from 'models';
import { mapSnakeCaseToCamelCase } from 'utils/common';
import { EHttpStatusCode } from 'enums';

/**
 * Represents a remote data source implementation for account-related operations.
 * @implements {IAccountDataSource}
 * @author giang.bui@zien.vn
 */
export class AccountRemoteDataSourceImpl implements IAccountDataSource {
  httpService: TAxiosClient;

  private static instance: AccountRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  public static getInstance(httpService: TAxiosClient): AccountRemoteDataSourceImpl {
    if (!AccountRemoteDataSourceImpl.instance) {
      AccountRemoteDataSourceImpl.instance = new AccountRemoteDataSourceImpl(httpService);
    }
    return AccountRemoteDataSourceImpl.instance;
  }

  async getDeviceReferenceListByAccountId(
    id: number
  ): Promise<TDataResponseEOSS<Array<{ id: number; serialNumber: string }>>> {
    const mockData: Array<{ id: number; serialNumber: string }> = [
      { id: 1, serialNumber: '0110100101467' },
      { id: 2, serialNumber: '0110100103467' },
      { id: 3, serialNumber: '0110100102467' },
      { id: 4, serialNumber: '0110100141467' },
      { id: 5, serialNumber: '0110100151467' },
      { id: 6, serialNumber: '0110100101667' }
    ];

    return new Promise((resolve, _) => {
      setTimeout(() => {
        resolve({
          code: 200,
          data: mockData
        });
      }, 500);
    });
  }

  async assignAccount({
    account_id,
    device_serial
  }: TAssignDeviceToAccountPayload): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `/account/${account_id}/device/add`,
      {
        device_serial
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async getDevicesSummaryByAccountId(id: number): Promise<TDataResponseEOSS<TDeviceSummary[]>> {
    const data = await this.httpService.get<TDeviceSummary[]>(`/account/${id}/devices`);

    return {
      code: data.status,
      data: data.data.map((deviceSummary: TDeviceSummary) => mapSnakeCaseToCamelCase(deviceSummary))
    };
  }

  async getAccountById(id: number): Promise<TDataResponseEOSS<TAccount>> {
    const data = await this.httpService.get<TAccount>(`/account/${id}`);
    return {
      code: data.status,
      data: mapSnakeCaseToCamelCase(data.data)
    };
  }

  async editAccount(params: TEditAccountPayload): Promise<TDataResponseEOSS<TAccount>> {
    const requestParams = params as Partial<TEditAccountPayload>;
    const id = requestParams.id;
    delete requestParams.id;
    const data = await this.httpService.put<TAccount>(`/account/${id}`, requestParams, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return {
      code: data.status,
      data: data.data
    };
  }

  async createAccount(params: TCreateAccountPayload): Promise<TDataResponseEOSS<TAccount>> {
    const data = await this.httpService.post<TAccount>('/account', params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return {
      code: data.status,
      data: data.data
    };
  }

  async getListAccounts(search: string): Promise<TDataResponseEOSS<TAccount[]>> {
    const data = await this.httpService.get<TAccount[]>('/account', {
      params: { search }
    });

    return {
      code: data.status,
      data: data.data.map((acc: TAccount) => mapSnakeCaseToCamelCase(acc))
    };
  }
}
