import React, { FC } from 'react';
import styles from '../cameraGroupPage.module.scss';
import { Button, Form, FormInstance, Select } from 'antd';
import { TAssignEyeViewUser } from '../cameraGroupPage.types';
import { Trans, useTranslation } from 'react-i18next';
import type { TCameraGroup, TEyeviewUser } from 'models';
import { DropdownIcon } from 'assets/icons';

type Props = {
  form: FormInstance<TAssignEyeViewUser>;
  isSubmitting: boolean;
  currentCameraGroup: TCameraGroup;
  eyeviewUserList: {
    data: TEyeviewUser[];
    loading: boolean;
  };
  filterAssignedEyeviewUserList: TEyeviewUser[];
  onAssignEyeviewUser: (value: number) => void;
};

const AssignAction: FC<Props> = ({
  form,
  isSubmitting,
  currentCameraGroup,
  eyeviewUserList,
  filterAssignedEyeviewUserList,
  onAssignEyeviewUser
}) => {
  const { t } = useTranslation();

  return (
    <div id="deviceGroupPage_assignAction" className={`${styles.selectWrapper} d-flex flex-column`}>
      <Form
        form={form}
        onFinish={({ eyeviewUserId }: TAssignEyeViewUser) => {
          if (eyeviewUserId) {
            onAssignEyeviewUser(eyeviewUserId);
          }
        }}
      >
        <div className="fs-12 fw-normal text-black mt-2 mb-3">
          <span className={`${styles.description} mb-3`}>
            <Trans
              i18nKey="cameraGroupPage.sections.assign.description"
              values={{ groupName: currentCameraGroup?.name ?? '' }}
            >
              <span className="fw-medium" />
            </Trans>
          </span>
        </div>
        <Form.Item<TAssignEyeViewUser> name="eyeviewUserId" className="mb-3">
          <Select
            className="w-100 mb-3"
            suffixIcon={eyeviewUserList.loading ? undefined : <DropdownIcon />}
            options={filterAssignedEyeviewUserList.map((item: TEyeviewUser) => ({
              label: item.username,
              value: item.id
            }))}
            loading={eyeviewUserList.loading}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option?: { label: string; value: string | number }) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <div className="d-flex justify-content-end">
          <Button
            className={`${styles.actionBtn} btn-fill`}
            htmlType="submit"
            disabled={
              // remove redundant conditions: No need to check the length of the list anymore because the section will hide if the list is empty
              // eyeviewUserList.data.length <=
              //   (assignedEyeviewUserList ?? []).length ||
              isSubmitting
            }
            onClick={() => {
              const event = new CustomEvent('close-sidebar-action');
              document.dispatchEvent(event);
            }}
          >
            <span className="fs-14 fw-semibold">{t('components.assign')}</span>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(AssignAction);
