export default {
  adminUserPage: {
    breadcrumb: {
      listing: 'Administrator Management',
      create: 'Create Administrator'
    },
    sections: {
      search: {
        title: 'Search Administrator',
        inputDescription: 'Search administrator by login and domain name'
      },
      create: {
        title: 'Create Administrator'
      },
      listing: {
        title: 'Administrator List',
        emptyDescription: 'By searching administrator login'
      },
      general: {
        title: 'General'
      },
      permission: {
        title: 'Permission',
        description: 'This is a list of permissions for the',
        instruction:
          'In addition to the permissions listed above, new permissions for this administrator can be added by selecting this box. <0></0> To ensure necessary visibility, selecting either ‘create’ or ‘update’ permissions will automatically include ‘view’ permission as well.'
      }
    },
    entity: 'Administrator',
    table: {
      columns: {
        login: 'Login',
        firstName: 'first name',
        lastName: 'last name',
        domain: 'domain',
        adminType: 'administrator type',
        valid: 'valid'
      }
    },
    modal: {
      action: 'Are you sure to {{action}} this {{entity}}?'
    },
    '404': {
      title: 'Not Found',
      subTitle: 'Administrator User with this ID has not existed yet'
    }
  }
};
