import type {
  TAccount,
  TDataResponseEOSS,
  TDevice,
  TDeviceSummary,
  TDomain,
  TSortOrder
} from 'models';

import {
  IAccountRepository,
  TAssignDeviceToAccountPayload,
  TPostAccountsRequest,
  TPutAccountsRequest
} from './AccountRepository';
import { IAccountDataSource } from 'data/Account/AccountDataSource';
import { convertAxiosErrorToApiError, isJsonString } from 'utils/common';
import { IDomainDataSource } from 'data/Domain/DomainDataSource';
import orderBy from 'lodash/orderBy';
import { EDeviceType, EHttpStatusCode } from 'enums';
import { IDeviceDataSource } from 'data/Device/DeviceDataSource';

/**
 * Represents a repository implementation for managing accounts.
 * @class AccountRepositoryImpl
 * @author giang.bui@zien.vn
 */
export class AccountRepositoryImpl implements IAccountRepository {
  domainDataSource: IDomainDataSource;

  accountDataSource: IAccountDataSource;

  deviceDataSource: IDeviceDataSource;

  private static instance: AccountRepositoryImpl;

  constructor(
    domainDataSource: IDomainDataSource,
    accountDataSource: IAccountDataSource,
    deviceDataSource: IDeviceDataSource
  ) {
    this.domainDataSource = domainDataSource;
    this.accountDataSource = accountDataSource;
    this.deviceDataSource = deviceDataSource;
  }

  /**
   * Gets the singleton instance of AccountRepositoryImpl.
   * @param {IDomainDataSource} domainDataSource - The domain data source.
   * @param {IAccountDataSource} accountDataSource - The account data source.
   * @returns {AccountRepositoryImpl} The singleton instance of AccountRepositoryImpl.
   */
  public static getInstance(
    domainDataSource: IDomainDataSource,
    accountDataSource: IAccountDataSource,
    deviceDataSource: IDeviceDataSource
  ): AccountRepositoryImpl {
    if (!AccountRepositoryImpl.instance) {
      AccountRepositoryImpl.instance = new AccountRepositoryImpl(
        domainDataSource,
        accountDataSource,
        deviceDataSource
      );
    }
    return AccountRepositoryImpl.instance;
  }

  async getServiceDeviceList({
    deviceTypeName,
    accountName,
    page,
    sortKey,
    order
  }: {
    deviceTypeName: EDeviceType;
    accountName: string;
    page: number;
    sortKey?: string;
    order?: TSortOrder;
  }): Promise<TDataResponseEOSS<TDevice[]>> {
    try {
      const res = await this.deviceDataSource.getListDevices({
        deviceTypeName: deviceTypeName,
        page,
        search: accountName,
        sortKey,
        order
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async assignDeviceToAccount(params: TAssignDeviceToAccountPayload): Promise<EHttpStatusCode> {
    try {
      const res = await this.accountDataSource.assignAccount({
        account_id: params.accountId,
        device_serial: params.serviceDeviceSerial
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getDeviceReferenceListByAccountId(
    id: number
  ): Promise<TDataResponseEOSS<Array<{ id: number; serialNumber: string }>>> {
    try {
      const res = await this.accountDataSource.getDeviceReferenceListByAccountId(id);
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getDevicesSummaryByAccountId(id: number): Promise<TDataResponseEOSS<TDeviceSummary[]>> {
    try {
      const res = await this.accountDataSource.getDevicesSummaryByAccountId(id);
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getAccountById(id: number): Promise<TDataResponseEOSS<TAccount>> {
    try {
      const res = await this.accountDataSource.getAccountById(id);
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async createAccount(params: TPostAccountsRequest): Promise<TDataResponseEOSS<TAccount>> {
    try {
      const address = JSON.stringify(params.address);
      const contact = JSON.stringify(params.contact);
      const res = await this.accountDataSource.createAccount({
        name: params.name,
        address: isJsonString(address) ? address : '',
        comment: params.comment,
        account_type_id: params.accountTypeId.toString(),
        contact,
        domain_id: params.domainId.toString(),
        language: params.language,
        invalid: params.invalid,
        discount_rate: params.discountRate,
        state_tax: params.stateTax,
        local_tax: params.localTax,
        url: params.url,
        billing_start_at: params.billingStartAt ?? undefined,
        billing_end_at: params.billingEndAt ?? undefined
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async editAccount(params: TPutAccountsRequest): Promise<TDataResponseEOSS<TAccount>> {
    try {
      const address = JSON.stringify(params.address);
      const contact = JSON.stringify(params.contact);
      const res = await this.accountDataSource.editAccount({
        id: params.id,
        address: isJsonString(address) ? address : '',
        comment: params.comment,
        account_type_id: params.accountTypeId,
        contact,
        language: params.language,
        invalid: params.invalid,
        discount_rate: params.discountRate,
        state_tax: params.stateTax,
        local_tax: params.localTax,
        billing_start_at: params.billingStartAt ?? undefined,
        billing_end_at: params.billingEndAt ?? undefined,
        url: params.url,
        domain_id: params.domainId.toString()
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getAccountsFilter(searchStr: string): Promise<TDataResponseEOSS<TAccount[]>> {
    try {
      const res = await this.accountDataSource.getListAccounts(searchStr);
      return {
        ...res,
        data: orderBy(res.data, 'name', 'asc')
      };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getAllDomains(): Promise<TDataResponseEOSS<TDomain[]>> {
    try {
      const res = await this.domainDataSource.getListDomain();
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
