import { usePermissionContext } from 'context/PermissionContext';
import React from 'react';
import { Navigate } from 'react-router-dom';

function HomePageView() {
  const { permissionArray } = usePermissionContext();

  if (permissionArray.length > 0) {
    return <Navigate to={permissionArray[0]} />;
  }

  return (
    <div className="animate__animated animate__fadeIn h-100 d-flex align-items-center justify-content-center" />
  );
}

export default HomePageView;
