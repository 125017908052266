import { TDataResponseEOSS, TDevice, TSortOrder } from 'models';
import { IDeviceDataSource } from './DeviceDataSource';
import { TAxiosClient } from 'services/axios';
import { isJsonString, mapSnakeCaseToCamelCase, mapSortOrder } from 'utils/common';
import { EDeviceCommand, EHttpStatusCode } from 'enums';

/**
 * Represents a Device Data Transfer Object (DTO)
 *
 * @interface {Object} TDeviceDTO
 *
 * @property {string|null} location - The device's location (presumably a string representation).
 * @property {number} id - The device's unique numerical identifier.
 * @property {string|null} software_version - The software version currently running on the device.
 * @property {string} model - The model name or type of the device.
 * @property {Object[]} servers - An array of server objects the device is associated with.
 * @property {number} servers.id - Unique numerical ID of the server.
 * @property {string} servers.name - Human-readable name of the server.
 * @property {string} servers.public_ip - The public IP address of the server.
 * @property {string} servers.ip - The internal IP address of the server.
 * @property {string} serial_number - The device's serial number.
 * @property {Object} account - The account the device is associated with.
 * @property {string} account.name - The name of the account.
 * @property {number} account.id  - The numerical ID of the account.
 * @property {number} last_contact_at - A timestamp (in Unix format) indicating the last time the device made contact.
 * @property {number} usage_start_at -  A timestamp (in Unix format) indicating when device usage began.
 * @property {string} type_name - The type of the device.
 * @property {string} comment - A general comment about the device.
 * @property {string|null} data - Device-specific statistical data: JSON Blob data (BARC, SEEK, v.v) stats.
 * @property {Object[]} cameras - An array of camera objects attached to the device.
 * @property {number} cameras.id - Unique numerical ID of the camera.
 * @property {string} cameras.name - Human-readable name of the camera.
 * @property {Object} cameras.service - The service associated with the camera.
 * @property {number} cameras.service.id - Numerical ID of the service.
 * @property {string} cameras.service.name - Name of the service.
 * @property {string} cameras.lens - The type of lens on the camera.
 * @property {string} cameras.firmware - The firmware version of the camera.
 * @property {Object[]} cameras.groups - Camera groups this camera belongs to.
 * @property {number} cameras.groups.id - ID of the group.
 * @property {string} cameras.groups.name - Name of the group.
 * @property {string} cameras.type_name - The type of the camera.
 * @property {string} cameras.product_serial - The camera's product serial number.
 * @property {Object} cameras.thermog_service - The thermal analytic service associated with the camera.
 * @property {number} cameras.thermog_service.id - Numerical ID of the thermal analytic service.
 * @property {string} cameras.thermog_service.name - Name of the thermal analytic service.
 * @property {null} form_factor - The device's physical form factor.
 * @property {string|null} system_data - JSON Blob data: Device System Runtime Stats.
 * @property {null} bvr_connected - Indicates if a BVR is connected.
 * @property {string|null} soc - Potentially the System-on-a-Chip identifier.
 * @property {string|null} service_provider - The device's service provider.
 * @property {string|null} public_ip - The device's public IP address.
 * @property {string|null} mac - The device's MAC address.
 * @property {string|null} local_ip - The device's internal IP address.
 * @property {string|null} last_command_output - Output from the most recent command sent to the device.
 * @property {string|null} kernel - The device's operating system kernel.
 * @property {string|null} ram - Information about the device's RAM.
 */
type TDeviceDTO = {
  location: string | null;
  id: number;
  software_version: string | null;
  model: string;
  servers: {
    id: number;
    name: string;
    public_ip: string;
    ip: string;
  }[];
  serial_number: string;
  account: {
    name: string;
    id: number;
  };
  last_contact_at: number;
  usage_start_at: number;
  type_name: string;
  comment: string;
  data: string | null;
  cameras: {
    id: number;
    name: string;
    service: {
      id: number;
      name: string;
    };
    lens: string;
    firmware: string;
    groups: {
      id: number;
      name: string;
    }[];
    type_name: string;
    product_serial: string;
    thermog_service: {
      id: number;
      name: string;
    };
  }[];
  form_factor: null;
  system_data: string | null;
  bvr_connected: null;
  soc: string | null;
  service_provider: string | null;
  public_ip: string | null;
  mac: string | null;
  local_ip: string | null;
  last_command_output: string | null;
  kernel: string | null;
  ram: string | null;
};

/**
 * Represents a remote data source implementation for device-related operations.
 * @implements {IDeviceDataSource}
 * @author hung.nguyen@zien.vn
 */
export class DeviceRemoteDataSourceImpl implements IDeviceDataSource {
  httpService: TAxiosClient;

  private static instance: DeviceRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of DeviceRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {DeviceRemoteDataSourceImpl} The singleton instance of DeviceRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): DeviceRemoteDataSourceImpl {
    if (!DeviceRemoteDataSourceImpl.instance) {
      DeviceRemoteDataSourceImpl.instance = new DeviceRemoteDataSourceImpl(httpService);
    }
    return DeviceRemoteDataSourceImpl.instance;
  }

  async replaceDevice({
    serial,
    device_id,
    account_id
  }: {
    serial: string;
    device_id: number;
    account_id: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `device/${device_id}/replace`,
      {
        serial,
        account_id
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async rebootDevice({
    device_id,
    command_id
  }: {
    device_id: number;
    command_id: EDeviceCommand;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `/device/${device_id}/command`,
      {
        command_id
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async getListDevices({
    deviceTypeName,
    search,
    page,
    sortKey,
    order
  }: {
    deviceTypeName: string;
    search: string;
    page: number;
    sortKey?: string;
    order?: TSortOrder;
  }): Promise<TDataResponseEOSS<TDevice[]>> {
    const orderVal = order ? mapSortOrder(order) : null;
    const requestParams = new URLSearchParams({
      val: search,
      page: (page - 1).toString(),
      ...(sortKey && orderVal ? { sort: `${sortKey},${orderVal}` } : {})
    });

    orderVal && sortKey === 'status' && requestParams.set('sort', `lastContactTime,${orderVal}`);
    orderVal &&
      sortKey === 'lastContactAt' &&
      requestParams.set('sort', `lastContactTime,${orderVal}`);
    orderVal && sortKey === 'servers' && requestParams.set('sort', `bvrStr,${orderVal}`);
    orderVal && sortKey === 'accountName' && requestParams.set('sort', `account.name,${orderVal}`);

    const { data, status } = await this.httpService.get<{
      devices: TDeviceDTO[];
      current_page: number;
      page_size: number;
      total_elements: number;
      total_pages: number;
    }>(`/device/${deviceTypeName}/search`, { params: requestParams });

    return {
      data: data.devices.map((device) => mapSnakeCaseToCamelCase(device)),
      code: status,
      page: {
        pageNum: data.current_page + 1,
        pageLimit: data.page_size,
        total: data.total_elements,
        totalPage: data.total_pages
      }
    };
  }

  async getDeviceById({ deviceId }: { deviceId: number }): Promise<TDevice> {
    const { data } = await this.httpService.get<TDeviceDTO>(`/device/${deviceId}`);
    return {
      ...mapSnakeCaseToCamelCase(data),
      location: data.location ?? '', // convert null to string
      comment: data.comment ?? '', // convert null to string
      systemData:
        data.system_data && isJsonString(data.system_data)
          ? mapSnakeCaseToCamelCase(JSON.parse(data.system_data))
          : {},
      data:
        data.data && isJsonString(data.data) ? mapSnakeCaseToCamelCase(JSON.parse(data.data)) : {}
    };
  }

  async updateDeviceBasic({
    deviceId,
    comment,
    location,
    form
  }: {
    deviceId: number;
    location: string;
    comment: string;
    form: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `/device/${deviceId}`,
      {
        comment,
        location,
        form
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );

    return status;
  }

  async updateDeviceAccount({
    deviceId,
    accountId
  }: {
    deviceId: number;
    accountId: number;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `/device/${deviceId}/account`,
      {
        id: accountId
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );

    return status;
  }

  async updateDeviceServers({
    deviceId,
    serverIds
  }: {
    deviceId: number;
    serverIds: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `/device/${deviceId}/server`,
      {
        ids: serverIds
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }

  async unbindCameraGroups({
    deviceId,
    cameraGroupIds
  }: {
    deviceId: number;
    cameraGroupIds: string;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.post(
      '/camera/group/all/remove/device',
      {
        device_id: deviceId,
        group_ids: cameraGroupIds
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }
}
