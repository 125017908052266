import { useCameraGroupInformationPageController } from './CameraGroupInformationPageController';
import { FormAction, Section } from 'presentation/components';
import { Button, Form, Popover, Select } from 'antd';
import { MAXIMUM_64_CHARACTERS, MAXIMUM_8_CHARACTERS } from 'constant';
import { useEffect, useRef, useState } from 'react';
import styles from './cameraGroupInformationPageView.module.scss';
import { useTranslation } from 'react-i18next';
import { General } from '../../CameraGroupsPage/components';
import type { TCameraGroupGeneralFieldType } from 'presentation/pages/CameraGroupsPage/cameraGroupPage.types';
import { ECameraBindingSwitchOption, EDeviceType } from 'enums';
import { useAppUtil } from 'context/UtilContext';
import TableCameraBinding from '../components/TableCameraBinding';
import TableOtherDeviceBinding from '../components/TableOtherDeviceBinding';
import TableEyeviewUserAssignment from '../components/TableEyeviewUserAssignment';
import { ToggleOption } from 'presentation/components';
import { CiFilter } from 'react-icons/ci';
import { DropdownIcon } from 'assets/icons';
import type { TAccount, TCameraGroupType } from 'models';

function CameraGroupInformationPageView() {
  const {
    isAllPublicIPMatches,
    isShowRefreshButton,
    formEditCameraGroup,
    orderSelectValue,
    cameraGroupSelectedRow,
    astroDeviceSelectedRow,
    menuItems,
    upsMonitorSelectedRow,
    eyeviewUserSelectedRow,
    cameraBindingSwitchValue,
    listCameraBinding,
    deviceBindingSummary,
    astroDeviceList,
    upsMonitorList,
    userAssignmentList,
    cameraGroupTypes,
    deviceBindingExtraOptions,
    onCancel,
    onCameraBindingListChange,
    onCameraBindingRefresh,
    onAstroDeviceRefresh,
    onUpsMonitorRefresh,
    onCameraGroupSelectedRowChange,
    onAstroDeviceSelectedRowChange,
    onUpsMonitorSelectedRowChange,
    onEyeviewUserSelectedRowChange,
    onFormSubmit,
    onCameraBindingSwitchChange,
    onOrderTableCameraBindingChange
  } = useCameraGroupInformationPageController();

  const { t } = useTranslation();
  const { openModal } = useAppUtil();

  const generalRef = useRef<HTMLDivElement | null>(null);

  const [maxHeight, setMaxHeight] = useState<number>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleConfirmSubmit = (values: TCameraGroupGeneralFieldType) => {
    const name = values.name.trim();
    if (name.length < MAXIMUM_8_CHARACTERS || name.length > MAXIMUM_64_CHARACTERS) {
      formEditCameraGroup.setFields([
        {
          name: 'name',
          errors: [t('forms.cameraGroupName.pattern')]
        }
      ]);
    } else {
      setSubmitting(true);
      openModal({
        title: t('components.confirmationTitle'),
        content: t('components.confirmationMessage', {
          action: t('actions.update').toLowerCase(),
          entity: t('cameraGroupPage.entity').toLowerCase()
        }),
        okText: `${t('components.ok')}`,
        cancelText: `${t('components.cancel')}`,
        onOk: async () => {
          await onFormSubmit(values);
          setSubmitting(false);
        },
        onCancel: () => setSubmitting(false),
        wrapClassName: styles.confirmModal
      });
    }
  };

  useEffect(() => {
    if (generalRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect.height) {
            setMaxHeight(entry.contentRect.height);
          }
        }
      });

      observer.observe(generalRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className="row gx-4">
        <div className="col-12 col-md-8">
          <Section
            bodyRef={generalRef}
            title={t('cameraGroupPage.sections.general.title')}
            classNameBody="p-4"
            warningMessage={
              isAllPublicIPMatches ? '' : t('cameraGroupPage.sections.general.publicIPMismatch')
            }
          >
            <Form
              form={formEditCameraGroup}
              layout="horizontal"
              scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
              onFinish={(values) => handleConfirmSubmit(values)}
              labelCol={{
                style: {
                  width: 130
                }
              }}
              colon={false}
            >
              <General<TCameraGroupType, TAccount> editMode cameraGroupTypes={cameraGroupTypes} />
            </Form>
          </Section>
        </div>
        <div className="col-12 col-md-4">
          <Section
            keyPanel="eyeview-user-assignment-device-group-page"
            title={t('cameraGroupPage.sections.eyeviewUser.title')}
            allowCollapse
            expandedByDefault
          >
            <TableEyeviewUserAssignment
              maxHeight={maxHeight}
              userAssignmentList={userAssignmentList}
              eyeviewUserSelectedRow={eyeviewUserSelectedRow}
              onEyeviewUserSelectedRowChange={onEyeviewUserSelectedRowChange}
            />
          </Section>
        </div>
      </div>

      <Section
        title={
          <div className="d-flex align-items-center gap-2">
            <div>
              {(cameraBindingSwitchValue ?? '').toLowerCase() ===
              ECameraBindingSwitchOption.Device.toLowerCase()
                ? t('cameraGroupPage.sections.deviceBind.title')
                : t('cameraGroupPage.sections.cameraBind.title')}
            </div>
            <Popover
              trigger="click"
              content={
                <div className="p-3" onClick={(e) => e.stopPropagation()}>
                  <div className="fs-12">{t('cameraGroupPage.cameraBindingTable.orderBy')}</div>
                  <div
                    style={{
                      width: 150
                    }}
                  >
                    <Select
                      className="w-100"
                      optionFilterProp="children"
                      defaultValue={0}
                      value={orderSelectValue.orderKey}
                      options={menuItems}
                      suffixIcon={<DropdownIcon />}
                      onChange={(value: number) => {
                        onOrderTableCameraBindingChange(value);
                      }}
                    />
                  </div>
                </div>
              }
              placement="right"
            >
              <Button
                size="small"
                icon={<CiFilter color="var(--persian-blue)" />}
                shape="circle"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popover>
          </div>
        }
        onClickRefresh={isShowRefreshButton ? onCameraBindingRefresh : undefined}
        headerStyle={{ paddingTop: 0, paddingBottom: 0 }}
        extraControl={
          <ToggleOption
            value={cameraBindingSwitchValue}
            options={deviceBindingExtraOptions}
            onToggleChange={onCameraBindingSwitchChange}
          />
        }
        extraFooter={
          <div className="d-flex justify-content-end me-4 fs-12 fw-normal py-2">
            {t('cameraGroupPage.cameraBindingTable.extraFooter.label', {
              camera: `${deviceBindingSummary.totalCameras} ${t('camera.entity').toLowerCase()}${
                deviceBindingSummary.totalCameras > 1 ? 's' : ''
              }`,
              device: `${deviceBindingSummary.totalDevices} ${t(
                'devicePage.entity'
              ).toLowerCase()}${deviceBindingSummary.totalDevices > 1 ? 's' : ''}`
            })}
          </div>
        }
      >
        <TableCameraBinding
          listCameraBinding={listCameraBinding}
          cameraBindingSwitchValue={cameraBindingSwitchValue}
          cameraGroupSelectedRow={cameraGroupSelectedRow}
          onCameraBindingListChange={onCameraBindingListChange}
          onCameraGroupSelectedRowChange={onCameraGroupSelectedRowChange}
        />
      </Section>

      {(astroDeviceList.data.length > 0 || astroDeviceList.loading) && (
        <Section
          title={t('cameraGroupPage.sections.astroDevice.title')}
          onClickRefresh={onAstroDeviceRefresh}
        >
          <TableOtherDeviceBinding
            deviceType={EDeviceType.ASTRO_CAMERA}
            deviceList={astroDeviceList}
            deviceSelectedRow={astroDeviceSelectedRow}
            onDeviceSelectedRowChange={onAstroDeviceSelectedRowChange}
          />
        </Section>
      )}

      {(upsMonitorList.data.length > 0 || upsMonitorList.loading) && (
        <Section
          title={t('cameraGroupPage.sections.ups.title')}
          onClickRefresh={onUpsMonitorRefresh}
        >
          <TableOtherDeviceBinding
            deviceType={EDeviceType.UPS_MONITOR}
            deviceList={upsMonitorList}
            deviceSelectedRow={upsMonitorSelectedRow}
            onDeviceSelectedRowChange={onUpsMonitorSelectedRowChange}
          />
        </Section>
      )}

      <FormAction
        onCancel={onCancel}
        onSubmit={() => formEditCameraGroup.submit()}
        SubmitTypographyProps={{
          disabled: isSubmitting
        }}
      />
    </div>
  );
}

export default CameraGroupInformationPageView;
