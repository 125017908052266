type Props = {
  active?: boolean;
};

export function DomainIcon({ active = false }: Props) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_2233_10270"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2233_10270)">
          <path
            d="M3 22V15H6V11H11V9H8V2H16V9H13V11H18V15H21V22H13V15H16V13H8V15H11V22H3ZM10 7H14V4H10V7ZM5 20H9V17H5V20ZM15 20H19V17H15V20Z"
            className="active-header-icon"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_1_37983" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_37983)">
        <path
          d="M3 22V15H6V11H11V9H8V2H16V9H13V11H18V15H21V22H13V15H16V13H8V15H11V22H3ZM10 7H14V4H10V7ZM5 20H9V17H5V20ZM15 20H19V17H15V20Z"
          className="inactive-header-icon"
        />
      </g>
    </svg>
  );
}

export function DomainSmallIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1_19565" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
        <rect width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_19565)">
        <path
          d="M1.875 13.75V9.375H3.75V6.875H6.875V5.625H5V1.25H10V5.625H8.125V6.875H11.25V9.375H13.125V13.75H8.125V9.375H10V8.125H5V9.375H6.875V13.75H1.875ZM6.25 4.375H8.75V2.5H6.25V4.375ZM3.125 12.5H5.625V10.625H3.125V12.5ZM9.375 12.5H11.875V10.625H9.375V12.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
