type Props = {
  active?: boolean;
};

export function AccountIcon({ active = false }: Props) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_274_8827"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="black" />
        </mask>
        <g mask="url(#mask0_274_8827)">
          <path
            d="M15 13C15.55 13 16.021 12.804 16.413 12.412C16.8043 12.0207 17 11.55 17 11C17 10.45 16.8043 9.979 16.413 9.587C16.021 9.19567 15.55 9 15 9C14.45 9 13.9793 9.19567 13.588 9.587C13.196 9.979 13 10.45 13 11C13 11.55 13.196 12.0207 13.588 12.412C13.9793 12.804 14.45 13 15 13ZM11 17H19V16.45C19 15.7 18.6333 15.104 17.9 14.662C17.1667 14.2207 16.2 14 15 14C13.8 14 12.8333 14.2207 12.1 14.662C11.3667 15.104 11 15.7 11 16.45V17ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H10L12 6H20C20.55 6 21.021 6.196 21.413 6.588C21.8043 6.97933 22 7.45 22 8V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM4 6V18H20V8H11.175L9.175 6H4Z"
            className="active-header-icon"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_1_38043" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_38043)">
        <path
          d="M15 13C15.55 13 16.021 12.804 16.413 12.412C16.8043 12.0207 17 11.55 17 11C17 10.45 16.8043 9.979 16.413 9.587C16.021 9.19567 15.55 9 15 9C14.45 9 13.9793 9.19567 13.588 9.587C13.196 9.979 13 10.45 13 11C13 11.55 13.196 12.0207 13.588 12.412C13.9793 12.804 14.45 13 15 13ZM11 17H19V16.45C19 15.7 18.6333 15.104 17.9 14.662C17.1667 14.2207 16.2 14 15 14C13.8 14 12.8333 14.2207 12.1 14.662C11.3667 15.104 11 15.7 11 16.45V17ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H10L12 6H20C20.55 6 21.021 6.196 21.413 6.588C21.8043 6.97933 22 7.45 22 8V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM4 6V18H20V8H11.175L9.175 6H4Z"
          className="inactive-header-icon"
        />
      </g>
    </svg>
  );
}

export function AccountSmallIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_35_11081" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
        <rect width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_35_11081)">
        <path
          d="M1.25 13.125V1.875H7.5V4.375H13.75V13.125H1.25ZM2.5 11.875H6.25V10.625H2.5V11.875ZM2.5 9.375H6.25V8.125H2.5V9.375ZM2.5 6.875H6.25V5.625H2.5V6.875ZM2.5 4.375H6.25V3.125H2.5V4.375ZM7.5 11.875H12.5V5.625H7.5V11.875ZM8.75 8.125V6.875H11.25V8.125H8.75ZM8.75 10.625V9.375H11.25V10.625H8.75Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
