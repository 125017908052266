import { TDomain, TDataResponseEOSS } from 'models';
import { IDomainDataSource, TAddDomainRequest, TEditDomainRequest } from './DomainDataSource';
import { TAxiosClient } from 'services/axios';
import { mapSnakeCaseToCamelCase } from 'utils/common';
import orderBy from 'lodash/orderBy';

/**
 * Represents a remote data source implementation for domain-related operations.
 * @author thai.ngo@zien.vn
 */
export class DomainRemoteDataSourceImpl implements IDomainDataSource {
  httpService: TAxiosClient;

  /**
   * Private static instance of the DomainRemoteDataSourceImpl class.
   */
  private static instance: DomainRemoteDataSourceImpl;

  /**
   * Constructs a new DomainRemoteDataSourceImpl instance.
   * @param httpService - The Axios client used for HTTP requests.
   */
  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Returns a singleton instance of DomainRemoteDataSourceImpl.
   * @param httpService - The Axios client used for HTTP requests.
   * @returns The singleton instance of DomainRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): DomainRemoteDataSourceImpl {
    if (!DomainRemoteDataSourceImpl.instance) {
      DomainRemoteDataSourceImpl.instance = new DomainRemoteDataSourceImpl(httpService);
    }
    return DomainRemoteDataSourceImpl.instance;
  }

  /**
   * Retrieves domain data by ID.
   * @param domainId - The ID of the domain to retrieve.
   * @returns A promise that resolves to the domain data response.
   */
  async getDomainById(domainId: number): Promise<TDataResponseEOSS<TDomain>> {
    const { data, status } = await this.httpService.get<TDomain>(`/domain/${domainId}`);
    return {
      ...data,
      data: mapSnakeCaseToCamelCase(data),
      code: status
    };
  }

  /**
   * Edits a domain.
   * @param id - The ID of the domain to edit.
   * @param request - The request object containing the new domain information.
   * @returns A promise that resolves to the edited domain data response.
   */
  async editDomain(
    id: number,
    { name, comment, invalid }: TEditDomainRequest
  ): Promise<TDataResponseEOSS<TDomain>> {
    const { data, status } = await this.httpService.put<TDomain>(
      `/domain/${id}`,
      {
        name,
        comment,
        invalid: invalid
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return {
      ...data,
      data: mapSnakeCaseToCamelCase(data),
      code: status
    };
  }

  /**
   * Adds a new domain.
   * @param request - The request object containing the new domain information.
   * @returns A promise that resolves to the added domain data response.
   */
  async addDomain({
    name,
    comment,
    invalid
  }: TAddDomainRequest): Promise<TDataResponseEOSS<TDomain>> {
    const { data, status } = await this.httpService.post<TDomain>(
      '/domain',
      {
        name,
        comment,
        invalid: invalid
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );

    return {
      ...data,
      data: mapSnakeCaseToCamelCase(data),
      code: status
    };
  }

  /**
   * Retrieves a list of domains.
   * @returns A promise that resolves to the list of domains data response.
   */
  async getListDomain(): Promise<TDataResponseEOSS<TDomain[]>> {
    const { data, status } = await this.httpService.get<TDomain[]>('/domain/all');

    return {
      ...data,
      data: orderBy(data, 'name', 'asc').map((item) => mapSnakeCaseToCamelCase(item)),
      code: status
    };
  }
}
