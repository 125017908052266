import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode, ERoleKey, ESubFeatureKey, Routes } from 'enums';
import { usePathPermission } from 'hooks/usePermission';
import { uniq } from 'lodash';
import { TError } from 'models';
import { TEyeviewConnection, TLogOptions } from 'models/EyeviewSession';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeviewSessionRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { getError, handleApiError } from 'utils/common';
import { TLoggingOption } from './eyeviewSessionPage.types';
import { ApiErrorResponse } from 'models/ApiError';

export const useEyeviewSessionPanelController = ({ serverId }: { serverId: number }) => {
  const eyeviewSessionRepository = EyeviewSessionRepository(AxiosClient);
  const [connections, setConnections] = useState<TEyeviewConnection[]>([]);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [isLoadingGetConnections, setIsLoadingGetConnections] = useState(false);
  const [options, setOptions] = useState<TLogOptions | null>(null);
  const { t } = useTranslation();
  const { openNotification, openModal } = useAppUtil();
  const [isLoadingListOptions, setIsLoadingListOptions] = useState<boolean>(false);
  const [isUpdatingLogging, setIsUpdatingLogging] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAllowViewEyeviewSessionLogging = usePathPermission(
    Routes.EEyeviewSessionsRoutes.LISTING,
    ERoleKey.VIEW,
    ESubFeatureKey.OPTIONS
  ).allowed;

  const isAllowUpdateEyeviewSessionLogging = usePathPermission(
    Routes.EEyeviewSessionsRoutes.LISTING,
    ERoleKey.UPDATE,
    ESubFeatureKey.OPTIONS
  ).allowed;

  async function handleGetEyeviewConnections({
    serverId,
    delay = 0
  }: {
    serverId: number;
    delay?: number;
  }) {
    if (isLoadingGetConnections) return;
    setIsLoadingGetConnections(true);
    setTimeout(async () => {
      try {
        const res = await eyeviewSessionRepository.getEyeviewUsersConnected({ serverId });
        if (res.code === EHttpStatusCode.OK) {
          setIsOnline(true);
          setConnections(res.data.connections);
        }
      } catch (error) {
        setIsOnline(false);
      } finally {
        setIsLoadingGetConnections(false);
      }
    }, delay);
  }

  async function handleLogoutUser({ userId }: { userId: string }) {
    try {
      if (!serverId) return;
      const status = await eyeviewSessionRepository.logoutEyeviewUser({
        clientId: userId,
        serverId: serverId
      });
      if (status === EHttpStatusCode.OK) {
        await handleGetEyeviewConnections({ serverId, delay: 1500 });

        openNotification({
          type: 'success',
          title: `${t('header.logout')} ${t('userPage.entity')}`,
          description: t('eyeviewSessionPage.successMessage.logout', {
            entity: t('header.user').toLowerCase()
          })
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('userPage.entity'),
        actionStr: t('components.logout'),
        t
      });
      // TODO: Localize Here
      openNotification({ type: 'error', title: 'Logout', description: message });
    }
  }

  async function handleLogoutAccount({ accountName }: { accountName: string }) {
    try {
      if (!serverId) return;
      const status = await eyeviewSessionRepository.logoutAccount({
        accountName,
        serverId: serverId
      });
      if (status === EHttpStatusCode.OK) {
        await handleGetEyeviewConnections({ serverId, delay: 1500 });

        openNotification({
          type: 'success',
          title: `${t('header.logout')} ${t('accountPage.entity')}`,
          description: t('eyeviewSessionPage.successMessage.logout', {
            entity: t('header.account').toLowerCase()
          })
        });
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        actionStr: t('header.logout'),
        entity: t('accountPage.entity'),
        t
      });

      openNotification({
        type: 'error',
        title: t('header.logout'),
        description: message
      });
    }
  }

  async function handleGetLogOptions({ serverId }: { serverId: number }) {
    try {
      if (isLoadingListOptions) return;
      setIsLoadingListOptions(true);
      const res = await eyeviewSessionRepository.getLogOptions({ serverId });
      setOptions(res);
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'other',
        entity: t('eyeviewSessionPage.sections.logging.entity'),
        t
      });

      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eyeviewSessionPage.sections.logging.entity')}  `,
        description: message
      });
    } finally {
      setIsLoadingListOptions(false);
    }
  }

  async function handleUpdateLogsOptions({ listOptions }: { listOptions: TLoggingOption[] }) {
    if (isSubmitting) return;
    if (!isAllowUpdateEyeviewSessionLogging) return;
    if (!serverId || isUpdatingLogging) return;
    setIsSubmitting(true);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('eyeviewSessionPage.confirmMessage.logging'),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      onOk: async () => {
        try {
          setIsUpdatingLogging(true);
          const res = await eyeviewSessionRepository.updateLogOptions({
            serverId,
            options: listOptions.reduce((a, v) => ({ ...a, [v.option]: v.value }), {
              Archive: false,
              Vcr: false,
              Stop: false,
              User_Pref: false,
              Clock: false,
              Properties: false,
              Cleanup: false,
              Protobuf: false,
              Login_Logout: false,
              Init_Stop: false,
              Export: false,
              JP2_Codec: false,
              Servlet: false,
              Gui: false,
              Pool: false,
              Db: false,
              Debug: false,
              Live: false,
              Range: false,
              Thread: false,
              Play: false,
              Nats: false,
              Performance: false,
              Frame_Processing: false,
              Bvr: false
            })
          });

          if (res === EHttpStatusCode.OK) {
            await handleGetLogOptions({ serverId });

            openNotification({
              type: 'success',
              title: `${t('actions.update')} ${t('eyeviewSessionPage.sections.logging.entity')}`,
              description: t('eyeviewSessionPage.successMessage.logging')
            });
          }
        } catch (error) {
          const message = handleApiError({
            apiErrorResponse: error as ApiErrorResponse,
            action: 'update',
            entity: t('eyeviewSessionPage.sections.logging.entity'),
            t
          });

          openNotification({
            type: 'error',
            title: `${t('actions.update')} ${t('eyeviewSessionPage.sections.logging.entity')}`,
            description: message
          });
        } finally {
          setIsUpdatingLogging(false);
          setIsSubmitting(false);
        }
      },
      onCancel: () => {
        setIsSubmitting(false);
      }
    });
  }

  async function handleLoggingOptionsRefresh({ serverId }: { serverId: number }) {
    try {
      await handleGetLogOptions({ serverId });
    } catch (error) {
      const err = getError<TError>(error as string);
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eyeviewSessionPage.sections.logging.entity')}`,
        description: err.message
      });
    }
  }

  useEffect(() => {
    if (serverId) {
      handleGetEyeviewConnections({ serverId });
    }
  }, [serverId]);

  useEffect(() => {
    if (isAllowViewEyeviewSessionLogging && isOnline) {
      handleGetLogOptions({ serverId });
    }
  }, [isAllowViewEyeviewSessionLogging, serverId, isOnline]);

  return {
    isAllowUpdateEyeviewSessionLogging,
    isAllowViewEyeviewSessionLogging,
    isUpdatingLogging,
    isLoadingListOptions,
    connections,
    isOnline,
    isLoadingGetConnections,
    totalUsers: connections.length,
    options,
    totalAccounts: uniq(connections.map((val) => val.subscriber)).length,
    logoutUser: ({ userId }: { userId: string }) => {
      handleLogoutUser({ userId });
    },
    logoutAccount: ({ accountName }: { accountName: string }) =>
      handleLogoutAccount({ accountName }),
    updateLogOptions: ({ listOptions }: { listOptions: TLoggingOption[] }) =>
      handleUpdateLogsOptions({ listOptions }),
    onLoggingOptionsRefresh: handleLoggingOptionsRefresh
  };
};
