function WarningIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 15.75C10.7975 15.75 11.047 15.6492 11.2486 15.4476C11.4502 15.246 11.5507 14.9968 11.55 14.7C11.55 14.4025 11.4492 14.1533 11.2476 13.9524C11.046 13.7515 10.7968 13.6507 10.5 13.65C10.2025 13.65 9.9533 13.7508 9.7524 13.9524C9.5515 14.154 9.4507 14.4032 9.45 14.7C9.45 14.9975 9.5508 15.247 9.7524 15.4486C9.954 15.6502 10.2032 15.7507 10.5 15.75ZM9.45 11.55H11.55V5.25H9.45V11.55ZM10.5 21C9.0475 21 7.6825 20.7242 6.405 20.1726C5.1275 19.621 4.01625 18.8731 3.07125 17.9287C2.12625 16.9837 1.3783 15.8725 0.8274 14.595C0.2765 13.3175 0.0007 11.9525 0 10.5C0 9.0475 0.2758 7.6825 0.8274 6.405C1.379 5.1275 2.12695 4.01625 3.07125 3.07125C4.01625 2.12625 5.1275 1.3783 6.405 0.8274C7.6825 0.2765 9.0475 0.0007 10.5 0C11.9525 0 13.3175 0.2758 14.595 0.8274C15.8725 1.379 16.9837 2.12695 17.9287 3.07125C18.8737 4.01625 19.622 5.1275 20.1736 6.405C20.7252 7.6825 21.0007 9.0475 21 10.5C21 11.9525 20.7242 13.3175 20.1726 14.595C19.621 15.8725 18.8731 16.9837 17.9287 17.9287C16.9837 18.8737 15.8725 19.622 14.595 20.1736C13.3175 20.7252 11.9525 21.0007 10.5 21Z"
        fill="#F46363"
      />
    </svg>
  );
}

export { WarningIcon };
