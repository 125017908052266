import { useCallback, useEffect, useState } from 'react';

const THRESHOLD = 5;

export const useDragNScroll = () => {
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const [isActiveScrollLeftIcon, setIsActiveScrollLeftIcon] = useState<boolean>(false);
  const [isActiveScrollRightIcon, setIsActiveScrollRightIcon] = useState<boolean>(true);

  const ref = useCallback((nodeElement: HTMLDivElement) => {
    setNode(nodeElement);
  }, []);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!node) return;
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: e.clientX,
        y: e.clientY
      };

      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos.x;
        const dy = e.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [node]
  );

  const handleWindowResize = useCallback(() => {
    if (!node) return;
    setIsOverflow(node.scrollWidth > node.clientWidth);
  }, [node]);

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (!node) return;
      const touch = e.touches[0];
      const startPos = {
        left: node.scrollLeft,
        top: node.scrollTop,
        x: touch.clientX,
        y: touch.clientY
      };

      const handleTouchMove = (e: TouchEvent) => {
        const touch = e.touches[0];
        const dx = touch.clientX - startPos.x;
        const dy = touch.clientY - startPos.y;
        node.scrollTop = startPos.top - dy;
        node.scrollLeft = startPos.left - dx;
      };

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      };

      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    },
    [node]
  );

  const handleMouseWheel = useCallback(
    (e: WheelEvent) => {
      if (node) {
        node.scrollLeft += e.deltaY;
      }
    },
    [node]
  );

  const handleScroll = useCallback(
    (e: any) => {
      if (!node) return;
      setIsActiveScrollLeftIcon(e.target.scrollLeft > THRESHOLD);
      setIsActiveScrollRightIcon(
        e.target.scrollLeft + THRESHOLD < node.scrollWidth - node.clientWidth
      );
    },
    [node]
  );

  const scrollToBeginOffset = useCallback(() => {
    if (!node) return;
    node.scrollTo({
      left: 0,
      behavior: 'smooth'
    });
  }, [node]);

  const scrollToLastOffset = useCallback(() => {
    if (!node) return;
    node.scrollTo({
      left: node.scrollWidth,
      behavior: 'smooth'
    });
  }, [node]);

  useEffect(() => {
    if (!node) return;
    setIsOverflow(node.scrollWidth > node.clientWidth || node.scrollHeight > node.clientHeight);

    node.addEventListener('mousedown', handleMouseDown);
    node.addEventListener('touchstart', handleTouchStart);
    node.addEventListener('wheel', handleMouseWheel);
    node.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      node.removeEventListener('mousedown', handleMouseDown);
      node.removeEventListener('touchstart', handleTouchStart);
      node.removeEventListener('wheel', handleMouseWheel);
      node.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [node]);

  return {
    ref,
    scrollToBeginOffset,
    scrollToLastOffset,
    isOverflow,
    isActiveScrollLeftIcon,
    isActiveScrollRightIcon
  };
};
