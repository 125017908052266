import React from 'react';

export function DateIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8337 3.33268H14.167V2.49935C14.167 2.27834 14.0792 2.06637 13.9229 1.91009C13.7666 1.75381 13.5547 1.66602 13.3337 1.66602C13.1126 1.66602 12.9007 1.75381 12.7444 1.91009C12.5881 2.06637 12.5003 2.27834 12.5003 2.49935V3.33268H7.50033V2.49935C7.50033 2.27834 7.41253 2.06637 7.25625 1.91009C7.09997 1.75381 6.88801 1.66602 6.66699 1.66602C6.44598 1.66602 6.23402 1.75381 6.07774 1.91009C5.92146 2.06637 5.83366 2.27834 5.83366 2.49935V3.33268H4.16699C3.50395 3.33268 2.86807 3.59607 2.39923 4.06492C1.93038 4.53376 1.66699 5.16964 1.66699 5.83268V15.8327C1.66699 16.4957 1.93038 17.1316 2.39923 17.6004C2.86807 18.0693 3.50395 18.3327 4.16699 18.3327H15.8337C16.4967 18.3327 17.1326 18.0693 17.6014 17.6004C18.0703 17.1316 18.3337 16.4957 18.3337 15.8327V5.83268C18.3337 5.16964 18.0703 4.53376 17.6014 4.06492C17.1326 3.59607 16.4967 3.33268 15.8337 3.33268ZM16.667 15.8327C16.667 16.0537 16.5792 16.2657 16.4229 16.4219C16.2666 16.5782 16.0547 16.666 15.8337 16.666H4.16699C3.94598 16.666 3.73402 16.5782 3.57774 16.4219C3.42146 16.2657 3.33366 16.0537 3.33366 15.8327V9.99935H16.667V15.8327Z"
        fill="#4A61E3"
      />
    </svg>
  );
}
