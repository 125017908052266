import { useCallback, useMemo } from 'react';
import { debounce } from 'utils/common';

export default function useDebounceCallback<T extends (...args: any) => void>(
  fn: T,
  deps: any[],
  ms: number,
  noFirst = false,
  noLast = false
) {
  const fnMemo = useCallback(fn, deps);
  return useMemo(() => debounce(fnMemo, ms, !noFirst, !noLast), [fnMemo, ms, noFirst, noLast]);
}
