import { useTranslation } from 'react-i18next';
import { useServerListingPageController } from './ServerListingPageController';
import { Button, Form, Input, Spin } from 'antd';
import { EmptyPage, Section, Pagination, LeftColumn, RightColumn } from 'presentation/components';
import { Outlet, useParams } from 'react-router-dom';
import TableListServer from './components/TableListServer';
import { ServerPageEmptyIcon } from 'assets/icons/ServerPageEmptyIcon';
import TableListVRSServer from './components/TableListVRSServer';
import { mapToServerType } from 'utils/common';
import styles from './serverListingPage.module.scss';
import { useEffect, useRef } from 'react';
import useFlag from 'hooks/useFlag';

type FieldType = {
  searchText: string;
};

const ServerListingPageView = () => {
  const {
    currentServerType,
    listServers,
    sortKey,
    order,
    searchParams,
    searchText,
    form,
    setSearchParams,
    handleSearch,
    handleResetSearch
  } = useServerListingPageController();
  const { t } = useTranslation();

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);

  function handleOnChangeSort(order?: string | null, columnKey?: string) {
    if (columnKey && order) {
      searchParams.set('sortKey', columnKey?.toString());
      searchParams.set('order', order);
    } else {
      searchParams.delete('sortKey');
      searchParams.delete('order');
    }
    setSearchParams(searchParams);
  }
  const { serialNumber } = useParams<{ serialNumber: string }>();

  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  if (!currentServerType) {
    return <Spin />;
  }

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  return (
    <div className={`wrap-container overflow-hidden ${styles.container}`}>
      <div className="h-100 w-100 position-relative">
        <LeftColumn
          withCollapse
          leftColumnOpen={leftColumnOpen}
          onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
          onMouseMove={() => toolTipRef.current?.onShowTooltip()}
          onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
        >
          <Section
            title={t('serverPage.sections.search.title', {
              tabName: mapToServerType(currentServerType.name).valueOf()
            })}
            classNameBody="px-4 pt-3 pb-4"
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={{ searchText: '*' }}
              onFinish={(values) => {
                handleSearch(values.searchText);
              }}
              onFieldsChange={(changedFields) => {
                const searchTextField = changedFields.find(
                  (field) => field?.name?.includes('searchText')
                );
                if (searchTextField && !searchTextField.value) {
                  handleResetSearch();
                }
              }}
            >
              <div className="mb-0">
                <Form.Item<FieldType> name={'searchText'} className="mb-2">
                  <Input className={'search-input'} allowClear />
                </Form.Item>
              </div>

              <p className="text-black mb-2 fw-normal">
                {t('serverPage.sections.search.instruction')}
              </p>

              <Form.Item style={{ textAlign: 'right' }} className="mb-0">
                <Button
                  htmlType="submit"
                  className="btn-fill px-5 py-1 fw-semibold"
                  style={{ height: '29px' }}
                  onClick={() => {
                    const event = new CustomEvent('close-sidebar-action');
                    document.dispatchEvent(event);
                  }}
                >
                  {t('components.search')}
                </Button>
              </Form.Item>
            </Form>
          </Section>
        </LeftColumn>

        <RightColumn
          toolTipRef={toolTipRef}
          leftColumnOpen={leftColumnOpen}
          onSideBarClick={() => {
            leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
          }}
        >
          {serialNumber ? (
            <Outlet
              context={{
                currentServerType
              }}
            />
          ) : (
            <>
              <Section
                title={t('serverPage.sections.listing.title', {
                  tabName: mapToServerType(currentServerType.name).valueOf()
                })}
              >
                <EmptyPage
                  showEmpty={listServers.data.length <= 0 && !searchText}
                  title={t('components.getStarted')}
                  subTitle={t('serverPage.sections.listing.emptyDescription')}
                  emptyIcon={<ServerPageEmptyIcon />}
                >
                  {currentServerType.id !== 1 ? (
                    <TableListServer
                      isLoading={listServers.isLoading}
                      listServers={listServers.data}
                      onChangeSort={handleOnChangeSort}
                      sortKey={sortKey}
                      order={order}
                      searchText={searchText}
                      paging={listServers.paging}
                    />
                  ) : (
                    <TableListVRSServer
                      isLoading={listServers.isLoading}
                      listServers={listServers.data}
                      onChangeSort={handleOnChangeSort}
                      sortKey={sortKey}
                      order={order}
                      searchText={searchText}
                      paging={listServers.paging}
                    />
                  )}
                </EmptyPage>
              </Section>
              <Pagination
                total={listServers.paging.total}
                pageSize={listServers.paging.pageLimit}
                initPageNumber={listServers.paging.pageNum}
                onChange={(page) => {
                  searchParams.set('page', page.toString());
                  setSearchParams(searchParams);
                }}
              />
            </>
          )}
        </RightColumn>
      </div>
    </div>
  );
};

export default ServerListingPageView;
