import { Form } from 'antd';
import { DATE_BEGINNING_TIME, DATE_FORMAT, MIDNIGHT_TIME } from 'constant';
import { useTranslation } from 'react-i18next';
import type { TAccountFieldType } from '../AccountPageController';
import { Billing, Contacts, General } from '../components';
import useCreationAccountController from './CreationAccountController';
import { TPostAccountsRequest } from 'repositories/Account/AccountRepository';
import dayjs from 'dayjs';
import { useLayoutEffect, useState } from 'react';
import type { TContact } from 'models';
import { useOutletContext } from 'react-router-dom';
import { useAppUtil } from 'context/UtilContext';
import { FormAction } from 'presentation/components';

function CreateAccountPageView() {
  const { t } = useTranslation();

  const { form, billingForm, listValidation, listAccountType, domainList, onCreate, onCancel } =
    useCreationAccountController();

  const { scrollToTop } = useOutletContext<{ scrollToTop: () => void }>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { openModal } = useAppUtil();

  useLayoutEffect(() => scrollToTop(), []);

  const handleFormSubmit = async (value: TAccountFieldType) => {
    const billingField = billingForm?.getFieldsValue(['billing']);
    const contactData = value.contacts
      ? value.contacts.reduce((acc: TContact[], value: TContact) => {
          if (
            (value.name && value.name.length > 0) ||
            (value.phone && value.phone.length > 0) ||
            (value.email && value.email.length > 0)
          ) {
            acc.push({
              name: value.name?.trim() ?? '',
              email: value.email?.trim() ?? '',
              phone: value.phone?.trim() ?? ''
            });
          }
          return acc;
        }, [])
      : [];
    const data: TPostAccountsRequest = {
      name: value.name.trim(),
      address: {
        street: value.street?.trim() ?? '',
        city: value.city?.trim() ?? '',
        state: value.state?.trim() ?? '',
        zip: value.zip?.trim() ?? ''
      },
      comment: value.comment?.trim() ?? '',
      accountTypeId: value.accountTypeId,
      contact: contactData,
      domainId: value.domainId,
      language: '',
      invalid: value.invalidId,
      discountRate: billingField.billing?.discount?.trim() ?? '',
      stateTax: billingField.billing?.stateTax?.trim() ?? '',
      localTax: billingField.billing?.localTax?.trim() ?? '',
      billingStartAt: billingField.billing?.start
        ? dayjs(dayjs(billingField.billing.start).format(DATE_FORMAT) + DATE_BEGINNING_TIME).unix()
        : null,
      billingEndAt: billingField.billing?.stop
        ? dayjs(dayjs(billingField.billing.stop).format(DATE_FORMAT) + MIDNIGHT_TIME).unix()
        : null,
      url: value.url?.trim() ?? ''
    };
    await onCreate(data);
  };

  const handleConfirmSubmit = (value: TAccountFieldType) => {
    setIsSubmitting(true);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('components.confirmationMessage', {
        action: t('components.create').toLowerCase(),
        entity: t('header.account').toLowerCase()
      }),
      okText: `${t('components.ok')}`,
      cancelText: `${t('components.cancel')}`,

      onOk: async () => {
        await handleFormSubmit(value);
        setIsSubmitting(false);
      },
      onCancel: () => setIsSubmitting(false)
    });
  };

  return (
    <Form
      form={form}
      onFinish={(value: TAccountFieldType) => handleConfirmSubmit(value)}
      className="pe-2"
      layout="horizontal"
      labelCol={{
        style: {
          width: 110
        }
      }}
      colon={false}
      scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
    >
      <div className="row gx-0 gx-md-4">
        <div className="col-12 col-lg-7 col-slg-8 col-md-9">
          <General
            accountTypesData={listAccountType}
            domainsData={domainList.data}
            validationsData={listValidation}
          />
          <Contacts />
        </div>
        <div className="col-12 col-lg-5 col-slg-4 col-md-3">
          <Billing form={billingForm} />
        </div>
      </div>

      <FormAction
        onCancel={onCancel}
        SubmitTypographyProps={{
          htmlType: 'submit',
          disabled: isSubmitting
        }}
      />
    </Form>
  );
}

export default CreateAccountPageView;
