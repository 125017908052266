type Props = {
  active?: boolean;
};

export function DeviceIcon({ active = false }: Props) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_274_6448"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_274_6448)">
          <path
            d="M9 15V9H15V15H9ZM11 13H13V11H11V13ZM9 21V19H7C6.45 19 5.97933 18.8043 5.588 18.413C5.196 18.021 5 17.55 5 17V15H3V13H5V11H3V9H5V7C5 6.45 5.196 5.97933 5.588 5.588C5.97933 5.196 6.45 5 7 5H9V3H11V5H13V3H15V5H17C17.55 5 18.021 5.196 18.413 5.588C18.8043 5.97933 19 6.45 19 7V9H21V11H19V13H21V15H19V17C19 17.55 18.8043 18.021 18.413 18.413C18.021 18.8043 17.55 19 17 19H15V21H13V19H11V21H9ZM17 17V7H7V17H17Z"
            className="active-header-icon"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_1_38028" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_38028)">
        <path
          d="M9 15V9H15V15H9ZM11 13H13V11H11V13ZM9 21V19H7C6.45 19 5.97933 18.8043 5.588 18.413C5.196 18.021 5 17.55 5 17V15H3V13H5V11H3V9H5V7C5 6.45 5.196 5.97933 5.588 5.588C5.97933 5.196 6.45 5 7 5H9V3H11V5H13V3H15V5H17C17.55 5 18.021 5.196 18.413 5.588C18.8043 5.97933 19 6.45 19 7V9H21V11H19V13H21V15H19V17C19 17.55 18.8043 18.021 18.413 18.413C18.021 18.8043 17.55 19 17 19H15V21H13V19H11V21H9ZM17 17V7H7V17H17Z"
          className="inactive-header-icon"
        />
      </g>
    </svg>
  );
}
