import { Button, Form, Input, Select, Tabs, notification } from 'antd';
import { RuleObject } from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { passwordValidate } from 'utils/common';
import { useProfilePageController } from './ProfilePageController';
import styles from './profilePageView.module.scss';
import { useEOSSContext } from 'context/EOSSContext';

type ProfileFieldType = {
  firstName: string;
  lastName: string;
  emailAddress?: string;
  phone?: string;
  username: string;
  position?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  languageId?: string;
};

type PasswordFieldType = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const INPUT_HEIGHT = 46;

export default function ProfilePageView() {
  const { listLanguage } = useEOSSContext();
  const { profileData, onUpdateProfile, onChangePassword } = useProfilePageController();
  const { t } = useTranslation();

  const [personalInfoForm] = useForm();
  const [changePasswordForm] = useForm();

  const [notificationAPI, notificationHolderContext] = notification.useNotification();

  useEffect(() => {
    personalInfoForm.setFieldsValue(profileData);
  }, [profileData]);

  const onSavePersonalInfo = async (_values: ProfileFieldType) => {
    const { error, success } = await onUpdateProfile({
      first_name: _values.firstName,
      last_name: _values.lastName,
      email: _values.emailAddress,
      phone: _values.phone,
      city: _values.city,
      position: _values.position,
      state: _values.state,
      street: _values.street,
      zip: _values.zip,
      language_id: _values.languageId
    });

    if (success) {
      notificationAPI.success({ message: t('profilePage.personalInfo.updateProfileSuccess') });
    }

    if (error) {
      notificationAPI.error({ message: error });
    }
  };

  const onSaveChangePassword = async (_values: PasswordFieldType) => {
    const { error, success } = await onChangePassword({
      current_password: _values.currentPassword,
      new_password: _values.newPassword
    });

    if (error) {
      notificationAPI.error({ message: error });
    }

    if (success) {
      notificationAPI.success({ message: t('profilePage.password.changePasswordSuccess') });
    }
  };

  const onValidateNewPassword = (_: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(t('form.newPassword.rules.required'));
    }

    if (passwordValidate(value)) {
      return Promise.resolve();
    }

    return Promise.reject(t('form.newPassword.rules.pattern'));
  };

  const onValidateConfirmPassword = (_: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(t('form.confirmNewPassword.rules.required'));
    }

    if (changePasswordForm.getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }

    return Promise.reject(t('form.confirmNewPassword.rules.notMatching'));
  };

  return (
    <>
      {notificationHolderContext}
      <div className={`${styles.container} animate__animated animate__fadeIn`}>
        <Tabs
          size="small"
          defaultActiveKey="personal-tab"
          items={[
            {
              key: 'personal-tab',
              label: t('profilePage.personalInfo.label'),
              children: (
                <>
                  <Form
                    form={personalInfoForm}
                    name="profile-info-form"
                    autoComplete="off"
                    onFinish={onSavePersonalInfo}
                    layout="vertical"
                  >
                    <h2 className="fw-bolder">{t('profilePage.personalInfo.general')}</h2>
                    <div className="row g-2">
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.firstName.label')}
                          className="m-0"
                          name="firstName"
                          required
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.firstName.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.lastName.label')}
                          className="m-0"
                          name="lastName"
                          required
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.lastName.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.emailAddress.label')}
                          className="m-0"
                          name="emailAddress"
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.emailAddress.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.phone.label')}
                          className="m-0"
                          name="phone"
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.phone.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.username.label')}
                          className="m-0"
                          name="username"
                          required
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.username.placeholder')}
                            disabled
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.position.label')}
                          className="m-0"
                          name="position"
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.position.placeholder')}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <h2 className="fw-bolder">{t('profilePage.personalInfo.address')}</h2>
                    <div className="row g-2">
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.street.label')}
                          className="m-0"
                          name="street"
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.street.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.city.label')}
                          className="m-0"
                          name="city"
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.city.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.state.label')}
                          className="m-0"
                          name="state"
                        >
                          <Input
                            className={styles.input}
                            placeholder={t('form.state.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item<ProfileFieldType>
                          label={t('form.zip.label')}
                          className="m-0"
                          name="zip"
                        >
                          <Input className={styles.input} placeholder={t('form.zip.placeholder')} />
                        </Form.Item>
                      </div>
                    </div>
                    <h2 className="fw-bolder">{t('profilePage.personalInfo.language')}</h2>
                    <div className="row g-2">
                      <div className="col-12">
                        <Form.Item<ProfileFieldType> className="m-0" name="languageId">
                          <Select
                            style={{ height: INPUT_HEIGHT }}
                            placeholder={t('form.language.placeholder')}
                            options={listLanguage.map((e) => ({ value: e.id, label: e.name }))}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <p className="ms-1 mt-1">{t('profilePage.personalInfo.selectLanguageDesc')}</p>

                    <div className="d-flex mt-4 justify-content-end">
                      <Form.Item>
                        <Button key={'personal-tab-save-btn'} className="btn-fill px-3 py-1">
                          {t('components.save')}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              )
            },
            {
              key: 'password-tab',
              label: t('profilePage.password.label'),
              children: (
                <>
                  <Form
                    form={changePasswordForm}
                    name="change-password-form"
                    autoComplete="off"
                    onFinish={onSaveChangePassword}
                    layout="vertical"
                  >
                    <h2 className="fw-bolder">{t('profilePage.password.changePassword')}</h2>
                    <div className="row g-2">
                      <div className="col-12">
                        <Form.Item<PasswordFieldType>
                          label={t('form.currentPassword.label')}
                          rules={[{ required: true, message: t('form.password.rules.required') }]}
                          className="m-0"
                          name="currentPassword"
                        >
                          <Input.Password
                            className={styles.input}
                            placeholder={t('form.currentPassword.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item<PasswordFieldType>
                          label={t('form.newPassword.label')}
                          rules={[{ validator: onValidateNewPassword }]}
                          className="m-0"
                          name="newPassword"
                        >
                          <Input.Password
                            className={styles.input}
                            placeholder={t('form.newPassword.placeholder')}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item<PasswordFieldType>
                          label={t('form.confirmNewPassword.label')}
                          className="m-0"
                          name="confirmNewPassword"
                          rules={[{ validator: onValidateConfirmPassword }]}
                          dependencies={['newPassword']}
                        >
                          <Input.Password
                            className={styles.input}
                            placeholder={t('form.confirmNewPassword.placeholder')}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <p className="m-0 mt-2">{t('profilePage.password.changePasswordDesc1')}</p>
                    <p className="m-0">{t('profilePage.password.changePasswordDesc2')}</p>
                    <p className="m-0">{t('profilePage.password.changePasswordDesc3')}</p>
                    <p className="m-0">{t('profilePage.password.changePasswordDesc4')}</p>

                    <div className="d-flex mt-4 justify-content-end">
                      <Form.Item>
                        <Button key={'personal-tab-save-btn'} className="btn-fill px-3 py-1">
                          {t('components.save')}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </>
              )
            }
          ]}
        />
      </div>
    </>
  );
}
