import { Divider, Input, Select, Tag } from 'antd';
import { CheckIcon, DropdownFilterIcon } from 'assets/icons';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { IoSearchOutline } from 'react-icons/io5';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';

type TSelectProp = {
  label: string;
  value: number;
};

type Props<T> = {
  searchValue: string;
  selectedRegions: string[];
  className?: string;
  placeholder?: string;
  loading?: boolean;
  options?: T[];
  value: number | null;
  suffixIcon?: React.ReactNode;
  style?: React.CSSProperties;
  onSelectChange: (value: number | null) => void;
  onSearchValueChange: (value: string) => void;
  onSelectedRegionsChange: (value: string[]) => void;
};

const MIN_HEIGHT_DROPDOWN = 250;
const DROPDOWN_WIDTH_DESKTOP = 350;

const SearchSelectComponent = <T extends AnyObject>({
  className = '',
  placeholder = '',
  searchValue,
  selectedRegions,
  loading,
  suffixIcon,
  value,
  options = [],
  style = {},
  onSelectChange,
  onSearchValueChange,
  onSelectedRegionsChange
}: Props<T>) => {
  const { t } = useTranslation();

  const { isMobile } = useResponsive();

  const { ref: refSelectedRegions } = useHorizontalScroll();
  const { ref: refUnSelectedRegions } = useHorizontalScroll();

  const { data, regionList } = useMemo(() => {
    const filterRegionList = new Set<string>();
    const data = options.map((value: T) => {
      const regions = value.name.split(':');
      if (regions.length > 1) {
        filterRegionList.add(regions[0]);
      }
      return {
        label: value.name,
        value: value.id
      };
    });
    const regionList = Array.from(filterRegionList);
    return { data, regionList };
  }, [options]);

  const [dataSource, setDataSource] = useState<TSelectProp[]>(data);

  const handleCheckedRegion = useCallback(
    (value: string) => {
      onSelectedRegionsChange([value, ...selectedRegions]);
    },
    [onSelectedRegionsChange]
  );

  const handleUnCheckedRegion = useCallback(
    (value: string) => {
      onSelectedRegionsChange(selectedRegions.filter((v) => v !== value));
    },
    [onSelectedRegionsChange]
  );

  useEffect(() => {
    if (data && data.length > 0) {
      const regionSet = new Set(regionList);
      const selectedList = selectedRegions.filter((r) => regionSet.has(r));
      let firstItem = null;
      let dataTemp = [];
      if (searchValue.length > 0 || selectedList.length > 0) {
        dataTemp = (
          selectedList.length > 0
            ? data.filter((value) => selectedList.some((v) => value.label.startsWith(`${v}:`))) // make sure the region is at the beginning of the value
            : data
        ).filter((value) => value.label?.toLowerCase().includes(searchValue?.toLowerCase()));

        if (dataTemp.length > 0) {
          firstItem = dataTemp[0];
        }
      } else {
        if (data.length > 0) {
          firstItem = data[0];
        }
        dataTemp = data;
      }

      const filterOutSelectedItem = dataTemp.filter((v) => v.value !== value);

      setDataSource(filterOutSelectedItem);
      if (value === null && firstItem !== null) {
        onSelectChange(firstItem.value);
      }
    }
  }, [data, regionList, searchValue, selectedRegions, value]);

  return (
    <Select<number>
      className={className}
      optionFilterProp="children"
      placeholder={placeholder}
      defaultActiveFirstOption
      options={dataSource}
      notFoundContent={<div className="d-flex justify-content-center">No search found</div>}
      value={value}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          onSearchValueChange('');
        }
      }}
      dropdownStyle={{
        ...(isMobile ? {} : { width: DROPDOWN_WIDTH_DESKTOP })
      }}
      dropdownRender={(menu) => (
        <>
          <div className="my-2">
            <Input
              value={searchValue}
              placeholder={placeholder}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onSearchValueChange(event.target.value);
              }}
              allowClear
              suffix={searchValue.length > 0 ? undefined : <IoSearchOutline />}
            />
          </div>
          {regionList.length > 0 && (
            <>
              <span>
                <DropdownFilterIcon />{' '}
                <span className="fs-10 fw-normal">{t('components.filterRegion')}</span>
              </span>
              <div
                ref={refSelectedRegions}
                className="d-flex custom-small-scrollbar overflow-auto my-2 pb-1"
              >
                {regionList
                  .filter((value) => !selectedRegions.includes(value))
                  .map((value: string, index: number) => (
                    <Tag
                      key={index}
                      className="cursor-pointer"
                      onClick={() => {
                        handleCheckedRegion(value);
                      }}
                    >
                      {value}
                    </Tag>
                  ))}
              </div>

              <div
                ref={refUnSelectedRegions}
                className="d-flex custom-small-scrollbar overflow-auto my-2 pb-1"
              >
                {selectedRegions && selectedRegions.length > 0 ? (
                  selectedRegions
                    .filter((v) => regionList.includes(v))
                    .map((value: string, index: number) => (
                      <Tag
                        key={index}
                        className="cursor-pointer"
                        color="blue"
                        onClick={() => {
                          handleUnCheckedRegion(value);
                        }}
                      >
                        {value}
                      </Tag>
                    ))
                ) : (
                  <span className="fs-10 fw-normal">{t('components.noRegionSelected')}</span>
                )}
              </div>
            </>
          )}
          <div className="selected-item">
            {data.find((v) => v.value === value)?.label ?? 'No selected item'}
            {data.find((v) => v.value === value)?.label && <CheckIcon />}
          </div>
          <Divider className="my-2" />
          <div
            style={{
              minHeight: MIN_HEIGHT_DROPDOWN
            }}
          >
            {menu}
          </div>
        </>
      )}
      style={style}
      loading={loading}
      suffixIcon={suffixIcon}
      onChange={(value: number | null) => {
        onSelectChange && onSelectChange(value);
      }}
    />
  );
};

const SearchSelect = React.memo(SearchSelectComponent) as typeof SearchSelectComponent;

export { SearchSelect };
