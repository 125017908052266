import { TLanguage } from 'models';
import { ILanguageDataSource } from './LanguageDataSource';
import { TAxiosClient } from 'services/axios';

/**
 * Represents a remote data source implementation for handling language data.
 * @author hung.nguyen@zien.vn
 */
export class LanguageRemoteDataSourceImpl implements ILanguageDataSource {
  httpService: TAxiosClient;

  private static instance: LanguageRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of LanguageRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {LanguageRemoteDataSourceImpl} The singleton instance of LanguageRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): LanguageRemoteDataSourceImpl {
    if (!LanguageRemoteDataSourceImpl.instance) {
      LanguageRemoteDataSourceImpl.instance = new LanguageRemoteDataSourceImpl(httpService);
    }
    return LanguageRemoteDataSourceImpl.instance;
  }

  async getListLanguages(): Promise<TLanguage[]> {
    const { data } = await this.httpService.get<TLanguage[]>('/languages/all');
    return data;
  }
}
