import { Collapse, Tooltip } from 'antd';
import { ExpandIcon } from 'assets/icons';
import { TOOLTIP_DELAY } from 'constant';
import useResponsive from 'hooks/useResponsive';
import { orderBy } from 'lodash';
import { TPermission } from 'models';

type Props = {
  permissionName: string;
  listPermissions: TPermission[];
};

function PermissionGroup({ permissionName, listPermissions }: Props) {
  const { isMobile } = useResponsive();

  return (
    <div id="adminPage_permissionGroupsComponent" className="mb-6">
      <Collapse
        expandIconPosition="end"
        bordered={false}
        className="border rounded"
        style={{ background: 'var(--primary-4)' }}
        expandIcon={(panelProps) => {
          return <ExpandIcon expanded={panelProps.isActive || false} />;
        }}
        items={[
          {
            key: 1,
            label: permissionName,
            children: (
              <div className="d-flex gap-2 flex-wrap p-4">
                {orderBy(listPermissions, 'name', 'asc').map((permission) => {
                  return (
                    <div
                      key={permission.id}
                      className="px-4 py-2 rounded-5"
                      style={{ backgroundColor: 'var(--primary-5)' }}
                    >
                      <Tooltip
                        trigger={isMobile ? ['click'] : ['hover']}
                        destroyTooltipOnHide
                        title={permission.description}
                        mouseEnterDelay={TOOLTIP_DELAY}
                        arrow={false}
                        placement="bottom"
                      >
                        {permission.displayName}
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            )
          }
        ]}
      />
    </div>
  );
}

export default PermissionGroup;
