import { TAxiosClient } from 'services/axios';
import { ICameraDataSource } from './CameraDataSource';
import { EHttpStatusCode } from 'enums';

export class CameraRemoteDataSourceImpl implements ICameraDataSource {
  httpService: TAxiosClient;

  private static instance: CameraRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  public static getInstance(httpService: TAxiosClient): CameraRemoteDataSourceImpl {
    if (!CameraRemoteDataSourceImpl.instance) {
      CameraRemoteDataSourceImpl.instance = new CameraRemoteDataSourceImpl(httpService);
    }
    return CameraRemoteDataSourceImpl.instance;
  }

  async updateCamera({
    id,
    lens,
    name,
    service_id,
    thermog_service_id
  }: {
    id: number;
    lens: string;
    name: string;
    service_id: number | null;
    thermog_service_id: number | null;
  }): Promise<EHttpStatusCode> {
    const { status } = await this.httpService.put(
      `/camera/${id}`,
      {
        lens,
        name,
        service_id,
        thermog_service_id
      },
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
    return status;
  }
}
