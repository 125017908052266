import { DomainEmptyIcon } from 'assets/icons/DomainEmptyIcon';
import { EmptyPage, Pagination, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { useDomainListingPageController } from './DomainListingPageController';
import TableListDomain from './components/TableListDomain';

function DomainListingPageView() {
  const { searchParams, searchText, setSearchParams, listDomains, order, sortKey } =
    useDomainListingPageController();
  const { t } = useTranslation();

  function handleOnChangeSort(order?: string | null, columnKey?: string) {
    if (columnKey && order) {
      searchParams.set('sortKey', columnKey?.toString());
      searchParams.set('order', order);
    } else {
      searchParams.delete('sortKey');
      searchParams.delete('order');
    }
    setSearchParams(searchParams);
  }

  return (
    <>
      <Section title={t('domainPage.sections.listing.title')}>
        <EmptyPage
          showEmpty={!searchText}
          title={t('components.getStarted')}
          subTitle={t('domainPage.sections.listing.emptyDescription')}
          emptyIcon={<DomainEmptyIcon />}
        >
          <TableListDomain
            isLoading={listDomains.isLoading}
            listDomains={listDomains.data}
            onChangeSort={handleOnChangeSort}
            sortKey={sortKey}
            order={order}
            searchText={searchText}
            paging={listDomains.paging}
          />
        </EmptyPage>
      </Section>
      <Pagination
        total={listDomains.paging.total}
        pageSize={listDomains.paging.pageLimit}
        initPageNumber={listDomains.paging.pageNum}
        onChange={(page) => {
          searchParams.set('page', page.toString());
          setSearchParams(searchParams);
        }}
      />
    </>
  );
}

export default DomainListingPageView;
