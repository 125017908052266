const translation = {
  eyeviewSessionPage: {
    breadcrumb: {
      listing: 'Eyeview Management'
    },
    sections: {
      account: {
        title: 'Customer'
      },
      eyeviewUser: {
        title: 'Eyeview User'
      },
      logging: {
        title: 'Logging',
        entity: 'Logging Options'
      }
    },
    table: {
      columns: {
        name: 'name',
        guid: 'guid',
        account: 'customer',
        logout: 'logout',
        option: 'option',
        enable: 'enable'
      }
    },
    confirmMessage: {
      logging: 'Are you sure to update the logging options you have selected?'
    },
    successMessage: {
      logging: 'Updated the logging options successfully!',
      logout: 'The {{entity}} is logged out successfully!'
    },
    actions: {
      collapseAll: 'Collapse All',
      expandAll: 'Expand All'
    }
  }
};

export default translation;
