import { Button } from 'antd';
import { AddIconNoOutline } from 'assets/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onCreateCameraGroupClick: () => void;
};

const CreateAction: FC<Props> = ({ onCreateCameraGroupClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      id="deviceGroupPage_createAction"
      className="btn-create w-100 d-flex justify-content-center align-items-center cursor-pointer"
      onClick={() => {
        onCreateCameraGroupClick();
        const event = new CustomEvent('close-sidebar-action');
        document.dispatchEvent(event);
      }}
    >
      <div className="h-100 d-flex align-items-center">
        <AddIconNoOutline color="var(--primary-1)" />
      </div>
      <div className="btn-create-content d-flex align-items-center fs-14 fw-semibold ms-2 h-100">
        {t('components.create')}
      </div>
    </Button>
  );
};

export default React.memo(CreateAction);
