import { useAuthContext } from 'context/AuthContext';
import { useEOSSContext } from 'context/EOSSContext';
import { useAppUtil } from 'context/UtilContext';
import { type TObjectPermission, checkPermission, usePermissionContext } from 'context/PermissionContext';
import { EHttpStatusCode, ERoleKey, Routes } from 'enums';
import { useTranslation } from 'react-i18next';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import type { TAccount, TAdminUser, TDeviceType, TDomain, TServer, TServerType, TEyeviewUser, TDevice, TCameraGroup } from 'models';
import { AuthorizedLayout, Layout } from 'presentation/layouts/Layout';
import {
  AccountRepository,
  AdministratorRepository,
  DeviceRepository,
  DomainRepository,
  EyeviewUserRepository,
  ServerRepository,
  CameraGroupRepository
} from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError, mapToServerType } from 'utils/common';

//#region Import Screen
import NoPermissionPageView from 'presentation/pages/404/NoPermissionPageView';
import NotFoundPage from 'presentation/pages/404/NotFoundPageView';
import HomePage from 'presentation/pages/HomePage/HomePageView';
import LoginPage from 'presentation/pages/LoginPage/LoginPageView';
import ResetPasswordPage from 'presentation/pages/ResetPasswordPage/ResetPasswordPage';

// ------------ Admin
import AdminDetailPage from 'presentation/pages/AdminPage/subPages/AdminDetailPageView';
import AdminPage from 'presentation/pages/AdminPage/AdminPageView';
import AdminCreatePage from 'presentation/pages/AdminPage/subPages/AdminCreatePageView';
import AdminListingPage from 'presentation/pages/AdminPage/subPages/AdminListingPageView';

// ------------ Domain
import DomainPage from 'presentation/pages/DomainPage/DomainPageView';
import CreateDomainPageView from 'presentation/pages/DomainPage/subPages/CreateDomainPageView';
import DomainListingPageView from 'presentation/pages/DomainPage/subPages/DomainListingPageView';
import EditDomainPageView from 'presentation/pages/DomainPage/subPages/EditDomainPageView';

// ------------ Account
import EditAccountPage from 'presentation/pages/AccountDetailPage/AccountDetailPageView';
import AccountPage from 'presentation/pages/AccountPage/AccountPageView';
import CreateAccountPage from 'presentation/pages/AccountPage/subPage/CreateAccountPageView';
import ListAccountPage from 'presentation/pages/AccountPage/subPage/ListAccountPageView';

// ------------ Device
import DeviceDetailPage from 'presentation/pages/DeviceDetailPage/DeviceDetailPageView';
import DevicesPage from 'presentation/pages/DevicesPage/DevicesPageView';
import DevicesListingPage from 'presentation/pages/DevicesPage/subPages/DevicesListingPageView';

// ------------ CameraGroup
import CameraGroupDetailPage from 'presentation/pages/CameraGroupDetailPage/CameraGroupDetailPage';
import CameraGroupInformationPage from 'presentation/pages/CameraGroupDetailPage/subPages/CameraGroupInformationPageView';
import CameraGroupsPage from 'presentation/pages/CameraGroupsPage/CameraGroupsPageView';
import CameraGroupCreatePage from 'presentation/pages/CameraGroupsPage/subPages/CameraGroupCreatePageView';
import CameraGroupsListingPage from 'presentation/pages/CameraGroupsPage/subPages/CameraGroupsListingPageView';

// ------------ Eyeview User
import EyeviewUserPage from 'presentation/pages/EyeviewUserPage/EyeviewUserPageView';
import CloneEyeviewUserPage from 'presentation/pages/EyeviewUserPage/subPage/CloneEyeviewUser/CloneEyeviewUserPageView';
import CreateEyeviewUserPage from 'presentation/pages/EyeviewUserPage/subPage/CreateEyeviewUser/CreateEyeviewUserPageView';
import EditEyeviewUserPage from 'presentation/pages/EyeviewUserPage/subPage/EditEyeviewUser/EditEyeviewUserPageView';
import EyeviewUserListingPage from 'presentation/pages/EyeviewUserPage/subPage/EyeviewUserListing/EyeviewUserListingPageView';

// ------------ Eyeview Sessions
import EyeviewSessionsPage from 'presentation/pages/EyeviewSessionsPage/EyeviewSessionsPageView';

// ------------ Server
import ServersPage from 'presentation/pages/ServersPage/ServersPageView';
import ServerDetailPageView from 'presentation/pages/ServerDetailPage/ServerDetailPageView';
import ServerListingPageView from 'presentation/pages/ServersPage/subPages/ServerListingPageView';

// ------------ System
import SystemPage from 'presentation/pages/SystemPage/SystemPageView';
import AccountTypePageView from 'presentation/pages/SystemPage/subPage/AccountType/AccountTypePageView';
import DeviceTypePageView from 'presentation/pages/SystemPage/subPage/DeviceType/DeviceTypePageView';
import PermissionPageView from 'presentation/pages/SystemPage/subPage/Permission/PermissionPageView';
import ServerTypePageView from 'presentation/pages/SystemPage/subPage/ServerType/ServerTypePageView';
import ServicePageView from 'presentation/pages/SystemPage/subPage/Service/ServicePageView';
import { Spin } from 'antd';
import { ApiErrorResponse } from 'models/ApiError';

//#endregion

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wrapPermission = (router: any, permissionObj: TObjectPermission) => {
  if (!router.children) {
    if (router.index) {
      return router;
    }
    return checkPermission({
      permissionObj,
      routerName: router.path,
      role: router.requiredRole || ERoleKey.VIEW
    })
      ? router
      : { ...router, element: <NoPermissionPageView isSubPage={true} /> };
  }
  if (
    !checkPermission({
      permissionObj,
      routerName: router.path,
      role: ERoleKey.VIEW
    })
  ) {
    return { ...router, element: <NoPermissionPageView /> };
  }
  return {
    ...router,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: router.children.map((route: any) => wrapPermission(route, permissionObj)).filter(Boolean)
  };
};

const createRouter = (homeRouter: RouteObject[]) => {
  return createBrowserRouter([
    {
      path: Routes.EHomeRoutes.INDEX,
      element: <AuthorizedLayout />,
      children: [{ index: true, element: <HomePage /> }, ...homeRouter]
    },
    {
      element: <Layout />,
      children: [
        {
          path: Routes.EAuthRoutes.LOGIN_PAGE,
          element: <LoginPage />
        },
        {
          path: Routes.EAuthRoutes.RESET_PASSWORD,
          element: <ResetPasswordPage />
        }
      ]
    },
    {
      path: '*',
      element: <NotFoundPage />
    }
  ]);
};

const createRouterWithPermission = (homeRouter: RouteObject[], permissionObj: TObjectPermission) => {
  const permissionWrapRouter = homeRouter
    .map((router) => {
      return wrapPermission(router, permissionObj);
    })
    .filter(Boolean) as RouteObject[];
  return createRouter(permissionWrapRouter);
};

const WrapRouterProvider = () => {
  const { permissionObj } = usePermissionContext();
  const { isLogged } = useAuthContext();
  const { t } = useTranslation();
  const { openNotification } = useAppUtil();
  const { listDeviceType, listServerType, initStatus } = useEOSSContext();

  const HomeRouter = initStatus
    ? ([
        {
          path: Routes.EAdminRoutes.LISTING,
          element: <AdminPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('adminUserPage.breadcrumb.listing')
          },
          children: [
            {
              index: true,
              element: <AdminListingPage />
            },
            {
              path: Routes.EAdminRoutes.CREATE,
              requiredRole: ERoleKey.CREATE,
              element: <AdminCreatePage />,
              handle: {
                crumb: () => t('adminUserPage.breadcrumb.create')
              }
            },
            {
              path: Routes.EAdminRoutes.DETAILED,
              element: <AdminDetailPage />,
              loader: async ({ params }: { params: { adminUserId: number } }) => {
                const administratorRepository = AdministratorRepository(AxiosClient);
                const { adminUserId } = params;
                try {
                  const res = await administratorRepository.getAdminUserById({ id: adminUserId });
                  if (res) {
                    return { ...res, allowCopied: true };
                  }
                  return null;
                } catch (error) {
                  const message = handleApiError({
                    apiErrorResponse: error as ApiErrorResponse,
                    action: 'get',
                    entity: t('adminUserPage.entity'),
                    t,
                    identifier: `ID ${adminUserId.toString()}`
                  });
                  openNotification({
                    type: 'error',
                    title: `${t('actions.get')} ${t('adminUserPage.entity')}`,
                    description: message
                  });
                  return null;
                }
              },
              handle: {
                crumb: (adminUser: TAdminUser | null) => {
                  if (adminUser?.username) {
                    return adminUser.username;
                  } else {
                    return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                  }
                }
              },
              shouldRevalidate: ({ currentParams, nextParams }: { currentParams: { adminUserId: string }; nextParams: { adminUserId: string } }) => {
                return currentParams.adminUserId !== nextParams.adminUserId;
              }
            }
          ]
        },
        {
          path: Routes.EDomainRoutes.LISTING,
          element: <DomainPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('domainPage.breadcrumb.listing')
          },
          children: [
            {
              index: true,
              element: <DomainListingPageView />
            },
            {
              path: Routes.EDomainRoutes.CREATE,
              requiredRole: ERoleKey.CREATE,
              element: <CreateDomainPageView />,
              loader: () => null,
              handle: {
                crumb: () => t('domainPage.breadcrumb.create')
              }
            },
            {
              path: Routes.EDomainRoutes.DETAILED,
              element: <EditDomainPageView />,
              loader: async ({ params }: { params: { domainId: number } }) => {
                const domainRepository = DomainRepository(AxiosClient);
                const domainOrError = await domainRepository.getDomainById(params.domainId);

                if (domainOrError.isLeft()) {
                  const err = domainOrError.error;
                  const message = handleApiError({
                    apiErrorResponse: err as ApiErrorResponse,
                    action: 'get',
                    entity: t('domainPage.entity'),
                    t,
                    identifier: `ID ${params.domainId.toString()}`
                  });
                  openNotification({
                    type: 'error',
                    title: `${t('actions.get')} ${t('domainPage.entity')}`,
                    description: message
                  });
                  return null;
                }

                const { data } = domainOrError.value;
                if (data) {
                  return { ...data, allowCopied: true };
                }
                return null;
              },
              shouldRevalidate: ({ currentParams, nextParams }: { currentParams: { id: string }; nextParams: { id: string } }) => {
                return currentParams.id !== nextParams.id;
              },
              handle: {
                crumb: (domain: TDomain | null) => {
                  if (domain?.name) {
                    return domain.name;
                  }
                  return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                }
              }
            }
          ]
        },
        {
          path: Routes.EAccountRoutes.LISTING,
          element: <AccountPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('accountPage.breadcrumb.listing')
          },
          children: [
            {
              index: true,
              element: <ListAccountPage />
            },
            {
              path: Routes.EAccountRoutes.CREATE,
              requiredRole: ERoleKey.CREATE,
              element: <CreateAccountPage />,
              loader: () => null,
              handle: {
                crumb: () => t('accountPage.breadcrumb.create')
              }
            },
            {
              path: Routes.EAccountRoutes.DETAIL,
              element: <EditAccountPage />,
              loader: async ({ params }: { params: { accountId: number } }) => {
                const accountRepository = AccountRepository(AxiosClient);
                try {
                  const { data } = await accountRepository.getAccountById(params.accountId);
                  if (data) {
                    return { ...data, allowCopied: true };
                  }
                  return null;
                } catch (error) {
                  const message = handleApiError({
                    apiErrorResponse: error as ApiErrorResponse,
                    action: 'get',
                    entity: t('accountPage.entity'),
                    t,
                    identifier: `ID ${params.accountId.toString()}`
                  });
                  openNotification({
                    type: 'error',
                    title: `${t('actions.get')} ${t('accountPage.entity')}`,
                    description: message
                  });
                  return null;
                }
              },
              shouldRevalidate: ({ currentParams, nextParams }: { currentParams: { accountId: string }; nextParams: { accountId: string } }) =>
                currentParams.accountId !== nextParams.accountId,
              handle: {
                crumb: (account: TAccount | null) => {
                  if (account?.accountNumber) {
                    return account.accountNumber;
                  }
                  return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                }
              }
            }
          ]
        },
        {
          path: Routes.EDevicesRoutes.INDEX,
          element: <DevicesPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('devicePage.breadcrumb.listing')
          },
          children: [
            {
              path: Routes.EDevicesRoutes.LISTING,
              element: <DevicesListingPage />,
              loader: async ({ params }: { params: { deviceType: string } }) => {
                if (listDeviceType.length > 0) {
                  const findDeviceType = listDeviceType.find((deviceType) => (deviceType.name ?? '').toLowerCase() === params?.deviceType);
                  if (findDeviceType) return findDeviceType;
                  return null;
                }
                return null;
              },
              shouldRevalidate: ({
                currentParams,
                nextParams
              }: {
                currentParams: { deviceId: string; deviceType: string };
                nextParams: { deviceId: string; deviceType: string };
              }) => {
                return currentParams.deviceType !== nextParams.deviceType;
              },
              handle: {
                crumb: (deviceType: TDeviceType | null) => {
                  if (deviceType?.displayName) {
                    return deviceType.displayName;
                  }
                  return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                }
              },
              children: [
                {
                  path: Routes.EDevicesRoutes.DETAILED,
                  element: <DeviceDetailPage />,
                  loader: async ({ params }: { params: { deviceId: number } }) => {
                    const deviceRepository = DeviceRepository(AxiosClient);
                    try {
                      const data = await deviceRepository.getDeviceById({
                        deviceId: params.deviceId
                      });
                      if (data) {
                        return { ...data, allowCopied: true };
                      }
                      return null;
                    } catch (error) {
                      const message = handleApiError({
                        apiErrorResponse: error as ApiErrorResponse,
                        action: 'get',
                        entity: t('devicePage.entity'),
                        t,
                        identifier: `ID ${params?.deviceId.toString()}`
                      });
                      openNotification({
                        type: 'error',
                        title: `${t('actions.get')} ${t('devicePage.entity')}`,
                        description: message
                      });
                      return null;
                    }
                  },
                  shouldRevalidate: ({
                    currentParams,
                    nextParams
                  }: {
                    currentParams: { deviceId: string; deviceType: string };
                    nextParams: { deviceId: string; deviceType: string };
                  }) => {
                    return currentParams.deviceId !== nextParams.deviceId;
                  },
                  handle: {
                    crumb: (device: TDevice | null) => {
                      if (device?.serialNumber) {
                        return device.serialNumber;
                      }
                      return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          path: Routes.ECameraGroupRoutes.LISTING,
          element: <CameraGroupsPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('cameraGroupPage.breadcrumb.listing')
          },
          children: [
            {
              index: true,
              element: <CameraGroupsListingPage />
            },
            {
              path: Routes.ECameraGroupRoutes.CREATE,
              requiredRole: ERoleKey.CREATE,
              element: <CameraGroupCreatePage />,
              loader: () => null,
              handle: {
                crumb: () => t('cameraGroupPage.breadcrumb.create')
              }
            },
            {
              id: Routes.ECameraGroupRoutes.DETAILED,
              path: Routes.ECameraGroupRoutes.DETAILED,
              element: <CameraGroupDetailPage />,
              loader: async ({ params }: { params: { cameraGroupId: number } }) => {
                const cameraGroupRepository = CameraGroupRepository(AxiosClient);
                try {
                  const { data, code } = await cameraGroupRepository.getCameraGroupById(params.cameraGroupId);
                  if (code === EHttpStatusCode.OK) {
                    return { ...data, allowCopied: true };
                  }
                  return null;
                } catch (error) {
                  const message = handleApiError({
                    apiErrorResponse: error as ApiErrorResponse,
                    action: 'get',
                    entity: t('cameraGroupPage.entity'),
                    t,
                    identifier: `ID ${params?.cameraGroupId.toString()}`
                  });
                  openNotification({
                    type: 'error',
                    title: `${t('actions.get')} ${t('cameraGroupPage.entity')}`,
                    description: message
                  });
                  return null;
                }
              },
              shouldRevalidate: ({
                currentParams,
                nextParams
              }: {
                currentParams: { cameraGroupId: string };
                nextParams: { cameraGroupId: string };
              }) => currentParams.cameraGroupId !== nextParams.cameraGroupId,
              handle: {
                crumb: (cameraGroup: TCameraGroup | null) => {
                  if (cameraGroup?.name) {
                    return cameraGroup.name;
                  }
                  return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                }
              },
              children: [
                {
                  index: true,
                  element: <CameraGroupInformationPage />
                }
              ]
            }
          ]
        },
        {
          path: Routes.EUserRoutes.LISTING,
          element: <EyeviewUserPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('userPage.breadcrumb.listing')
          },
          children: [
            { index: true, element: <EyeviewUserListingPage /> },
            {
              path: Routes.EUserRoutes.CREATE,
              handle: {
                crumb: () => t('userPage.breadcrumb.create')
              },
              element: <CreateEyeviewUserPage />,
              requiredRole: ERoleKey.CREATE
            },
            {
              id: Routes.EUserRoutes.DETAIL,
              path: Routes.EUserRoutes.DETAIL,
              shouldRevalidate: ({ currentParams, nextParams }) => currentParams.userId !== nextParams.userId,
              loader: async ({ params }) => {
                const eyeviewUserRepository = EyeviewUserRepository(AxiosClient);
                try {
                  const { data, code } = await eyeviewUserRepository.getEyeviewUserById(Number(params?.userId));
                  if (code === 200) {
                    return { ...data, allowCopied: true };
                  }
                  return null;
                } catch (error) {
                  const message = handleApiError({
                    apiErrorResponse: error as ApiErrorResponse,
                    action: 'get',
                    entity: t('userPage.entity'),
                    t,
                    identifier: `ID ${params?.userId}`
                  });
                  openNotification({
                    type: 'error',
                    title: `${t('actions.get')} ${t('userPage.entity')}`,
                    description: message
                  });
                  return null;
                }
              },
              handle: {
                crumb: (user: TEyeviewUser) => {
                  if (user?.username) {
                    return user.username;
                  }
                  return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                }
              },
              children: [
                {
                  index: true,
                  requiredRole: ERoleKey.UPDATE,
                  element: <EditEyeviewUserPage />
                },
                {
                  path: Routes.EUserRoutes.CLONE,
                  requiredRole: ERoleKey.UPDATE,
                  element: <CloneEyeviewUserPage />,
                  loader: () => null,
                  handle: {
                    crumb: () => t('userPage.breadcrumb.clone')
                  }
                }
              ]
            }
          ]
        },
        {
          path: Routes.EEyeviewSessionsRoutes.LISTING,
          element: <EyeviewSessionsPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => 'Eyeview Session Management'
          }
        },
        {
          path: Routes.EServerRoutes.INDEX,
          element: <ServersPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('serverPage.breadcrumb.listing')
          },
          children: [
            {
              path: Routes.EServerRoutes.LISTING,
              element: <ServerListingPageView />,
              loader: async ({ params }: { params: { serverType: string } }) => {
                if (listServerType.length > 0) {
                  const findServerType = listServerType.find((data) => data?.id === Number(params?.serverType));
                  if (findServerType) {
                    return findServerType;
                  }
                  return null;
                }
                return null;
              },
              shouldRevalidate: ({ currentParams, nextParams }: { currentParams: { serverType: string }; nextParams: { serverType: string } }) => {
                return currentParams.serverType !== nextParams.serverType;
              },
              handle: {
                crumb: (serverType: TServerType | null) => {
                  if (serverType?.name) {
                    return mapToServerType(serverType.name).valueOf();
                  }
                  return null;
                }
              },
              children: [
                {
                  path: Routes.EServerRoutes.DETAILED,
                  element: <ServerDetailPageView />,
                  loader: async ({ params }: { params: { serialNumber: string; serverType: string } }) => {
                    const serveRepository = ServerRepository(AxiosClient);
                    try {
                      const { data } = await serveRepository.getServerBySerialNumber({
                        serialNumber: params.serialNumber,
                        serverTypeId: Number(params.serverType)
                      });
                      if (data) {
                        return { ...data, allowCopied: true };
                      }
                      return null;
                    } catch (error) {
                      const message = handleApiError({
                        apiErrorResponse: error as ApiErrorResponse,
                        action: 'get',
                        entity: t('serverPage.entity'),
                        t,
                        identifier: `${t('serverPage.table.columns.serialNumber')} ${params?.serialNumber}`
                      });
                      openNotification({
                        type: 'error',
                        title: `${t('actions.get')} ${t('serverPage.entity')}`,
                        description: message
                      });
                      return null;
                    }
                  },
                  shouldRevalidate: ({
                    currentParams,
                    nextParams
                  }: {
                    currentParams: { serialNumber: string };
                    nextParams: { serialNumber: string };
                  }) => {
                    return currentParams.serialNumber !== nextParams.serialNumber;
                  },
                  handle: {
                    crumb: (server: TServer | null) => {
                      if (server?.serialNumber) {
                        return server.serialNumber;
                      }
                      return <span style={{ color: 'var(--sub-3)' }}>{t('components.notFound')}</span>;
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          path: Routes.ESystemRoutes.INDEX,
          element: <SystemPage />,
          requiredRole: ERoleKey.VIEW,
          loader: () => null,
          handle: {
            crumb: () => t('systemPage.breadcrumb.index')
          },
          children: [
            {
              path: Routes.ESystemRoutes.SERVICE,
              loader: () => null,
              handle: {
                crumb: () => t('systemPage.breadcrumb.service')
              },
              element: <ServicePageView />
            },
            {
              path: Routes.ESystemRoutes.PERMISSION,
              loader: () => null,
              handle: {
                crumb: () => t('systemPage.breadcrumb.permission')
              },
              element: <PermissionPageView />
            },
            {
              path: Routes.ESystemRoutes.DEVICE_TYPE,
              loader: () => null,
              handle: {
                crumb: () => t('systemPage.breadcrumb.deviceType')
              },
              element: <DeviceTypePageView />
            },
            {
              path: Routes.ESystemRoutes.ACCOUNT_TYPE,
              loader: () => null,
              handle: {
                crumb: () => t('systemPage.breadcrumb.accountType')
              },
              element: <AccountTypePageView />
            },
            {
              path: Routes.ESystemRoutes.SERVER_TYPE,
              loader: () => null,
              handle: {
                crumb: () => t('systemPage.breadcrumb.serverType')
              },
              element: <ServerTypePageView />
            }
          ]
        }
      ] as (RouteObject & { requiredRole: ERoleKey })[])
    : [];

  if (!initStatus && isLogged)
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spin size="large" />
      </div>
    );

  return <RouterProvider router={isLogged ? createRouterWithPermission(HomeRouter, permissionObj) : createRouter(HomeRouter)} />;
};

export default WrapRouterProvider;
