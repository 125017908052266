import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { useAppUtil } from 'context/UtilContext';
import { EHttpStatusCode } from 'enums';
import { TEyeviewUser, TPaginationEOSS } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { EyeviewUserRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

export const useEyeviewUserListingPageController = () => {
  const eyeviewUserRepository = EyeviewUserRepository(AxiosClient);

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get('search');
  const pageNumber = searchParams.get('page');

  const { refetchCount } = useOutletContext<{ refetchCount: number }>();

  const { openNotification } = useAppUtil();

  const [eyeviewUserList, setEyeviewUserList] = useState<{
    data: TEyeviewUser[];
    loading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    loading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  const handleFetchEyeviewUserList = async (searchString: string) => {
    if (eyeviewUserList.loading) return;
    setEyeviewUserList((prev) => ({
      ...prev,
      loading: true
    }));
    try {
      if (
        !pageNumber ||
        !Number.isInteger(Number(pageNumber)) ||
        pageNumber.includes('.') ||
        Number(pageNumber) < 1
      ) {
        setEyeviewUserList({
          data: [],
          loading: false,
          paging: {
            pageLimit: DEFAULT_LIMIT,
            pageNum: DEFAULT_PAGE_NUMBER,
            total: 0,
            totalPage: 0
          }
        });
        return;
      }
      const { code, data } = await eyeviewUserRepository.getListEyeviewUser(searchString);
      if (code === EHttpStatusCode.OK) {
        setEyeviewUserList((prev) => ({
          ...prev,
          loading: false,
          data,
          paging: {
            ...prev.paging,
            total: data.length,
            pageLimit: DEFAULT_LIMIT
          }
        }));
      }
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('userPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.search')} ${t('userPage.entity')}`,
        description: message
      });
      setEyeviewUserList((prev) => ({
        ...prev,
        loading: false
      }));
    }
  };

  const handlePageChange = (page: number) => {
    if (page > 0) {
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
    }
  };

  function refetchList() {
    if (!searchString) return;
    handleFetchEyeviewUserList(searchString);
  }

  useEffect(() => {
    if (refetchCount > 0) {
      refetchList();
    }
  }, [refetchCount]);

  useEffect(() => {
    if (searchString && pageNumber) {
      handleFetchEyeviewUserList(searchString);
    } else {
      setEyeviewUserList({
        data: [],
        loading: false,
        paging: {
          pageLimit: DEFAULT_LIMIT,
          pageNum: DEFAULT_PAGE_NUMBER,
          total: 0,
          totalPage: 0
        }
      });
    }
  }, [searchString, pageNumber]);

  useEffect(() => {
    if (pageNumber) {
      setEyeviewUserList((prev) => {
        return {
          ...prev,
          paging: {
            ...prev.paging,
            pageNum: Number(pageNumber)
          }
        };
      });
    }
  }, [pageNumber]);

  return {
    eyeviewUserList,
    onPageChange: handlePageChange,
    onFetchEyeviewUserList: handleFetchEyeviewUserList
  };
};
