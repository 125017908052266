import { Form, Select, Tooltip } from 'antd';
import { DropdownIcon } from 'assets/icons';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { TPermission } from 'models';
import { Section } from 'presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './userPermission.module.scss';
import useResponsive from 'hooks/useResponsive';
import { TOOLTIP_DELAY } from 'constant';
import { orderBy } from 'lodash';

type Props = {
  dataPermission: TPermission[];
  listPermission: TPermission[];
  addPermission: (_: number) => void;
  removePermission: (_: number) => void;
};

export default React.memo((props: Props) => {
  const { t } = useTranslation();
  const { dataPermission, listPermission, addPermission, removePermission } = props;
  const { isMobile } = useResponsive();

  const filterOption = (input: string, option?: { label: string; value: string | number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div id="eyeviewUserPage_userPermissionComponent" className={`${styles.userPermission} mb-2`}>
      <Section
        title={t('userPage.sections.permission.title')}
        classNameBody="p-4"
        allowCollapse
        expandedByDefault
        keyPanel="eyeview-user-permission-section"
      >
        <div className="w-100">
          <p>{t('userPage.sections.permission.description')}</p>
          <div className="mt-3">
            <Form.Item
              className="m-0"
              label={t('userPage.form.permission.title')}
              labelCol={{
                style: {
                  width: 80
                }
              }}
            >
              <Select
                value={null}
                size="large"
                mode="multiple"
                placement="topLeft"
                placeholder={t('userPage.form.permission.placeholder')}
                suffixIcon={<DropdownIcon />}
                options={orderBy(
                  dataPermission
                    .filter((p) => !listPermission.some((_p) => _p.id === p.id))
                    .map((p) => ({
                      value: p.id,
                      label: p.displayName
                    })),
                  'label',
                  'asc'
                )}
                onChange={(value) => {
                  addPermission(Number(value));
                }}
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
              />
            </Form.Item>
          </div>
          <div className="mt-1 d-flex flex-wrap">
            {listPermission.map((p) => (
              <div key={p.id} className={`${styles.permissionElement} me-2 mt-2`}>
                <Tooltip
                  trigger={isMobile ? ['click'] : ['hover']}
                  destroyTooltipOnHide
                  title={p.description}
                  mouseEnterDelay={TOOLTIP_DELAY}
                  arrow={false}
                  placement="bottom"
                >
                  <span>{p.displayName} </span>
                </Tooltip>

                <button
                  className={`btn-fill ${styles.closeBtn}`}
                  onClick={() => removePermission(p.id)}
                >
                  <CloseIcon color="#fff" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
});
