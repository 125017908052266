export function AdminUserEmptyIcon() {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <path
        d="M112.06 93.7233L116 89.7835L105.588 79.3711L89.2655 95.6933C87.8584 94.849 86.1699 94.5676 84.4814 94.5676C79.1345 94.5676 74.6318 99.0703 74.6318 104.417C74.6318 109.764 79.1345 114.267 84.4814 114.267C89.8283 114.267 94.331 109.764 94.331 104.417C94.331 102.729 93.7682 101.04 93.2053 99.6331L98.5522 94.2862L105.025 100.759L108.965 96.8189L102.492 90.3463L105.588 87.2508L112.06 93.7233ZM84.4814 108.638C82.2301 108.638 80.2602 106.668 80.2602 104.417C80.2602 102.166 82.2301 100.196 84.4814 100.196C86.7327 100.196 88.7027 102.166 88.7027 104.417C88.7027 106.668 86.7327 108.638 84.4814 108.638Z"
        fill="#888C90"
      />
      <path
        d="M54.9327 100.198C54.9327 89.2224 63.6566 80.4985 74.6318 80.4985C80.2601 80.4985 85.6071 83.0313 89.5469 87.2525L93.7681 83.5941C91.2354 80.7799 88.4212 78.5286 85.0442 77.1215C88.9841 74.0259 91.5168 68.9604 91.5168 63.6135C91.5168 54.3268 83.9186 46.7285 74.6318 46.7285C65.3451 46.7285 57.7468 54.3268 57.7468 63.6135C57.7468 68.9604 60.2796 74.0259 64.5008 77.1215C55.4955 81.0613 49.3043 89.7853 49.3043 100.198V114.268H71.8176V108.64H54.9327V100.198ZM63.3751 63.6135C63.3751 57.4223 68.4406 52.3568 74.6318 52.3568C80.823 52.3568 85.8885 57.4223 85.8885 63.6135C85.8885 69.8047 80.823 74.8702 74.6318 74.8702C68.4406 74.8702 63.3751 69.8047 63.3751 63.6135Z"
        fill="#888C90"
      />
    </svg>
  );
}
