import { TAxiosClient } from 'services/axios';
import { IAdminTypeDataSource } from './AdminTypeDataSource';
import { TAdminUserType, TDataResponseEOSS } from 'models';
import { orderBy } from 'lodash';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Represents a data transfer object (DTO) for an administrator user type.
 * @interface {Object} TAdminUserTypeDTO
 * @property {number} id - The unique identifier of the administrator user type.
 * @property {string} name - The name of the administrator user type.
 * @property {string} display_name - The display name of the administrator user type.
 * @property {string | null} description - The description of the administrator user type (nullable).
 * @property {Array<Object>} permissions - The permissions associated with the administrator user type.
 * @property {number} permissions.id - The unique identifier of the permission.
 * @property {string} permissions.name - The name of the permission.
 */
type TAdminUserTypeDTO = {
  id: number;
  name: string;
  display_name: string;
  description: string | null;
  permissions: {
    id: number;
    name: string;
  }[];
};

/**
 * Represents a remote data source implementation for handling administrator-related data.
 * @author hung.nguyen@zien.vn
 */
export class AdminTypeRemoteDataSourceImpl implements IAdminTypeDataSource {
  httpService: TAxiosClient;

  private static instance: AdminTypeRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  public static getInstance(httpService: TAxiosClient): AdminTypeRemoteDataSourceImpl {
    if (!AdminTypeRemoteDataSourceImpl.instance) {
      AdminTypeRemoteDataSourceImpl.instance = new AdminTypeRemoteDataSourceImpl(httpService);
    }
    return AdminTypeRemoteDataSourceImpl.instance;
  }

  async getListAdminTypes(): Promise<TAdminUserType[]> {
    const { data } = await this.httpService.get<TAdminUserTypeDTO[]>('/admin/types/all');

    return orderBy(data, 'name', 'asc').map((val) => mapSnakeCaseToCamelCase(val));
  }
}
