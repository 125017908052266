import { IAdministratorDataSource } from 'data/Administrator/AdministratorDataSource';
import { IDomainDataSource } from 'data/Domain/DomainDataSource';
import orderBy from 'lodash/orderBy';
import { TAdminUser, TDomain } from 'models';
import { IAdministratorRepository } from './AdministratorRepository';
import { convertAxiosErrorToApiError } from 'utils/common';

/**
 * Implementation of the Administrator Repository interface.
 * @author hung.nguyen@zien.vn
 */
export class AdministratorRepositoryImpl implements IAdministratorRepository {
  adminDataSource: IAdministratorDataSource;

  domainDataSource: IDomainDataSource;

  private static instance: AdministratorRepositoryImpl;

  /**
   * Constructor for AdministratorRepositoryImpl class.
   * @param adminDataSource - The administrator data source.
   * @param domainDataSource - The domain data source.
   */
  constructor(adminDataSource: IAdministratorDataSource, domainDataSource: IDomainDataSource) {
    this.adminDataSource = adminDataSource;
    this.domainDataSource = domainDataSource;
  }

  /**
   * Get an instance of AdministratorRepositoryImpl.
   * @param adminDataSource - The administrator data source.
   * @param domainDataSource - The domain data source.
   * @returns An instance of AdministratorRepositoryImpl.
   */
  public static getInstance(
    adminDataSource: IAdministratorDataSource,
    domainDataSource: IDomainDataSource
  ): AdministratorRepositoryImpl {
    if (!AdministratorRepositoryImpl.instance) {
      AdministratorRepositoryImpl.instance = new AdministratorRepositoryImpl(
        adminDataSource,
        domainDataSource
      );
    }
    return AdministratorRepositoryImpl.instance;
  }

  async getListAdminUsers({ search }: { search: string }): Promise<TAdminUser[]> {
    try {
      const res = await this.adminDataSource.getListAdminUsers({
        search
      });
      return orderBy(res.data, 'username', 'asc');
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getAdminUserById({ id }: { id: number }): Promise<TAdminUser> {
    try {
      const res = await this.adminDataSource.getAdminUserById({ id });
      return res.data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListDomains(): Promise<TDomain[]> {
    try {
      const res = await this.domainDataSource.getListDomain();
      return res.data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async createAdminUser({
    adminTypeId,
    domainId,
    firstName,
    languageId,
    lastName,
    address,
    invalid,
    password,
    username,
    comment,
    contact,
    adhocPermissionIds
  }: {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    address: string | null;
    contact: string;
    adminTypeId: number;
    invalid: number;
    domainId?: number;
    languageId: number;
    comment: string | null;
    adhocPermissionIds: string;
  }): Promise<TAdminUser> {
    try {
      const res = await this.adminDataSource.createAdminUser({
        admin_type_id: adminTypeId,
        domain_id: domainId,
        address: address || '',
        first_name: firstName,
        last_name: lastName,
        language_id: languageId,
        password,
        username,
        comment: comment || '',
        contact,
        invalid,
        adhoc_permission_ids: adhocPermissionIds
      });
      return res.data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateAdminUser({
    id,
    adminTypeId,
    domainId,
    firstName,
    languageId,
    invalid,
    lastName,
    address,
    password,
    username,
    comment,
    contact,
    adhocPermissionIds
  }: {
    id: number;
    username: string;
    password?: string;
    firstName: string;
    lastName: string;
    address: string | null;
    contact: string;
    adminTypeId: number;
    invalid: number;
    domainId?: number;
    languageId: number;
    comment: string | null;
    adhocPermissionIds: string;
  }): Promise<TAdminUser> {
    try {
      const res = await this.adminDataSource.updateAdminUser({
        id,
        admin_type_id: adminTypeId,
        domain_id: domainId,
        address: address || '',
        first_name: firstName,
        last_name: lastName,
        language_id: languageId,
        password,
        username,
        comment: comment || '',
        contact,
        invalid,
        adhoc_permission_ids: adhocPermissionIds
      });
      return res.data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
