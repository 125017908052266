import {
  TAccount,
  TCameraGroup,
  TCameraGroupAvailable,
  TDataResponseEOSS,
  TDevice,
  TServer,
  TSortOrder
} from 'models';
import { IDeviceRepository } from './DeviceRepository';
import { IDeviceDataSource } from 'data/Device/DeviceDataSource';
import { IAccountDataSource } from 'data/Account/AccountDataSource';
import { EDeviceCommand, EHttpStatusCode, EStatusInvalid } from 'enums';
import { IServerDataSource } from 'data/Server/ServerDataSource';
import { ICameraDataSource } from 'data/Camera/CameraDataSource';
import { ICameraGroupDataSource } from 'data/CameraGroup/CameraGroupDataSource';
import { orderBy } from 'lodash';
import { convertAxiosErrorToApiError } from 'utils/common';

/**
 * Implements of the Device Repository interface.
 * @author hung.nguyen@zien.vn
 */
export class DeviceRepositoryImpl implements IDeviceRepository {
  deviceDataSource: IDeviceDataSource;

  accountDataSource: IAccountDataSource;

  serverDataSource: IServerDataSource;

  cameraDataSource: ICameraDataSource;

  cameraGroupDataSource: ICameraGroupDataSource;

  private static instance: DeviceRepositoryImpl;

  constructor(
    deviceDataSource: IDeviceDataSource,
    accountDataSource: IAccountDataSource,
    serverDataSource: IServerDataSource,
    cameraDataSource: ICameraDataSource,
    cameraGroupDataSource: ICameraGroupDataSource
  ) {
    this.deviceDataSource = deviceDataSource;
    this.accountDataSource = accountDataSource;
    this.serverDataSource = serverDataSource;
    this.cameraDataSource = cameraDataSource;
    this.cameraGroupDataSource = cameraGroupDataSource;
  }

  /**
   * Implements a Singleton factory method for the DeviceRepositoryImpl class.
   * Ensures only a single instance of DeviceRepositoryImpl is created.
   *
   * @param {IDeviceDataSource} deviceDataSource - The data source for device information.
   * @param {IAccountDataSource} accountDataSource -  The data source for account information.
   * @param {IServerDataSource} serverDataSource - The data source for server information.
   * @param {ICameraDataSource} cameraDataSource - The data source for camera information.
   * @param {ICameraGroupDataSource} cameraGroupDataSource - The data source for camera group information.
   * @returns {DeviceRepositoryImpl} A single instance of the DeviceRepositoryImpl class.
   */
  public static getInstance(
    deviceDataSource: IDeviceDataSource,
    accountDataSource: IAccountDataSource,
    serverDataSource: IServerDataSource,
    cameraDataSource: ICameraDataSource,
    cameraGroupDataSource: ICameraGroupDataSource
  ): DeviceRepositoryImpl {
    if (!DeviceRepositoryImpl.instance) {
      DeviceRepositoryImpl.instance = new DeviceRepositoryImpl(
        deviceDataSource,
        accountDataSource,
        serverDataSource,
        cameraDataSource,
        cameraGroupDataSource
      );
    }
    return DeviceRepositoryImpl.instance;
  }

  async replaceDevice({
    serialNumber,
    deviceId,
    unassignedAccountId
  }: {
    serialNumber: string;
    deviceId: number;
    unassignedAccountId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.deviceDataSource.replaceDevice({
        account_id: `${unassignedAccountId}`,
        device_id: deviceId,
        serial: serialNumber
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListDeviceGroupByLocation({
    location,
    page,
    accountId
  }: {
    location?: string;
    page?: number;
    accountId?: number;
  }): Promise<TDataResponseEOSS<TCameraGroup[]>> {
    try {
      const res = await this.cameraGroupDataSource.getListCameraGroup({
        search: location ?? '',
        page: `${page ?? 0}`,
        account_id: accountId
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async rebootDevice({
    deviceId,
    commandId
  }: {
    deviceId: number;
    commandId: EDeviceCommand;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.deviceDataSource.rebootDevice({
        device_id: deviceId,
        command_id: commandId
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListCameraGroup({
    search,
    accountId,
    page,
    sortKey,
    order,
    excludedTypeIds
  }: {
    search: string;
    accountId: number;
    page: number;
    sortKey?: string;
    order?: TSortOrder;
    excludedTypeIds?: string;
  }): Promise<TDataResponseEOSS<TCameraGroup[]>> {
    try {
      const res = await this.cameraGroupDataSource.getListCameraGroup({
        search,
        account_id: accountId,
        page: page.toString(),
        sortKey,
        order,
        excluded_type_ids: excludedTypeIds
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListDevices({
    deviceTypeName,
    search,
    page,
    sortKey,
    order
  }: {
    deviceTypeName: string;
    search: string;
    page: number;
    sortKey?: string;
    order?: TSortOrder;
  }): Promise<TDataResponseEOSS<TDevice[]>> {
    try {
      const res = await this.deviceDataSource.getListDevices({
        deviceTypeName,
        search,
        page,
        sortKey,
        order
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getDeviceById({ deviceId }: { deviceId: number }): Promise<TDevice> {
    try {
      const res = await this.deviceDataSource.getDeviceById({
        deviceId
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListAccounts(): Promise<TAccount[]> {
    try {
      const res = await this.accountDataSource.getListAccounts('*');
      return orderBy(
        res.data.filter((account) => account.invalid === EStatusInvalid.VALID),
        'name',
        'asc'
      );
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListServer({ typeId }: { typeId: number }): Promise<TServer[]> {
    try {
      const res = await this.serverDataSource.getListServer({ typeId: typeId });
      return res.data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateDeviceBasic({
    deviceId,
    comment,
    location,
    form
  }: {
    deviceId: number;
    location: string;
    comment: string;
    form: string;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.deviceDataSource.updateDeviceBasic({
        deviceId,
        comment,
        location,
        form
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateDeviceAccount({
    deviceId,
    accountId
  }: {
    deviceId: number;
    accountId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.deviceDataSource.updateDeviceAccount({ deviceId, accountId });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateDeviceServers({
    deviceId,
    serverIds
  }: {
    deviceId: number;
    serverIds: string;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.deviceDataSource.updateDeviceServers({ deviceId, serverIds });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateCamera({
    cameraId,
    lens,
    name,
    serviceId,
    thermogServiceId
  }: {
    cameraId: number;
    lens: string;
    name: string;
    serviceId: number | null;
    thermogServiceId: number | null;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.cameraDataSource.updateCamera({
        id: cameraId,
        lens,
        name,
        service_id: serviceId,
        thermog_service_id: thermogServiceId
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async bindGroupToDevice({
    groupId,
    serialNumbers
  }: {
    groupId: number;
    serialNumbers: string;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.cameraGroupDataSource.bindDeviceToGroup({
        groupId,
        serialNumbers
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async unbindCameraGroups({
    deviceId,
    cameraGroupIds
  }: {
    deviceId: number;
    cameraGroupIds: string;
  }): Promise<EHttpStatusCode> {
    try {
      const res = await this.deviceDataSource.unbindCameraGroups({
        deviceId,
        cameraGroupIds
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListCameraGroupByDeviceId({
    deviceId,
    page,
    search
  }: {
    deviceId: number;
    page?: number;
    search?: string;
  }): Promise<TDataResponseEOSS<TCameraGroupAvailable>> {
    try {
      const res = await this.cameraGroupDataSource.getListCameraGroupsAvailable({
        deviceId,
        page,
        search
      });
      return res;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
