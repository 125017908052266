import { Tooltip } from 'antd';
import React, { FC, useRef, useState } from 'react';

type Props = {
  children: React.ReactNode;
  name: string;
  className?: string;
};

const FormInputTooltip: FC<Props> = ({ children, name, className = '' }) => {
  const [tooltipTitle, setTooltipTitle] = useState<string>('');

  const textComponentRef = useRef<HTMLDivElement | null>(null);

  const handleOpenTooltip = () => {
    if (textComponentRef.current) {
      const inputElement = textComponentRef.current.querySelector(
        `input#${name}`
      ) as HTMLInputElement | null;
      if (inputElement) {
        if (inputElement.scrollWidth >= inputElement.offsetWidth) {
          setTooltipTitle(inputElement?.value ?? '');
        } else {
          setTooltipTitle('');
        }
      }
    }
  };

  return (
    <Tooltip title={tooltipTitle}>
      <div ref={textComponentRef} onMouseEnter={handleOpenTooltip} className={className}>
        {children}
      </div>
    </Tooltip>
  );
};

export { FormInputTooltip };
