import { TAccountType, TDataResponseEOSS } from 'models';
import { TAxiosClient } from 'services/axios';
import { IAccountTypeDataSource } from './AccountTypeDataSource';
import orderBy from 'lodash/orderBy';

/**
 * Represents a remote data source implementation for handling account type data.
 * @author hung.nguyen@zien.vn
 */
export class AccountTypeRemoteDataSourceImpl implements IAccountTypeDataSource {
  httpService: TAxiosClient;

  private static instance: AccountTypeRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of AccountTypeRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {AccountTypeRemoteDataSourceImpl} The singleton instance of AccountTypeRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): AccountTypeRemoteDataSourceImpl {
    if (!AccountTypeRemoteDataSourceImpl.instance) {
      AccountTypeRemoteDataSourceImpl.instance = new AccountTypeRemoteDataSourceImpl(httpService);
    }
    return AccountTypeRemoteDataSourceImpl.instance;
  }

  async getListAccountType(): Promise<TAccountType[]> {
    const { data } = await this.httpService.get<TAccountType[]>('/account/types/all');
    return orderBy(data, 'name', 'asc');
  }

  async addAccountType({
    name,
    comments
  }: {
    name: string;
    comments?: string | undefined;
  }): Promise<TAccountType> {
    const { data } = await this.httpService.post<TDataResponseEOSS<TAccountType>>(
      '/admin/account-type',
      {
        name,
        comments
      }
    );
    return data.data;
  }

  async updateAccountType({
    accountTypeId,
    name,
    validId,
    comments
  }: {
    name: string;
    comments?: string | undefined;
    accountTypeId: number;
    validId: number;
  }): Promise<TAccountType> {
    const { data } = await this.httpService.put<TDataResponseEOSS<TAccountType>>(
      `/admin/account-type/${accountTypeId}`,
      { name, comments, valid_id: validId }
    );
    return data.data;
  }
}
