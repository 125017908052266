import { EHttpStatusCode, EStorageKey, Routes } from 'enums';
import { TError, TPermission } from 'models';
import { ApiErrorResponse, expiredTokenError } from 'models/ApiError';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { AuthRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { getCountdownTime, getError, isTokenExpired } from 'utils/common';
import { Either, Left, Right } from 'utils/either';

type TLoginParams = { username: string; password: string };

interface IAuthContext {
  isLogged: boolean;
  permissions: TPermission[];
  login: (params: TLoginParams) => Promise<Either<ApiErrorResponse | undefined, void>>;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const authRepository = AuthRepository(AxiosClient);
  const eossToken = localStorage.getItem(EStorageKey.EOSS_TOKEN);
  const [isLogged, setIsLogged] = useState(!!eossToken);
  const [myPermissions, setMyPermissions] = useState<TPermission[]>([]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  async function handleLogin(
    params: TLoginParams
  ): Promise<Either<ApiErrorResponse | undefined, void>> {
    const { username, password } = params;
    const dataResponseOrError = await authRepository.login({ username, password });

    if (dataResponseOrError.isLeft()) {
      return Left.create(dataResponseOrError.error);
    }

    const { data } = dataResponseOrError.value;

    localStorage.setItem(
      EStorageKey.EOSS_CURRENT_USER,
      JSON.stringify({
        username: data.username,
        adminTypeDisplayName: data.adminTypeDisplayName,
        language: data.language
      })
    );

    setIsLogged(true);

    return Right.create(undefined);
  }

  async function handleGetProfile() {
    try {
      const { code, data } = await authRepository.getProfile();
      if (code === EHttpStatusCode.OK) {
        setMyPermissions(data.permissions);
      }
    } catch (error) {
      const err = getError<TError>(error as string);
      return {
        success: false,
        error: err.message
      };
    }
  }

  function handleLogout() {
    localStorage.clear();
    sessionStorage.clear();
    setMyPermissions([]);
    setIsLogged(false);
  }

  useEffect(() => {
    setIsLogged(!!eossToken);
  }, [eossToken]);

  useEffect(() => {
    function detectVisibilityChange() {
      const visible = document.visibilityState === 'visible';
      if (visible) {
        const token = localStorage.getItem(EStorageKey.EOSS_TOKEN);
        if (!token && !window.location.href.includes('/login')) {
          window.location.reload();
        }
      }
    }

    document.addEventListener('visibilitychange', detectVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', detectVisibilityChange);
    };
  }, []);

  const checkTokenExpired = () => {
    const tokenTime = localStorage.getItem(EStorageKey.EOSS_EXP_TIME);

    const cacheCurrentData = () => {
      const currUser = localStorage.getItem(EStorageKey.EOSS_CURRENT_USER);
      const userDataFromLocal = JSON.parse(currUser || '{}');
      sessionStorage.setItem(EStorageKey.EOSS_CURRENT_USER, JSON.stringify(userDataFromLocal));
      sessionStorage.setItem(
        EStorageKey.REDIRECT_TO,
        `${window.location.pathname}${window.location.search}`
      );
      sessionStorage.setItem(EStorageKey.AUTH_ERROR, expiredTokenError.error);
    };

    // Refresh page
    if (isTokenExpired(tokenTime) && !window.location.href.includes('/login')) {
      cacheCurrentData();
      localStorage.clear();
      window.location.replace(Routes.EAuthRoutes.LOGIN_PAGE);
    } else {
      const countdownTime = getCountdownTime(tokenTime);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (countdownTime !== undefined) {
        // Countdown from current time to token expired time
        timeoutRef.current = setTimeout(() => {
          cacheCurrentData();
          localStorage.clear();
          window.location.replace(Routes.EAuthRoutes.LOGIN_PAGE);
        }, countdownTime * 1000);
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      handleGetProfile();
      checkTokenExpired();

      const storageChange = () => {
        checkTokenExpired();
      };

      window.addEventListener('storage', storageChange);

      return () => {
        window.removeEventListener('storage', storageChange);
      };
    }
  }, [isLogged]);

  const value: IAuthContext = {
    isLogged: isLogged,
    permissions: myPermissions,
    logout: handleLogout,
    login: handleLogin
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
