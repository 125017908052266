import { EmptyPage, Pagination, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { CameraGroupEmptyIcon } from 'assets/icons/CameraGroupEmptyIcon';
import { useCameraGroupListingPageController } from './CameraGroupListingPageController';
import { useSearchParams } from 'react-router-dom';
import TableCameraGroupListing from '../components/TableCameraGroupListing';

function CameraGroupsListingPageView() {
  const { t } = useTranslation();
  const { listCameraGroup, onPageChange } = useCameraGroupListingPageController();
  const [searchParams] = useSearchParams();
  const searchKey = searchParams.get('search');

  const isRenderPagination =
    listCameraGroup?.paging?.pageNum <= listCameraGroup?.paging?.totalPage &&
    listCameraGroup?.paging?.pageNum > 0;

  return (
    <>
      <Section title={t('cameraGroupPage.sections.cameraGroupList.title')}>
        <EmptyPage
          showEmpty={!searchKey}
          title={t('cameraGroupPage.emptyTable.title')}
          subTitle={t('cameraGroupPage.emptyTable.subTitle')}
          emptyIcon={<CameraGroupEmptyIcon />}
        >
          <TableCameraGroupListing listCameraGroup={listCameraGroup} />
        </EmptyPage>
      </Section>
      {isRenderPagination && (
        <Pagination
          total={listCameraGroup.paging.total}
          pageSize={listCameraGroup.paging.pageLimit}
          initPageNumber={listCameraGroup.paging.pageNum}
          onChange={onPageChange}
          disable={listCameraGroup.isLoading}
        />
      )}
    </>
  );
}

export default CameraGroupsListingPageView;
