function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M16.9437 7.05625C16.2962 6.40424 15.5259 5.88687 14.6774 5.53395C13.8289 5.18104 12.919 4.99957 12 5C10.1435 5 8.36301 5.7375 7.05025 7.05025C5.7375 8.36301 5 10.1435 5 12C5 13.8565 5.7375 15.637 7.05025 16.9497C8.36301 18.2625 10.1435 19 12 19C15.2638 19 17.985 16.7688 18.7638 13.75H16.9437C16.5833 14.7733 15.9142 15.6596 15.0287 16.2865C14.1432 16.9134 13.085 17.25 12 17.25C10.6076 17.25 9.27226 16.6969 8.28769 15.7123C7.30312 14.7277 6.75 13.3924 6.75 12C6.75 10.6076 7.30312 9.27226 8.28769 8.28769C9.27226 7.30312 10.6076 6.75 12 6.75C13.4525 6.75 14.7475 7.35375 15.6925 8.3075L12.875 11.125H19V5L16.9437 7.05625Z"
        fill="#4A61E3"
      />
    </svg>
  );
}

export { RefreshIcon };
