import { Form, Input } from 'antd';
import { TDevice } from 'models';
import { TDynamicResponse } from 'models/DynamicResponse';
import React, { FC, useEffect } from 'react';
import { FormBARCStatsFieldType } from '../DeviceDetailPageController';
import { useTranslation } from 'react-i18next';
import { convertBytePerSecondToMbps, isNumber } from 'utils/common';
import { FormInputTooltip } from 'presentation/components';
import useResponsive from 'hooks/useResponsive';

type Props = {
  fieldDisplayNameMappings: TDynamicResponse | undefined;
  currentDevice: TDevice;
};

const FormBARCStats: FC<Props> = ({ fieldDisplayNameMappings, currentDevice }) => {
  const { t } = useTranslation();

  const { isTablet, isSemiDesktop } = useResponsive();

  const [formBARCStats] = Form.useForm<FormBARCStatsFieldType>();

  useEffect(() => {
    if (currentDevice) {
      formBARCStats.setFieldsValue({
        status: currentDevice?.data?.status?.toString(),
        curThrpt: isNumber(currentDevice?.data?.curThrpt)
          ? convertBytePerSecondToMbps(Number(currentDevice.data.curThrpt))
          : undefined,
        maxThrpt: isNumber(currentDevice?.data?.maxThrpt)
          ? convertBytePerSecondToMbps(Number(currentDevice.data.maxThrpt))
          : undefined,
        minThrpt: isNumber(currentDevice?.data?.minThrpt)
          ? convertBytePerSecondToMbps(Number(currentDevice.data.minThrpt))
          : undefined,
        bufferSz: currentDevice?.data?.bufferSz?.toString(),
        barcFrame: currentDevice?.data?.barcFrame?.toString()
      });
    }
  }, [currentDevice]);

  return (
    <Form
      id="devicePage_BARCStatsForm"
      form={formBARCStats}
      className="w-100"
      layout={isTablet || isSemiDesktop ? 'horizontal' : 'vertical'}
      labelCol={{
        style: {
          width: 125
        }
      }}
      scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
    >
      <div className="row">
        {!!formBARCStats.getFieldValue('status') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="status">
              <Form.Item<FormBARCStatsFieldType>
                name="status"
                label={
                  <span className="text-truncate">{t('devicePage.sections.barcStats.status')}</span>
                }
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formBARCStats.getFieldValue('curThrpt') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="curThrpt">
              <Form.Item<FormBARCStatsFieldType>
                name="curThrpt"
                label={<span className="text-truncate">Bandwidth (Mbps):</span>}
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formBARCStats.getFieldValue('maxThrpt') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="maxThrpt">
              <Form.Item<FormBARCStatsFieldType>
                name="maxThrpt"
                label={<span className="text-truncate">Max Throughput:</span>}
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formBARCStats.getFieldValue('minThrpt') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="minThrpt">
              <Form.Item<FormBARCStatsFieldType>
                name="minThrpt"
                label={<span className="text-truncate">Min Throughput:</span>}
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
        {!!formBARCStats.getFieldValue('bufferSz') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="bufferSz">
              <Form.Item<FormBARCStatsFieldType>
                name="bufferSz"
                label={<span className="text-truncate">Buffered Frames:</span>}
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}

        {!!formBARCStats.getFieldValue('barcFrame') && (
          <div className="col-6 col-md-12 col-slg-6">
            <FormInputTooltip name="barcFrame">
              <Form.Item<FormBARCStatsFieldType>
                name="barcFrame"
                label={<span className="text-truncate">Adjusted Frames:</span>}
                colon={false}
              >
                <Input className="text-truncate" disabled />
              </Form.Item>
            </FormInputTooltip>
          </div>
        )}
      </div>
    </Form>
  );
};

export default FormBARCStats;
