import { Button, Divider, Form, Input, Tooltip } from 'antd';
import { Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import styles from './contacts.module.scss';
import { AddIconNoOutline, DeleteIcon } from 'assets/icons';
import React, { memo } from 'react';
import useResponsive from 'hooks/useResponsive';
import ValidationUtilities from 'utils/validationUtils';

export const Contacts = memo(() => {
  const { t } = useTranslation();
  const { isMobile, isSemiTablet } = useResponsive();

  return (
    <Section
      title={t('accountPage.sections.contacts.title')}
      classNameBody="p-3"
      allowCollapse
      expandedByDefault
      keyPanel="customer-contact"
    >
      <div id="customerPage_contactsForm" className={styles.container}>
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key, ...restField }) => {
                return (
                  <Form.Item key={key} {...restField}>
                    <div className="row mb-0 mb-smd-4 ps-3">
                      {name !== 0 && (isSemiTablet || isMobile) && (
                        <div className="pe-13">
                          <Divider className="my-4" />
                        </div>
                      )}

                      <div className="row" style={{ width: '97%', gap: '0' }}>
                        <Form.Item
                          name={[name, 'name']}
                          className="col-12 col-smd-4 mb-2 mb-smd-0 pe-0"
                          rules={[
                            {
                              validator: (_, value: string) =>
                                ValidationUtilities.shortNameValidationFn({
                                  value,
                                  errorMessage: t('forms.contactName.maxLength')
                                })
                            }
                          ]}
                        >
                          <Input
                            className={`${styles.addonData} text-truncate`}
                            addonBefore="Name"
                          />
                        </Form.Item>
                        <Form.Item
                          name={[name, 'email']}
                          className="col-12 col-smd-4 mb-2 mb-smd-0 pe-0 ps-smd-2"
                          rules={[
                            {
                              validator: (_, value: string) =>
                                ValidationUtilities.emailValidationFn({
                                  value,
                                  errorMessages: new Map([
                                    ['maxLength', t('forms.email.maxLength')],
                                    ['pattern', t('forms.email.pattern')]
                                  ])
                                })
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.resolve();
                                }
                                const isExist =
                                  getFieldValue('contacts').filter(
                                    (item: any) =>
                                      item?.email?.trim() === value?.trim() && value?.length > 0
                                  ).length > 1;
                                if (isExist) {
                                  return Promise.reject(t('forms.email.unique'));
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <Input
                            className={`${styles.addonData} text-truncate`}
                            addonBefore="Email"
                          />
                        </Form.Item>
                        <Form.Item
                          name={[name, 'phone']}
                          className="col-12 col-smd-4 mb-0 pe-0 ps-smd-2"
                          rules={[
                            {
                              validator: (_, value: string) =>
                                ValidationUtilities.phoneNumberValidationFn({
                                  value,
                                  errorMessage: t('forms.phoneNumber.maxLength')
                                })
                            }
                          ]}
                        >
                          <Input
                            className={`${styles.addonData} text-truncate`}
                            addonBefore="Phone"
                          />
                        </Form.Item>
                      </div>
                      <Tooltip
                        title={
                          <span className="text-nowrap">
                            {t('accountPage.sections.deleteIcon.tooltip')}
                          </span>
                        }
                        trigger="hover"
                      >
                        <Button
                          type="text"
                          className={styles.delBtn}
                          style={{
                            left: '8px',
                            ...(isMobile || isSemiTablet ? { top: '36px' } : {})
                          }}
                          icon={<DeleteIcon />}
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            remove(name);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Form.Item>
                );
              })}
              <div className="ps-3">
                <Button className={styles.addBtn} onClick={() => add()}>
                  <AddIconNoOutline />
                  {t('accountPage.sections.contacts.actionButton')}
                </Button>
              </div>
            </>
          )}
        </Form.List>
      </div>
    </Section>
  );
});
