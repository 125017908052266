import { checkPermission, usePermissionContext } from 'context/PermissionContext';
import { ERoleKey, ESubFeatureKey } from 'enums';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const usePathPermission = (
  pathname: string, //Ex: '/camera-group'
  role?: ERoleKey,
  subFeature?: ESubFeatureKey
) => {
  if (!role) return { allowed: false };
  const { permissionObj } = usePermissionContext();

  const allowed = useMemo(() => {
    const routerName = pathname;
    return checkPermission({ permissionObj, routerName, role, subFeature });
  }, [permissionObj, pathname]);

  return { allowed };
};

export const usePermission = (role?: ERoleKey, subFeature?: ESubFeatureKey) => {
  if (!role) return { allowed: false };
  const { permissionObj } = usePermissionContext();
  const { pathname } = useLocation();

  const allowed = useMemo(() => {
    const routerName = pathname;
    return checkPermission({ permissionObj, routerName, role, subFeature });
  }, [permissionObj, pathname]);

  return { allowed };
};
