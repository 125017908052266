import { Form, Input, notification } from 'antd';
import { RuleObject } from 'antd/es/form';
import { CheckCircle } from 'assets/icons/CheckCircle';
import { Routes } from 'enums';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { passwordValidate } from 'utils/common';
import { useResetPasswordController } from './ResetPasswordController';
import styles from './resetPasswordPage.module.scss';

type FieldType = {
  email: string;
  newPassword: string;
  confirmPassword: string;
};

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get('requestId');
  const { sendMail, isLoading, resetPassword } = useResetPasswordController();
  const [notificationAPI, notificationHolderContext] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSendEmail, setSendEmail] = useState(false);
  const emailRef = useRef<string>('');

  const onFinish = async (values: FieldType) => {
    if (requestId) {
      const { error, success } = await resetPassword({
        newPassword: values.newPassword,
        requestId
      });
      if (success) {
        notificationAPI.success({
          message: t('resetPasswordPage.passwordHasBeenReset'),
          onClose: () => {
            navigate(Routes.EAuthRoutes.LOGIN_PAGE);
          },
          duration: 1
        });
      } else {
        notificationAPI.error({ message: error, duration: null });
      }
    } else {
      const { error, success } = await sendMail({ email: values.email });
      if (success) {
        emailRef.current = values.email;
        setSendEmail(true);
        // notificationAPI.success({
        //   message: t('resetPasswordPage.sendEmailSuccess')
        // });
      } else {
        notificationAPI.error({ message: error, duration: null });
      }
    }
  };
  const onFinishFailed = () => {};

  const onValidateNewPassword = (_: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(t('form.newPassword.rules.required'));
    }

    if (passwordValidate(value)) {
      return Promise.resolve();
    }

    return Promise.reject(t('form.newPassword.rules.pattern'));
  };

  const onValidateConfirmPassword = (_: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(t('form.confirmNewPassword.rules.required'));
    }
    if (form.getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }

    return Promise.reject(t('form.confirmNewPassword.rules.notMatching'));
  };

  return (
    <>
      {notificationHolderContext}
      <div
        className={`animate__animated animate__fadeIn h-100 d-flex align-items-center justify-content-center ${styles.screenContainer}`}
      >
        {isSendEmail ? (
          <div className={`${styles.contentContainer} ${styles.center}`}>
            <CheckCircle />
            <div className={`${styles.txtReset}`}>{t('resetPasswordPage.resetLinkSent')}</div>
            <div className={`${styles.txtEnter}`}>{`${t('resetPasswordPage.sentTo')} ${
              emailRef.current
            }`}</div>
            <button
              className={`btn-fill ${styles.btnSubmit}`}
              type="submit"
              onClick={() => navigate(-1)}
            >
              {t('resetPasswordPage.backToLogin')}
            </button>
          </div>
        ) : (
          <div className={`${styles.contentContainerMain}`}>
            {!requestId && (
              <BiLeftArrowAlt
                size={30}
                color="var(--primary-1)"
                className="cursor-pointer mb-4"
                onClick={() => navigate(-1)}
                style={{ marginLeft: -6 }}
              />
            )}
            <div className={`${styles.txtResetMain}`}>
              {t('resetPasswordPage.resetYourPassword')}
            </div>
            {!requestId && (
              <div className={`${styles.txtEnterMain}`}>{t('resetPasswordPage.enterEmail')}</div>
            )}
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {requestId ? (
                <>
                  <Form.Item<FieldType>
                    name="newPassword"
                    className="mb-4"
                    rules={[{ validator: onValidateNewPassword }]}
                  >
                    <Input.Password placeholder={t('form.newPassword.placeholder')} />
                  </Form.Item>

                  <Form.Item<FieldType>
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    className="mb-4"
                    rules={[{ validator: onValidateConfirmPassword }]}
                  >
                    <Input.Password placeholder={t('form.confirmNewPassword.placeholder')} />
                  </Form.Item>

                  <div className="mb-3" style={{ whiteSpace: 'pre-line' }}>
                    {t('resetPasswordPage.rulePassword')}
                  </div>
                </>
              ) : (
                <Form.Item<FieldType>
                  name="email"
                  className="mb-8"
                  rules={[
                    { required: true, message: t('form.emailAddress.rules.required') },
                    { type: 'email', message: t('form.emailAddress.rules.pattern') }
                  ]}
                >
                  <Input placeholder={t('form.emailAddress.placeholder')} />
                </Form.Item>
              )}

              <Form.Item className="mb-0">
                <button
                  className={`btn-fill ${styles.btnSubmit}`}
                  type="submit"
                  disabled={isLoading}
                >
                  {t('resetPasswordPage.btnSubmit')}
                </button>
              </Form.Item>
            </Form>
          </div>
        )}
        <div className={`${styles.txtVersion} text-center`}>
          DEV-{process.env.REACT_APP_BASE_VERSION}
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
