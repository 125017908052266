import type { TServer } from 'models';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './tableListVRSServer.module.scss';
import { EDeviceStatus } from 'enums';

type Props = {
  isLoading: boolean;
  server: TServer;
  isOnline: boolean;
  totalUsers: number;
  totalAccounts: number;
};

function TableListVRSServer({ server, isOnline, isLoading, totalUsers, totalAccounts }: Props) {
  const { t } = useTranslation();

  const columns: ColumnsType<TServer> = useMemo(() => {
    return [
      {
        title: () => t('serverPage.table.columns.serialNumber'),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        render: (value) => {
          return <span style={{ color: 'var(--primary-1)' }}>{value}</span>;
        },
        width: '20%'
      },
      {
        title: () => t('serverPage.table.columns.serverName'),
        dataIndex: 'name',
        key: 'name',
        render: (value) => {
          return value;
        },
        width: '20%'
      },
      {
        title: () => t('serverPage.table.columns.ipAddress'),
        dataIndex: 'ip',
        key: 'ip',
        render: (value) => {
          return value;
        },
        width: '20%'
      },
      {
        title: () => t('serverPage.table.columns.status'),
        key: 'status',
        render: (_) => {
          return (
            <>
              {isOnline ? (
                <span className="fw-medium" style={{ color: 'var(--sub-5)' }}>
                  {EDeviceStatus.ONLINE}
                </span>
              ) : (
                <span className="fw-medium" style={{ color: 'var(--sub-3)' }}>
                  {EDeviceStatus.OFFLINE}
                </span>
              )}
              {isLoading && <Spin size="small" className="ms-1" />}
            </>
          );
        },
        width: '20%'
      },
      {
        title: () => t('serverPage.table.columns.accountEyeviewUser'),
        key: 'accountDevices',
        render: () => {
          return `${totalAccounts} / ${totalUsers}`;
        },
        width: '20%'
      }
    ];
  }, [isOnline, totalAccounts, totalUsers, isLoading]);

  return (
    <Table
      id="eyeviewSessionPage_listVRSServerTable"
      className={styles.container}
      rowKey="id"
      dataSource={[server]}
      rowHoverable={false}
      columns={columns}
      tableLayout="auto"
      scroll={{
        x: server ? 'auto' : 'min-content'
      }}
      pagination={false}
    />
  );
}

export default TableListVRSServer;
