import { TDataResponseEOSS, TDomain } from 'models';
import { IDomainRepository } from './DomainRepository';
import { IDomainDataSource } from 'data/Domain/DomainDataSource';
import { AxiosError } from 'axios';
import { mapCamelCaseToSnakeCase } from 'utils/common';
import { EHttpStatusCode, EStatusInvalid } from 'enums';
import { ApiErrorResponse, unknownError } from 'models/ApiError';
import { Either, Left, Right } from 'utils/either';

/**
 * DomainRepositoryImpl class that implements IDomainRepository interface.
 * @author thai.ngo@zien.vn
 */
export class DomainRepositoryImpl implements IDomainRepository {
  domainDataSource: IDomainDataSource;

  private static instance: DomainRepositoryImpl;

  constructor(domainDataSource: IDomainDataSource) {
    this.domainDataSource = domainDataSource;
  }

  /**
   * Get an instance of DomainRepositoryImpl if it exists, otherwise create a new instance.
   * @param {IDomainDataSource} domainDataSource - The data source for the domain.
   * @returns {DomainRepositoryImpl} An instance of DomainRepositoryImpl.
   */
  public static getInstance(domainDataSource: IDomainDataSource): DomainRepositoryImpl {
    if (!DomainRepositoryImpl.instance) {
      DomainRepositoryImpl.instance = new DomainRepositoryImpl(domainDataSource);
    }
    return DomainRepositoryImpl.instance;
  }

  async getDomainById(
    domainId: number
  ): Promise<Either<ApiErrorResponse | undefined, TDataResponseEOSS<TDomain>>> {
    try {
      const res = await this.domainDataSource.getDomainById(domainId);
      return Right.create(res);
    } catch (error) {
      if (error instanceof AxiosError) {
        const err = error as AxiosError<ApiErrorResponse>;
        return Left.create(err.response?.data);
      }

      return Left.create(unknownError);
    }
  }

  async addDomain(params: {
    name: string;
    comment: string | null;
    invalid: EStatusInvalid;
  }): Promise<Either<ApiErrorResponse | undefined, TDataResponseEOSS<TDomain>>> {
    try {
      const res = await this.domainDataSource.addDomain(mapCamelCaseToSnakeCase(params));
      return Right.create(res);
    } catch (error) {
      if (error instanceof AxiosError) {
        const err = error as AxiosError<ApiErrorResponse>;
        return Left.create(err.response?.data);
      }

      return Left.create(unknownError);
    }
  }

  async editDomain(
    id: number,
    params: {
      name: string;
      comment: string | null;
      invalid: EStatusInvalid;
    }
  ): Promise<Either<ApiErrorResponse | undefined, TDataResponseEOSS<TDomain>>> {
    try {
      const res = await this.domainDataSource.editDomain(id, mapCamelCaseToSnakeCase(params));
      return Right.create(res);
    } catch (error) {
      if (error instanceof AxiosError) {
        const err = error as AxiosError<ApiErrorResponse>;
        return Left.create(err.response?.data);
      }

      return Left.create(unknownError);
    }
  }

  async getListDomain({
    search,
    filterStatus
  }: {
    search?: string;
    filterStatus?: EStatusInvalid;
  }): Promise<TDataResponseEOSS<TDomain[]>> {
    return this.fetchAllDomainRemote({
      search,
      filterStatus
    });
  }

  async fetchAllDomainRemote({
    search,
    filterStatus
  }: {
    search?: string;
    filterStatus?: EStatusInvalid;
  }) {
    try {
      const res = await this.domainDataSource.getListDomain();
      return this.localSearchWildCardDomainsByName(res.data, {
        search,
        filterStatus
      });
    } catch (error) {
      const err = error as AxiosError;
      throw JSON.stringify({ message: err.message });
    }
  }

  private localSearchWildCardDomainsByName(
    listDomains: TDomain[],
    {
      search,
      filterStatus
    }: {
      search?: string;
      filterStatus?: EStatusInvalid;
    }
  ) {
    const searchText = search || '';
    let filteredDomainList: TDomain[] = listDomains;

    if (searchText) {
      const regexString = searchText
        .replace(/([.+?^=!:${}()|\\[\]\\/\\])/g, '\\$1') //handle for special character
        .replace(/\*/g, '.*');
      const regex = new RegExp(`^(${regexString})$`, 'i');

      filteredDomainList = listDomains.filter((domain) => regex.test(domain.name));
    }

    if (filterStatus !== undefined) {
      filteredDomainList = filteredDomainList.filter((domain) => domain.invalid === filterStatus);
    }

    const dataResp: TDataResponseEOSS<TDomain[]> = {
      code: EHttpStatusCode.OK,
      data: filteredDomainList
    };

    return dataResp;
  }
}
