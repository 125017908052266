import { Button, Form, Input } from 'antd';
import { AddIconNoOutline } from 'assets/icons';
import { EAdminTypeDisplayName, EStorageKey, Routes } from 'enums';
import { LeftColumn, PermissionWrapper, RightColumn, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useRef } from 'react';
import { useAccountPageController } from './AccountPageController';
import styles from './accountPage.module.scss';
import { useAppUtil } from 'context/UtilContext';
import useFlag from 'hooks/useFlag';
import { parseLocalUser } from 'utils/common';
import { useLoaderContext } from 'context/LoaderContext';

const AccountPageView = () => {
  const navigate = useNavigate();

  const { loader } = useLoaderContext();

  const {
    searchForm,
    serviceDeviceForm,
    referenceDeviceForm,
    actionStatus,
    account,
    refetchCountRef,
    handleClearForm,
    handleChangeAccount,
    onSearch,
    onAssign,
    onSearchChange,
    onResetActionStatus
  } = useAccountPageController();

  const { openModal } = useAppUtil();

  const [leftColumnOpen, markAsLeftColumnOpen, markAsLeftColumnClose] = useFlag(true);

  const toolTipRef = useRef<{
    onHideTooltip: () => void;
    onShowTooltip: () => void;
  }>();

  const [isSubmitting, markAsSubmitting, markAsNotSubmitting] = useFlag(false);
  const { openNotification } = useAppUtil();

  // 2024-02-09 Feedback: Create Action should be shown in both Listing and Detail page
  const EAccountRoutes = Routes.EAccountRoutes;
  const isInListingPage = useMatch(EAccountRoutes.LISTING);
  const isInDetailPage = useMatch(EAccountRoutes.DETAIL);
  // check create because useMatch understands /account/create as /account/:accountId
  const canShowCreateAction = isInListingPage || isInDetailPage?.pathname !== EAccountRoutes.CREATE;

  // ERRP-97: This variable is used for searching instruction which is displayed based on the admin type.
  const isCustomerDomainAdmin = useMemo(
    () =>
      parseLocalUser(localStorage.getItem(EStorageKey.EOSS_CURRENT_USER))?.adminTypeDisplayName ===
      EAdminTypeDisplayName.DOMAIN_ADMIN,
    []
  );

  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const onSearchSubmit = ({ search }: { search: string }) => {
    if (search) {
      onSearch(search);
    } else {
      navigate(Routes.EAccountRoutes.LISTING);
    }
  };

  const onAssignServiceDevice = ({ serviceDeviceSerial }: { serviceDeviceSerial: string }) => {
    if (serviceDeviceSerial.length > 0) {
      markAsSubmitting();
      openModal({
        title: t('components.confirmationTitle'),
        content: (
          <>
            <div>
              {t('userPage.confirmModal.assign')}
              {t('header.cameraGroups').toLowerCase()}{' '}
              <span className="fw-semibold">{serviceDeviceSerial}</span>
            </div>
            <div>
              {t('components.to')} {t('accountPage.entity').toLowerCase()}{' '}
              <span className="fw-semibold">{account?.name}</span>
              {'?'}
            </div>
          </>
        ),
        okText: t('components.ok'),
        cancelText: t('components.cancel'),
        styles: {
          content: {
            width: 'auto'
          }
        },
        width: 'fit-content',
        onOk: () => onAssign(serviceDeviceSerial.trim(), () => markAsNotSubmitting()),
        onCancel: () => markAsNotSubmitting()
      });
    }
  };

  const onAssignReferenceDevice = ({ serialNumber }: { serialNumber: string }) => {
    openNotification({
      type: 'info',
      title: t('components.comingSoon')
    });
  };

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    return () => {
      markAsLeftColumnOpen();
    };
  }, []);

  return (
    <div className={`wrap-container overflow-hidden ${styles.container}`}>
      <div className="h-100 w-100 position-relative">
        <LeftColumn
          withCollapse
          leftColumnOpen={leftColumnOpen}
          onMouseEnter={() => toolTipRef.current?.onShowTooltip()}
          onMouseMove={() => toolTipRef.current?.onShowTooltip()}
          onMouseLeave={() => toolTipRef.current?.onHideTooltip()}
        >
          <Section title={t('accountPage.sections.search.title')} classNameBody="p-4">
            <Form id="customerPage_searchAction" form={searchForm} onFinish={onSearchSubmit}>
              <Form.Item name="search" className="mb-2">
                <Input
                  type="search"
                  className={'search-input'}
                  allowClear
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onSearchChange(e.target.value)
                  }
                />
              </Form.Item>
              <div className="fs-12 fw-normal text-black mt-2 mb-3">
                {isCustomerDomainAdmin
                  ? t('accountPage.sections.search.instructionForDomainAdmin')
                  : t('accountPage.sections.search.instruction')}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="btn-action btn-fill"
                  htmlType="submit"
                  onClick={() => {
                    const event = new CustomEvent('close-sidebar-action');
                    document.dispatchEvent(event);
                  }}
                >
                  <span className="btn-content fs-14 fw-semibold">{t('components.search')}</span>
                </Button>
              </div>
            </Form>
          </Section>

          {canShowCreateAction && (
            <PermissionWrapper.Create>
              <Section title={t('accountPage.sections.create.title')} classNameBody="px-4 py-5">
                <Button
                  id="customerPage_createAction"
                  onClick={() => {
                    loader.start();
                    navigate(Routes.EAccountRoutes.CREATE);
                  }}
                  className="btn-create w-100 d-flex justify-content-center align-items-center cursor-pointer"
                >
                  <div className="h-100 d-flex align-items-center">
                    <AddIconNoOutline color="var(--primary-1)" />
                  </div>
                  <div className="btn-create-content d-flex align-items-center fs-14 fw-semibold ms-2 h-100">
                    {t('components.create')}
                  </div>
                </Button>
              </Section>
            </PermissionWrapper.Create>
          )}

          {/**
           * TODO: ERRP-135: Hide this action - request by Josh & Tom
           */}
          {/* {isShowAssignAction && (
                <PermissionWrapper.Update>
                  <Section
                    title={t('accountPage.sections.assignServiceDevice.title')}
                    classNameBody="px-4 pt-3 pb-4"
                  >
                    <Form form={serviceDeviceForm} onFinish={onAssignServiceDevice}>
                      <div className={`${styles.content} fs-12 fw-normal text-black mt-2 mb-3`}>
                        {t('accountPage.sections.assignServiceDevice.description')}{' '}
                        <span className="fw-medium">{account?.name}</span>
                      </div>
                      <Form.Item name="serviceDeviceSerial" className="mb-3">
                        <Input
                          className={`${styles.actionInputNoIcon}`}
                          placeholder={t('accountPage.sections.assignServiceDevice.placeholder')}
                        />
                      </Form.Item>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-action btn-fill"
                          htmlType="submit"
                          disabled={isSubmitting}
                          onClick={() => {
                            const event = new CustomEvent('close-sidebar-action');
                            document.dispatchEvent(event);
                          }}
                        >
                          <span className="fs-14 fw-semibold">{t('components.assign')}</span>
                        </Button>
                      </div>
                    </Form>
                  </Section>

                // Assign Reference Device Section is commented out because it is not used in EP1 - requested by Josh
                 <Section
                      title={t('accountPage.sections.assignReferenceDevice.title')}
                      classNameBody="px-4 pt-3 pb-4"
                    >
                      <Form form={referenceDeviceForm} onFinish={onAssignReferenceDevice}>
                        <div className="fs-12 fw-normal text-black mt-2 mb-3">
                          {t('accountPage.sections.assignReferenceDevice.description')}{' '}
                          <span className="fw-medium">{accountName}</span>
                        </div>
                        <Form.Item name="referenceDeviceSerial" className="mb-3">
                          <Input
                            className={`${styles.actionInputNoIcon} fs-12`}
                            placeholder={t('accountPage.sections.assignReferenceDevice.placeholder')}
                          />
                        </Form.Item>
                        <div className="d-flex justify-content-end">
                          <Button className="btn-action btn-fill" htmlType="submit">
                            <span className="fs-14 fw-semibold">{t('components.assign')}</span>
                          </Button>
                        </div>
                      </Form>
                    </Section> 
                </PermissionWrapper.Update>
              )} */}
        </LeftColumn>

        <RightColumn
          toolTipRef={toolTipRef}
          leftColumnOpen={leftColumnOpen}
          onSideBarClick={() => {
            leftColumnOpen ? markAsLeftColumnClose() : markAsLeftColumnOpen();
          }}
        >
          <Outlet
            context={{
              actionStatus,
              refetchCount: refetchCountRef.current,
              scrollToTop,
              handleChangeAccount,
              handleClearForm,
              onResetActionStatus
            }}
          />
        </RightColumn>
      </div>
    </div>
  );
};

export default AccountPageView;
