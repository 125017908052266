import { Outlet, useRouteLoaderData } from 'react-router-dom';
import { useCameraGroupDetailPageController } from './CameraGroupDetailPageController';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Routes } from 'enums';

function CameraGroupDetailPage() {
  const { t } = useTranslation();

  const currentCameraGroup = useRouteLoaderData(Routes.ECameraGroupRoutes.DETAILED);

  const { actionStatus, onChangeCurrentCamera, onResetActionStatus, resetBindForm } =
    useCameraGroupDetailPageController();

  if (!currentCameraGroup) {
    return (
      <Result
        status="404"
        title={t('cameraGroupPage.404.title')}
        subTitle={t('cameraGroupPage.404.subTitle')}
      />
    );
  }

  return (
    <Outlet
      context={{
        actionStatus,
        onChangeCurrentCamera,
        onResetActionStatus,
        resetBindForm
      }}
    />
  );
}

export default CameraGroupDetailPage;
