type Props = {
  color?: string;
};

export function AddIconNoOutline({ color = '#4A61E3' }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_2215_3436)">
        <path
          d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2215_3436">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
