import { TAxiosClient } from 'services/axios';
import { INameMappingDataSource } from './NameMappingDataSource';
import { TDynamicResponse } from 'models/DynamicResponse';

/**
 * Represents a remote data source implementation for handling name mapping data.
 * @author thai.ngo@zien.vn
 */
export class NameMappingRemoteDataSourceImpl implements INameMappingDataSource {
  httpService: TAxiosClient;

  private static instance: NameMappingRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  async getFieldDisplayNameMappings(): Promise<TDynamicResponse> {
    const response = await this.httpService.get<TDynamicResponse>('/fields/display/all');
    return response.data;
  }

  /**
   * Gets the singleton instance of NameMappingRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {NameMappingRemoteDataSourceImpl} The singleton instance of NameMappingRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): NameMappingRemoteDataSourceImpl {
    if (!NameMappingRemoteDataSourceImpl.instance) {
      NameMappingRemoteDataSourceImpl.instance = new NameMappingRemoteDataSourceImpl(httpService);
    }
    return NameMappingRemoteDataSourceImpl.instance;
  }
}
