import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEOSSContext } from 'context/EOSSContext';
import { orderBy } from 'lodash';
import { TServerType } from 'models';
import { Section } from 'presentation/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './tableServerType.module.scss';

function ServerTypePageView() {
  const { listServerType, refetchListServerType } = useEOSSContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const columns: ColumnsType<TServerType> = [
    {
      title: t('systemPage.table.columns.id'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (val) => (
        <span className="fs-13 fw-medium" style={{ color: 'var(--primary-1)' }}>
          {val}
        </span>
      )
    },
    {
      title: t('systemPage.table.columns.name'),
      dataIndex: 'name',
      key: 'name',
      render(value, record) {
        return (
          <span className="fs-13 fw-medium" style={{ color: 'var(--primary-1)' }}>
            {record.displayName} ({record.name})
          </span>
        );
      }
    }
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refetchListServerType();
      setIsLoading(false);
    })();
  }, []);

  return (
    <Section title={t('systemPage.sections.serverType.title')}>
      <Table
        id="systemPage_listServerTypesTable"
        className={styles.container}
        loading={isLoading}
        pagination={false}
        tableLayout="auto"
        rowHoverable={false}
        scroll={{
          x: listServerType.length > 0 ? 'auto' : 'min-content'
        }}
        columns={columns}
        rowKey="id"
        dataSource={isLoading ? [] : orderBy(listServerType, 'id', 'asc')}
        rowClassName={(_, index) => (index % 2 ? 'row-even' : 'row-odd')}
      />
    </Section>
  );
}

export default ServerTypePageView;
