export function CheckIcon() {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33333 8L0 4.59574L1.16667 3.40426L3.33333 5.61702L8.83333 0L10 1.19149L3.33333 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
