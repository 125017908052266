import { TDataResponseEOSS, TServer } from 'models';
import { TEyeviewUserConnected, TLogOptions } from 'models/EyeviewSession';
import { IEyeviewSessionRepository } from './EyeviewSessionReposiotry';
import { IEyeviewSessionDataSource } from 'data/EyeviewSession/EyeviewSessionDataSource';
import { IServerDataSource } from 'data/Server/ServerDataSource';
import { EHttpStatusCode } from 'enums';
import { convertAxiosErrorToApiError } from 'utils/common';

/**
 * Repository implementation for Eyeview session.
 * @author hung.nguyen@zien.vn
 */
export class EyeviewSessionRepositoryImpl implements IEyeviewSessionRepository {
  eyeviewSessionDataSource: IEyeviewSessionDataSource;

  serverDataSource: IServerDataSource;

  private static instance: EyeviewSessionRepositoryImpl;

  constructor(
    eyeviewSessionDataSource: IEyeviewSessionDataSource,
    serverDataSource: IServerDataSource
  ) {
    this.eyeviewSessionDataSource = eyeviewSessionDataSource;
    this.serverDataSource = serverDataSource;
  }

  /**
   * Get the singleton instance of EyeviewSessionRepositoryImpl.
   * If an instance does not exist, it creates a new one.
   * @param {IEyeviewSessionDataSource} eyeviewSessionDataSource - The data source for Eyeview session.
   * @param {IServerDataSource} serverDataSource - The data source for the server.
   * @returns {EyeviewSessionRepositoryImpl} The singleton instance of EyeviewSessionRepositoryImpl.
   */
  public static getInstance(
    eyeviewSessionDataSource: IEyeviewSessionDataSource,
    serverDataSource: IServerDataSource
  ): EyeviewSessionRepositoryImpl {
    if (!EyeviewSessionRepositoryImpl.instance) {
      EyeviewSessionRepositoryImpl.instance = new EyeviewSessionRepositoryImpl(
        eyeviewSessionDataSource,
        serverDataSource
      );
    }
    return EyeviewSessionRepositoryImpl.instance;
  }

  async getEyeviewUsersConnected({
    serverId
  }: {
    serverId: number;
  }): Promise<TDataResponseEOSS<TEyeviewUserConnected>> {
    try {
      const { data, code } = await this.eyeviewSessionDataSource.getEyeviewUsersConnected({
        serverId
      });
      return { code, data };
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getListVASServer(): Promise<TServer[]> {
    try {
      const VAS_TYPE_SERVER = 1;
      const { data } = await this.serverDataSource.getListServer({ typeId: VAS_TYPE_SERVER });
      return data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  /**
   * Logoff Account possible have two error NOT_FOUND and FORBIDDEN
   * @description
   * NOT_FOUND: serverId, AccountName -> The requested resource could not be found.
   * FORBIDDEN: You don't have permission to logout this Account
   */
  async logoutAccount({
    accountName,
    serverId
  }: {
    accountName: string;
    serverId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const status = await this.eyeviewSessionDataSource.logoutAccount({
        server_id: serverId,
        account_name: accountName
      });
      return status;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  /**
   * Logoff EyeviewUser possible have two errors: NOT_FOUND and FORBIDDEN
   * @description
   * NOT_FOUND: serverId, clientId -> The requested resource could not be found.
   * FORBIDDEN: You don't have permission to logout this Eyeview User
   */
  async logoutEyeviewUser({
    clientId,
    serverId
  }: {
    clientId: string;
    serverId: number;
  }): Promise<EHttpStatusCode> {
    try {
      const status = await this.eyeviewSessionDataSource.logoutEyeviewUser({
        server_id: serverId,
        client_id: clientId
      });
      return status;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async getLogOptions({ serverId }: { serverId: number }): Promise<TLogOptions> {
    try {
      const data = await this.eyeviewSessionDataSource.getLogOptions({
        serverId
      });
      return data;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }

  async updateLogOptions({
    serverId,
    options
  }: {
    serverId: number;
    options: TLogOptions;
  }): Promise<EHttpStatusCode> {
    try {
      const status = await this.eyeviewSessionDataSource.updateLogOptions({
        serverId,
        options
      });
      return status;
    } catch (error) {
      throw convertAxiosErrorToApiError(error);
    }
  }
}
