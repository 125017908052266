import { EHttpStatusCode, EStatusInvalid, EStatusString } from 'enums';
import {
  TAccountType,
  TAdminUserType,
  TCameraGroupType,
  TDeviceType,
  TLanguage,
  TPermission,
  TServerType,
  TService,
  TValidation
} from 'models';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { EOSSGlobalRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { useAuthContext } from './AuthContext';
import { TDynamicResponse } from 'models/DynamicResponse';
import { ApiErrorResponse } from 'models/ApiError';
import { useAppUtil } from './UtilContext';
import { handleApiError } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

interface IEOSSContext {
  listValidation: TValidation[];
  listAccountType: TAccountType[];
  listServerType: TServerType[];
  listDeviceType: TDeviceType[];
  listLanguage: TLanguage[];
  listPermission: TPermission[];
  listCameraGroupTypes: TCameraGroupType[];
  listServices: TService[];
  listAdminType: TAdminUserType[];
  fieldDisplayNameMappings: TDynamicResponse | undefined;

  refetchListDeviceType: () => void;
  refetchListPermission: () => void;
  refetchListAccountType: () => void;
  refetchListServerType: () => void;
  refetchListServices: () => void;
  refetchListLanguage: () => void;
  refetchListAdminType: () => void;
  refetchFieldDisplayName: () => void;

  initStatus: boolean;
  refetchInitCount: number;
}

const EOSSContext = createContext<IEOSSContext>(null!);
export function EOSSProvider({ children }: { children: React.ReactNode }) {
  const eossGlobalRepository = EOSSGlobalRepository(AxiosClient);
  const [listServerType, setListServerType] = useState<TServerType[]>([]);
  const [listAccountType, setListAccountType] = useState<TAccountType[]>([]);
  const [listDeviceType, setListDeviceType] = useState<TDeviceType[]>([]);
  const [listLanguage, setListLanguage] = useState<TLanguage[]>([]);
  const [listPermission, setListPermission] = useState<TPermission[]>([]);
  const [listServices, setListService] = useState<TService[]>([]);
  const [listAdminType, setListAdminType] = useState<TAdminUserType[]>([]);
  const [listCameraGroupTypes, setListCameraGroupTypes] = useState<TCameraGroupType[]>([]);
  const [fieldDisplayNameMappings, setFieldDisplayNameMappings] = useState<TDynamicResponse>();
  const { openNotification } = useAppUtil();
  const initStatus = useRef<boolean>(false);
  const refetchInitStatusCountRef = useRef<number>(0);
  const { t } = useTranslation();

  const { isLogged, logout } = useAuthContext();

  async function fetchListPermission() {
    try {
      if (listPermission.length) return EHttpStatusCode.OK;
      const { data } = await eossGlobalRepository.getListPermission();
      // if (_.isEqual(data, listPermission)) return;
      setListPermission(data);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.permission'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.permission')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchListServerType() {
    try {
      if (listServerType.length) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getListServerType();
      // if (_.isEqual(res, listServerType)) return;
      setListServerType(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.serverType'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.serverType')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchListAccountType() {
    try {
      if (listAccountType.length) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getListAccountType();
      // if (_.isEqual(res, listAccountType)) return;
      setListAccountType(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.accountType'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.accountType')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchListDeviceType() {
    try {
      if (listDeviceType.length) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getListDeviceType();
      // if (_.isEqual(res, listDeviceType)) return;
      setListDeviceType(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.deviceType'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.deviceType')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchListAdminType() {
    try {
      const res = await eossGlobalRepository.getListAdminType();
      if (isEqual(res, listAdminType)) return EHttpStatusCode.OK;
      setListAdminType(res);
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('forms.adminType.label'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('forms.adminType.label')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchListLanguage() {
    try {
      if (listLanguage.length) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getListLanguage();
      // if (_.isEqual(res, listLanguage)) return;
      setListLanguage(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.language'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.language')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchListServices() {
    try {
      if (listServices.length) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getListServices();
      // if (_.isEqual(res, listServices)) return;
      setListService(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.services'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.services')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchCameraGroupTypes() {
    try {
      if (listCameraGroupTypes.length) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getCameraGroupType();
      setListCameraGroupTypes(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('cameraGroupPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('cameraGroupPage.entity')}`,
        description: message
      });
      return err.status;
    }
  }

  async function fetchFieldDisplayNameMappings() {
    try {
      if (fieldDisplayNameMappings) return EHttpStatusCode.OK;
      const res = await eossGlobalRepository.getFieldDisplayNameMappings();
      setFieldDisplayNameMappings(res);
      return EHttpStatusCode.OK;
    } catch (error) {
      const err = error as ApiErrorResponse;
      const message = handleApiError({
        apiErrorResponse: err,
        action: 'get',
        entity: t('eossContext.fieldDisplayMapping'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.get')} ${t('eossContext.fieldDisplayMapping')}`,
        description: message
      });
      return err.status;
    }
  }

  async function initEOSSDataGlobal() {
    const [
      resAccountType,
      resDeviceType,
      resServerType,
      resServices,
      resLanguage,
      resPermission,
      resCameraGroupTypes
    ] = await Promise.allSettled([
      fetchListAccountType(),
      fetchListDeviceType(),
      fetchListServerType(),
      fetchListServices(),
      fetchListLanguage(),
      fetchListPermission(),
      fetchCameraGroupTypes(),
      fetchFieldDisplayNameMappings()
    ]);
    const isFetchAccountTypeSuccess =
      resAccountType.status === 'fulfilled' && resAccountType.value === EHttpStatusCode.OK;

    const isFetchDeviceTypeSuccess =
      resDeviceType.status === 'fulfilled' && resDeviceType.value === EHttpStatusCode.OK;

    const isFetchServerTypeSuccess =
      resServerType.status === 'fulfilled' && resServerType.value === EHttpStatusCode.OK;

    const isFetchServicesSuccess =
      resServices.status === 'fulfilled' && resServices.value === EHttpStatusCode.OK;

    const isFetchLanguageSuccess =
      resLanguage.status === 'fulfilled' && resLanguage.value === EHttpStatusCode.OK;

    const isFetchPermissionSuccess =
      resPermission.status === 'fulfilled' && resPermission.value === EHttpStatusCode.OK;

    const isFetchCameraGroupSuccess =
      resCameraGroupTypes.status === 'fulfilled' &&
      resCameraGroupTypes.value === EHttpStatusCode.OK;

    const isSuccessfully =
      isFetchAccountTypeSuccess &&
      isFetchDeviceTypeSuccess &&
      isFetchServerTypeSuccess &&
      isFetchServicesSuccess &&
      isFetchLanguageSuccess &&
      isFetchPermissionSuccess &&
      isFetchCameraGroupSuccess;

    if (isSuccessfully) {
      initStatus.current = true;
    } else {
      initStatus.current = false;
      refetchInitStatusCountRef.current += 1;
      logout();
    }
  }

  useEffect(() => {
    if (isLogged) {
      initEOSSDataGlobal();
    }
  }, [isLogged]);

  const value: IEOSSContext = {
    listValidation: [
      {
        id: EStatusInvalid.VALID,
        name: EStatusString.VALID
      },
      {
        id: EStatusInvalid.INVALID,
        name: EStatusString.INVALID
      }
    ],
    listAccountType,
    listServerType,
    listDeviceType,
    listLanguage,
    listPermission,
    listServices,
    listCameraGroupTypes,
    listAdminType,
    fieldDisplayNameMappings,
    initStatus: initStatus.current,
    refetchInitCount: refetchInitStatusCountRef.current,
    refetchListDeviceType: fetchListDeviceType,
    refetchListPermission: fetchListPermission,
    refetchListAccountType: fetchListAccountType,
    refetchListServerType: fetchListServerType,
    refetchListServices: fetchListServices,
    refetchListLanguage: fetchListLanguage,
    refetchFieldDisplayName: fetchFieldDisplayNameMappings,
    refetchListAdminType: fetchListAdminType
  };

  return <EOSSContext.Provider value={value}>{children}</EOSSContext.Provider>;
}

export function useEOSSContext() {
  return useContext(EOSSContext);
}
