import { Form, Result } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserGeneral from '../../components/UserGeneral';
import UserPermission from '../../components/UserPermission';
import styles from './editEyeviewUserPage.module.scss';
import { useEditEyeviewUserPageController } from './EditEyeviewUserPageController';
import { TEyeviewUserField } from '../../EyeviewUserPageController';
import { TPutEyeviewUserPayload } from 'repositories/EyeviewUser/EyeviewUserRepository';
import { useForm } from 'antd/es/form/Form';
import { orderBy } from 'lodash';
import { FormAction, Section } from 'presentation/components';
import { ERoleKey, ESubFeatureKey, Routes } from 'enums';
import { useAppUtil } from 'context/UtilContext';
import { usePathPermission } from 'hooks/usePermission';
import TableDeviceGroup from '../../components/TableDeviceGroupAssignment';

export default function EditEyeviewUserPageView() {
  const { t } = useTranslation();

  const { openModal } = useAppUtil();

  const [editEyeviewUserForm] = useForm<TEyeviewUserField>();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    currentUser,
    currentEyeviewUser,
    selectedPermission,
    accountList,
    dataPermission,
    listValidation,
    listLanguage,
    listCameraGroupAssignment,
    listCameraGroupRowSelected,
    addPermission,
    removePermission,
    onFormSubmit,
    onCancel,
    onListCameraGroupRowSelectedChange
  } = useEditEyeviewUserPageController();

  const allowedAssignEyeviewUserToCameraGroup = usePathPermission(
    Routes.ECameraGroupRoutes.LISTING,
    ERoleKey.UPDATE,
    ESubFeatureKey.ASSIGNMENT
  ).allowed;

  if (!currentUser) {
    return (
      <Result
        status={'404'}
        title={t('userPage.404.title')}
        subTitle={t('userPage.404.subTitle')}
      />
    );
  }

  const handleFormSubmit = async (value: TEyeviewUserField) => {
    const data: TPutEyeviewUserPayload = {
      accountId: currentEyeviewUser.accountId,
      address: JSON.stringify({
        street: (value?.street ?? '').trim(),
        city: (value?.city ?? '').trim(),
        state: (value?.state ?? '').trim(),
        zip: (value?.zip ?? '').trim()
      }),
      comment: (value?.comment ?? '').trim(),
      contact: JSON.stringify({
        email: (value?.email ?? '').trim(),
        phone: (value?.phone ?? '').trim()
      }),
      firstName: value.firstName.trim(),
      invalid: value.invalid,
      languageId: value.languageId,
      lastName: value.lastName.trim(),
      password: value.password,
      permissionIds: selectedPermission.map((p) => p.id).toString()
    };
    await onFormSubmit(data);
  };

  const handleConfirmSubmit = (values: TEyeviewUserField) => {
    setIsSubmitting(true);
    openModal({
      title: t('components.confirmationTitle'),
      content: t('components.confirmationMessage', {
        action: t('actions.update').toLowerCase(),
        entity: t('header.user').toLowerCase()
      }),
      okText: t('components.ok'),
      cancelText: t('components.cancel'),
      styles: {
        content: {
          width: 340
        }
      },
      onOk: async () => {
        await handleFormSubmit(values);
        setIsSubmitting(false);
      },
      onCancel: () => setIsSubmitting(false)
    });
  };

  useEffect(() => {
    if (
      currentEyeviewUser &&
      accountList.data.length > 0 &&
      listValidation.length > 0 &&
      listLanguage.length > 0
    ) {
      editEyeviewUserForm.setFieldsValue({
        accountId: currentEyeviewUser.account.name,
        username: currentEyeviewUser.username,
        firstName: currentEyeviewUser.firstName,
        lastName: currentEyeviewUser.lastName,
        comment: currentEyeviewUser.comment ?? '',
        invalid: currentEyeviewUser.invalid,
        languageId: currentEyeviewUser.language.id,
        city: currentEyeviewUser.address?.city ?? '',
        email: currentEyeviewUser.contact?.email ?? '',
        state: currentEyeviewUser.address?.state ?? '',
        phone: currentEyeviewUser.contact?.phone ?? '',
        zip: currentEyeviewUser.address?.zip ?? '',
        street: currentEyeviewUser.address?.street ?? ''
      });
    }
  }, [currentEyeviewUser, accountList, listValidation, listLanguage]);

  useEffect(() => {
    const findRow = document.querySelector('.new-group');
    if (findRow) {
      findRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [listCameraGroupAssignment]);

  return (
    <div className={`${styles.container} h-100 animate__animated animate__fadeIn`}>
      <Form
        form={editEyeviewUserForm}
        className="row gx-0 gx-md-5"
        colon={false}
        labelCol={{
          style: {
            width: 135
          }
        }}
        scrollToFirstError={{ behavior: 'smooth', block: 'center', scrollMode: 'if-needed' }}
        autoComplete="off"
        layout="horizontal"
        onFinish={(value: TEyeviewUserField) => handleConfirmSubmit(value)}
      >
        <div className="col-12 col-slg-8">
          <UserGeneral
            editMode
            disableFields={['accountId', 'username']}
            accountList={{
              data: accountList.data,
              loading: accountList.loading
            }}
            validationList={listValidation}
          />
        </div>

        <div className="col-12 col-slg-4">
          <UserPermission
            dataPermission={dataPermission}
            addPermission={addPermission}
            removePermission={removePermission}
            listPermission={selectedPermission}
          />
          <div className={styles.userCameraGroup}>
            <Section title={t('userPage.sections.cameraGroup.title')}>
              <TableDeviceGroup
                listCameraGroupAssignment={{
                  data: orderBy(listCameraGroupAssignment.data, 'name', 'asc'),
                  loading: listCameraGroupAssignment.loading
                }}
                listCameraGroupRowSelected={listCameraGroupRowSelected}
                onListCameraGroupRowSelectedChange={onListCameraGroupRowSelectedChange}
                allowedAssignEyeviewUserToCameraGroup={allowedAssignEyeviewUserToCameraGroup}
              />
            </Section>
          </div>
        </div>

        <FormAction
          onCancel={onCancel}
          SubmitTypographyProps={{
            htmlType: 'submit',
            disabled: isSubmitting
          }}
        />
      </Form>
    </div>
  );
}
