/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import dayjs from 'dayjs';
import { EStorageKey } from 'enums';
import { ApiErrorResponse, disabledAuthError, expiredTokenError } from 'models/ApiError';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL
});

function onRequest(config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> {
  const expTime = localStorage.getItem(EStorageKey.EOSS_EXP_TIME);
  const expired = dayjs.unix(Number(expTime)).isBefore(dayjs());
  if (expTime && expired) {
    const currUser = localStorage.getItem(EStorageKey.EOSS_CURRENT_USER);
    const userDataFromLocal = JSON.parse(currUser || '{}');
    sessionStorage.setItem(EStorageKey.EOSS_CURRENT_USER, JSON.stringify(userDataFromLocal));
    sessionStorage.setItem(
      EStorageKey.REDIRECT_TO,
      `${window.location.pathname}${window.location.search}`
    );
    sessionStorage.setItem(EStorageKey.AUTH_ERROR, expiredTokenError.error);
    localStorage.clear();
    window.location.replace('/login');
  }
  const token = localStorage.getItem(EStorageKey.EOSS_TOKEN);
  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  } else {
    if (!window.location.href.includes('/login')) {
      window.location.reload();
    }
  }

  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

async function onResponseError(
  error: AxiosError<ApiErrorResponse>
): Promise<AxiosError<ApiErrorResponse>> {
  // let isRefreshToken = false;
  // const originalRequest = error.config;
  // if (
  //   error.response?.status === EHttpStatusCode.FORBIDDEN &&
  //   originalRequest &&
  //   localStorage.getItem(EStorageKey.EOSS_TOKEN) &&
  //   !isRefreshToken
  // ) {
  //   isRefreshToken = true;
  //   localStorage.clear();
  //   sessionStorage.setItem(
  //     EStorageKey.REDIRECT_TO,
  //     `${window.location.pathname}${window.location.search}`
  //   );
  //   window.location.replace('/login');
  //   isRefreshToken = false;
  // let isRefreshToken = false;
  // const originalRequest = error.config;
  // if (
  //   error.response?.status === EHttpStatusCode.FORBIDDEN &&
  //   originalRequest &&
  //   localStorage.getItem(EStorageKey.EOSS_TOKEN) &&
  //   !isRefreshToken
  // ) {
  //   isRefreshToken = true;
  //   localStorage.clear();
  //   sessionStorage.setItem(
  //     EStorageKey.REDIRECT_TO,
  //     `${window.location.pathname}${window.location.search}`
  //   );
  //   window.location.replace('/login');
  //   isRefreshToken = false;

  //   return axios(originalRequest);
  // }

  if (error.response) {
    const apiError = error.response.data;

    // Process errors based on their type
    const isAdminDisabled =
      apiError.error === disabledAuthError.error && apiError.status === disabledAuthError.status;

    if (apiError.error === expiredTokenError.error || isAdminDisabled) {
      const currUser = localStorage.getItem(EStorageKey.EOSS_CURRENT_USER);
      const userDataFromLocal = JSON.parse(currUser || '{}');
      sessionStorage.setItem(EStorageKey.EOSS_CURRENT_USER, JSON.stringify(userDataFromLocal));
      sessionStorage.setItem(
        EStorageKey.REDIRECT_TO,
        `${window.location.pathname}${window.location.search}`
      );
      sessionStorage.setItem(EStorageKey.AUTH_ERROR, apiError.error);
      localStorage.clear();
      window.location.replace('/login');
    } else {
      return Promise.reject(error);
    }
  }

  // Re-throw to allow component-level handling if needed
  return Promise.reject({
    ...error,
    message: 'Something went wrong with your request!'
  });
}

instance.interceptors.request.use(onRequest, onRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

type HTTPRequestConfig = AxiosRequestConfig;

const api = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: HTTPRequestConfig = {}) => {
      return axios.get<T>(url, config);
    },
    delete: <T>(url: string, config: HTTPRequestConfig = {}) => {
      return axios.delete<T>(url, config);
    },
    put: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) => {
      return axios.put<T>(url, body, config);
    },
    patch: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) => {
      return axios.patch<T>(url, body, config);
    },
    post: <T>(url: string, body: unknown, config: HTTPRequestConfig = {}) => {
      return axios.post<T>(url, body, config);
    }
  };
};

export const AxiosClient = api(instance);

export type TAxiosClient = typeof AxiosClient;
