type Props = {
  color?: string;
};

export function DropdownIcon({ color = '#4A61E3' }: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1_4852" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1_4852)">
        <path d="M10.0007 12.4997L5.83398 8.33301H14.1673L10.0007 12.4997Z" fill={color} />
      </g>
    </svg>
  );
}
