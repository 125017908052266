import { EmptyPage, Pagination, Section } from 'presentation/components';
import { useTranslation } from 'react-i18next';
import { useAdminListingPageController } from './AdminListingPageController';
import TableListAdmin from './components/TableListAdmin';
import { AdminUserEmptyIcon } from 'assets/icons';

function AdminListingPageView() {
  const { listAdminUsers, order, searchText, sortKey, searchParams, setSearchParams } =
    useAdminListingPageController();
  const { t } = useTranslation();

  function handleOnChangeSort(order?: string | null, columnKey?: string) {
    if (columnKey && order) {
      searchParams.set('sortKey', columnKey?.toString());
      searchParams.set('order', order);
    } else {
      searchParams.delete('sortKey');
      searchParams.delete('order');
    }
    setSearchParams(searchParams);
  }

  return (
    <>
      <Section title={t('adminUserPage.sections.listing.title')}>
        <EmptyPage
          showEmpty={!searchText}
          title={t('components.getStarted')}
          subTitle={t('adminUserPage.sections.listing.emptyDescription')}
          emptyIcon={<AdminUserEmptyIcon />}
        >
          <TableListAdmin
            isLoading={listAdminUsers.isLoading}
            listAdminUsers={listAdminUsers.data}
            onChangeSort={handleOnChangeSort}
            sortKey={sortKey}
            order={order}
            searchText={searchText}
            paging={listAdminUsers.paging}
          />
        </EmptyPage>
      </Section>
      {Boolean(listAdminUsers.paging.total) && (
        <Pagination
          total={listAdminUsers.paging.total}
          pageSize={listAdminUsers.paging.pageLimit}
          initPageNumber={listAdminUsers.paging.pageNum}
          onChange={(page: number) => {
            searchParams.set('page', page.toString());
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}

export default AdminListingPageView;
