export const AccountPageEmptyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <path
        d="M80.5 83C82.1225 83 83.512 82.412 84.6684 81.236C85.8228 80.062 86.4 78.65 86.4 77C86.4 75.35 85.8228 73.937 84.6684 72.761C83.512 71.587 82.1225 71 80.5 71C78.8775 71 77.489 71.587 76.3346 72.761C75.1782 73.937 74.6 75.35 74.6 77C74.6 78.65 75.1782 80.062 76.3346 81.236C77.489 82.412 78.8775 83 80.5 83ZM68.7 95H92.3V93.35C92.3 91.1 91.2183 89.312 89.055 87.986C86.8917 86.662 84.04 86 80.5 86C76.96 86 74.1083 86.662 71.945 87.986C69.7817 89.312 68.7 91.1 68.7 93.35V95ZM56.9 104C55.2775 104 53.889 103.413 52.7346 102.239C51.5782 101.063 51 99.65 51 98V62C51 60.35 51.5782 58.938 52.7346 57.764C53.889 56.588 55.2775 56 56.9 56H74.6L80.5 62H104.1C105.722 62 107.112 62.588 108.268 63.764C109.423 64.938 110 66.35 110 68V98C110 99.65 109.423 101.063 108.268 102.239C107.112 103.413 105.722 104 104.1 104H56.9Z"
        fill="#888C90"
      />
    </svg>
  );
};
