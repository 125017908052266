import { Collapse } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './passwordCriteriaChecking.module.scss';
import { FaCheck } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { ExpandIcon } from 'assets/icons';
import { CONTAINER_NUMBER, PASSWORD_SPECIAL_CHARACTER, UPPERCASE_AND_LOWERCASE } from 'constant';

type Props = {
  value: string;
};

const ACTIVE_KEY = '1';

const PasswordCriteriaChecking = React.memo(({ value }: Props) => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState<string[]>([]);

  const testMinimumOf10Characters = useCallback(
    (value?: string) => (value && value.length > 0 ? value.length >= 10 : true),
    []
  );

  const testAtLeastBothUppercaseAndLowercase = useCallback(
    (value?: string) => (value && value.length > 0 ? UPPERCASE_AND_LOWERCASE.test(value) : true),
    []
  );

  const testAtLeastOneNumber = useCallback(
    (value?: string) => (value && value.length > 0 ? CONTAINER_NUMBER.test(value) : true),
    []
  );

  const testSpecialCharacter = useCallback((value?: string) => {
    if (value && value.length > 0) {
      // Replace all uppercase, lowercase, space and number in the value string
      const specialCharacterStr = value.replaceAll(/[A-Za-z0-9 ]/g, '');
      return specialCharacterStr.length > 0 && PASSWORD_SPECIAL_CHARACTER.test(specialCharacterStr);
    }
    return true;
  }, []);

  useEffect(() => {
    if (
      value &&
      value.length > 0 &&
      activeKey.length === 0 &&
      (!testMinimumOf10Characters(value) ||
        !testAtLeastBothUppercaseAndLowercase(value) ||
        !testAtLeastOneNumber(value) ||
        !testSpecialCharacter(value))
    ) {
      setActiveKey([ACTIVE_KEY]);
    }
  }, [value]);

  const testNotContainSpace = useCallback(
    (value?: string) => (value && value.length > 0 ? !value.includes(' ') : true),
    []
  );

  return (
    <Collapse
      expandIconPosition="end"
      bordered={false}
      collapsible="icon"
      className={`${styles.collapseContainer} border rounded`}
      style={{ background: 'var(--primary-4)' }}
      activeKey={activeKey}
      expandIcon={(panelProps) => {
        return (
          <ExpandIcon
            onClick={() => {
              if (activeKey) {
                if (activeKey.length > 0) {
                  setActiveKey([]);
                } else {
                  setActiveKey([ACTIVE_KEY]);
                }
              }
            }}
            expanded={panelProps.isActive || false}
          />
        );
      }}
      ghost
      items={[
        {
          key: ACTIVE_KEY,
          label: (
            <span className={styles.passwordCriteria}>
              {`${t('components.passwordCriteria')}${activeKey.length > 0 ? ':' : ''}`}
            </span>
          ),
          children: (
            <div id="passwordCriteriaCheckingComponent" className={styles.container}>
              <div
                data-testid="minimum10Characters"
                className={`${styles.passwordHint} ${
                  value && value.length > 0
                    ? testMinimumOf10Characters(value)
                      ? 'text-success'
                      : 'text-danger'
                    : ''
                }`}
              >
                {value && value.length > 0 ? (
                  testMinimumOf10Characters(value) ? (
                    <FaCheck color="green" />
                  ) : (
                    <FaXmark color="red" size={16} />
                  )
                ) : (
                  <FaCheck />
                )}
                <span>{t('components.rules.password.leastCharacters')}</span>
              </div>
              <div
                data-testid="atLeastBothUppercaseAndLowercase"
                className={`${styles.passwordHint} ${
                  value && value.length > 0
                    ? testAtLeastBothUppercaseAndLowercase(value)
                      ? 'text-success'
                      : 'text-danger'
                    : ''
                }`}
              >
                {value && value.length > 0 ? (
                  testAtLeastBothUppercaseAndLowercase(value) ? (
                    <FaCheck color="green" />
                  ) : (
                    <FaXmark color="red" size={16} />
                  )
                ) : (
                  <FaCheck />
                )}
                <span>{t('components.rules.password.textTransform')}</span>
              </div>
              <div
                data-testid="atLeastOneNumber"
                className={`${styles.passwordHint} ${
                  value && value.length > 0
                    ? testAtLeastOneNumber(value)
                      ? 'text-success'
                      : 'text-danger'
                    : ''
                }`}
              >
                {value && value.length > 0 ? (
                  testAtLeastOneNumber(value) ? (
                    <FaCheck color="green" />
                  ) : (
                    <FaXmark color="red" size={16} />
                  )
                ) : (
                  <FaCheck />
                )}
                <span>{t('components.rules.password.letters')}</span>
              </div>
              <div
                data-testid="specialCharacters"
                className={`${styles.passwordHint} ${
                  value && value.length > 0
                    ? testSpecialCharacter(value)
                      ? 'text-success'
                      : 'text-danger'
                    : ''
                }`}
              >
                {value && value.length > 0 ? (
                  testSpecialCharacter(value) ? (
                    <FaCheck color="green" />
                  ) : (
                    <FaXmark color="red" size={16} />
                  )
                ) : (
                  <FaCheck />
                )}
                <span>{t('components.rules.password.specialCharacters')}</span>
              </div>
              <div
                data-testid="notContainSpace"
                className={`${styles.passwordHint} ${
                  value && value.length > 0
                    ? testNotContainSpace(value)
                      ? 'text-success'
                      : 'text-danger'
                    : ''
                }`}
              >
                {value && value.length > 0 ? (
                  testNotContainSpace(value) ? (
                    <FaCheck color="green" />
                  ) : (
                    <FaXmark color="red" size={16} />
                  )
                ) : (
                  <FaCheck />
                )}
                <span>{t('components.rules.password.spaceNotAllowed')}</span>
              </div>
            </div>
          )
        }
      ]}
    />
  );
});

export { PasswordCriteriaChecking };
