import orderBy from 'lodash/orderBy';
import { TDataResponseEOSS } from 'models';
import { TServer } from 'models/Server';
import { TAxiosClient } from 'services/axios';
import { mapSnakeCaseToCamelCase } from 'utils/common';
import { IServerDataSource } from './ServerDataSource';

/**
 * Represents the data transfer object for a server.
 * @interface {Object} TServerDTO
 * @property {number} id - The ID of the server.
 * @property {string} name - The name of the server.
 * @property {string} description - The description of the server.
 * @property {string} serial_number - The serial number of the server.
 * @property {string | null} software_version - The software version of the server.
 * @property {string} usage - The usage of the server.
 * @property {string} ip - The IP address of the server.
 * @property {string} url - The URL of the server.
 * @property {string | null} comment - Any additional comments about the server.
 */
export type TServerDTO = {
  id: number;
  name: string;
  description: string;
  serial_number: string;
  software_version: string | null;
  usage: string;
  ip: string;
  url: string;
  comment: string | null;
};

/**
 * Represents a remote data source implementation for server-related data.
 * Implements the IServerDataSource interface.
 * @author thai.ngo@zien.vn
 */
export class ServerRemoteDataSourceImpl implements IServerDataSource {
  httpService: TAxiosClient;

  private static instance: ServerRemoteDataSourceImpl;

  /**
   * Constructs a new ServerRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   */
  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets an instance of ServerRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {ServerRemoteDataSourceImpl} An instance of ServerRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): ServerRemoteDataSourceImpl {
    if (!ServerRemoteDataSourceImpl.instance) {
      ServerRemoteDataSourceImpl.instance = new ServerRemoteDataSourceImpl(httpService);
    }
    return ServerRemoteDataSourceImpl.instance;
  }

  /**
   * Retrieves a list of servers based on the provided type ID.
   * @param {Object} options - The options object.
   * @param {number} options.typeId - The type ID to filter the servers by.
   * @returns {Promise<TDataResponseEOSS<TServer[]>>} A promise that resolves to a data response object containing an array of servers.
   */
  async getListServer({ typeId }: { typeId?: number }): Promise<TDataResponseEOSS<TServer[]>> {
    const { data, status } = await this.httpService.get<TServerDTO[]>(`/servers/type/${typeId}`);

    return {
      ...data,
      data: orderBy(data, 'name', 'asc').map((value) => mapSnakeCaseToCamelCase(value)),
      code: status
    };
  }
}
