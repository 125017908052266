import React from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

type Props = {
  isActive?: boolean;
};

function ArrowScrollRightIcon({ isActive = true }: Props) {
  return (
    <MdOutlineKeyboardArrowRight
      className={`scroll-icon hover-right ${isActive ? '' : 'scroll-icon-disable'} `}
      fontSize={36}
      style={{
        padding: '2px',
        borderRadius: '50%'
      }}
    />
  );
}

export { ArrowScrollRightIcon };
