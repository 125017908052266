export const CameraGroupEmptyIcon = () => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <g opacity="0.5">
        <mask
          id="mask0_1_33078"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="35"
          y="30"
          width="89"
          height="89"
        >
          <rect x="35" y="30" width="89" height="89" fill="#4A61E3" />
        </mask>
        <g mask="url(#mask0_1_33078)">
          <path
            d="M57.2493 107.875C53.1702 107.875 49.6782 106.423 46.7733 103.518C43.8684 100.613 42.416 97.1208 42.416 93.0417C42.416 88.9625 43.8684 85.4705 46.7733 82.5656C49.6782 79.6608 53.1702 78.2083 57.2493 78.2083C61.3285 78.2083 64.8205 79.6608 67.7254 82.5656C70.6303 85.4705 72.0827 88.9625 72.0827 93.0417C72.0827 97.1208 70.6303 100.613 67.7254 103.518C64.8205 106.423 61.3285 107.875 57.2493 107.875ZM101.749 107.875C97.6702 107.875 94.1782 106.423 91.2733 103.518C88.3684 100.613 86.916 97.1208 86.916 93.0417C86.916 88.9625 88.3684 85.4705 91.2733 82.5656C94.1782 79.6608 97.6702 78.2083 101.749 78.2083C105.829 78.2083 109.321 79.6608 112.225 82.5656C115.13 85.4705 116.583 88.9625 116.583 93.0417C116.583 97.1208 115.13 100.613 112.225 103.518C109.321 106.423 105.829 107.875 101.749 107.875ZM79.4993 70.7917C75.4202 70.7917 71.9282 69.3392 69.0233 66.4344C66.1184 63.5295 64.666 60.0375 64.666 55.9583C64.666 51.8792 66.1184 48.3872 69.0233 45.4823C71.9282 42.5774 75.4202 41.125 79.4993 41.125C83.5785 41.125 87.0705 42.5774 89.9754 45.4823C92.8802 48.3872 94.3327 51.8792 94.3327 55.9583C94.3327 60.0375 92.8802 63.5295 89.9754 66.4344C87.0705 69.3392 83.5785 70.7917 79.4993 70.7917Z"
            fill="#21272F"
          />
        </g>
      </g>
    </svg>
  );
};
