import React from 'react';

export function DeviceEmptyIcon() {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="80" cy="80" r="80" fill="#CCCCCC" />
      <g opacity="0.5">
        <mask
          id="mask0_1150_13302"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="39"
          y="39"
          width="80"
          height="80"
        >
          <rect x="39" y="39" width="80" height="80" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1150_13302)">
          <path
            d="M69 89V69H89V89H69ZM75.6667 82.3333H82.3333V75.6667H75.6667V82.3333ZM69 109V102.333H62.3333C60.5 102.333 58.9311 101.681 57.6267 100.377C56.32 99.07 55.6667 97.5 55.6667 95.6667V89H49V82.3333H55.6667V75.6667H49V69H55.6667V62.3333C55.6667 60.5 56.32 58.9311 57.6267 57.6267C58.9311 56.32 60.5 55.6667 62.3333 55.6667H69V49H75.6667V55.6667H82.3333V49H89V55.6667H95.6667C97.5 55.6667 99.07 56.32 100.377 57.6267C101.681 58.9311 102.333 60.5 102.333 62.3333V69H109V75.6667H102.333V82.3333H109V89H102.333V95.6667C102.333 97.5 101.681 99.07 100.377 100.377C99.07 101.681 97.5 102.333 95.6667 102.333H89V109H82.3333V102.333H75.6667V109H69ZM95.6667 95.6667V62.3333H62.3333V95.6667H95.6667Z"
            fill="#21272F"
          />
        </g>
      </g>
    </svg>
  );
}
