import { Result } from 'antd';
import { useAuthContext } from 'context/AuthContext';
import { Routes } from 'enums';
import { Link, Navigate } from 'react-router-dom';

function NotFoundPageView() {
  const { isLogged } = useAuthContext();
  if (!isLogged) {
    return <Navigate to={Routes.EAuthRoutes.LOGIN_PAGE} />;
  }
  return (
    <div className="animate__animated animate__fadeIn h-100 d-flex justify-content-center align-content-center">
      <Result
        status={'404'}
        title="404!"
        subTitle="Something wrong with url"
        className="text-center m-auto"
      >
        <Link to={'/'} replace>
          Go home
        </Link>
      </Result>
    </div>
  );
}

export default NotFoundPageView;
