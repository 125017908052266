import { TDataResponseEOSS, TDeviceType } from 'models';
import { TAxiosClient } from 'services/axios';
import { IDeviceTypeDataSource } from './DeviceTypeDataSource';
import { mapSnakeCaseToCamelCase } from 'utils/common';

/**
 * Represents a device type data transfer object (DTO).
 *
 * @interface {Object} TDeviceTypeDTO
 * @property {string} display_name - Human-readable name for the device type.
 * @property {number} id - Unique numeric identifier for the device type.
 * @property {string} name - Machine-readable name for the device type.
 * @property {number} num_cams - Number of cameras supported by this device type.
 * @property {string|null} resolution_link -  Link to a resource detailing the supported resolutions for this device type (optional).
 */
type TDeviceTypeDTO = {
  display_name: string;
  id: number;
  name: string;
  num_cams: number;
  resolution_link: string | null;
};

/**
 * Represents a remote data source implementation for handling device type data.
 * @author hung.nguyen@zien.vn
 */
export class DeviceTypeRemoteDataSourceImpl implements IDeviceTypeDataSource {
  httpService: TAxiosClient;

  private static instance: DeviceTypeRemoteDataSourceImpl;

  constructor(httpService: TAxiosClient) {
    this.httpService = httpService;
  }

  /**
   * Gets the singleton instance of DeviceTypeRemoteDataSourceImpl.
   * @param {TAxiosClient} httpService - The Axios client service.
   * @returns {DeviceTypeRemoteDataSourceImpl} The singleton instance of DeviceTypeRemoteDataSourceImpl.
   */
  public static getInstance(httpService: TAxiosClient): DeviceTypeRemoteDataSourceImpl {
    if (!DeviceTypeRemoteDataSourceImpl.instance) {
      DeviceTypeRemoteDataSourceImpl.instance = new DeviceTypeRemoteDataSourceImpl(httpService);
    }
    return DeviceTypeRemoteDataSourceImpl.instance;
  }

  async getListDeviceType(): Promise<TDeviceType[]> {
    const { data } = await this.httpService.get<TDeviceTypeDTO[]>('/device/types/all');
    return data.map((val) => mapSnakeCaseToCamelCase(val));
  }

  async addDeviceType({
    name,
    comments
  }: {
    name: string;
    comments?: string | undefined;
  }): Promise<TDeviceType> {
    const { data } = await this.httpService.post<TDataResponseEOSS<TDeviceType>>(
      '/admin/device-type',
      {
        name,
        comments
      }
    );
    return data.data;
  }

  async updateDeviceType({
    deviceTypeId,
    name,
    validId,
    comments
  }: {
    name: string;
    comments?: string | undefined;
    deviceTypeId: number;
    validId: number;
  }): Promise<TDeviceType> {
    const { data } = await this.httpService.put<TDataResponseEOSS<TDeviceType>>(
      `/admin/device-type/${deviceTypeId}`,
      { name, comments, valid_id: validId }
    );
    return data.data;
  }

  async getDeviceTypeDetail({ deviceTypeId }: { deviceTypeId: number }): Promise<TDeviceType> {
    const { data } = await this.httpService.get<TDataResponseEOSS<TDeviceType>>(
      `/admin/device-type/${deviceTypeId}`
    );

    return data.data;
  }
}
