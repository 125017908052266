import { TAxiosClient } from 'services/axios';

import { AccountRemoteDataSourceImpl } from 'data/Account/AccountRemoteDataSourceImpl';
import { AccountTypeRemoteDataSourceImpl } from 'data/AccountType/AccountTypeRemoteDataSourceImpl';
import { AdministratorRemoteDataSourceImpl } from 'data/Administrator/AdministratorRemoteDataSourceImpl';
import { AuthLocalDataSourceImpl } from 'data/Auth/AuthLocalDataSourceImpl';
import { AuthRemoteDataSourceImpl } from 'data/Auth/AuthRemoteDataSourceImpl';
import { CameraGroupRemoteDataSourceImpl } from 'data/CameraGroup/CameraGroupRemoteDataSourceImpl';
import { DeviceRemoteDataSourceImpl } from 'data/Device/DeviceRemoteDataSourceImpl';
import { DeviceTypeRemoteDataSourceImpl } from 'data/DeviceType/DeviceTypeRemoteDataSourceImpl';
import { DomainRemoteDataSourceImpl } from 'data/Domain/DomainRemoteDataSourceImpl';
import { LanguageRemoteDataSourceImpl } from 'data/Language/LanguageRemoteDataSourceImpl';
import { PermissionRemoteDataSourceImpl } from 'data/Permission/PermissionRemoteDataSourceImpl';
import { ServerRemoteDataSourceImpl } from 'data/Server/ServerRemoteDataSourceImpl';
import { ServerTypeRemoteDataSourceImpl } from 'data/ServerType/ServerTypeRemoteDataSourceImpl';
import { ServiceRemoteDataSourceImpl } from 'data/Service/ServiceRemoteDataSourceImpl';
import { UserRemoteDataSourceImpl } from 'data/EyeviewUser/EyeviewUserRemoteDataSourceImpl';
import { CameraRemoteDataSourceImpl } from 'data/Camera/CameraRemoteDataSourceImpl';
import { IAccountRepository } from './Account/AccountRepository';
import { AccountRepositoryImpl } from './Account/AccountRepositoryImpl';
import { IAuthRepository } from './Auth/AuthRepository';
import { AuthRepositoryImpl } from './Auth/AuthRepositoryImpl';
import { ICameraGroupRepository } from './CameraGroup/CameraGroupRepository';
import { CameraGroupRepositoryImpl } from './CameraGroup/CameraGroupRepositoryImpl';
import { IDeviceRepository } from './Device/DeviceRepository';
import { DeviceRepositoryImpl } from './Device/DeviceRepositoryImpl';
import { IEOSSGlobalRepository } from './EOSSGlobal/EOSSGlobalRepository';
import { EOSSGlobalRepositoryImpl } from './EOSSGlobal/EOSSGlobalRepositoryImpl';
import { IServerRepository } from './Server/ServerRepository';
import { ServerRepositoryImpl } from './Server/ServerRepositoryImpl';
import { IUserRepository } from './EyeviewUser/EyeviewUserRepository';
import { UserRepositoryImpl } from './EyeviewUser/EyeviewUserRepositoryImpl';
import { CameraGroupTypeRemoteDataSourceImpl } from 'data/CameraGroupType/CameraGroupTypeRemoteDataSource';
import { IDomainRepository } from './Domain/DomainRepository';
import { DomainRepositoryImpl } from './Domain/DomainRepositoryImpl';
import { AdministratorRepositoryImpl } from './Administrator/AdministratorRepositoryImpl';
import { IAdministratorRepository } from './Administrator/AdministratorRepository';
import { IEyeviewSessionRepository } from './EyeviewSession/EyeviewSessionReposiotry';
import { EyeviewSessionRepositoryImpl } from './EyeviewSession/EyeviewSessionRepositoryImpl';
import { EyeviewSessionRemoteDataSourceImpl } from 'data/EyeviewSession/EyeviewSessionRemoteDataSourceImpl';
import { NameMappingRemoteDataSourceImpl } from 'data/NameMapping/NameMappingRemoteDataSourceImpl';
import { AdminTypeRemoteDataSourceImpl } from 'data/AdminType/AdminTypeRemoteDataSource';

const EyeviewUserRepository = (httpService: TAxiosClient): IUserRepository => {
  return UserRepositoryImpl.getInstance(
    UserRemoteDataSourceImpl.getInstance(httpService),
    AccountRemoteDataSourceImpl.getInstance(httpService),
    PermissionRemoteDataSourceImpl.getInstance(httpService),
    CameraGroupRemoteDataSourceImpl.getInstance(httpService)
  );
};

const DeviceRepository = (httpService: TAxiosClient): IDeviceRepository => {
  return DeviceRepositoryImpl.getInstance(
    DeviceRemoteDataSourceImpl.getInstance(httpService),
    AccountRemoteDataSourceImpl.getInstance(httpService),
    ServerRemoteDataSourceImpl.getInstance(httpService),
    CameraRemoteDataSourceImpl.getInstance(httpService),
    CameraGroupRemoteDataSourceImpl.getInstance(httpService)
  );
};

const DomainRepository = (httpService: TAxiosClient): IDomainRepository => {
  return DomainRepositoryImpl.getInstance(DomainRemoteDataSourceImpl.getInstance(httpService));
};

const CameraGroupRepository = (httpService: TAxiosClient): ICameraGroupRepository => {
  return CameraGroupRepositoryImpl.getInstance(
    CameraGroupRemoteDataSourceImpl.getInstance(httpService),
    AccountRemoteDataSourceImpl.getInstance(httpService),
    UserRemoteDataSourceImpl.getInstance(httpService),
    CameraGroupTypeRemoteDataSourceImpl.getInstance(httpService)
  );
};

const AccountRepository = (httpService: TAxiosClient): IAccountRepository => {
  return AccountRepositoryImpl.getInstance(
    DomainRemoteDataSourceImpl.getInstance(httpService),
    AccountRemoteDataSourceImpl.getInstance(httpService),
    DeviceRemoteDataSourceImpl.getInstance(httpService)
  );
};

const AuthRepository = (httpService: TAxiosClient): IAuthRepository => {
  return AuthRepositoryImpl.getInstance(
    AuthRemoteDataSourceImpl.getInstance(httpService),
    AuthLocalDataSourceImpl.getInstance()
  );
};

const ServerRepository = (httpService: TAxiosClient): IServerRepository => {
  return ServerRepositoryImpl.getInstance(ServerRemoteDataSourceImpl.getInstance(httpService));
};

const EOSSGlobalRepository = (httpService: TAxiosClient): IEOSSGlobalRepository => {
  return EOSSGlobalRepositoryImpl.getInstance(
    ServerTypeRemoteDataSourceImpl.getInstance(httpService),
    AccountTypeRemoteDataSourceImpl.getInstance(httpService),
    DeviceTypeRemoteDataSourceImpl.getInstance(httpService),
    LanguageRemoteDataSourceImpl.getInstance(httpService),
    PermissionRemoteDataSourceImpl.getInstance(httpService),
    ServiceRemoteDataSourceImpl.getInstance(httpService),
    CameraGroupTypeRemoteDataSourceImpl.getInstance(httpService),
    NameMappingRemoteDataSourceImpl.getInstance(httpService),
    AdminTypeRemoteDataSourceImpl.getInstance(httpService)
  );
};

const AdministratorRepository = (httpService: TAxiosClient): IAdministratorRepository => {
  return AdministratorRepositoryImpl.getInstance(
    AdministratorRemoteDataSourceImpl.getInstance(httpService),
    DomainRemoteDataSourceImpl.getInstance(httpService)
  );
};

const EyeviewSessionRepository = (httpService: TAxiosClient): IEyeviewSessionRepository => {
  return EyeviewSessionRepositoryImpl.getInstance(
    EyeviewSessionRemoteDataSourceImpl.getInstance(httpService),
    ServerRemoteDataSourceImpl.getInstance(httpService)
  );
};

export {
  AccountRepository,
  AdministratorRepository,
  AuthRepository,
  CameraGroupRepository,
  DeviceRepository,
  DomainRepository,
  EOSSGlobalRepository,
  ServerRepository,
  EyeviewUserRepository,
  EyeviewSessionRepository
};
