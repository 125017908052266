import React from 'react';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';

type Props = {
  isActive?: boolean;
};

function ArrowScrollLeftIcon({ isActive = false }: Props) {
  return (
    <MdOutlineKeyboardArrowLeft
      className={`scroll-icon hover-left ${isActive ? '' : 'scroll-icon-disable'} `}
      fontSize={36}
      style={{
        padding: '2px',
        borderRadius: '50%'
      }}
    />
  );
}

export { ArrowScrollLeftIcon };
