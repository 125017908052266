import React from 'react';

function MoreInfoIcon() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8946_21197)">
        <path
          d="M9.33464 2.83398C8.21388 2.83398 7.11829 3.16633 6.18641 3.78899C5.25453 4.41165 4.52822 5.29666 4.09932 6.33211C3.67042 7.36756 3.55821 8.50694 3.77685 9.60616C3.9955 10.7054 4.5352 11.7151 5.3277 12.5076C6.1202 13.3001 7.1299 13.8398 8.22913 14.0584C9.32835 14.2771 10.4677 14.1649 11.5032 13.736C12.5386 13.3071 13.4236 12.5808 14.0463 11.6489C14.669 10.717 15.0013 9.62141 15.0013 8.50065C15.0013 6.99776 14.4043 5.55642 13.3416 4.49371C12.2789 3.43101 10.8375 2.83398 9.33464 2.83398ZM8.39019 5.26593C8.39019 5.07914 8.44558 4.89654 8.54936 4.74122C8.65314 4.58591 8.80064 4.46486 8.97321 4.39338C9.14579 4.32189 9.33569 4.30319 9.51889 4.33963C9.70209 4.37607 9.87038 4.46602 10.0025 4.59811C10.1345 4.73019 10.2245 4.89847 10.2609 5.08168C10.2974 5.26488 10.2787 5.45478 10.2072 5.62735C10.1357 5.79993 10.0147 5.94743 9.85934 6.05121C9.70403 6.15498 9.52143 6.21037 9.33464 6.21037C9.2067 6.21678 9.07879 6.19709 8.9587 6.1525C8.8386 6.10792 8.72884 6.03936 8.63607 5.95102C8.54331 5.86267 8.46949 5.75638 8.4191 5.6386C8.36871 5.52083 8.34281 5.39403 8.34297 5.26593H8.39019ZM11.6957 11.334C11.6957 11.4592 11.646 11.5793 11.5574 11.6679C11.4689 11.7565 11.3488 11.8062 11.2235 11.8062H7.91797C7.79273 11.8062 7.67262 11.7565 7.58406 11.6679C7.4955 11.5793 7.44575 11.4592 7.44575 11.334C7.44575 11.2087 7.4955 11.0886 7.58406 11.0001C7.67262 10.9115 7.79273 10.8618 7.91797 10.8618H8.86242V8.02843H8.39019C8.26495 8.02843 8.14484 7.97868 8.05628 7.89012C7.96772 7.80156 7.91797 7.68145 7.91797 7.55621C7.91797 7.43097 7.96772 7.31085 8.05628 7.22229C8.14484 7.13374 8.26495 7.08398 8.39019 7.08398H10.2791V10.8618H11.2235C11.3488 10.8618 11.4689 10.9115 11.5574 11.0001C11.646 11.0886 11.6957 11.2087 11.6957 11.334Z"
          fill="#4A61E3"
        />
      </g>
      <defs>
        <clipPath id="clip0_8946_21197">
          <rect width="17" height="17" fill="white" transform="translate(0.833984)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export { MoreInfoIcon };
