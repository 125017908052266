import { DEFAULT_LIMIT, DEFAULT_PAGE_NUMBER } from 'constant';
import { useAppUtil } from 'context/UtilContext';
import type { TAdminUser, TPaginationEOSS, TSortOrder } from 'models';
import { ApiErrorResponse } from 'models/ApiError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { AdministratorRepository } from 'repositories';
import { AxiosClient } from 'services/axios';
import { handleApiError } from 'utils/common';

export const useAdminListingPageController = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('search');
  const sortKey = searchParams.get('sortKey');
  const page = searchParams.get('page');
  const order = searchParams.get('order') as TSortOrder;
  const administratorRepository = AdministratorRepository(AxiosClient);
  const { openNotification } = useAppUtil();
  const { refetchCount } = useOutletContext<{ refetchCount: number }>();
  const { t } = useTranslation();

  const [listAdminUsers, setListAdminUsers] = useState<{
    data: TAdminUser[];
    isLoading: boolean;
    paging: TPaginationEOSS;
  }>({
    data: [],
    isLoading: false,
    paging: {
      pageLimit: DEFAULT_LIMIT,
      pageNum: DEFAULT_PAGE_NUMBER,
      total: 0,
      totalPage: 0
    }
  });

  async function handleFetchList({ search }: { search: string }) {
    if (listAdminUsers.isLoading) return;

    try {
      setListAdminUsers((prev) => {
        return {
          ...prev,
          isLoading: true
        };
      });
      const data = await administratorRepository.getListAdminUsers({
        search
      });

      setListAdminUsers((prev) => {
        return {
          ...prev,
          data: data,
          isLoading: false,
          paging: {
            ...prev.paging,
            total: data.length,
            pageLimit: DEFAULT_LIMIT
          }
        };
      });
    } catch (error) {
      const message = handleApiError({
        apiErrorResponse: error as ApiErrorResponse,
        action: 'get',
        entity: t('adminUserPage.entity'),
        t
      });
      openNotification({
        type: 'error',
        title: `${t('actions.search')} ${t('adminUserPage.entity')}`,
        description: message
      });
    }
  }

  function refetchList() {
    if (!searchText) return;
    handleFetchList({ search: searchText });
  }

  useEffect(() => {
    if (refetchCount > 0) {
      refetchList();
    }
  }, [refetchCount]);

  useEffect(() => {
    if (searchText) {
      handleFetchList({ search: searchText });
    } else {
      setListAdminUsers({
        data: [],
        isLoading: false,
        paging: { pageLimit: DEFAULT_LIMIT, pageNum: DEFAULT_PAGE_NUMBER, total: 0, totalPage: 0 }
      });
    }
  }, [searchText]);

  useEffect(() => {
    if (page) {
      setListAdminUsers((prev) => {
        return {
          ...prev,
          paging: {
            ...prev.paging,
            pageNum: Number(page)
          }
        };
      });
    }
  }, [page]);

  return {
    listAdminUsers,
    sortKey,
    order,
    searchParams,
    searchText,
    page,
    setSearchParams
  };
};
